import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getPoiById, pointOfInterestSetBackOfficeDataDemo } from '../../../queryMutation';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-set-active-or-disative-paypal-btn',
  templateUrl: './set-active-or-disative-paypal-btn.component.html',
  styleUrls: ['./set-active-or-disative-paypal-btn.component.scss']
})
export class SetActiveOrDisativePaypalBtnComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  public querySubscriptionProfileUser: Subscription;
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router,public apollo: Apollo,public spinner: NgxSpinnerService) {

  
  }


  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  abilitaPaypalBtnPoi() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    var data = {
      paypalBtnActiveForSell:true,
      userId:localStorage.getItem("userLoggedId")
    }
    this.querySubscriptionProfileUser = this.apollo.use("base-user").mutate({
      mutation: pointOfInterestSetBackOfficeDataDemo.pointOfInterestSetBackOfficeDataDemo,
      variables: {
        'data': {
          'id':this.rowData.id,
          'backOfficeData':JSON.stringify(data)
       
        }
      },
    }).subscribe((result: any) => {
      if (result.data['pointOfInterestSetBackOfficeDataDemo']['success']) {
        setTimeout(() => {

          this.spinner.hide();
          this.router.navigateByUrl("/pages/pointOfInterest/list");
        }, 3000);

      }
    }, (err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {

        this.spinner.hide();

      }, 2000);
    })
  }
  disabilitaPaypalBtnPoi() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    var data = {
     paypalBtnActiveForSell:false,
     userId:localStorage.getItem("userLoggedId")
    }
    this.querySubscriptionProfileUser = this.apollo.use("base-user").mutate({
      mutation: pointOfInterestSetBackOfficeDataDemo.pointOfInterestSetBackOfficeDataDemo,
      variables: {
        'data': {
          'id':this.rowData.id,
          'backOfficeData':JSON.stringify(data)
       
        }
      },
    }).subscribe((result: any) => {
      if (result.data['pointOfInterestSetBackOfficeDataDemo']['success']) {
        setTimeout(() => {

          this.spinner.hide();
          this.router.navigateByUrl("/pages/pointOfInterest/list");
        }, 3000);

      }
    }, (err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {

        this.spinner.hide();

      }, 2000);
    })
  }
}

