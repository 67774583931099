import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogRef } from '@nebular/theme';
import { featureTranslationUpdate, featureTranslationCreate, featureTranslationDelete } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-modal-translation-feature-it',
  templateUrl: './modal-translation-feature-it.component.html',
  styleUrls: ['./modal-translation-feature-it.component.scss']
})
export class ModalTranslationFeatureItComponent {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() categoryId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  
  @Input() languageId:any;

  @Output("addFeatureTrans") addFeatureTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editFeatureTrans") editFeatureTrans: EventEmitter<any> = new EventEmitter();
  @Output("deleteFeatureTrans") deleteFeatureTrans: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<ModalTranslationFeatureItComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: featureTranslationDelete.featureTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['featureTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deleteFeatureTrans.emit({ 
            'id':parseInt(this.translationId)
          });

        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
         
        this.spinner.hide();
        
      }, 2000);
     })
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: featureTranslationCreate.featureTranslationCreate,
        variables: {
          data:{
            featureId:parseInt(this.categoryId),
            languageId: parseInt(this.languageId),
            name:this.translationName
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['featureTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addFeatureTrans.emit({ 
              'languageId':this.languageId,
              'name':this.translationName,
              'featureId':parseInt(this.categoryId),
              'id':result.data['featureTranslationCreate']['id']
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
           
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: featureTranslationUpdate.featureTranslationUpdate,
        variables: {
          data:{
            featureId:parseInt(this.categoryId),
            languageId: parseInt(this.languageId),
            name:this.translationName,
            id:this.translationId
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['featureTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.editFeatureTrans.emit({ 
              'languageId':this.languageId,
              'name':this.translationName,
              'featureId':parseInt(this.categoryId),
              'id':this.translationId
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
           
          this.spinner.hide();
          
        }, 2000);
       })
    } 

}
