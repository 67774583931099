import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, seoCodeUpdate, seoCodeDelete, seoCodeCreate, municipalityGet, seoTagDelete, seoTagCreate, seoTagUpdate } from '../../../queryMutation';
import { CookieXSRFStrategy } from '@angular/http';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';

@Component({
  selector: 'ngx-open-modal-seo-tag-categories',
  templateUrl: './open-modal-seo-tag-categories.component.html',
  styleUrls: ['./open-modal-seo-tag-categories.component.scss']
})
export class OpenModalSeoTagCategoriesComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() description:string;
  @Input() seoCodeId:any;
  @Input() seoTagName:any[];
  @Input() languageId:any;
  @Input() newsCategoryId:any;
  @Input() seoTagTitle:string;

  @Input() noFollow:boolean;
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Output("addCategorySeoTag") addCategorySeoTag: EventEmitter<any> = new EventEmitter();
    
  @Output("editCategorySeoTag") editCategorySeoTag: EventEmitter<any> = new EventEmitter();
  @Output("deleteCategorySeoTag") deleteCategorySeoTag: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<OpenModalSeoTagCategoriesComponent>,public apollo:Apollo) { 
this.seoTagName = [];
  }

  ngOnInit() {
    this.seoTagName.push("");
  }
  
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent(){
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: seoTagDelete.seoTagDelete,
      variables: {
        'id':parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if( result.data['seoTagDelete']['success']){
        this.deleteCategorySeoTag.emit({ 
          'id':parseInt(this.seoCodeId)
        })
      }
     });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
     event.confirm.reject();
    }
  }
  addInput(){


    this.seoTagName.push("");

    console.log(this.seoTagName);
  }
  trackByFn(index: any, item: any) {
    return index;
 }
  Salva() {
 
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagCreate.seoTagCreate,
        variables: {
          data:{
            'languageId':this.languageId,
            'openGraphTags':[""],
            'title':this.seoTagTitle,
            'description':this.description,
            'newsCategoryId':parseInt(this.newsCategoryId),
            'noFollow':this.noFollow
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoTagCreate']['success']){
          this.addCategorySeoTag.emit({ 
            'languageId':this.languageId,
            'openGraphTags':[""],
            'title':this.seoTagTitle,
            'description':this.description,
            'newsCategoryId':parseInt(this.newsCategoryId),
            'id':result.data['seoTagCreate']['id'],
            'noFollow':this.noFollow
          });
        }
       });
    } 
    Edit(item) {
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagUpdate.seoTagUpdate,
        variables: {
          data:{
            'languageId':this.languageId,
            'openGraphTags':[""],
            'title':this.seoTagTitle,
            'description':this.description,
            'newsCategoryId':parseInt(this.newsCategoryId),
            'id':parseInt(this.seoCodeId),
            'noFollow':this.noFollow
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoTagUpdate']['success']){
          this.editCategorySeoTag.emit({ 
            'languageId':this.languageId,
            'openGraphTags':[""],
            'title':this.seoTagTitle,
            'description':this.description,
            'newsCategoryId':parseInt(this.newsCategoryId),
            'id':parseInt(this.seoCodeId),
            'noFollow':this.noFollow
          });
        }
       });
    } 
   }


