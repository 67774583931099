import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { CostExplorer } from 'aws-sdk';
@Component({
  selector: 'ngx-news-translation',
  templateUrl: './news-translation.component.html',
  styleUrls: ['./news-translation.component.scss']
})
export class NewsTranslationComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  translations: any;
  constructor(public dialogService:NbDialogService, public router:Router) {


  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.newsUnitGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.newsUnitGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.newsUnitGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal(languageId) {
    
    localStorage.removeItem("isFromNewsUnit");
    localStorage.removeItem("newsUnitId");
    localStorage.removeItem("newsId");
    localStorage.removeItem("languageId");

    if(this.rowData.newsUnitGetAllSub.length > 0){
      for (let index = 0; index < this.rowData.newsUnitGetAllSub.length; index++) {
        var data = this.rowData.newsUnitGetAllSub.filter(item => item.languageId ==languageId);
           console.log(data);
          if((this.rowData.newsUnitGetAllSub.findIndex(item => item.languageId ==languageId)) == -1){
          localStorage.setItem("isFromNewsUnit","false");
          localStorage.setItem("newsId",this.rowData.id);
          localStorage.setItem("languageId",languageId);
          this.router.navigateByUrl("/pages/articoli/editArticoli");
        }
        else{
          localStorage.setItem("isFromNewsUnit","true");
          localStorage.setItem("newsUnitId",data[0].id);
          localStorage.setItem("newsId",this.rowData.id);
          localStorage.setItem("languageId",languageId);
         this.router.navigateByUrl("/pages/articoli/editArticoli");
        }
      }
    }else{
      localStorage.setItem("languageId",languageId);
      this.router.navigateByUrl("/pages/articoli/create");
    }
  }

}
