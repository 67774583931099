import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService,private router:Router) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
   if(token === undefined || token ==null){
    console.log(`isAuthenticated ${token} if = false`);
    return false
   }
   else{
      if(!this.jwtHelper.isTokenExpired(token)){
        console.log(`isAuthenticated ${token} else.if = true`);
        return true;
      }
      else{
        console.log(`isAuthenticated ${token} else.else = false`);
        return false;
      }
   }


  }
}
