import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { getPoiById, newsGetByid, newsUnitGet } from '../../../queryMutation';

@Component({
  selector: 'ngx-clone-news',
  templateUrl: './clone-news.component.html',
  styleUrls: ['./clone-news.component.scss']
})
export class CloneNewsComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;
  public querySubscriptionProfileUser: Subscription;
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router,public apollo: Apollo) {

  
  }


  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }
  async newsGetbyID() {

    this.querySubscriptionProfileUser = this.apollo.watchQuery<Response>({
      query: newsGetByid.GetNew,
      fetchPolicy: 'network-only',
      variables: {
        'id': this.rowData.id
      },
    })
      .valueChanges.subscribe((result: any) => {
        localStorage.setItem("clonedNews",JSON.stringify(result.data));
        this.router.navigateByUrl("/pages/articoli/create");

     
      });
  }



  async openCreateNews() {
    
    if(this.rowData.id > 0){
      console.log(this.rowData.id);
       await this.newsGetbyID();
        
         
  }
  }

}
