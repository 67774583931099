import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryRef, Apollo } from 'apollo-angular';
import { NbDialogRef } from '@nebular/theme';
import { seoTagDelete, seoTagCreate, seoTagUpdate, poiFindHintCreate, poiFindHintUpdate, poiFindHintDelete, poiFindHintCreateMany } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
  selector: 'ngx-open-modal-poi-find-hint',
  templateUrl: './open-modal-poi-find-hint.component.html',
  styleUrls: ['./open-modal-poi-find-hint.component.scss']
})
export class OpenModalPoiFindHintComponent implements OnInit {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;

  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories: any[];
  public newsCategoryTranslationGetAll: any[];
  @Input() title: string;
  @Input() keyWord: string;
  @Input() pointOfInterestId: any;
  @Input() id: any;
  @Input() seoTagName: any[];
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Output("addPoiFindHint") addPoiFindHint: EventEmitter<any> = new EventEmitter();

  @Output("editPoiFindHint") editPoiFindHint: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiFindHint") deletePoiFindHint: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<OpenModalPoiFindHintComponent>, public apollo: Apollo) {
    this.seoTagName = []
  }

  ngOnInit() {
    if (this.seoTagName.length < 1)
      this.seoTagName.push("");
  }
  addInput() {
    this.seoTagName.push("");

  }

  dismiss() {
    this.ref.close();
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  poiFindHintDeleteEvent() {
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiFindHintDelete.poiFindHintDelete,
      variables: {
        'id': parseInt(this.id)
      }
    }).subscribe((result: any) => {
      if (result.data['poiFindHintDelete']['success']) {
        this.deletePoiFindHint.emit({
          'id': this.id,
        });
      }
    });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questa KeyWord?')) {

      this.poiFindHintDeleteEvent();
    } else {
      event.confirm.reject();
    }
  }

  Salva() {
    var tasks$ = [];
    
    //fare create many
    for (let index = 0; index < this.seoTagName.length; index++) {
      tasks$.push({
        'pointOfInterestId':this.pointOfInterestId,
        'keyWord': this.seoTagName[index]
       
      })
    }

    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiFindHintCreateMany.poiFindHintCreateMany,
      variables: {
        data: tasks$
      },
    }).subscribe((result: any) => {

      console.log(result);
        for (let index = 0; index < this.seoTagName.length; index++) {
          this.addPoiFindHint.emit({
            'pointOfInterestId': this.pointOfInterestId,
            'keyWord': this.seoTagName[index],
            'id': result.data['poiFindHintCreateMany'][index]['id'],

          });
      }
    });
  }
  Edit(item) {
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiFindHintUpdate.poiFindHintUpdate,
      variables: {
        data: {
          'pointOfInterestId': parseInt(item.pointOfInterestId),
          'keyWord': item.keyWord,
          'id': parseInt(item.id)

        }
      },
    }).subscribe((result: any) => {
      if (result.data['poiFindHintUpdate']['success']) {
        this.editPoiFindHint.emit({
          'pointOfInterestId': parseInt(item.pointOfInterestId),
          'keyWord': item.keyWord,
          'id': parseInt(item.id)
        });
      }
    });
  }

}
