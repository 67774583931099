
import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { JourneyAdminSelectedOpenModalTranslationComponent } from '../journey-admin-selected-open-modal-translation/journey-admin-selected-open-modal-translation.component';


import { Router } from '@angular/router';
@Component({
  selector: 'ngx-journey-admin-selected-open-translation',
  templateUrl: './journey-admin-selected-open-translation.component.html',
  styleUrls: ['./journey-admin-selected-open-translation.component.scss']
})
export class JourneyAdminSelectedOpenTranslationComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveJourneyAdminTrans: EventEmitter<any> = new EventEmitter();
  translationIt="";
  data:any;
  translations: any;

  public indexSelected:any;
  constructor(public dialogService:NbDialogService,public router:Router) {

    
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.journeyAdminSelectedTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.journeyAdminSelectedTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.journeyAdminSelectedTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.saveJourneyAdminTrans.emit(this.rowData);
  }

  openEditModal(languageId) {
    
    if(this.rowData.journeyAdminSelectedTranslationGetAllSub.length > 0){

       this.indexSelected = this.rowData.journeyAdminSelectedTranslationGetAllSub.findIndex(item => item.languageId == languageId);
        this.data = this.rowData.journeyAdminSelectedTranslationGetAllSub.filter(item => item.languageId == languageId);
        console.log("journeyAdminSelectedTranslationGetAllSub", this.data)
        if(this.data.length > 0){
          console.log(this.data[0].id);
             const ref = this.dialogService.open(JourneyAdminSelectedOpenModalTranslationComponent, {
               context: {
                title: 'Edit Translation',
                categoryId:this.rowData.id,
                translationName : this.data[0].name,
                translationId: this.data[0].id,
                languageId:languageId,
                description:this.data[0].description,
                limitations: this.data[0].limitations == null ? "" : this.data[0].limitations,
                facilitations: this.data[0].facilitations == null ? "" : this.data[0].facilitations,
                preview: this.data[0].preview,
               },
             });

             ref.componentRef.instance["editJourneyAdminTrans"].subscribe((data) => {
              ref.close();
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].id = data.id;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].imageId = data.imageId;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].name = data.name;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].description = data.description;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].facilitations = data.facilitations == null ? "" : data.facilitations;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].limitations = data.limitations == null ? "" : data.limitations;
              this.rowData.journeyAdminSelectedTranslationGetAllSub[this.indexSelected].preview = data.preview;
              this.saveJourneyAdminTrans.emit(this.rowData);
            });
         
          ref.componentRef.instance["deleteJourneyAdminTrans"].subscribe((data) => {
            ref.close();
            const indexToRemove =  this.rowData.journeyAdminSelectedTranslationGetAllSub.findIndex(res => res.id == data.id);
            this.rowData.journeyAdminSelectedTranslationGetAllSub.splice(indexToRemove,1);
            this.saveJourneyAdminTrans.emit(this.rowData);
          });

           return;
        }
        else{
          const ref =  this.dialogService.open(JourneyAdminSelectedOpenModalTranslationComponent, {
            context: {
              title: 'Create Translation',
              categoryId:this.rowData.id,
              translationName : "",
              translationId: null,
             languageId:languageId,
             description:"",
             limitations: "",
             facilitations: "",
             preview: ""
            },
          });

          ref.componentRef.instance["addJourneyAdminTrans"].subscribe((data) => {
            ref.close();
          
            this.rowData.journeyAdminSelectedTranslationGetAllSub.push(data);
            this.saveJourneyAdminTrans.emit(this.rowData);
          });
          return;
        }
      
    }
    else{
      const ref = this.dialogService.open(JourneyAdminSelectedOpenModalTranslationComponent, {
        context: {
          title: 'Create Translation',
          categoryId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          description:"",
          limitations: "",
          facilitations: "",
          preview: ""
        },
      });

      ref.componentRef.instance["addJourneyAdminTrans"].subscribe((data) => {
        ref.close();
        this.rowData.journeyAdminSelectedTranslationGetAllSub.push(data);
        this.saveJourneyAdminTrans.emit(this.rowData);
      });
      return;
    }
  }

}
