import { Component, OnInit, Compiler } from '@angular/core';
import { municipalityGet, newsGetAll, newsDelete, pointOfInterestGetAll, pointOfInterestDelete, pointOfInterestAdminSelectedGetAll, pointOfInterestAdminSelectedDelete, pointOfInterestAdminSelectedUpdate, poiAdminSelectedGetAll } from '../../../queryMutation';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { LocalDataSource } from 'ng2-smart-table';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { Subscription } from 'rxjs/internal/Subscription';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OpenTranslationPoiComponent } from '../open-translation-poi/open-translation-poi.component';
import { OpenUpdatePointOfInterestComponent } from '../open-update-point-of-interest/open-update-point-of-interest.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenSeoCodePoiComponent } from '../open-seo-code-poi/open-seo-code-poi.component';
import { PoiAdminSelectedOpenActionComponent } from '../poi-admin-selected-open-action/poi-admin-selected-open-action.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpenPoiAdminImageUploadActionComponent } from '../open-poi-admin-image-upload-action/open-poi-admin-image-upload-action.component';
import { OpenPoiCoverImageUploadActionComponent } from '../open-poi-cover-image-upload-action/open-poi-cover-image-upload-action.component';
import { OpenAdminPoiCoverImageUploadActionComponent } from '../open-admin-poi-cover-image-upload-action/open-admin-poi-cover-image-upload-action.component';
import { bool } from 'aws-sdk/clients/signer';

@Component({
  selector: 'ngx-poi-admin-selected-list',
  templateUrl: './poi-admin-selected-list.component.html',
  styleUrls: ['./poi-admin-selected-list.component.scss']
})
export class PoiAdminSelectedListComponent implements OnInit {

 public doNotAddPoiAdminSelected:boolean= false;
  public queryGetAllAdminSelected : any;
  public querySubscriptionAllProvince: Subscription;
  public pointOfInterestAdminSelectedGetAll:any[];
  public poiAdminSelectedPagination:QueryRef<any>;

  newsData:any;
  limit:number=25;
  datauri:any;
  myFile:Blob;
  profile:any;
  fileToUpload:File;
  response:any;
  selectedProduct:any;
  lastId:null;
  
  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo:Apollo,public _compiler: Compiler,public service: SmartTableService,public router:Router,public datePipe: DatePipe,public _sanitizer:DomSanitizer,public spinner:NgxSpinnerService) {
 
    this.pointOfInterestAdminSelectedGetAll = [];
   }

  async ngOnInit() {
    this.lastId = null;
    this._compiler.clearCache();
    this.source.reset();
    this.pointOfInterestAdminSelectedGetAll=[];
    
   await this.getAllPontoDiIntereseAdminSelected();
  
  }
  onRowSelect(event) {
    for (let index = 0; index < event.selected.length; index++) {
    event.selected[index].isSelected = true;
    event.data.isSelected = true;
    }
  
    }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit:false
      },
    delete: {
      deleteButtonContent: "<img src='assets/images/deleteTable.png'>",
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      dollarSigns: {
        title: 'dollarSigns',
        type: 'html',
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { 
          if(row.dollarSigns != null){
           return row.dollarSigns;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        }
      },
      position: 
      {
        title: 'position',
        type: 'string',
        filter: false,
        editable: true,
        addable: false
      },
      /*
      code: {
        title: 'Travel Tag Code',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { 
          
          if(row.pointOfInterestAdminSelectedTravelTagGetAllSub.length > 0){
            if (row.pointOfInterestAdminSelectedTravelTagGetAllSub[0].travelTagGetSub != null) {

              row.code = row.pointOfInterestAdminSelectedTravelTagGetAllSub[0].travelTagGetSub.code;
              return row.code;
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
       
      },*/
      pointOfInterestName: {
        title: 'PointOfInterest Name ',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { 
          var data = row.pointOfInterestGetSub.poiTranslationGetAllSub.filter(item => item.languageId == 1);
         
          if (data.length > 0) {
            if( data[0].name !== null){
              row.pointOfInterestName = data[0].name;
              return this._sanitizer.bypassSecurityTrustHtml(row.pointOfInterestName);
            }
            else{
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      },
      Actions: {
        title: 'Update Action',
        type: 'custom',
        renderComponent: PoiAdminSelectedOpenActionComponent,
        onComponentInitFunction: (instance: any) => {
            instance.save.subscribe(row => {
               console.log(row);
            });
        },
        filter: false,
        editable: false,
        addable: false
    },
    UploadImage: {
      title: 'Upload Image',
      type: 'custom',
      renderComponent: OpenPoiAdminImageUploadActionComponent,
      onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
             console.log(row);
          });
      },
      filter: false,
      editable: false,
      addable: false
  },    UploadCoverImage: {
    title: 'Upload Cover Image',
    type: 'custom',
    renderComponent: OpenAdminPoiCoverImageUploadActionComponent,
    onComponentInitFunction: (instance: any) => {
        instance.save.subscribe(row => {
           console.log(row);
        });
    },
    filter: false,
    editable: false,
    addable: false
},
    },
  };

  PointOfInterestingAdminSelectedDelete(idToDelete){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.queryGetAllAdminSelected = this.apollo.use('super-admin').mutate({
      mutation: pointOfInterestAdminSelectedDelete.pointOfInterestAdminSelectedDelete,
      variables: {
        'id': idToDelete
    },
    }).subscribe((result: any) => { 
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        //this.source.reset();
        //this.pointOfInterestAdminSelectedGetAll=[];
        //this.getAllPoiAdminSelected();
        this.ngOnInit();
      }, 2000);
     
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  
}
onEditConfirm(event: any) {
  
  this.newsCategoryEdit(event.data);

  }
newsCategoryEdit(updateCategoryObj){

  console.log(updateCategoryObj);
  this.spinner.show(undefined,
    {
      type: 'square-spin',
      size: 'medium',
      bdColor: 'rgba(100,149,237, .8)',
      color: 'white',
      fullScreen: true
    }
  );
   this.querySubscriptionAllProvince = this.apollo.use("super-admin").mutate({
    mutation: pointOfInterestAdminSelectedUpdate.pointOfInterestAdminSelectedUpdate,
    fetchPolicy:'no-cache',
    variables: {
      'data': {
        'id': parseInt(updateCategoryObj.id),
        'dollarSigns':updateCategoryObj.dollarSigns,
        'position':parseInt(updateCategoryObj.position)
      }
    },
  }).subscribe((result: any) => {
    if (result.data['pointOfInterestAdminSelectedUpdate']['success']) {
      this.doNotAddPoiAdminSelected = false;
      setTimeout(() => {
     
        this.spinner.hide();
        
      }, 2000);
    }
  },(err) => {
    alert("An error occurred.Try again later.");
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
      
    }, 2000);
   })
}
createPoiAdminSelected(){
  if(this.doNotAddPoiAdminSelected == true){
    alert("Non si poi creare nessuno poi adminselected aggiorna primo la position");
  }
  else{
    this.router.navigateByUrl("/pages/pointOfInterest/adminSelected-create");
  }
  
}
  onDeleteConfirm(event): void {
    if (window.confirm('Do you want to Delete this row?')) {
    this.PointOfInterestingAdminSelectedDelete(event.data.id);
    } else {
      event.confirm.reject();
    }
  }
  async getAllPontoDiIntereseAdminSelected(){
    
      this.apollo.watchQuery<Response>({
        query: poiAdminSelectedGetAll.poiAdminSelectedGetAll,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
          },
          'limit': 25,
          'idCursor': this.lastId
        },
      }).valueChanges.subscribe((data:any) =>{
        this.lastId = data.data.pointOfInterestAdminSelectedGetAll.length > 0 ? ((data.data['pointOfInterestAdminSelectedGetAll'][data.data['pointOfInterestAdminSelectedGetAll'].length - 1]['id'])) : null;
        
        var newData = data.data.pointOfInterestAdminSelectedGetAll.filter(item => item.position == -1);

        console.log(`verifico se posso aggiungere altro`);
        this.doNotAddPoiAdminSelected = newData.length > 0;
        console.log(`doNotAddPoiAdminSelected -> ${this.doNotAddPoiAdminSelected}`);
        /*if(newData.length > 0){
          this.doNotAddPoiAdminSelected = true;
         }*/
         console.log(`getAllPontoDiIntereseAdminSelected -> data.data.pointOfInterestAdminSelectedGetAll`)
         console.log(data.data.pointOfInterestAdminSelectedGetAll);
        this.source.load(data.data.pointOfInterestAdminSelectedGetAll);
    });
    
  }

  async getAllPoiAdminSelected(){
      this.poiAdminSelectedPagination = this.apollo.watchQuery<Response>({
        query: this.queryGetAllAdminSelected,
        fetchPolicy:'network-only',
        variables: {
          'data': {
          },
        },
      });
  
      this.querySubscriptionAllProvince = this.poiAdminSelectedPagination.valueChanges.subscribe((data: any) => {
  
        const lastId = data.data.pointOfInterestAdminSelectedGetAll.length > 0 ? ((data.data['pointOfInterestAdminSelectedGetAll'][data.data['pointOfInterestAdminSelectedGetAll'].length - 1]['id'])) : null;
        this.queryGetAllAdminSelected = new pointOfInterestAdminSelectedGetAll({}, this.limit, lastId).getQuery();
        this.poiAdminSelectedPagination.fetchMore({
          query: this.queryGetAllAdminSelected,
          variables: {
            'data': {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
  
            if (fetchMoreResult['pointOfInterestAdminSelectedGetAll'].length < 1) {
              this.pointOfInterestAdminSelectedGetAll.push(...prev['pointOfInterestAdminSelectedGetAll']);
              this.pointOfInterestAdminSelectedGetAll = this.pointOfInterestAdminSelectedGetAll.map((item) => {
                return item;
              })
      
              var data = this.pointOfInterestAdminSelectedGetAll.filter(item => item.position == -1);
              
              console.log(`verifico se posso aggiungere altro`);
              this.doNotAddPoiAdminSelected = data.length > 0;
              console.log(`doNotAddPoiAdminSelected -> ${this.doNotAddPoiAdminSelected}`);
              /*if(data.length > 0){
                this.doNotAddPoiAdminSelected = true;
              }*/
               this.doNotAddPoiAdminSelected = data.length > 0;
              this.source.load(this.pointOfInterestAdminSelectedGetAll);
           
              return prev;
            } else {
              return Object.assign({}, prev, {
               
                pointOfInterestAdminSelectedGetAll: [...prev['pointOfInterestAdminSelectedGetAll'], ...fetchMoreResult['pointOfInterestAdminSelectedGetAll']]
              });
             
            }
          }
        });
      });
  }


}
