import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OpenModalSeoTagPoiComponent } from '../open-modal-seo-tag-poi/open-modal-seo-tag-poi.component';
import { OpenModalPoiFindHintComponent } from '../open-modal-poi-find-hint/open-modal-poi-find-hint.component';

@Component({
  selector: 'ngx-open-poi-find-hint',
  templateUrl: './open-poi-find-hint.component.html',
  styleUrls: ['./open-poi-find-hint.component.scss']
})
export class OpenPoiFindHintComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  public data:any;
  ddlLanguage={}
  @Output() savePoiFindHint: EventEmitter<any> = new EventEmitter();
  public indexSelected:any;
  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.savePoiFindHint.emit(this.rowData);
  }
  openModal() {
    
    this.indexSelected =  this.rowData.poiFindHintGetAllSub.findIndex(item => item.pointOfInterestId == this.rowData.id);
    this.data = this.rowData.poiFindHintGetAllSub.filter(item => item.pointOfInterestId == this.rowData.id);

    if(this.data.length > 0){
     
         const ref =  this.dialogService.open(OpenModalPoiFindHintComponent, {
           context: {
            title: 'Edit PoiHint',
            id:this.data[0].id,
            pointOfInterestId:this.data[0].pointOfInterestId,
            keyWord:this.data[0].keyWord,
            seoTagName:this.data
           
           },
         });
         ref.componentRef.instance["editPoiFindHint"].subscribe((data) => {
          ref.close();
          
          this.indexSelected =  this.rowData.poiFindHintGetAllSub.findIndex(item => item.id == data.id);

          this.rowData.poiFindHintGetAllSub[this.indexSelected].pointOfInterestId = data.pointOfInterestId;
          this.rowData.poiFindHintGetAllSub[this.indexSelected].id = data.id;
          this.rowData.poiFindHintGetAllSub[this.indexSelected].keyWord = data.keyWord;
        

          this.savePoiFindHint.emit(this.rowData);
        });
        ref.componentRef.instance["deletePoiFindHint"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.poiFindHintGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.poiFindHintGetAllSub.splice(indexToRemove,1);
        
        this.savePoiFindHint.emit(this.rowData);
  
        });


         return;
       
    }
    else{
      const ref =  this.dialogService.open(OpenModalPoiFindHintComponent, {
        context: {
          title: 'Create Poi Hint',
          id:null,
          pointOfInterestId:this.rowData.id,
          keyWord:"",
          seoTagName:[]
        },
      });

      ref.componentRef.instance["addPoiFindHint"].subscribe((data) => {
        ref.close();
        this.rowData.poiFindHintGetAllSub.push(data);
        
        this.savePoiFindHint.emit(this.rowData);
        });
     return;
    }
  }

}
