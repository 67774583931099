
import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModelEditArticoliComponent } from '../model-edit-articoli/model-edit-articoli.component';
import { Route } from 'aws-sdk/clients/apigatewayv2';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-open-dialog',
  templateUrl: './open-dialog.component.html',
  styleUrls: ['./open-dialog.component.scss']
})

export class OpenDialogComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router) {

  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal() {

    localStorage.removeItem("isFromNewsUnit");
    localStorage.removeItem("newsUnitId");
    localStorage.removeItem("newsId");
    localStorage.removeItem("languageId");
  
    if(this.rowData.id > 0){
      
      if(this.rowData.newsUnitGetAllSub.length  > 0){
        var data = this.rowData.newsUnitGetAllSub.filter(item => item.languageId ==1);
        if(data.length > 0){
          localStorage.setItem("isFromNewsUnit","true");
          localStorage.setItem("newsUnitId",data[0].id);
          localStorage.setItem("newsId",this.rowData.id);
          localStorage.setItem("languageId",data[0].languageId);
         this.router.navigateByUrl("/pages/articoli/editArticoli");
        }
      }
  }
  }
}

