import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { seoCodeCreate, seoCodeDelete, seoCodeUpdate, seoCodeGetByUniqueCode, seoCodeGet } from '../../../queryMutation';

@Component({
  selector: 'ngx-modal-open-category-seo-code',
  templateUrl: './modal-open-category-seo-code.component.html',
  styleUrls: ['./modal-open-category-seo-code.component.scss']
})
export class ModalOpenCategorySeoCodeComponent {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionGetIdbyCode: Subscription;
  public querySubscriptionEditMunicipality: Subscription;
  public querySubscriptionCreateMunicipality: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories: any[];
  public newsCategoryTranslationGetAll: any[];
  public dataToRedirect: any;
  public isActiveToSend: boolean = false;


  @Input() title: string;
  @Input() seoCodeId: string;
  @Input() seoCodeName: string;
  @Input() languageId: string;
  @Input() newsCategoryId: string;
  @Input() redirectStatusCode: any;
  @Input() staticRedirectUrl: string;
  @Input() redirectToId: string;

  @Output("addCategorySeoCode") addCategorySeoCode: EventEmitter<any> = new EventEmitter();

  @Output("editCategorySeoCode") editCategorySeoCode: EventEmitter<any> = new EventEmitter();
  @Output("deleteCategorySeoCode") deleteCategorySeoCode: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<ModalOpenCategorySeoCodeComponent>, public apollo: Apollo) {
    this.dataToRedirect = []
    this.isActiveToSend = false;
  }

  async ngOnInit() {

   
    if(this.redirectToId !== null && this.redirectToId !== '' &&  typeof(this.redirectToId) === 'number')
      await this.getRedirectId();

  }

  async getRedirectId() {
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGet.seoCodeGet,
      fetchPolicy: 'network-only',
      variables: {
        'id': parseInt(this.redirectToId)
      },
    })
      .valueChanges.subscribe((result: any) => {

        this.redirectToId = result.data.seoCodeGet.code;
      });

  }

  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent() {
    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeDelete.seoCodeDelete,
      variables: {
        'id': parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if (result.data['seoCodeDelete']['success']) {
        this.deleteCategorySeoCode.emit({
          'id': parseInt(this.seoCodeId)
        })
      }
    });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
      event.confirm.reject();
    }
  }
  getBySeoCode() {
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGetByUniqueCode.seoCodeGetByUniqueCode,
      fetchPolicy: 'network-only',
      variables: {
        'code': this.redirectToId
      },
    }).valueChanges.subscribe((data: any) => {
      if (data.data.seoCodeGetByUniqueCode !== null) {
        this.isActiveToSend = true;
        this.dataToRedirect.push(data.data.seoCodeGetByUniqueCode)
      }
      else {
        this.isActiveToSend = false;
        alert("No esiste seoCode, prova con altro.")
      }
    })

  }
  Salva() {

    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeCreate.seoCodeCreate,
      variables: {
        data: {
          'newsCategoryId': parseInt(this.newsCategoryId),
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        }
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeCreate']['success']) {
        this.addCategorySeoCode.emit({
          'newsCategoryId': parseInt(this.newsCategoryId),
          'languageId': this.languageId,
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': result.data['seoCodeCreate']['id'],
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        });
      }
    });
  }
  Edit() {
    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeUpdate.seoCodeUpdate,
      variables: {
        data: {

          'newsCategoryId': parseInt(this.newsCategoryId),
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'id': parseInt(this.seoCodeId),
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        }
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeUpdate']['success']) {
        this.editCategorySeoCode.emit({
          'newsCategoryId': parseInt(this.newsCategoryId),
          'languageId': this.languageId,
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': parseInt(this.seoCodeId),
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        });
      }
    });
  }
}


