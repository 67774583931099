import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalTranslationPoiCategoryItComponent } from '../../modal-translation-poi-category-it/modal-translation-poi-category-it.component';

@Component({
  selector: 'ngx-traduzione',
  templateUrl: './traduzione.component.html',
  styleUrls: ['./traduzione.component.scss']
})
export class TraduzioneCategoryComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  @Output() savePoiCatTrans: EventEmitter<any> = new EventEmitter();
  translationIt="";
  public data:any[];
  public indexSelected:any;
  translations: any;

  constructor(public dialogService:NbDialogService ) {

   this.data =[];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.travelCategoryTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.travelCategoryTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.travelCategoryTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.savePoiCatTrans.emit(this.rowData);
  }
  openEditModal(languageId) {
    
    this.indexSelected = this.rowData.travelCategoryTranslationGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.travelCategoryTranslationGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          const ref = this.dialogService.open(ModalTranslationPoiCategoryItComponent, {
           context: {
            title: 'Edit Translation',
            categoryId:this.rowData.id,
            translationName : this.data[0].name,
            translationId: this.data[0].id,
            languageId:languageId,
           

           },
         });

         ref.componentRef.instance["editPoiCatTrans"].subscribe((data) => {
          ref.close();
          this.rowData.travelCategoryTranslationGetAllSub[this.indexSelected].id = data.id;
          this.rowData.travelCategoryTranslationGetAllSub[this.indexSelected].travelCategoryId = data.travelCategoryId;
          this.rowData.travelCategoryTranslationGetAllSub[this.indexSelected].name = data.name;
          this.rowData.travelCategoryTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
      
          this.savePoiCatTrans.emit(this.rowData);
        });
     
      ref.componentRef.instance["deletePoiCatTrans"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.travelCategoryTranslationGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.travelCategoryTranslationGetAllSub.splice(indexToRemove,1);
        this.savePoiCatTrans.emit(this.rowData);
      });
         return;
       
    }
    else{
      const ref = this.dialogService.open(ModalTranslationPoiCategoryItComponent, {
        context: {
          title: 'Insert Translation',
          categoryId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          
        },
      });

      ref.componentRef.instance["addPoiCatTrans"].subscribe((data) => {
        ref.close();
     
        this.rowData.travelCategoryTranslationGetAllSub.push(data);
        this.savePoiCatTrans.emit(this.rowData);
      });
      return;
     
    }
  }

}
