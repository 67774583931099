import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { DashboardModule } from './dashboard/dashboard.module';

import { PagesRoutingModule } from './pages-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { TraduzioneComponent } from './categories/translation/traduzione/traduzione.component';
import { ModalTraduzioneComponent } from '../modal-traduzione/modal-traduzione.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule } from '@angular/forms';
import { OpenDialogComponent } from './news/open-dialog/open-dialog.component';
import { TraduzioneModalComponent } from './modal-overlays/translation/traduzione-modal/traduzione-modal.component';
import { TraduzioneCategoryComponent } from './charts/translation/traduzione/traduzione.component';
import { OpenFutureComponentComponent } from './modal-overlays/open-future-component/open-future-component.component';
import { OpenMacroFeatureComponentComponent } from './modal-overlays/open-macro-feature-component/open-macro-feature-component.component';
import { OpenCittaComponentComponent } from './ui-features/open-citta-component/open-citta-component.component';
import { TranslationFeatureComponent } from './modal-overlays/translation-feature/translation-feature.component';
import { OpenUpdateTravelCategoryComponent } from './charts/open-update-travel-category/open-update-travel-category.component';
import { OpenUpdateCategoryComponent } from './categories/open-update-category/open-update-category.component';
import { OpenUpdatePointOfInterestComponent } from './miscellaneous/open-update-point-of-interest/open-update-point-of-interest.component';
import { OpenTranslationPoiComponent } from './miscellaneous/open-translation-poi/open-translation-poi.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OpenCittaSeoCodeComponent } from './ui-features/open-citta-seo-code/open-citta-seo-code.component';
import { TravelTagOpenComponentComponent } from './maps/travel-tag-open-component/travel-tag-open-component.component';
import { NewsTranslationComponent } from './news/news-translation/news-translation.component';
import { OpenCategorySeoCodeComponent } from './categories/open-category-seo-code/open-category-seo-code.component';
import { OpenSeoCodeComponent } from './news/open-seo-code/open-seo-code.component';
import { OpenSeoCodePoiComponent } from './miscellaneous/open-seo-code-poi/open-seo-code-poi.component';
import { OpenModalSeoCodePoiComponent } from './miscellaneous/open-modal-seo-code-poi/open-modal-seo-code-poi.component';
import { PoiAdminSelectedOpenActionComponent } from './miscellaneous/poi-admin-selected-open-action/poi-admin-selected-open-action.component';
import { JourneyOpenUpdateActionComponent } from './bootstrap/journey-open-update-action/journey-open-update-action.component';
import { JourneyAdminSelectedOpenSeoCodeComponent } from './bootstrap/journey-admin-selected-open-seo-code/journey-admin-selected-open-seo-code.component';
import { JourneyAdminSelectedOpenTranslationComponent } from './bootstrap/journey-admin-selected-open-translation/journey-admin-selected-open-translation.component';
import { OpenUpdateUserListComponent } from './forms/open-update-user-list/open-update-user-list.component';
import { FaqsComponent } from './faqs/faqs.component';
import { FaqsOpenUpdateActionComponent } from './faqs/faqs-open-update-action/faqs-open-update-action.component';
import { FaqsOpenTranslationActionComponent } from './faqs/faqs-open-translation-action/faqs-open-translation-action.component';
import { AuthorComponent } from './author/author.component';
import { AuthorOpenTranslationActionComponent } from './author/author-open-translation-action/author-open-translation-action.component';
import { AuthorOpenUpdateActionComponent } from './author/author-open-update-action/author-open-update-action.component';
import { OpenSeoTagCategoriesComponent } from './categories/open-seo-tag-categories/open-seo-tag-categories.component';
import { OpenSeoTagCittaComponent } from './ui-features/open-seo-tag-citta/open-seo-tag-citta.component';
import { OpenSeoTagComponent } from './news/open-seo-tag/open-seo-tag.component';
import { OpenSeoTagPoiComponent } from './miscellaneous/open-seo-tag-poi/open-seo-tag-poi.component';
import { JourneyAdminSelectedOpenSeoTagComponent } from './bootstrap/journey-admin-selected-open-seo-tag/journey-admin-selected-open-seo-tag.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClonePointOfInterestComponent } from './miscellaneous/clone-point-of-interest/clone-point-of-interest.component';
import { CloneNewsComponent } from './news/clone-news/clone-news.component';
import { ModalTranslationBillingComponent } from './miscellaneous/modal-translation-billing/modal-translation-billing.component';
import { OpenTranslationBillingComponent } from './miscellaneous/open-translation-billing/open-translation-billing.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { JourneyModalTranslationComponent } from './bootstrap/journey-modal-translation/journey-modal-translation.component';
import { JourneyModalImageComponent } from './bootstrap/journey-modal-image/journey-modal-image.component';
import { JourneyModalFeatureComponent } from './bootstrap/journey-modal-feature/journey-modal-feature.component';
import { ImmaginiModalTranslationComponent } from './immagini/immagini-modal-translation/immagini-modal-translation.component';
import { ImmaginiOpenUpdateComponent } from './immagini/immagini-open-update/immagini-open-update.component';
import { ImmaginiOpenDetailsComponent } from './immagini/immagini-open-details/immagini-open-details.component';
import { NewsUploadImageActionComponent } from './news/news-upload-image-action/news-upload-image-action.component';

import { NotificaCreateComponent } from './notification/notifica-create/notifica-create.component';
import { ModalPoiTypeTranslationComponent } from './miscellaneous/modal-poi-type-translation/modal-poi-type-translation.component';
import { ModalPoiMacroTypeTranslationComponent } from './miscellaneous/modal-poi-macro-type-translation/modal-poi-macro-type-translation.component';
import { ModalPoiDayNoteTranslationComponent } from './miscellaneous/modal-poi-day-note-translation/modal-poi-day-note-translation.component';
import { ModalPoiCoverEditPositionActionComponent } from './miscellaneous/modal-poi-cover-edit-position-action/modal-poi-cover-edit-position-action.component';
import { OpenModalPoiFindHintComponent } from './miscellaneous/open-modal-poi-find-hint/open-modal-poi-find-hint.component';
import { OpenModalNewsFindHintComponent } from './news/open-modal-news-find-hint/open-modal-news-find-hint.component';
import { SuppliersOpenUpdateComponent } from './suppliers/suppliers-open-update/suppliers-open-update.component';
import { SuppliersOpenSearchComponent } from './suppliers/suppliers-open-search/suppliers-open-search.component';
import { ImmaginiDetailsComponent } from './immagini/immagini-details/immagini-details.component';
import { ResetCacheComponent } from './reset-cache/reset-cache.component';
import { NewsUpdateComponent } from './news/create/list-articoli/list-articoli.component';
import { ModalEditTagsComponent } from './modal/modal-edit-tags/modal-edit-tags.component';
import { OpenPOIComponent } from './miscellaneous/point-of-interest-expired/point-of-interest-expired.component';


const PAGES_COMPONENTS = [
  PagesComponent,
];

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    DashboardModule,
    MiscellaneousModule,
    NgxSpinnerModule,
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  declarations: [
    ...PAGES_COMPONENTS, SuppliersOpenSearchComponent, OpenUpdateUserListComponent,
    TraduzioneComponent, OpenDialogComponent, ImmaginiOpenUpdateComponent, ImmaginiOpenDetailsComponent, TraduzioneModalComponent, TraduzioneCategoryComponent, OpenFutureComponentComponent, OpenMacroFeatureComponentComponent, OpenCittaComponentComponent, TranslationFeatureComponent, OpenUpdateTravelCategoryComponent, OpenUpdateCategoryComponent, OpenUpdatePointOfInterestComponent, OpenTranslationPoiComponent, OpenCittaSeoCodeComponent, TravelTagOpenComponentComponent, NewsTranslationComponent, OpenCategorySeoCodeComponent, OpenSeoCodeComponent, OpenSeoCodePoiComponent, PoiAdminSelectedOpenActionComponent, JourneyOpenUpdateActionComponent, JourneyAdminSelectedOpenTranslationComponent, JourneyModalTranslationComponent, ImmaginiModalTranslationComponent,
    JourneyAdminSelectedOpenSeoCodeComponent, FaqsOpenUpdateActionComponent, FaqsOpenTranslationActionComponent, AuthorOpenTranslationActionComponent, AuthorOpenUpdateActionComponent, OpenSeoTagCategoriesComponent, OpenSeoTagCittaComponent, OpenSeoTagComponent, OpenSeoTagPoiComponent, JourneyAdminSelectedOpenSeoTagComponent, ClonePointOfInterestComponent, CloneNewsComponent, ModalTranslationBillingComponent, OpenTranslationBillingComponent, ModalPoiMacroTypeTranslationComponent, OpenModalPoiFindHintComponent, OpenModalNewsFindHintComponent,
    ModalPoiTypeTranslationComponent, ModalPoiDayNoteTranslationComponent, ModalPoiCoverEditPositionActionComponent, SuppliersOpenUpdateComponent, ResetCacheComponent, NewsUpdateComponent, ModalEditTagsComponent, OpenPOIComponent],
  entryComponents: [SuppliersOpenSearchComponent, OpenUpdateUserListComponent, TraduzioneComponent, OpenDialogComponent, ImmaginiOpenUpdateComponent, ImmaginiOpenDetailsComponent
    , TraduzioneModalComponent, TraduzioneCategoryComponent, OpenFutureComponentComponent, OpenMacroFeatureComponentComponent, OpenCittaComponentComponent, TranslationFeatureComponent, OpenUpdateTravelCategoryComponent, OpenUpdateCategoryComponent, OpenUpdatePointOfInterestComponent, OpenTranslationPoiComponent, OpenCittaSeoCodeComponent, TravelTagOpenComponentComponent, NewsTranslationComponent, OpenCategorySeoCodeComponent, OpenSeoCodeComponent, OpenSeoCodePoiComponent, PoiAdminSelectedOpenActionComponent, JourneyOpenUpdateActionComponent, JourneyAdminSelectedOpenTranslationComponent, JourneyModalTranslationComponent, ImmaginiModalTranslationComponent,
    JourneyAdminSelectedOpenSeoCodeComponent, FaqsOpenUpdateActionComponent, FaqsOpenTranslationActionComponent, AuthorOpenTranslationActionComponent, AuthorOpenUpdateActionComponent, OpenSeoTagCategoriesComponent, OpenSeoTagCittaComponent, OpenSeoTagComponent, OpenSeoTagPoiComponent, JourneyAdminSelectedOpenSeoTagComponent, ClonePointOfInterestComponent, CloneNewsComponent, ModalTranslationBillingComponent, OpenTranslationBillingComponent, ModalPoiMacroTypeTranslationComponent, OpenModalPoiFindHintComponent, OpenModalNewsFindHintComponent,
    ModalPoiTypeTranslationComponent, ModalPoiDayNoteTranslationComponent, ModalPoiCoverEditPositionActionComponent, SuppliersOpenUpdateComponent, NewsUpdateComponent, ModalEditTagsComponent, OpenPOIComponent],
})
export class PagesModule {
}
