import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate } from '../../../queryMutation';
import { CookieXSRFStrategy } from '@angular/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-modal-translation-poi-category-it',
  templateUrl: './modal-translation-poi-category-it.component.html',
  styleUrls: ['./modal-translation-poi-category-it.component.scss']
})
export class ModalTranslationPoiCategoryItComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() categoryId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() languageId:any;
  @Output("addPoiCatTrans") addPoiCatTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editPoiCatTrans") editPoiCatTrans: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiCatTrans") deletePoiCatTrans: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<ModalTranslationPoiCategoryItComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: travelCategoryTranslationDelete.travelCategoryTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['travelCategoryTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deletePoiCatTrans.emit({ 
            'id':this.translationId
          });
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: travelCategoryTranslationCreate.travelCategoryTranslationCreate,
        variables: {
          data:{
            travelCategoryId:parseInt(this.categoryId),
            languageId: this.languageId,
            name:this.translationName
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['travelCategoryTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addPoiCatTrans.emit({ 
              'travelCategoryId':parseInt(this.categoryId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'id':result.data['travelCategoryTranslationCreate']['id'],
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: travelCategoryTranslationUpdate.travelCategoryTranslationUpdate,
        variables: {
          data:{
            travelCategoryId:parseInt(this.categoryId),
            languageId: this.languageId,
            name:this.translationName,
            id:this.translationId
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['travelCategoryTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.editPoiCatTrans.emit({ 
              'travelCategoryId':parseInt(this.categoryId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'id':this.translationId
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
   }


