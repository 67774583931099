import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Apollo, QueryRef } from 'apollo-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { pointprova } from '../../../queryMutation';
import { ConstantsService } from '../../../service/utilities/constants.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ngx-point-of-interest-expired',
  templateUrl: './point-of-interest-expired.component.html',
  styleUrls: ['./point-of-interest-expired.component.scss']
})
export class PointOfInterestExpiredComponent implements OnInit {


  source: LocalDataSource = new LocalDataSource()
  pointOfInterestGetAll = []
  lastId = null

  keyword: any

  constructor(public spinner: NgxSpinnerService, public apollo: Apollo, public _sanitizer: DomSanitizer) { }

  ngOnInit() {
    var now = new Date()
    now.setFullYear(now.getFullYear() - 1)
    now.setMonth(11)
    now.setDate(31)
    this.keyword = now
  }

  pageChange() {

    console.log("pageChange", this.lastId)
    this.apollo.query<Response>({
      query: pointprova.pointprova,
      variables: {
        'data': {},
        'limit': ConstantsService.LIMIT_QUERY,
        'idCursor': this.lastId
      },
      fetchPolicy: "no-cache",
    }).subscribe((data: any) => {

      var result = data.data.pointOfInterestGetAll
      console.log("pageChange.data", result)

      result.forEach(element => {
        if (this.isExpired(element))
          this.pointOfInterestGetAll.push(element)
      })

      console.log("pageChange.pointOfInterestGetAll", this.pointOfInterestGetAll)

      this.lastId = result[result.length - 1]['id']
      console.log("pageChange.lastId", this.lastId)

      if (result.length == ConstantsService.LIMIT_QUERY) {
        this.pageChange()
      } else {
        this.source.load(this.pointOfInterestGetAll)
        setTimeout(() => {
          this.spinner.hide();
        }, 2000)
      }

    })
  }

  doSearch() {
    this.keyword.setHours(23)
    this.keyword.setMinutes(59)
    this.keyword.setSeconds(59)
    console.log("doSearch", this.keyword)
    if (this.keyword === null || this.keyword === undefined) {
      alert("Expired Date is empty")
      return
    }

    this.source.reset();
    this.pointOfInterestGetAll = []
    this.lastId = null

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    })
    this.pageChange()
  }

  isExpired(data) {
    var k = moment(this.keyword)
    var lastDate = null
    data.poiOpeningPeriodGetAllSub.forEach(element => {
      var d = moment(element.endingDateTime)
      if (lastDate == null)
        lastDate = d
      else {
        if (d > lastDate)
          lastDate = d
      }
      console.log("data.poiOpeningPeriodGetAllSub", d <= k, k, data.id, data.poiOpeningPeriodGetAllSub)
    });

    return lastDate < k
  }

  downloadFile() {
    console.log("source", this.source)
    var data = this.pointOfInterestGetAll
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    //const header = Object.keys(data[0]);
    const header = ["ID", "Translation Name", "Draft", "Last Date", "All Date"]
    const csv = data.map((row) => {
      console.log("ROW", row)
      console.log("ROW.id", row['id'])
      var r = []
      r.push(row['id'])
      r.push(this.getTranslationName(row))
      r.push(this.getWorkInProgress(row))
      r.push(this.getLastDate(row))
      r.push(this.getAllDate(row))
      return r.join(';')
    });
    csv.unshift(header.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  getTranslationName(data) {
    var translation = data.poiTranslationGetAllSub.filter(item => item.languageId == 1);
    if (translation.length > 0) {
      return translation[0].name;
    }
    return ""
  }

  getWorkInProgress(data) {
    return data.workInProgress == true ? "SI" : "NO"
  }

  getLastDate(data) {
    var lastDate = null
    data.poiOpeningPeriodGetAllSub.forEach(element => {
      var d = moment(element.endingDateTime)
      if (d > lastDate)
        lastDate = d
    });

    return lastDate == null ? "Always Closed" : lastDate
  }

  getAllDate(data) {
    var toReturn = ""
    data.poiOpeningPeriodGetAllSub.forEach(element => {
      toReturn += `[${element.startingDateTime.split("T")[0]}, ${element.endingDateTime.split("T")[0]}]<br/>`;
    });

    return toReturn
  }

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    // rowClassFunction: (row) => {
    //   console.log(row.data);

    //   if (row.data.isSelected == true) {
    //     return 'solved';
    //   } 
    // },
    delete: {
      deleteButtonContent: "<img src='assets/images/deleteTable.png'>",
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      name: {
        title: 'Translation Name',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiTranslationGetAllSub.length > 0) {

            var name = this.getTranslationName(row)
            if (name.length > 0)
              return name
            else
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
        filterFunction(obj?: any, search?: string): boolean {

          console.log("obj", obj, "search", search, obj.indexOf(search) > -1);

          if (obj.indexOf(search) > -1)
            return true;

          return false;
        },
      },
      workInProgress: {
        title: 'Draft',
        type: 'checkbox',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => this.getWorkInProgress(row),

      },
      lastDate: {
        title: 'Last Date',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => this.getLastDate(row),
      },
      allDate: {
        title: 'All Date',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => this.getAllDate(row),
      },
      openPOI: {
        title: 'Open POI',
        type: 'custom',
        renderComponent: OpenPOIComponent,
        onComponentInitFunction: (instance: any) => {
          console.log("INSTANCE", instance)
          instance.poi.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      }
    }
  }
}


@Component({

  selector: 'ngx-point-of-interest-expired-open-compoment',
  styles: [],
  template: `<div>
              <button class="btn btn-warning with-margins" (click)="goto()">Open</button>
              </div>`
})

export class OpenPOIComponent {

  @Output() poi: EventEmitter<any> = new EventEmitter();

  @Input() id: string | number;
  @Input() rowData: any;

  constructor(public router: Router) { }

  ngOnInit() {
    console.log("ngOnInit", this.rowData)
  }

  goto() {
    console.log("goto", this.rowData.id)
    if (this.rowData.id > 0) {
      console.log(this.rowData.id);
      localStorage.setItem("poiID", this.rowData.id);
      this.router.navigateByUrl("/pages/pointOfInterest/editPoi");
    }
  }
}