



import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { Router } from '@angular/router';

@Component({
  selector: 'ngx-author-open-update-action',
  templateUrl: './author-open-update-action.component.html',
  styleUrls: ['./author-open-update-action.component.scss']
})
export class AuthorOpenUpdateActionComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router) {

  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal() {
    if(this.rowData.id > 0){
  
      localStorage.setItem("AuthorId",this.rowData.id);
       this.router.navigateByUrl("/pages/author/update");
         
  }
  }
}

