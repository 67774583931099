import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalPoiOpenTranslationItComponent } from '../modal-poi-open-translation-it/modal-poi-open-translation-it.component';
import { Router } from '@angular/router';
import { ModalTranslationBillingComponent } from '../modal-translation-billing/modal-translation-billing.component';
@Component({
  selector: 'ngx-open-translation-billing',
  templateUrl: './open-translation-billing.component.html',
  styleUrls: ['./open-translation-billing.component.scss']
})
export class OpenTranslationBillingComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  data:any;
  @Output() savePoiBilling: EventEmitter<any> = new EventEmitter();

  public indexSelected:any;
  translations: any;
  constructor(public dialogService:NbDialogService ) {

  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
      this.translations = []
      if (this.rowData.poiBillingGetSub != null && this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub != null) {
        this.translations['it'] = this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
        this.translations['eng'] = this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
        this.translations['fr'] = this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
      } else {
        this.translations['it'] = false;
        this.translations['eng'] = false;
        this.translations['fr'] = false;
      }
      if (this.rowData.poiBillingGetSub == null)
        console.log("poiBillingTranslationGetAllSub", "this.rowData.poiBillingGetSub == null")
      else if (this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub == null)
        console.log("poiBillingTranslationGetAllSub", "this.rowData.poiBillingGetSub == null")
      else 
        console.log("poiBillingTranslationGetAllSub", "ok")
  }

  onClick() {
      this.savePoiBilling.emit(this.rowData);
  }


  openEditModal(languageId) {
    console.log(this.rowData);
    if(this.rowData.poiBillingGetSub !== null){

      this.indexSelected = this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.findIndex(item => item.languageId == languageId);
      this.data = this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.filter(item => item.languageId == languageId);
      if(this.data.length > 0){
        console.log(this.data[0].id);
           const ref = this.dialogService.open(ModalTranslationBillingComponent, {
             context: {
  
              title: 'Edit Translation',
              id:this.data[0].id,
              poiBillingId:this.data[0].poiBillingId,
              languageId: languageId,
              name:this.data[0].name,
              description:this.data[0].description
             },
           });
           ref.componentRef.instance["editPoiBillingTrans"].subscribe((data) => {
            ref.close();
    
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub[this.indexSelected].poiBillingId = data.poiBillingId;
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub[this.indexSelected].name = data.name;
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub[this.indexSelected].description = data.description;
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub[this.indexSelected].id = data.id;
            this.savePoiBilling.emit(this.rowData);
          });
          ref.componentRef.instance["deletePoiBillingTrans"].subscribe((data) => {
            ref.close();
            const indexToRemove =  this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.findIndex(res => res.id == data.id);
            this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.splice(indexToRemove,1);
            this.savePoiBilling.emit(this.rowData);
          });
           return;
      }
      else{
        const ref = this.dialogService.open(ModalTranslationBillingComponent, {
          context: {
            title: 'Create Translation',
                id:null,
                poiBillingId:this.rowData.poiBillingGetSub.id,
                languageId: languageId,
                name:null,
                description:null
          },
        });

        ref.componentRef.instance["addPoiBillingTrans"].subscribe((data) => {
          ref.close();
          this.rowData.poiBillingGetSub.poiBillingTranslationGetAllSub.push(data);
          this.savePoiBilling.emit(this.rowData);
        });
        return;
      }
  }

}
}
