import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogRef } from '@nebular/theme';
import { seoCodeDelete, seoCodeCreate, seoCodeUpdate, seoCodeGetByUniqueCode, seoCodeGet } from '../../../queryMutation';

@Component({
  selector: 'ngx-journey-admin-selected-open-modal-seo-code',
  templateUrl: './journey-admin-selected-open-modal-seo-code.component.html',
  styleUrls: ['./journey-admin-selected-open-modal-seo-code.component.scss']
})
export class JourneyAdminSelectedOpenModalSeoCodeComponent {

  public querySubscriptionGetIdbyCode: Subscription;
  public querySubscriptionEditMunicipality: Subscription;
  public querySubscriptionCreateMunicipality: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  dataProvince: any[];
  newsAllCategories: any[];
  newsCategoryTranslationGetAll: any[];
  public dataToRedirect: any;
  public isActiveToSend: boolean = false;

  @Input() title: string;
  @Input() seoCodeId: string;
  @Input() seoCodeName: string;
  @Input() languageId: any;
  @Input() journeyId: any;
  @Input() journeyAdminSelectedId: any;
  @Input() redirectStatusCode: any;
  @Input() staticRedirectUrl: string;
  @Input() redirectToId: string;
  @Input() isJourneyAdminSelected: boolean = false;

  @Output("addJourneyAdminCode") addJourneyAdminCode: EventEmitter<any> = new EventEmitter();

  @Output("editJourneyAdminCode") editJourneyAdminCode: EventEmitter<any> = new EventEmitter();
  @Output("deleteJourneyAdminCode") deleteJourneyAdminCode: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<JourneyAdminSelectedOpenModalSeoCodeComponent>, public apollo: Apollo) {
    this.dataToRedirect = [];
    this.isActiveToSend = false;
  }

  async ngOnInit() {

    if (this.redirectToId !== null && this.redirectToId !== '' && typeof (this.redirectToId) === 'number')
      await this.getRedirectId();

  }

  async getRedirectId() {
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGet.seoCodeGet,
      fetchPolicy: 'network-only',
      variables: {
        'id': parseInt(this.redirectToId)
      },
    })
      .valueChanges.subscribe((result: any) => {

        this.redirectToId = result.data.seoCodeGet.code;
      });

  }
  getBySeoCode() {
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGetByUniqueCode.seoCodeGetByUniqueCode,
      fetchPolicy: 'network-only',
      variables: {
        'code': this.redirectToId
      },
    }).valueChanges.subscribe((data: any) => {
      if (data.data.seoCodeGetByUniqueCode !== null) {
        this.isActiveToSend = true;
        this.dataToRedirect.push(data.data.seoCodeGetByUniqueCode)
      }
      else {
        this.isActiveToSend = false;
        alert("No esiste seoCode, prova con altro.")
      }
    })

  }
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent() {
    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeDelete.seoCodeDelete,
      variables: {
        'id': parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if (result.data['seoCodeDelete']['success']) {
        this.deleteJourneyAdminCode.emit({

          'id': parseInt(this.seoCodeId),
        });
      }
    });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
      event.confirm.reject();
    }
  }

  Salva() {
    var data = {
      'languageId': this.languageId,
      'code': this.seoCodeName,
      'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
      'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
      'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null
    }
    if (this.isJourneyAdminSelected) {
      data['journeyAdminSelectedId'] = parseInt(this.journeyAdminSelectedId)
    } else {
      data['journeyId'] = parseInt(this.journeyId)
    }
    console.log("SALVA.SALVA", data, this.isJourneyAdminSelected)
    
    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeCreate.seoCodeCreate,
      variables: {
        data: data
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeCreate']['success']) {
        var data = {
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': result.data['seoCodeCreate']['id'],
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null
        }
        if (this.isJourneyAdminSelected) {
          data['journeyAdminSelectedId'] = parseInt(this.journeyAdminSelectedId)
        } else {
          data['journeyId'] = parseInt(this.journeyId)
        }
        this.addJourneyAdminCode.emit(data);
      }
    });
  }
  Edit() {
    var data = {
      'languageId': parseInt(this.languageId),
      'code': this.seoCodeName,
      'id': parseInt(this.seoCodeId),
      'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
      'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
      'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null
    }
    if (this.isJourneyAdminSelected) {
      data['journeyAdminSelectedId'] = parseInt(this.journeyAdminSelectedId)
    } else {
      data['journeyId'] = parseInt(this.journeyId)
    }
    console.log("SALVA.EDITA", data, this.isJourneyAdminSelected)
    
    this.querySubscriptionAllProvince = this.apollo.use('super-admin').mutate({
      mutation: seoCodeUpdate.seoCodeUpdate,
      variables: {
        data: data
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeUpdate']['success']) {
        var data = {
          'journeyId': parseInt(this.journeyId),
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': parseInt(this.seoCodeId),
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null
        }
        if (this.isJourneyAdminSelected) {
          data['journeyAdminSelectedId'] = parseInt(this.journeyAdminSelectedId)
        } else {
          data['journeyId'] = parseInt(this.journeyId)
        }
        this.editJourneyAdminCode.emit(data);
      }
    });
  }
}


