import { Component, OnInit, Compiler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { ZipService } from '../../../service/zipFile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsTranslationComponent } from '../../news/news-translation/news-translation.component';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { getPoiById, imageGetAll, imageGetAllAdminVersion } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { FileUploadService } from '../../../service/file-upload.service';
import { forkJoin, concat } from 'rxjs';
import { Location } from '@angular/common';
import { concatMap } from 'rxjs/operators';
import { HttpRequestService } from '../../../service/http-request.service';
import { ConstantsService } from '../../../service/utilities/constants.service';

@Component({
  selector: 'ngx-poi-image-upload-action',
  templateUrl: './poi-image-upload-action.component.html',
  styleUrls: ['./poi-image-upload-action.component.scss']
})
export class PoiImageUploadActionComponent implements OnInit {

  selectedMode: boolean = true;
  // This will contain selected rows
  selectedRows: any;
  showbtnUpload: boolean = false;
  public querySubscriptionImageCreate: Subscription;
  isPoiFilterAtive: any;
  public queryGetAllImage: any;
  public businessPagination: QueryRef<any>;

  public dataProvince: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn: boolean = false;
  public imageAll: any[];
  public imageGetByTag: any[];
  source: LocalDataSource = new LocalDataSource();


  public images: any = []
  public rowSelected: any = []
  public maxSize = 3

  constructor(public apollo: Apollo, public _location: Location, public service: SmartTableService, public zipS: ZipService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler, public spinner: NgxSpinnerService, public fileUp: FileUploadService, public httpS: HttpRequestService) {
    this.imageAll = [];
    this.imageGetByTag = [];
    this.queryGetAllImage = new imageGetAllAdminVersion({}, ConstantsService.LIMIT_QUERY).getQuery();

  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {

      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      fileName: {
        title: 'Name',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.url) {

            row.UrlConverted = "<img class='imgRendering' src=" + row.url + " style='height:100px;width: 100px;'>";
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted)
          }
        }
      },
      backOfficeData: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.backOfficeData) {

            const data = JSON.parse(row.backOfficeData);
            if (data !== "") {

              if (data.imagePosition !== undefined) {
                data.imagePosition = (data.imagePosition == "") ? this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>") : data.imagePosition;

                return data.imagePosition
              } else {
                return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
              }
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
        }
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.mimeType) {

            return row.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      },

    },
  };

  tag = ""

  async ngOnInit() {
    console.log("image-type", localStorage.getItem("image-type"))

    var json = JSON.parse(localStorage.getItem("image-type"))
    console.log("image-type:json", json)
    //{type: 'GALLERY', bo: 'POI'}
    //"POI_COVER_IMAGE
    var type = (json.type == "COVER" ? "COVER_" : "") + "IMAGE"
    this.tag = `${json.bo}_${type}`
    console.log("image-type:tag", this.tag)

    this.getPoi(parseInt(localStorage.getItem("poiId")))
    await this.imageGetAll();
  }


  async getPoi(id) {
    this.apollo.query<Response>({
      query: getPoiById.getPoiById,
      //fetchPolicy: "network-only",
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
      },
    }).subscribe((result: any) => {
      console.log('result.data.pointOfInterestGet', result.data.pointOfInterestGet);
      var data = result.data.pointOfInterestGet.pointOfInterestImageGetAllSub
      this.images = []
      data.forEach(element => {
        if (element.imageGetSub.tags.filter(item => item == this.tag).length > 0) {
          var filename = element.imageGetSub.fileName.split(".")[0]
          if (!this.images.includes(filename)) {
            this.images.push(filename)
          }
        }
      });
      console.log("images.ids", this.images, this.images.length)
    });
  }

  // rowSelected Event handler

  onUserRowSelect(event) {

    console.log("onRowSelect", event);
    this.selectedRows = event.data;
    this.rowSelected = event.selected;

    /*
    controllo rimosso per numero immagine
    console.log("rowSelected", this.rowSelected);

    console.log("sum", "rowSelected", this.rowSelected.length)
    console.log("sum", "images", this.images.length)
    console.log("sum", "maxSize", this.maxSize)

    if ((this.rowSelected.length + this.images.length) > this.maxSize) {
      alert(`OPS! devi selezionare al massimo ${this.maxSize} immagini`)
    }
    */

    this.getAllImagebyTagName();

  }
  isFilterAtive(event) {
    this.source.reset();
    if (event.target.checked == true) {

      var imageFilter2: any[] = this.httpS.filterImageByTags(this.imageAll);
      this.source.load(imageFilter2);
    }
    else {
      this.source.load(this.imageAll);
    }
  }
  async getAllImagebyTagName() {


    this.businessPagination = this.apollo.use("super-admin").watchQuery<Response>({
      query: this.queryGetAllImage,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'tags': this.selectedRows.tags[0],
          'belongsTo': "SUPER_ADMIN",
          'filePermission': "PUBLIC"

        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.imageGetAllAdminVersion.length > 0 ? ((data.data['imageGetAllAdminVersion'][data.data['imageGetAllAdminVersion'].length - 1]['id'])) : null;
      this.queryGetAllImage = new imageGetAllAdminVersion({}, ConstantsService.LIMIT_QUERY, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'tags': this.selectedRows.tags[0],
            'belongsTo': "SUPER_ADMIN",
            'filePermission': "PUBLIC"

          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['imageGetAllAdminVersion'].length < 1) {
            this.imageGetByTag.push(...prev['imageGetAllAdminVersion']);

            this.imageGetByTag = this.imageGetByTag.map((item) => {
              return item;
            })
          } else {
            return Object.assign({}, prev, {
              imageGetAllAdminVersion: [...prev['imageGetAllAdminVersion'], ...fetchMoreResult['imageGetAllAdminVersion']]
            });

          }
        }
      });
    });
  }

  setDataToList(data) {
    var imageData = data.filter(item => item.mimeType == "image/webp");

    this.source.load(imageData);

    this.spinner.hide();
  }
  async imageGetAll() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.businessPagination = this.apollo.use("super-admin").watchQuery<Response>({
      query: this.queryGetAllImage,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'role': 'ORIGINAL',
          'belongsTo': 'SUPER_ADMIN',
          'filePermission': "PUBLIC"

        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.imageGetAllAdminVersion.length > 0 ? ((data.data['imageGetAllAdminVersion'][data.data['imageGetAllAdminVersion'].length - 1]['id'])) : null;
      this.queryGetAllImage = new imageGetAllAdminVersion({}, ConstantsService.LIMIT_QUERY, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'role': 'ORIGINAL',
            'belongsTo': 'SUPER_ADMIN',
            'filePermission': "PUBLIC"

          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['imageGetAllAdminVersion'].length < 1) {
            this.imageAll.push(...prev['imageGetAllAdminVersion']);

            this.imageAll = this.imageAll.filter(item => item.mimeType == "image/webp");

            this.imageAll = this.imageAll.map((item) => {
              return item;
            });

            this.setDataToList(this.imageAll);

          } else {
            return Object.assign({}, prev, {
              imageGetAllAdminVersion: [...prev['imageGetAllAdminVersion'], ...fetchMoreResult['imageGetAllAdminVersion']]
            });

          }
        }
      });
    });
  }
  goCitta() {
    this._compiler.clearCache();
    this.router.navigateByUrl("/pages/pointOfInterest/list");
  }
  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
  CreateImageUpload() {


    /*
    console.log("sum", "rowSelected", this.rowSelected.length)
    console.log("sum", "images", this.images.length)
    console.log("sum", "maxSize", this.maxSize)

    controllo rimosso per numero immagine
    if ((this.rowSelected.length + this.images.length) > this.maxSize) {
      alert(`OPS! devi selezionare al massimo ${this.maxSize} immagini`)
      return
    }
    */

    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    var tasks$ = [];
    var imageFilter2: any[] = this.removeDuplicates(this.imageGetByTag, 'id');
    console.log(imageFilter2);

    for (let index = 0; index < imageFilter2.length; index++) {

      if (imageFilter2[index].tags.indexOf("POI_IMAGE") == -1) {
        imageFilter2[index].tags.push("POI_IMAGE");
      }


      tasks$.push(this.fileUp.pointOfInterestImageCreate(imageFilter2[index].id, parseInt(localStorage.getItem("poiId")), null, JSON.stringify("")).pipe(
        concatMap((first) => {
          return this.fileUp.updateImage(imageFilter2[index].id, imageFilter2[index].tags);
        })
      ));


    }
    console.log(tasks$)

    return concat(...tasks$).subscribe(y => {
      this.showbtnUpload = true;

      setTimeout(() => {
        this.spinner.hide();
      }, 2000);

    }, (err) => {

      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
      this.router.navigateByUrl("/pages/pointOfInterest/list");


    })

  }

  goBack() {
    this._location.back();
  }
}


