import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalPoiOpenTranslationItComponent } from '../modal-poi-open-translation-it/modal-poi-open-translation-it.component';
import { Router } from '@angular/router';
import { ModalPoiMacroTypeTranslationComponent } from '../modal-poi-macro-type-translation/modal-poi-macro-type-translation.component';
@Component({
  selector: 'ngx-open-poi-macro-type-translation',
  templateUrl: './open-poi-macro-type-translation.component.html',
  styleUrls: ['./open-poi-macro-type-translation.component.scss']
})
export class OpenPoiMacroTypeTranslationComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Input() data: any;
  ddlLanguage={}

  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  translations: any;

  constructor(public dialogService:NbDialogService,public router:Router) {

    this.data = [];
    
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.poiMacroTypeTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.poiMacroTypeTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.poiMacroTypeTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
     
  }

  onClick() {
      this.save.emit(this.data);
  }

  openEditModal(languageId) {
    
    this.data = this.rowData.poiMacroTypeTranslationGetAllSub.filter(item => item.languageId == languageId);
    
    console.log(this.data);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          this.dialogService.open(ModalPoiMacroTypeTranslationComponent, {
           context: {
             title: 'Edit Translation',
             poiMacroTypeId:this.data[0].poiMacroTypeId,
             translationName : this.data[0].name,
             translationId: this.data[0].id,
             languageId:languageId,
            description:this.data[0].description,
           },
         });
    }
    else{

      this.dialogService.open(ModalPoiMacroTypeTranslationComponent, {
        context: {
          title: 'Insert Translation',
          poiMacroTypeId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          description:"",

        },
      });
     
    }
  }

}
