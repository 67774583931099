import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Apollo } from 'apollo-angular';
import { GetPOI, journeyAdminSelectedGetAll, pointOfInterestAdminSelectedGetAll } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ngx-suppliers-open-search',
  templateUrl: './suppliers-open-search.component.html',
  styleUrls: ['./suppliers-open-search.component.scss']
})
export class SuppliersOpenSearchComponent implements OnInit {

  //title = "Search JourneyAdminSelected"
  keyword: string
  list = []
  limit = 25

  @Output("searchEmitter") searchEmitter: EventEmitter<any> = new EventEmitter()
  @Input() title: string
  @Input() type: string

  constructor(public spinner: NgxSpinnerService, public apollo: Apollo, protected ref: NbDialogRef<SuppliersOpenSearchComponent>) {
  }

  search(lastId = null) {
    if (lastId == null) {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.list = []
    }

    if (this.type == "JourneyAdminSelected") {
      this.apollo.query<Response>({
        query: new journeyAdminSelectedGetAll({searchByText: this.keyword, searchByTextWithLanguageId: 1}, this.limit, lastId).getQuery(),
        fetchPolicy: 'network-only',
        variables: {
          data: {searchByText: this.keyword, searchByTextWithLanguageId: 1},
        },
      }).subscribe((data: any) => {
        var items = data.data.journeyAdminSelectedGetAll
        console.log("journeyAdminSelectedGetAll", items)

        if (items.length == 0) {
          this.spinner.hide();
          return;
        }

        for (var item of items) {
          var display_one = item.id
          var display_two = ""
          if (item.journeyAdminSelectedTranslationGetAllSub != null && item.journeyAdminSelectedTranslationGetAllSub.length > 0) {
            var trad = item.journeyAdminSelectedTranslationGetAllSub.filter(data => data.languageId == 1)
            if (trad.length == 0) {
              trad = item.journeyAdminSelectedTranslationGetAllSub[0]
            } 
            display_two = trad[0].name
          } else {
          }
          /*
          if (item.journeyGetSub != null) {
            if (item.journeyGetSub.journeyTranslationGetAllSub != null && item.journeyGetSub.journeyTranslationGetAllSub.length > 0) {
              var trad = item.journeyGetSub.journeyTranslationGetAllSub.filter(data => data.languageId == 1)
              if (trad.length == 0) {
                trad = item.journeyGetSub.journeyTranslationGetAllSub[0]
              } 
              display_two = trad[0].name
            } else {
            }
          }
          */
          item.display_one = display_one
          item.display_two = display_two
          item.checked = false
        }

        this.list.push(...items)
        lastId = items[items.length-1].id
        this.search(lastId)
      })
    } else if(this.type == "PointOfInterest") {

      var filter = {
        localizedNameQuery: {
          languageId: 1,
          name: this.keyword
        }
      }


      this.apollo.query<Response>({
        query: new GetPOI(filter, this.limit, lastId).getQuery(),
        fetchPolicy: 'network-only',
        variables: {
          data: filter
        },
      }).subscribe((data: any) => {
        var items = data.data.pointOfInterestGetAll
        console.log("pointOfInterestGetAll", items)

        if (items.length == 0) {
          this.spinner.hide();
          return;
        }

        for (var item of items) {
          var display_one = item.id
          var display_two = ""
          if (item.poiTranslationGetAllSub != null && item.poiTranslationGetAllSub.length > 0) {
            var trad = item.poiTranslationGetAllSub.filter(data => data.languageId == 1)
            console.log(trad)
            if (trad.length == 0) {
              trad = item.poiTranslationGetAllSub[0]
            } 
            display_two = trad[0].name
          } else {
          }
          item.display_one = display_one
          item.display_two = display_two
          item.checked = false
        }

        this.list.push(...items)
        lastId = items[items.length-1].id
        this.search(lastId)
      })

    }
  }

  add() {
    var checked = this.list.filter(data => data.checked)
    this.searchEmitter.emit({'searchEmitter': checked})
    this.ref.close();
  }

  dismiss() {
    this.ref.close();
  }

  ngOnInit() {
  }

}
