// src/app/auth/role-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import * as jwtDecode from 'jwt-decode'
@Injectable()
export class RoleGuardService implements CanActivate {
  roleScopes:any;
  token:any;
  constructor(public auth: AuthService, public router: Router) {}


  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    this.token = localStorage.getItem('token');
    this.roleScopes = localStorage.getItem('roleScopes');

    const tokenPayload = jwtDecode(this.token);
    
    if (!this.auth.isAuthenticated())
      return false;

    var toReturn = false;
    expectedRole.forEach(role => {
      toReturn = toReturn || this.roleScopes.indexOf(role) >= 0
    });

    return toReturn;
  }

  _canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    this.token = localStorage.getItem('token');
    this.roleScopes = localStorage.getItem('roleScopes');

    // decode the token to get its payload
    const tokenPayload = jwtDecode(this.token);
  
    if(route.routeConfig.path !== "articoli"){
   if(expectedRole.length > 1){
    for (let index = 0; index < expectedRole.length; index++) {
      if (!this.auth.isAuthenticated() || this.roleScopes.indexOf(expectedRole[index]) ===  -1 ) {
        return false;
      }
    }
   }
   else{
    if (!this.auth.isAuthenticated() || this.roleScopes.indexOf(expectedRole[0]) ===  -1 ) {
      return false;
    }
   }
  }
  else{

    if(expectedRole.length > 1){
      for (let index = 0; index < expectedRole.length; index++) {
        if (!this.auth.isAuthenticated() && expectedRole.indexOf("BASE_USER") ===  -1 
        ||!this.auth.isAuthenticated() && expectedRole.indexOf("EDITOR") ===  -1 
        ||!this.auth.isAuthenticated() && expectedRole.indexOf("SUPER_ADMIN") ===  -1 
        || !this.auth.isAuthenticated() && expectedRole.indexOf("MUNICIPALITY_OPERATOR") ===  -1) {
          return false;
        }
      }
     }
   }
  
    return true;
  }

}
