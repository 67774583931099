import { Component, OnInit, Compiler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { ZipService } from '../../../service/zipFile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsTranslationComponent } from '../../news/news-translation/news-translation.component';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { imageGetAll, imageGetAllAdminVersion } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { FileUploadService } from '../../../service/file-upload.service';
import { forkJoin, concat } from 'rxjs';
import { Location } from '@angular/common';
import { concatMap } from 'rxjs/operators';
import { HttpRequestService } from '../../../service/http-request.service';
@Component({
  selector: 'ngx-poi-admin-image-upload-action',
  templateUrl: './poi-admin-image-upload-action.component.html',
  styleUrls: ['./poi-admin-image-upload-action.component.scss']
})
export class PoiAdminImageUploadActionComponent implements OnInit {

  limit = 25;
  selectedMode: boolean = true;
  // This will contain selected rows
  selectedRows: any;
  showbtnUpload: boolean = false;
  public querySubscriptionImageCreate: Subscription;
  isPoiFilterAtive:any;
  public queryGetAllImage: any;
  public businessPagination: QueryRef<any>;

  public dataProvince: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn: boolean = false;
  public imageAll: any[];
  public imageGetByTag: any[];
  source: LocalDataSource = new LocalDataSource();


  constructor(public apollo: Apollo, public _location: Location, public service: SmartTableService, public zipS: ZipService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler, public spinner: NgxSpinnerService, public fileUp: FileUploadService,public httpS:HttpRequestService) {
    this.imageAll = [];
    this.imageGetByTag = [];
    this.queryGetAllImage = new imageGetAllAdminVersion({}, this.limit).getQuery();

  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {

      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      fileName: {
        title: 'Name',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },

      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.url) {

            row.UrlConverted = "<img class='imgRendering' src=" + row.url + " style='height:100px;width: 100px;'>";
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted)
          }
        }
      },
      backOfficeData: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { 
          if(row.backOfficeData){

            const data = JSON.parse(row.backOfficeData);
            if(data !== ""){

              if (data.imagePosition !== undefined) {
                data.imagePosition = (data.imagePosition == "") ? this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>") : data.imagePosition;

                return data.imagePosition
              } else {
                return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
              }
          }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
           
          }
        }
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.mimeType) {

            return row.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      },

    },
  };
  async ngOnInit() {
    await this.imageGetAll();
  }

  async onrowSelect(event) {
    console.log(event);

    this.selectedRows = event.data;
    this.getAllImagebyTagName();
  }
  async  getAllImagebyTagName() {


    this.businessPagination = this.apollo.use("super-admin").watchQuery<Response>({
      query: this.queryGetAllImage,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'tags': this.selectedRows.tags[0],
          'belongsTo': "SUPER_ADMIN",
          'filePermission': "PUBLIC"

        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.imageGetAllAdminVersion.length > 0 ? ((data.data['imageGetAllAdminVersion'][data.data['imageGetAllAdminVersion'].length - 1]['id'])) : null;
      this.queryGetAllImage = new imageGetAllAdminVersion({}, this.limit, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'tags': this.selectedRows.tags[0],
            'belongsTo': "SUPER_ADMIN",
            'filePermission': "PUBLIC"
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['imageGetAllAdminVersion'].length < 1) {
            this.imageGetByTag.push(...prev['imageGetAllAdminVersion']);

            this.imageGetByTag = this.imageGetByTag.map((item) => {
              return item;
            })
            return prev;
          } else {
            return Object.assign({}, prev, {
              imageGetAllAdminVersion: [...prev['imageGetAllAdminVersion'], ...fetchMoreResult['imageGetAllAdminVersion']]
            });

          }
        }
      });
    });
  }
  goCitta() {
    this._compiler.clearCache();
    this.router.navigateByUrl("/pages/pointOfInterest/admin");
  }
  async  imageGetAll() {

    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.businessPagination = this.apollo.use("super-admin").watchQuery<Response>({
      query: this.queryGetAllImage,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'role': "ORIGINAL",
          'belongsTo': 'SUPER_ADMIN',
          'filePermission': "PUBLIC"
        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.imageGetAllAdminVersion.length > 0 ? ((data.data['imageGetAllAdminVersion'][data.data['imageGetAllAdminVersion'].length - 1]['id'])) : null;
      this.queryGetAllImage = new imageGetAllAdminVersion({}, this.limit, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'role': "ORIGINAL",
            'belongsTo': 'SUPER_ADMIN',
            'filePermission': "PUBLIC"
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['imageGetAllAdminVersion'].length < 1) {
            this.imageAll.push(...prev['imageGetAllAdminVersion']);

            this.imageAll = this.imageAll.map((item) => {
              return item;
            })
            this.imageAll = this.imageAll.filter(item => item.mimeType == "image/webp");
            //this.source.load(this.imageAll);

            this.setDataToList(this.imageAll);
            
            return prev;
          } else {
            return Object.assign({}, prev, {
              imageGetAllAdminVersion: [...prev['imageGetAllAdminVersion'], ...fetchMoreResult['imageGetAllAdminVersion']]
            });

          }
        }
      });
    });
  }
  setDataToList(data){
    var imageData = data.filter(item => item.mimeType == "image/webp");

    this.source.load(imageData);
    
    this.spinner.hide();
  }
  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
  CreateImageUpload() {

    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    var tasks$ = [];
    var imageFilter2: any[] = this.removeDuplicates(this.imageGetByTag, 'id');
    console.log(imageFilter2);
    for (let index = 0; index < imageFilter2.length; index++) {


      if (imageFilter2[index].tags.indexOf("POI_ADMIN_IMAGE") == -1) {
        imageFilter2[index].tags.push("POI_ADMIN_IMAGE");
      }
      tasks$.push(this.fileUp.poiAdminSelectedImageCreate(imageFilter2[index].id, parseInt(localStorage.getItem("poiAdminSelectedId")),null,JSON.stringify("")).pipe(
        concatMap((first) => {
          return this.fileUp.updateImage(imageFilter2[index].id, imageFilter2[index].tags);
        })
      ));

    }
    console.log(...tasks$)

    return concat(...tasks$).subscribe(y => {

      this.showbtnUpload = true;
      this.spinner.hide();

    }, (err) => {

      this.spinner.hide();
      this.router.navigateByUrl("/pages/pointOfInterest/admin");




    })

  }
  isFilterAtive(event){
    this.source.reset();
  if(event.target.checked == true){
    // var imageFilter2:any[] = this.imageAll.filter(item => item.tags.findIndex((itemSelected) => itemSelected === "POI_ADMIN_IMAGE") === -1);
   
    var imageFilter2:any[] = this.httpS.filterImageByTags(this.imageAll);
     this.source.load(imageFilter2);
  }
  else{
    this.source.load(this.imageAll);
  }
  }
  goBack() {
    this._location.back();
  }
}


