


import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OpenModalSeoCodePoiComponent } from '../open-modal-seo-code-poi/open-modal-seo-code-poi.component';


@Component({
  selector: 'ngx-open-seo-code-poi',
  templateUrl: './open-seo-code-poi.component.html',
  styleUrls: ['./open-seo-code-poi.component.scss']
})

export class OpenSeoCodePoiComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
   public data: any[];
  ddlLanguage={}
  @Output() saveSeoCodePoi: EventEmitter<any> = new EventEmitter();
public indexSelected:any;
translations: any;
  constructor(public dialogService:NbDialogService ) {
      this.data =[];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
      
      this.translations = []
      this.translations['it'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0;
    
  }

  onClick() {
      this.saveSeoCodePoi.emit(this.rowData);
  }
  openCreatePoi(languageId) {
    
    this.indexSelected = this.rowData.seoCodeGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
         const ref = this.dialogService.open(OpenModalSeoCodePoiComponent, {
           context: {

            title: 'Edit SeoCode',
            seoCodeId:this.data[0].id,
            pointOfInterestId:this.data[0].pointOfInterestId,
            languageId: this.data[0].languageId,
            seoCodeName:this.data[0].code,
            redirectStatusCode:this.data[0].redirectStatusCode,
            staticRedirectUrl:this.data[0].staticRedirectUrl,
            redirectToId:this.data[0].redirectToId
           },
         });
         ref.componentRef.instance["editPoiSeoCode"].subscribe((data) => {
          ref.close();
          this.rowData.seoCodeGetAllSub[this.indexSelected].pointOfInterestId = data.pointOfInterestId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].code = data.code;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectStatusCode = data.redirectStatusCode;
          this.rowData.seoCodeGetAllSub[this.indexSelected].staticRedirectUrl = data.staticRedirectUrl;
          this.rowData.seoCodeGetAllSub[this.indexSelected].languageId = data.languageId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].id = data.id;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectToId = data.redirectToId;
          this.saveSeoCodePoi.emit(this.rowData);
        });
        ref.componentRef.instance["deletePoiSeoCode"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.seoCodeGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.seoCodeGetAllSub.splice(indexToRemove,1);
        
        this.saveSeoCodePoi.emit(this.rowData);
  
        });
       return;
    }
    else{
     const ref = this.dialogService.open(OpenModalSeoCodePoiComponent, {
        context: {
          title: 'Create SeoCode',
          seoCodeId:'',
          pointOfInterestId:this.rowData.id,
          languageId: languageId,
          seoCodeName:'',
          redirectStatusCode:null,
          staticRedirectUrl:"",
          redirectToId:''
        },
      });

      ref.componentRef.instance["addPoiSeoCode"].subscribe((data) => {
        ref.close();
        this.rowData.seoCodeGetAllSub.push(data);
        
        this.saveSeoCodePoi.emit(this.rowData);
  
        });
     return;
    }
  }

}
