import { Component, OnInit, Compiler, ɵConsole } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { ZipService } from '../../../service/zipFile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsTranslationComponent } from '../../news/news-translation/news-translation.component';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { imageGetAll, newsImageAssociationGetAll, imageGet, poiAdminSelectedImageGetAll } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { FileUploadService } from '../../../service/file-upload.service';
import { forkJoin, concat } from 'rxjs';
import { Location } from '@angular/common';
import { concatMap } from 'rxjs/operators';
import { PoiAdminOpenGalleryEditPositionActionComponent } from '../poi-admin-open-gallery-edit-position-action/poi-admin-open-gallery-edit-position-action.component';
@Component({
  selector: 'ngx-poi-admin-edit-image-upload-action',
  templateUrl: './poi-admin-edit-image-upload-action.component.html',
  styleUrls: ['./poi-admin-edit-image-upload-action.component.scss']
})
export class PoiAdminEditImageUploadActionComponent implements OnInit {

  limit = 25;
  selectedMode: boolean = true;
  // This will contain selected rows
  selectedRows: any;
  showbtnUpload: boolean = false;
  public querySubscriptionImageCreate: Subscription;


  public querySubscriptionImageUpdate: Subscription;

  public queryGetAllImage: any;
  public queryGetAllImageByPosition: any;

  public businessPagination: QueryRef<any>;
  public businessPaginationImage: QueryRef<any>;

  public dataProvince: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn: boolean = false;
  public imageAll: any[];
  source: LocalDataSource = new LocalDataSource();

  modelData: any[];
  public imageGetByTag: any[];
  public imageGetByPosition: any[];
  public data: any;
  public sendDataToServer: any;
  public oldData: any;
  public newData: any;

  constructor(public apollo: Apollo, public _location: Location, public service: SmartTableService, public zipS: ZipService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler, public spinner: NgxSpinnerService, public fileUp: FileUploadService) {
    this.imageAll = [];
    this.imageGetByTag = [];
    this.imageGetByPosition = [];
    this.queryGetAllImage = new poiAdminSelectedImageGetAll({}, this.limit).getQuery();
    this.queryGetAllImageByPosition = new poiAdminSelectedImageGetAll({}, this.limit).getQuery();
    this.modelData = [];
    this.newData = [];
    this.oldData = [];
  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: true,
      delete: false
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    columns: {

      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.url) {

            row.UrlConverted = "<img class='imgRendering' src=" + row.url + " style='height:100px;width: 100px;'>";
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted)
          }
        }
      },
      positionGallery: {
        title: 'positionGallery',
        type: 'number',
        filter: false,
        editable: true,
        addable: false
      },
      backOfficeData: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.backOfficeData) {

            const data = JSON.parse(row.backOfficeData);
            if (data !== "") {

              if (data.imagePosition !== undefined) {
                data.imagePosition = (data.imagePosition == "") ? this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>") : data.imagePosition;

                return data.imagePosition
              } else {
                return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
              }
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
        }
      },
      Actions: {
        title: 'Nome',
        type: 'custom',
        renderComponent: PoiAdminOpenGalleryEditPositionActionComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            this.handleUpdatedUser(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.mimeType) {

            return row.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      }

    },
  };
  private handleUpdatedUser(updatedUserData: any) {

    //this.source.refresh();

    this.source.update(updatedUserData, updatedUserData);

  }
  async ngOnInit() {

    this.data = JSON.parse(localStorage.getItem("imageData"));
    this.data.selected = this.data.selected.filter(item => item.mimeType == "image/webp");



    this.source.load(this.data.selected);
    //await this.newsGetbyID();
  }
  async onUserRowSelect(event) {
    console.log(event);

    this.selectedRows = event.data;

    await this.getAllImagebyTagName();

  }

  async  getAllImagebyTagName() {


    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllImage,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'tags': this.selectedRows.tags[0]

        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.poiAdminSelectedImageGetAll.length > 0 ? ((data.data['poiAdminSelectedImageGetAll'][data.data['poiAdminSelectedImageGetAll'].length - 1]['id'])) : null;
      this.queryGetAllImage = new poiAdminSelectedImageGetAll({}, this.limit, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'tags': this.selectedRows.tags[0]

          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['poiAdminSelectedImageGetAll'].length < 1) {
            this.imageGetByTag.push(...prev['poiAdminSelectedImageGetAll']);

            this.imageGetByTag = this.imageGetByTag.map((item) => {
              return item;
            })

            console.log(this.imageGetByTag);
            return prev;
          } else {
            return Object.assign({}, prev, {
              poiAdminSelectedImageGetAll: [...prev['poiAdminSelectedImageGetAll'], ...fetchMoreResult['poiAdminSelectedImageGetAll']]
            });

          }
        }
      });
    });
  }
  uploadImage() {

    this._compiler.clearCache();
    localStorage.removeItem("poiAdminSelectedId");
    localStorage.removeItem("imageData");
    this.router.navigateByUrl("/pages/pointOfInterest/adminSelected-upload-Image");
  }

  DeleteImageUpload() {
    console.log("DeleteImageUpload.imageGetByTag", this.imageGetByTag)
    var tasks$ = [];
    for (let index = 0; index < this.imageGetByTag.length; index++) {
      tasks$.push(this.fileUp.poiAdminSelectedImageDelete(this.imageGetByTag[index].id))
    }

    return concat(...tasks$).subscribe(y => {
      this.goBack()
    }, (err) => {
      this.router.navigateByUrl("/pages/pointOfInterest/admin");
    })

  }
  _DeleteImageUpload() {

    var tasks$ = [];


    for (let index = 0; index < this.imageGetByTag.length; index++) {
      this.imageGetByTag[index].imageGetSub.tags = Array.from(new Set([...this.imageGetByTag[index].imageGetSub.tags.slice(0, this.imageGetByTag[index].imageGetSub.tags.indexOf("POI_ADMIN_IMAGE")),
      ...this.imageGetByTag[index].imageGetSub.tags.slice(this.imageGetByTag[index].imageGetSub.tags.indexOf("POI_ADMIN_IMAGE") + 1)]));


      tasks$.push(this.fileUp.poiAdminSelectedImageDelete(this.imageGetByTag[index].id).pipe(
        concatMap((first) => {
          return this.fileUp.updateImage(this.imageGetByTag[index].imageId, this.imageGetByTag[index].imageGetSub.tags);
        })
      ));



    }
    console.log(tasks$)

    return concat(...tasks$).subscribe(y => {
      this.showbtnUpload = true;
    }, (err) => {
      this._compiler.clearCache();

      this.router.navigateByUrl("/pages/pointOfInterest/admin");

    })

  }
  goBack() {
    this._location.back();
  }


  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }



  async onEditConfirm(event) {

    this.sendDataToServer = [];
    if (event.data.positionGallery !== null) {
      var indexOlDataPosition = this.data.selected.findIndex(item => parseInt(item.positionGallery) === parseInt(event.newData.positionGallery))
      if (indexOlDataPosition > -1) {
        this.data.selected[indexOlDataPosition].positionGallery = event.data.positionGallery;
        this.oldData = this.data.selected[indexOlDataPosition];
      }

      var newDatabyId = this.data.selected.findIndex(item => parseInt(item.id) === parseInt(event.newData.id))
      if (newDatabyId > -1) {
        this.data.selected[newDatabyId].positionGallery = event.newData.positionGallery;
        this.newData = this.data.selected[newDatabyId];
      }
    }
    if (event.data.positionGallery !== null) {

      Promise.all([this.getAllImagebyPosition(this.newData), this.getAllImagebyPosition(this.oldData)]).then(([res1, res2]) => {

        const FilterNewData: any[] = res1.filter(item => item.pointOfInterestAdminSelectedId == event.newData.pointOfInterestAdminSelectedId);
        const FilterOldData: any[] = res2.filter(item => item.pointOfInterestAdminSelectedId == event.newData.pointOfInterestAdminSelectedId);
        const dataNew: any[] = this.removeDuplicates(FilterNewData, 'imageId');

        for (let index = 0; index < dataNew.length; index++) {
          dataNew[index].positionGallery = this.newData.positionGallery;
        }
        this.sendDataToServer = dataNew;

        const dataOld: any[] = this.removeDuplicates(FilterOldData, 'imageId');

        for (let index = 0; index < dataOld.length; index++) {
          dataOld[index].positionGallery = this.oldData.positionGallery;
        }

        this.sendDataToServer = dataOld;

        this.Edit(this.sendDataToServer);
      }).catch((err) => {

      });
    }

    if (event.data.positionGallery === null) {
      var indexOlDataPosition = this.data.selected.findIndex(item => parseInt(item.id) === parseInt(event.newData.id))
      this.data.selected[indexOlDataPosition].positionGallery = event.newData.positionGallery;

      Promise.all([this.getAllImagebyPosition(event.newData)]).then(([res1]) => {

        const FilterNewData: any[] = res1.filter(item => item.pointOfInterestAdminSelectedId == event.newData.pointOfInterestAdminSelectedId);

        const dataNew: any[] = this.removeDuplicates(FilterNewData, 'imageId');

        for (let index = 0; index < dataNew.length; index++) {
          dataNew[index].positionGallery = event.newData.positionGallery;
        }
        this.sendDataToServer = dataNew;
        console.log(dataNew);

        this.Edit(this.sendDataToServer);
      }).catch((err) => {
        console.log(err);
      });
    }

  }


  async  getAllImagebyPosition(event): Promise<any> {
    this.imageGetByPosition=[];
    return new Promise((resolve, reject) => {
      this.businessPaginationImage = this.apollo.watchQuery<Response>({
        query: this.queryGetAllImageByPosition,
        variables: {
          data: {
            'tags': event.tags[0]

          },
        },
      });

      this.querySubscriptionImageUpdate = this.businessPaginationImage.valueChanges.subscribe((data: any) => {
        const lastId = data.data.poiAdminSelectedImageGetAll.length > 0 ? ((data.data['poiAdminSelectedImageGetAll'][data.data['poiAdminSelectedImageGetAll'].length - 1]['id'])) : null;
        this.queryGetAllImageByPosition = new poiAdminSelectedImageGetAll({}, this.limit, lastId).getQuery();
        this.businessPaginationImage.fetchMore({
          query: this.queryGetAllImageByPosition,
          variables: {
            data: {
              'tags': event.tags[0]

            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult['poiAdminSelectedImageGetAll'].length < 1) {

              this.imageGetByPosition.push(...prev['poiAdminSelectedImageGetAll']);

              for (let index = 0; index < this.imageGetByPosition.length; index++) {
                this.imageGetByPosition[index].positionGallery = event.positionGallery;
                this.imageGetByPosition[index].backOfficeData = event.backOfficeData;
              }
              resolve(this.imageGetByPosition);
              // return prev;
            } else {
              return Object.assign({}, prev, {
                poiAdminSelectedImageGetAll: [...prev['poiAdminSelectedImageGetAll'], ...fetchMoreResult['poiAdminSelectedImageGetAll']]
              });

            }
          }
        });
      });
    });
  }

  Edit(data) {

    var tasks$ = [];

    for (let index = 0; index < data.length; index++) {
      tasks$.push(this.fileUp.poiAdminSelectedImageUpdateByGallery(data[index].id, data[index].imageId, data[index].pointOfInterestAdminSelectedId, data[index].positionGallery, data[index].backOfficeData));
    }

    return concat(...tasks$).subscribe(y => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();
        this.source.reset();
        this.source.load(this.data.selected);
      }, 2000);


    }, (err) => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();

      }, 2000);

    })
  }








}



