import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, poiTranslationUpdate, poiTranslationCreate, poiTranslationDelete, poiTypeTranslationCreate, poiTypeTranslationUpdate, poiTypeTranslationDelete, poiMacroTypeTranslationUpdate, poiMacroTypeTranslationCreate, poiMacroTypeTranslationDelete } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigBase } from 'aws-sdk/lib/config';


@Component({
  selector: 'ngx-modal-poi-macro-type-translation',
  templateUrl: './modal-poi-macro-type-translation.component.html',
  styleUrls: ['./modal-poi-macro-type-translation.component.scss']
})
export class ModalPoiMacroTypeTranslationComponent  {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }
  
  @Input() title: string;
  @Input() poiMacroTypeId:any;
  
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() isFromIt:boolean;
  @Input() languageId:any;

  @Input() description:string;
  
  


  constructor(protected ref: NbDialogRef<ModalPoiMacroTypeTranslationComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 


  }

  ngOnInit() {
    

  }
  trackByFn(index: any, item: any) {
    return index;
 }


  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiMacroTypeTranslationDelete.poiMacroTypeTranslationDelete,
      fetchPolicy:'no-cache',
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['poiMacroTypeTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.ref.close();
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
 
  onDeleteConfirm(event): void {
    if (window.confirm('Conferma che voi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }
  
  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiMacroTypeTranslationCreate.poiMacroTypeTranslationCreate,
        fetchPolicy:'no-cache',
        variables: {
          data:{
            poiMacroTypeId:parseInt(this.poiMacroTypeId),
            languageId:this.languageId,
            name:this.translationName,
            description:this.description,
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiMacroTypeTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.ref.close();
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiMacroTypeTranslationUpdate.poiMacroTypeTranslationUpdate,
        fetchPolicy:'no-cache',
        variables: {
          data:{
            poiMacroTypeId:parseInt(this.poiMacroTypeId),
            languageId:this.languageId,
            name:this.translationName,
            id:this.translationId,
            description:this.description,
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiMacroTypeTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.ref.close();
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
}

