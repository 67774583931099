import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Apollo, QueryRef } from 'apollo-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { journeyTranslationUpdate, journeyAdminSelectedImageGetAll, newsImageAssociationGetAll, municipalityImageGetAll, pointOfInterestImageGetAll } from '../../../../app/queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { FileUploadService } from '../../../service/file-upload.service';
import { concatMap } from 'rxjs/operators';
import { concat } from 'rxjs';


@Component({
  selector: 'ngx-modal-poi-gallery-edit-position-action',
  templateUrl: './modal-poi-gallery-edit-position-action.component.html',
  styleUrls: ['./modal-poi-gallery-edit-position-action.component.scss']
})
export class ModalPoiGalleryEditPositionActionComponent implements OnInit {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionImageCreate: Subscription;
  @Input() title: string;
  @Input() positionGallery:number;
  @Input() id:any;
  @Input() tags:any[];
  public imageGetByTag:any[];
  limit: number = 25;
  public mappObj:any;
  public position:string;
  public queryGetAllImage: any;
  public businessPagination: QueryRef<any>;
  
  constructor(protected ref: NbDialogRef<ModalPoiGalleryEditPositionActionComponent>,public apollo:Apollo,public spinner:NgxSpinnerService,public fileUp:FileUploadService) { 
    this.imageGetByTag=[];
    this.queryGetAllImage = new pointOfInterestImageGetAll({}, this.limit).getQuery();
  }

  async ngOnInit() {

    
 
    await this.getAllImagebyTagName();
  }

    async  getAllImagebyTagName() {


    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllImage,
      variables: {
        data: {
          'tags': this.tags
          
        },
      },
    });

    this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.pointOfInterestImageGetAll.length > 0 ? ((data.data['pointOfInterestImageGetAll'][data.data['pointOfInterestImageGetAll'].length - 1]['id'])) : null;
      this.queryGetAllImage = new pointOfInterestImageGetAll({}, this.limit, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllImage,
        variables: {
          data: {
            'tags': this.tags
            
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult['pointOfInterestImageGetAll'].length < 1) {
            this.imageGetByTag.push(...prev['pointOfInterestImageGetAll']);

            this.imageGetByTag = this.imageGetByTag.map((item) => {
              return item;
            })

            console.log(this.imageGetByTag);
            return prev;
          } else {
            return Object.assign({}, prev, {
              pointOfInterestImageGetAll: [...prev['pointOfInterestImageGetAll'], ...fetchMoreResult['municipalityImageGetAll']]
            });

          }
        }
      });
    });
  }
  dismiss() {
    this.ref.close();
  }
  Edit() {
        const dataP =   {
          position:this.mappObj.position
        }
    var tasks$ = [];
    
    for (let index = 0; index < this.imageGetByTag.length; index++) {
     
      tasks$.push(this.fileUp.pointOfInterestImageUpdateByGallery(this.imageGetByTag[index].id,this.imageGetByTag[index].imageId,this.imageGetByTag[index].pointOfInterestId,this.positionGallery,JSON.stringify("")));
    }
    console.log(tasks$)

    return concat(...tasks$).subscribe(y => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();
        
      }, 2000);
      

    }, (err) => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();
        
      }, 2000);
      
    })
  } 
}




