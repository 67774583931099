import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { Router } from '@angular/router';

@Component({
  selector: 'ngx-open-poi-cover-image-upload-action',
  templateUrl: './open-poi-cover-image-upload-action.component.html',
  styleUrls: ['./open-poi-cover-image-upload-action.component.scss']
})
export class OpenPoiCoverImageUploadActionComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  setData:any[];
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";

  class = "btn-success"
  
  public data = {
    data : {},
    isSelected : true,
    selected:[]
  
  };
  constructor(public dialogService:NbDialogService,public router:Router) {

    this.setData=[];
  }

  ngOnInit() {
      //this.renderValue = this.value.toString().toUpperCase();
    console.log("qui c'è? " + this.rowData.id, this.rowData)
    if (this.rowData.id > 0) {
      if (this.rowData.pointOfInterestImageGetAllSub.filter(item => (item.imageGetSub.mimeType=="image/webp" && item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes("POI_COVER_IMAGE"))).length == 0) {
        this.class = "btn-danger"
      }
    }
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openImageModal() {
    console.log(this.rowData.id);
    if(this.rowData.id > 0){
  
      localStorage.setItem("poiId",this.rowData.id);
         this.router.navigateByUrl("/pages/pointOfInterest/cover-upload-image");
        
      }
  }
  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
   }
  openEditModal() {

    localStorage.removeItem("imageData");
    if (this.rowData.id > 0) {
      
      var imageFilter = this.rowData.pointOfInterestImageGetAllSub.filter(item => (item.imageGetSub.mimeType=="image/webp" && item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes("POI_COVER_IMAGE")));
      
      console.log("entrei", imageFilter);
        for (let index = 0; index < imageFilter.length; index++) {

          imageFilter[index].imageGetSub.positionCover = imageFilter[index].positionCover;
          imageFilter[index].imageGetSub.imageId = imageFilter[index].imageId;
          imageFilter[index].imageGetSub.backOfficeData =  (imageFilter[index].backOfficeData == undefined) ?  "":imageFilter[index].backOfficeData;
          imageFilter[index].imageGetSub.pointOfInterestId = imageFilter[index].pointOfInterestId;
          this.setData.push(imageFilter[index].imageGetSub);
        }

        if(imageFilter.length > 0) {
          
          this.data = {
            data : imageFilter[0].imageGetSub,
            isSelected : true,
            selected:[]
          
          };
          var imageFilter2:any[] = this.removeDuplicates(this.setData, 'id');
          if (imageFilter2.length > 0) {

            for (let index = 0; index < imageFilter2.length; index++) {
    
              this.data.selected.push(imageFilter2[index]);
            }
            localStorage.setItem("imageData",JSON.stringify(this.data));
  
      localStorage.setItem("poiId",this.rowData.id);
      this.router.navigateByUrl("/pages/pointOfInterest/edit-cover-upload-image");
          }
        }
    

    }
  }
  // openEditModal() {

  //   localStorage.removeItem("imageData");
  //   if(this.rowData.id > 0){

  //     const imageFilter:any[] = Array.from(new Set(this.rowData.pointOfInterestImageGetAllSub.filter((image) => {
  //       return (((image.imageGetSub.role === 'ORIGINAL')) && (image.imageGetSub.tags.indexOf('POI_COVER_IMAGE')));
  //     }).map((pickSingleImages:any) => JSON.stringify(pickSingleImages)))
  // ).map((imageToParse:any) => {
  //     return JSON.parse(imageToParse);
  //   });
    
  //     if(imageFilter.length > 0) {
  //     this.data = {
  //       data : imageFilter[0].imageGetSub,
  //       isSelected : true,
  //       selected:[]
      
  //     };
  //     for (let index = 0; index < imageFilter.length; index++) {
  //       this.data.selected.push(imageFilter[index]);
        
  //     }
  //     localStorage.setItem("imageData",JSON.stringify(this.data));
  
  //     localStorage.setItem("poiId",this.rowData.id);
  //     this.router.navigateByUrl("/pages/pointOfInterest/edit-cover-upload-image");
  //   }
  // }
  // }
  
}



