import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, seoCodeUpdate, seoCodeDelete, seoCodeCreate, municipalityGet, seoTagCreate, seoTagUpdate, seoTagDelete, imageTranslationDelete, imageTranslationCreate, imageTranslationUpdate } from '../../../queryMutation';

@Component({
  selector: 'ngx-immagini-modal-translation',
  templateUrl: './immagini-modal-translation.component.html',
  styleUrls: ['./immagini-modal-translation.component.scss']
})
export class ImmaginiModalTranslationComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() translationId:any;
  @Input() languageId:any;
  @Input() imageId:any;
  @Input() translationName:string;
  
  @Output("addImageTag") addImageTag: EventEmitter<any> = new EventEmitter();
    
  @Output("editImageTag") editImageTag: EventEmitter<any> = new EventEmitter();
  @Output("deleteImageTag") deleteImageTag: EventEmitter<any> = new EventEmitter();
  constructor(public ref: NbDialogRef<ImmaginiModalTranslationComponent>,public apollo:Apollo) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent(){
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: imageTranslationDelete.imageTranslationDelete,
      variables: {
        'id':parseInt(this.translationId)
      }
    }).subscribe((result: any) => {
      if( result.data['imageTranslationDelete']['success']){
        this.deleteImageTag.emit({ 
          'id':parseInt(this.translationId)
        });
      }
     });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Do you want to delete this Translation?')) {

      this.seoCodeDeleteEvent();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
 
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: imageTranslationCreate.imageTranslationCreate,
        variables: {
          data:{
            'imageId':parseInt(this.imageId),
            'languageId':parseInt(this.languageId),
            'name':this.translationName,
          

          }
      },
      }).subscribe((result: any) => {
        if( result.data['imageTranslationCreate']['success']){
          this.addImageTag.emit({ 
            'imageId':parseInt(this.imageId),
            'languageId':parseInt(this.languageId),
            'name':this.translationName,
            'id':result.data['imageTranslationCreate']['id'],
          });
        }
       });
    } 
    Edit(item) {
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: imageTranslationUpdate.imageTranslationUpdate,
        variables: {
          data:{
            'imageId':parseInt(this.imageId),
            'languageId':parseInt(this.languageId),
            'name':this.translationName,
          
            'id':parseInt(this.translationId)
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['imageTranslationUpdate']['success']){
          this.editImageTag.emit({ 
            'imageId':parseInt(this.imageId),
            'languageId':parseInt(this.languageId),
            'name':this.translationName,
            'id':parseInt(this.translationId),
          });
        }
       });
    } 
   }


