import { Injectable } from '@angular/core';

import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { concatMap, concat, mergeAll, mergeMap, catchError, map, switchMap, skip, tap, take } from 'rxjs/operators';

import * as Generic from '../service/Image';
import { throwError, of, iif, from, Subscriber, observable } from 'rxjs';
import gql from 'graphql-tag';
import {
  ImageUploadType,
  SignedUrlOut,
  ImageCreateFromUpload,
  RemoteFileUploadRequestGetAllResponse,
  BaseUserImageCreate,
  newsImageAssociationOut
} from '../service/Image';

import { ApolloQueryResult } from 'apollo-client';
import { Apollo } from 'apollo-angular';
import { HttpRequestService } from './http-request.service';
import { ObservableLike } from 'zen-observable-ts';
import { imageCreateFromUpload } from '../queryMutation';

var myReader: FileReader = new FileReader();
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  public filesToUpload: any[];
  MAX_GET_ALL_LIMIT = 25;
  imageData = { image: '' };
   data = {
    imageBase64:"",
    width:"",
    height:""
  }
  constructor(public http: HttpClient, public apollo: Apollo, public requestService: HttpRequestService) { }

  //upload Image To Amazon S3
  public uploadfileAWSS3(fileuploadurl, contenttype, file): Observable<any> {
    //this will be used to upload all csv files to AWS S3
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const req = new HttpRequest('PUT', fileuploadurl, file, {
      headers: headers,
      reportProgress: false, //This is required for track upload process
    });


    return this.http.request(req);



  }

  public NewuploadImageToAWSS3(
    signedUrl: string,
    headers: string | { [name: string]: string | string[]; } | {key: string; value: string}[],
    imageToUpload: File
  ): Observable<any> {
    const parsedHeaders = {};
    (headers as {key: string; value: string}[]).forEach((singleHeader) => {
      parsedHeaders[singleHeader.key] = singleHeader.value;
    });
    const awsHeaders = new HttpHeaders(parsedHeaders);

    return this.http.put(
      signedUrl,
      imageToUpload,
      {
        headers: awsHeaders,
        reportProgress: false,
        observe: 'events'
      }
    );

  }

  public convertImageToWebp(files) {
          this.fileToString(files).then((binary: string): any => { 
            console.log(binary);
            return binary});
  }

  public readFileWebp(inputFile):Observable<any> {
   
    myReader.readAsDataURL(inputFile);

     return Observable.create((sub: Subscriber<string>): void => {
     
      myReader.onloadstart = (ev: ProgressEvent): void => {
        sub.next((ev.target as any).result);
    };
    });

}

public b64toBlob(dataURI:string,type:string){
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
}

public fileToString(file: File){

  const r = new FileReader();

  return new Promise((resolve, reject) => {
    r.onload = () => {
   
      resolve(r.result);
    };
    r.readAsDataURL(file);
  });
} 
public fileBlob(file){

  const r = new FileReader();

  return new Promise((resolve, reject) => {
    r.onload = () => {
   
      resolve(r.result);
    };
    r.readAsDataURL(file);
  });
} 
public CalculateHeight(newWight, originalWidth, originalHeight){

  return parseInt(((newWight/(originalWidth/originalHeight))).toString())

  
}

// end fileToString()
  public imageUpload(
    imageUploadInput: ImageUploadType,
    fileToUpload: File
  ): Observable<never | ApolloQueryResult<newsImageAssociationOut>> {
    return this.createUploadLink(imageUploadInput).pipe(
      catchError((err) => {
        return this.remoteFileUploadRequestGetAll(
          imageUploadInput.uploadedByBaseUserId
        ).pipe(
          concatMap((remoteRequests) => {
            return this.remoteFileUploadRequestDelete(
              remoteRequests.data.remoteFileUploadRequestGetAll[0].id
            );
          }),
          tap(() => {
            throw new Error('Upload error, try again');
          })
        );
      }),
      take(1),
      concatMap((createdLinkData: ApolloQueryResult<SignedUrlOut>) => {
        return this.uploadImageToAWSS3(
          createdLinkData.data.imageCreateUploadLink.signedUrl,
          createdLinkData.data.imageCreateUploadLink.headers,
          fileToUpload
        ).pipe(
          skip(1),
          concatMap((uploadToS3) => {
            return iif(
              () => (uploadToS3.status && uploadToS3.status === 200),
              this.imageCreateFromUpload(
                createdLinkData.data.imageCreateUploadLink.remoteFileUploadRequestId,
                imageUploadInput.tags
              ),
              this.remoteFileUploadRequestDelete(
                createdLinkData.data.imageCreateUploadLink.remoteFileUploadRequestId
              ).pipe(
                switchMap(() => throwError(new Error('Upload error, try again')))
              )
            );
          })
        );
      })
    );
  }
 public createUploadLink(
    imageUploadInput: ImageUploadType
  ): Observable<any> {
    const mutation = gql`
        mutation imageCreateUploadLink(
          $fileName: String!,
          $belongsToId: Long!,
          $size: Long!,
          $mimeType: String!,
          $role: String!,
          $uploadedByBaseUserId:Long!,
          $belongsTo: BelongsTo!
          
        ) {
          imageCreateUploadLink(uploadRequest: {
            fileName: $fileName
            belongsToId: $belongsToId
            size: $size
            mimeType: $mimeType
            role: $role
            uploadedByBaseUserId: $uploadedByBaseUserId
            belongsTo: $belongsTo
          }) {
            expiresIn
            fileName
            headers {
              key
              value
            }
            mimeType
            remoteFileUploadRequestId
            signedUrl
          }
        }
      `;

    return this.apollo.use('base-user').mutate({
      mutation: mutation,
      variables: {
        'fileName': imageUploadInput.fileName,
        'belongsToId': imageUploadInput.belongsToId,
        'size': imageUploadInput.size,
        'mimeType': imageUploadInput.mimeType,
        'role': imageUploadInput.role,
        'uploadedByBaseUserId': imageUploadInput.uploadedByBaseUserId,
        'belongsTo': imageUploadInput.belongsTo
      }
    });
  }
  public loginRefresh(
    refreshToken: string,
    baseUserId: number
  ): Observable<any> {
    const query = gql`
    query loginRefresh(
      $refreshToken: String!
      $baseUserId: Long!
  ) {
      loginRefresh(
          refreshToken: $refreshToken
          loginOptions: []
          baseUserId: $baseUserId
      ) {
          refreshToken
          token
          userId
          scopeErrors {
              actionTaken
              scope
              errors
          }
      }
  }
`;
    return this.apollo.query<any, any>({
      query: query,
      fetchPolicy:'network-only',
      variables: {
        'baseUserId': baseUserId,
        'refreshToken': refreshToken
      }
    });
  }
  public uploadImageToAWSS3(
    signedUrl: string,
    headers: string | { [name: string]: string | string[]; } | {key: string; value: string}[],
    imageToUpload: File
  ): Observable<any> {
    const parsedHeaders = {};
    (headers as {key: string; value: string}[]).forEach((singleHeader) => {
      parsedHeaders[singleHeader.key] = singleHeader.value;
    });
    const awsHeaders = new HttpHeaders(parsedHeaders);

    return this.http.put(
      signedUrl,
      imageToUpload,
      {
        headers: awsHeaders,
        reportProgress: false,
        observe: 'events'
      }
    );
  }

  public imageCreateFromUpload(
    remoteFileUploadRequestId: number,
    tags:any[]
  ): Observable<any> {
    const mutation = gql`
        mutation imageCreateFromUpload(
          $remoteFileUploadRequestId: Long!
          $tags:[String!]!
        ) {
          imageCreateFromUpload(create: {
            remoteFileUploadRequestId: $remoteFileUploadRequestId
            tags: $tags
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('base-user').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'remoteFileUploadRequestId': remoteFileUploadRequestId,
        'tags':tags
      }
    });
  }
  public updateImage(
    id: number,
    tags:any[]
  ): Observable<any> {
    const mutation = gql`
        mutation imageUpdate(
          $id: Long!
          $tags: [String!]!
        ) {
          imageUpdate(updateData: {
            id: $id
            tags:$tags
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('base-user').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        'tags':tags
      }
    });
  }
  public baseUserImageCreate(
    imageId: number,
    baseUserId: number
  ): Observable<any> {
    const mutation = gql`
        mutation baseUserImageCreate(
          $imageId: Long!
          $baseUserId: Long!
        ) {
          baseUserImageCreate(createData: {
            imageId: $imageId
            baseUserId: $baseUserId
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('base-user').mutate({
      mutation: mutation,
      variables: {
        'imageId': imageId,
        'baseUserId': baseUserId
      },
      refetchQueries: [{
        query: gql`
            query baseUserGet($id: Long!) {
              baseUserGet(id: $id) {
                email
                name
                surname
                phoneNumber
                preferredLanguageId
                baseUserImageGetAllSub(genericFilterQuery: {}, limit: 1) {
                  imageGetSub {
                    url
                  }
                }
              }
            }
          `,
        variables: {
          'id': baseUserId
        }
      }]
    });
  }


  public imageGet(
    id: number
  ): Observable<any> {
    const query = gql`
        query imageGet(
          $id: Long!) {
          imageGet(id: $id) {
            bucketName
            createdAt
            description
            doNotShow
            fileName
            
            id
          
            imageTypology
            metaDataCopyright
            mibacId
            mimeType
            path
            permission
            role
            size
            tags
            url
    
          }
        }
      `;

    return this.apollo.watchQuery<any>({
      query: query,
      variables: {
        'id': id
      }
    }).valueChanges;
  }

  public municipalityImageDelete(
    id: number

  ): Observable<any> {
    const mutation = gql`
        mutation municipalityImageDelete(
          $id: Long!
        ) {
          municipalityImageDelete(id: $id) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }
  public municipalityCreate(
    imageId: number,
    municipalityId: number,
    positionCover:number,
    backOfficeData:string
  ): Observable<any> {
    const mutation = gql`
        mutation municipalityImageCreate(
          $imageId: Long!
          $municipalityId: Long!
          $positionCover:Long
          $backOfficeData:String!
        ) {
          municipalityImageCreate(createData: {
            imageId: $imageId
            municipalityId: $municipalityId
            positionCover:$positionCover
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'municipalityId': municipalityId,
        'positionCover':positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }
  public newsImageAssociationCreate(
    imageId: number,
    newsId: number,
    positionCover:number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation newsImageAssociationCreate(
          $imageId: Long!
          $newsId: Long!
          $positionCover:Long,
          $backOfficeData:String!
        ) {
          newsImageAssociationCreate(createData: {
            imageId: $imageId
            newsId: $newsId
            positionCover:$positionCover
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'newsId': newsId,
        'positionCover':positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }
  public newsImageAssociationDelete(
    id: number

  ):  Observable<any> {
    const mutation = gql`
        mutation newsImageAssociationDelete(
          $id: Long!
        ) {
          newsImageAssociationDelete(
            id: $id) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }

  
  public poiAdminSelectedImageDelete(
    id: number

  ):  Observable<any> {
    const mutation = gql`
        mutation poiAdminSelectedImageDelete(
          $id: Long!
        ) {
          poiAdminSelectedImageDelete(
            id: $id
           
          ) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }
  
  
  public journeyAdminSelectedImageDelete(
    id: number

  ):  Observable<any> {
    const mutation = gql`
        mutation journeyAdminSelectedImageDelete(
          $id: Long!
        ) {
          journeyAdminSelectedImageDelete(
            id: $id
           
          ) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }

  public journeyImageDelete(
    id: number

  ): Observable<any> {
    const mutation = gql`
        mutation journeyImageDelete(
          $id: Long!
        ) {
          journeyImageDelete(
            id: $id
           
          ) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }
  public pointOfInterestImageDelete(
    id: number

  ):  Observable<any> {
    const mutation = gql`
        mutation pointOfInterestImageDelete(
          $id: Long!
        ) {
          pointOfInterestImageDelete(
            id: $id
           
          ) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id': id,
        
      }
    }).pipe(
      take(1)
    );
  }
  public pointOfInterestImageCreate(
    imageId: number,
    pointOfInterestId: number,
    positionCover:number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation pointOfInterestImageCreate(
          $imageId: Long!
          $pointOfInterestId: Long!
          $positionCover: Long
          $backOfficeData: String!
        ) {
          pointOfInterestImageCreate(createData: {
            imageId: $imageId
            pointOfInterestId: $pointOfInterestId
            positionCover:$positionCover
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'pointOfInterestId': pointOfInterestId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
        
      }
    }).pipe(
      take(1)
    );
  }
  
  public journeyAdminSelectedImageCreate(
    imageId: number,
    journeyAdminSelectedId: number,
    positionCover:number,
    backOfficeData:string
  ): Observable<any> {
    const mutation = gql`
        mutation journeyAdminSelectedImageCreate(
          $imageId: Long!
          $journeyAdminSelectedId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          journeyAdminSelectedImageCreate(createData: {
            imageId: $imageId
            journeyAdminSelectedId: $journeyAdminSelectedId
            positionCover: $positionCover
            backOfficeData: $backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'journeyAdminSelectedId': journeyAdminSelectedId,
        'positionCover':positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public journeyImageCreate(
    imageId: number,
    journeyId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation journeyImageCreate(
          $imageId: Long!
          $journeyId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          journeyImageCreate(createData: {
            imageId: $imageId
            journeyId: $journeyId,
            positionCover: $positionCover,
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('movery-user').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'journeyId': journeyId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public poiAdminSelectedImageCreate(
    imageId: number,
    pointOfInterestAdminSelectedId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation poiAdminSelectedImageCreate(
          $imageId: Long!
          $pointOfInterestAdminSelectedId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          poiAdminSelectedImageCreate(createData: {
            imageId: $imageId
            pointOfInterestAdminSelectedId: $pointOfInterestAdminSelectedId,
            positionCover: $positionCover
            backOfficeData: $backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'imageId': imageId,
        'pointOfInterestAdminSelectedId': pointOfInterestAdminSelectedId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public journeyAdminSelectedImageUpdate(
    id:number,
    imageId: number,
    journeyAdminSelectedId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation journeyAdminSelectedImageUpdate(
          $id:Long!
          $imageId: Long!
          $journeyAdminSelectedId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          journeyAdminSelectedImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            journeyAdminSelectedId: $journeyAdminSelectedId,
            positionCover: $positionCover,
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'journeyAdminSelectedId': journeyAdminSelectedId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public poiAdminSelectedImageUpdate(
    id:number,
    imageId: number,
    pointOfInterestAdminSelectedId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation poiAdminSelectedImageUpdate(
          $id:Long!
          $imageId: Long!
          $pointOfInterestAdminSelectedId: Long!
          $positionCover: Long
          $backOfficeData: String!
        ) {
          poiAdminSelectedImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            pointOfInterestAdminSelectedId: $pointOfInterestAdminSelectedId,
            positionCover: $positionCover,
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'pointOfInterestAdminSelectedId': pointOfInterestAdminSelectedId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public municipalityImageUpdate(
    id:number,
    imageId: number,
    municipalityId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation municipalityImageUpdate(
          $id:Long!
          $imageId: Long!
          $municipalityId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          municipalityImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            municipalityId: $municipalityId,
            positionCover: $positionCover,
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'municipalityId': municipalityId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public newsImageAssociationUpdate(
    id:number,
    imageId: number,
    newsId: number,
    positionCover: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation newsImageAssociationUpdate(
          $id:Long!
          $imageId: Long!
          $newsId: Long!
          $positionCover: Long,
          $backOfficeData: String!
        ) {
          newsImageAssociationUpdate(updateData: {
            id:$id
            imageId: $imageId
            newsId: $newsId,
            positionCover: $positionCover,
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'newsId': newsId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public pointOfInterestImageUpdate(
    id:number,
    imageId: number,
    pointOfInterestId: number,
    positionCover:number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation pointOfInterestImageUpdate(
          $id: Long!,
          $imageId: Long!
          $pointOfInterestId: Long!
          $positionCover: Long
          $backOfficeData: String!
        ) {
          pointOfInterestImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            pointOfInterestId: $pointOfInterestId
            positionCover:$positionCover
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'pointOfInterestId': pointOfInterestId,
        'positionCover': positionCover,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }





  public journeyAdminSelectedImageUpdateByGallery(
    id:number,
    imageId: number,
    journeyAdminSelectedId: number,
    positionGallery: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation journeyAdminSelectedImageUpdate(
          $id: Long!
          $imageId: Long!
          $journeyAdminSelectedId: Long!
          $positionGallery: Long,
          $backOfficeData: String!
        ) {
          journeyAdminSelectedImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            journeyAdminSelectedId: $journeyAdminSelectedId,
            positionGallery: $positionGallery
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'journeyAdminSelectedId': journeyAdminSelectedId,
        'positionGallery': positionGallery,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public poiAdminSelectedImageUpdateByGallery(
    id:number,
    imageId: number,
    pointOfInterestAdminSelectedId: number,
    positionGallery: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation poiAdminSelectedImageUpdate(
          $id:Long!
          $imageId: Long!
          $pointOfInterestAdminSelectedId: Long!
          $positionGallery: Long
          $backOfficeData: String!
        ) {
          poiAdminSelectedImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            pointOfInterestAdminSelectedId: $pointOfInterestAdminSelectedId,
            positionGallery: $positionGallery
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'pointOfInterestAdminSelectedId': pointOfInterestAdminSelectedId,
        'positionGallery': positionGallery,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public municipalityImageUpdateByGallery(
    id:number,
    imageId: number,
    municipalityId: number,
    positionGallery: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation municipalityImageUpdate(
          $id: Long!
          $imageId: Long!
          $municipalityId: Long!
          $positionGallery: Long
          $backOfficeData: String!
        ) {
          municipalityImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            municipalityId: $municipalityId,
            positionGallery: $positionGallery
            backOfficeData: $backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'municipalityId': municipalityId,
        'positionGallery': positionGallery,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }

  public newsImageAssociationUpdateByGallery(
    id:number,
    imageId: number,
    newsId: number,
    positionGallery: number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation newsImageAssociationUpdate(
          $id: Long!
          $imageId: Long!
          $newsId: Long!
          $positionGallery: Long
          $backOfficeData: String!
        ) {
          newsImageAssociationUpdate(updateData: {
            id:$id
            imageId: $imageId
            newsId: $newsId,
            positionGallery: $positionGallery
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'newsId': newsId,
        'positionGallery': positionGallery,
        'backOfficeData': backOfficeData,
      }
    }).pipe(
      take(1)
    );
  }

  public pointOfInterestImageUpdatePositionCover(
    id: number,
    imageId: number,
    pointOfInterestId: number,
    positionCover: number,
    positionGallery: number,
    backOfficeData: string
  ):  Observable<any> {
    const mutation = gql`
        mutation pointOfInterestImageUpdate(
          $id: Long!,
          $imageId: Long!
          $pointOfInterestId: Long!
          $positionCover: Long
          $positionGallery: Long
          $backOfficeData: String!
        ) {
          pointOfInterestImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            pointOfInterestId: $pointOfInterestId
            positionCover:$positionCover
            positionGallery:$positionGallery
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;
      
      return this.apollo.use('super-admin').mutate({
        mutation: mutation,
        fetchPolicy:'no-cache',
        variables: {
          'id':id,
          'imageId': imageId,
          'pointOfInterestId': pointOfInterestId,
          'positionCover': positionCover,
          'positionGallery': positionGallery,
          'backOfficeData':backOfficeData
        }
      }).pipe(
        take(1)
      );
  }

  public pointOfInterestImageUpdateByGallery(
    id:number,
    imageId: number,
    pointOfInterestId: number,
    positionGallery:number,
    backOfficeData:string
  ):  Observable<any> {
    const mutation = gql`
        mutation pointOfInterestImageUpdate(
          $id: Long!,
          $imageId: Long!
          $pointOfInterestId: Long!
          $positionGallery: Long
          $backOfficeData: String!
        ) {
          pointOfInterestImageUpdate(updateData: {
            id:$id
            imageId: $imageId
            pointOfInterestId: $pointOfInterestId
            positionGallery:$positionGallery
            backOfficeData:$backOfficeData
          }) {
            id
            success
          }
        }
      `;

    return this.apollo.use('super-admin').mutate({
      mutation: mutation,
      fetchPolicy:'no-cache',
      variables: {
        'id':id,
        'imageId': imageId,
        'pointOfInterestId': pointOfInterestId,
        'positionGallery': positionGallery,
        'backOfficeData':backOfficeData
      }
    }).pipe(
      take(1)
    );
  }
  
  
  public remoteFileUploadRequestGetAll(
    baseUserId: number
  ): Observable<ApolloQueryResult<RemoteFileUploadRequestGetAllResponse>> {
    const query = gql`
        query remoteFileUploadRequestGetAll(
          $baseUserId: Long!
        ) {
          remoteFileUploadRequestGetAll(genericFilterQuery: {
            parentId: $baseUserId
          }) {
            id
          }
        }
      `;

    return this.apollo.use('base-user').watchQuery<RemoteFileUploadRequestGetAllResponse, { baseUserId: number }>({
      query: query,
      variables: {
        'baseUserId': baseUserId
      }
    }).valueChanges;
  }
  public imageUploadManager(
    imageUploadInput: ImageUploadType,
    fileToUpload: File
  ): Observable<never | ApolloQueryResult<any>> {
    return this.createUploadLink(imageUploadInput).pipe(
      catchError((err) => {
        return this.remoteFileUploadRequestGetAll(
          imageUploadInput.uploadedByBaseUserId
        ).pipe(
          concatMap((remoteRequests) => {
            return this.remoteFileUploadRequestDelete(
              remoteRequests.data.remoteFileUploadRequestGetAll[0].id
            );
          }),
          tap(() => {
            throw new Error('Upload error, try again');
          })
        );
      }),
      take(1),
      concatMap((createdLinkData: ApolloQueryResult<SignedUrlOut>) => {
        return this.uploadImageToAWSS3(
          createdLinkData.data.imageCreateUploadLink.signedUrl,
          createdLinkData.data.imageCreateUploadLink.headers,
          fileToUpload
        ).pipe(
          skip(1),
          concatMap((uploadToS3) => {
            return iif(
              () => (uploadToS3.status && uploadToS3.status === 200),
              this.imageCreateFromUpload(
                createdLinkData.data.imageCreateUploadLink.remoteFileUploadRequestId,
                imageUploadInput.tags
              ),
              this.remoteFileUploadRequestDelete(
                createdLinkData.data.imageCreateUploadLink.remoteFileUploadRequestId
              ).pipe(
                switchMap(() => throwError(new Error('Upload error, try again')))
              )
            );
          })
        );
      })
    );
  }
  public remoteFileUploadRequestDelete(
    remoteFileUploadRequestId: number
  ): Observable<any> {
    const mutation = gql`
        mutation remoteFileUploadRequestDelete(
          $id: Long!
        ) {
          remoteFileUploadRequestDelete(
            id: $id
          ) {
            id
            success
          }
        }
      `;

    return this.apollo.use('base-user').mutate({
      mutation: mutation,
      variables: {
        'id': remoteFileUploadRequestId
      }
    });
  }

  poiTranslationGetAll(
    filter: any = {},
    limit?: number,
    cursor?: number
  ): Generic.GenericCursorQueryOutput<any, any> {
    const queryVariables: any = {
      genericFilterQuery: {
        ...filter
      }
    };
    const query = gql`
      query poiTranslationGetAll($genericFilterQuery:getAllFilterPoiTranslationInputType!, $limit: Long,
        $idCursor: Long) {
        poiTranslationGetAll(genericFilterQuery:$genericFilterQuery,limit: $limit,idCursor: $idCursor) {
          id
          languageId
          pointOfInterestId
          name
          additionalInformation
          indicationsContent
          indicationsTitle
          preview
        }
      }
    `;

    queryVariables.limit = limit ? limit : this.MAX_GET_ALL_LIMIT;
    if (cursor) { queryVariables.idCursor = cursor; }
    return {
      queryRef: this.apollo
        .watchQuery<any,any>(
          {
            query: query,
            variables: queryVariables,
            fetchPolicy: 'no-cache'
          }
        ),
      query: query
    };
  }
}
