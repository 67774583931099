


import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Route } from 'aws-sdk/clients/apigatewayv2';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-open-citta-component',
  templateUrl: './open-citta-component.component.html',
  styleUrls: ['./open-citta-component.component.scss']
})

export class OpenCittaComponentComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Input() data: any;
  translationIt="";
  public translations: any;

  constructor(public router:Router) {
this.data =[];
  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.municipalityTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.municipalityTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.municipalityTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal(languageId) {
   localStorage.removeItem("translationID");
   localStorage.removeItem("languageId");
   localStorage.removeItem("cittaId");
   localStorage.removeItem("provinceId");
    if(this.rowData.municipalityTranslationGetAllSub.length > 0){

      var data = this.rowData.municipalityTranslationGetAllSub.filter(item => item.languageId == languageId);

      if(data.length > 0){
        localStorage.setItem("translationID",data[0].id);
        localStorage.setItem("languageId",languageId);
        localStorage.setItem("cittaId",data[0].municipalityId);
       this.router.navigateByUrl("/pages/citta/editCitta");
      }
      else{
        var data = this.rowData.municipalityTranslationGetAllSub.filter(item => item.languageId == 1);
        localStorage.setItem("languageId",languageId);
        localStorage.setItem("provinceId",this.rowData.provinceId);
        localStorage.setItem("cittaId",data[0].municipalityId);
        this.router.navigateByUrl("/pages/citta/create");

      }
    }else{
      localStorage.setItem("languageId",languageId);
      this.router.navigateByUrl("/pages/citta/create");
    }
  }
}

