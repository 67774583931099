import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { featureCreate, macroFeatureGetAll, featureUpdate, macroFeatureCreate, macroFeatureUpdate, macroFeatureGet, poiMacroTypeGet, poiMacroTypeUpdate } from '../../../queryMutation';
import { Apollo, QueryRef } from 'apollo-angular';
import { Route, Router} from '@angular/router';
import { RouterScroller } from '@angular/router/src/router_scroller';
import { ZipService } from '../../../service/zipFile.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ngx-poi-macro-type-update',
  templateUrl: './poi-macro-type-update.component.html',
  styleUrls: ['./poi-macro-type-update.component.scss']
})
export class PoiMacroTypeUpdateComponent implements OnInit {
  fileToUpload:FileList;
  public selectedMacroFeature:any[];
  public newsCategoryPagination:QueryRef<any>;
  public macroFeatureGetAll:any[];
  public queryGetMacroFeature;
  model= {code:'',icon:'',doNotShow:false, isTopPosition:false };
  newsData:any;
  limit:number=25;
  datauri:any;
  myFile:Blob;
  response:any;
  selectedProduct:any;
  iconConvert:any = null;

  configMacroFeature = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Find Macro-Feature ', // label thats displayed in search input,
    searchOnKey: 'code', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  public querySubscriptionProfileUser: Subscription;
  constructor(public apollo:Apollo,public route:Router,public zipFile:ZipService,public spinner: NgxSpinnerService,public _location:Location ) { 

  }

 async ngOnInit() {
    await this.poiMacroTypeGetById();
  }
  handleFileInput(files: File) {
    this.fileToUpload = files[0];
    this.zipFile.generate(this.fileToUpload).then((data:any) => {

      this.iconConvert = data;
     console.log(this.iconConvert);
   });
  }
  async poiMacroTypeGetById() {

    this.querySubscriptionProfileUser = this.apollo.watchQuery<Response>({
      query: poiMacroTypeGet.poiMacroTypeGet,
      variables: {
        'id': parseInt(localStorage.getItem("poiMacroTypeID"))
      },
    })
      .valueChanges.subscribe((result: any) => {

        this.model = result.data.poiMacroTypeGet;
        this.iconConvert = this.model.icon;
      });
  }

  updateMacroFeature(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiMacroTypeUpdate.poiMacroTypeUpdate,
        variables: {
          data:{
          id:parseInt(localStorage.getItem("poiMacroTypeID")),
          code:this.model.code,
            icon:this.iconConvert
          }
      },
      }).subscribe((result: any) => {
        if (result.data['poiMacroTypeUpdate']['success']) {

          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.route.navigateByUrl("/pages/pointOfInterest/poi-macro-type-list");
          }, 2000);
          
         }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
           
          this.spinner.hide();
          this.route.navigateByUrl("/pages/pointOfInterest/poi-macro-type-list");
        }, 2000);
       })
    }
    goBack(){
      this._location.back();
    }
}
