import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getPoiById } from '../../../queryMutation';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'ngx-clone-point-of-interest',
  templateUrl: './clone-point-of-interest.component.html',
  styleUrls: ['./clone-point-of-interest.component.scss']
})
export class ClonePointOfInterestComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  public querySubscriptionProfileUser: Subscription;
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router,public apollo: Apollo) {

  
  }


  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }
  async getPoibyId() {
    this.querySubscriptionProfileUser = this.apollo.watchQuery<Response>({
      query: getPoiById.getPoiById,
      fetchPolicy: 'network-only',
      variables: {
        'id': parseInt(this.rowData.id)
      },
    })
      .valueChanges.subscribe((result: any) => {
        localStorage.setItem("clonedPoi",JSON.stringify(result.data));
        this.router.navigateByUrl("/pages/pointOfInterest/createPoi");
      });

  }

  async openCreatePoi() {
    
    if(this.rowData.id > 0){
      console.log(this.rowData.id);
       await this.getPoibyId();
        
         
  }
  }
}
