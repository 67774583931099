import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { HttpRequestService } from '../../service/http-request.service';

@Component({
  selector: 'ngx-reset-cache',
  templateUrl: './reset-cache.component.html',
  styleUrls: ['./reset-cache.component.scss']
})
export class ResetCacheComponent implements OnInit {

  constructor(public spinner: NgxSpinnerService, public service_request:HttpRequestService) { }

  ngOnInit() {
  }

  reset() {
    alert("OPERAZIONE IN CORSO... ");
    var tasks = []
    tasks.push(this.service_request.clearCache())
    forkJoin(tasks).subscribe((data) => {
      alert("OPERAZIONE TERMINATA...");
    }, (err) => {
      alert("An error occurred.Try again later.");
    })
  }

}
