import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { Route } from 'aws-sdk/clients/apigatewayv2';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-open-macro-feature-component',
  templateUrl: './open-macro-feature-component.component.html',
  styleUrls: ['./open-macro-feature-component.component.scss']
})

export class OpenMacroFeatureComponentComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router) {

  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal() {
    
    if(this.rowData.id > 0){
      console.log(this.rowData.id);
          localStorage.setItem("articoloId",this.rowData.id);
         this.router.navigateByUrl("/pages/macro-feature/edit-macroFeature");
         
  }
  }
}

