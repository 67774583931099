import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalTranslationFeatureItComponent } from '../modal-translation-feature-it/modal-translation-feature-it.component';
import { ModalTranslationFeatureEnComponent } from '../modal-translation-feature-en/modal-translation-feature-en.component';

@Component({
  selector: 'ngx-translation-feature',
  templateUrl: './translation-feature.component.html',
  styleUrls: ['./translation-feature.component.scss']
})
export class TranslationFeatureComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveTransFeat: EventEmitter<any> = new EventEmitter();
  translationIt="";
  data:any;
  translations: any;
  public indexSelected:any;
  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.featureTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.featureTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.featureTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.saveTransFeat.emit(this.rowData);
  }
  openEditModal(languageId) {

    if(this.rowData.featureTranslationGetAllSub.length > 0){
  
        this.indexSelected = this.rowData.featureTranslationGetAllSub.findIndex(item => item.languageId == languageId);
        this.data = this.rowData.featureTranslationGetAllSub.filter(item => item.languageId == languageId);

        if(this.data.length > 0){
          console.log(this.data[0].id);
              const ref = this.dialogService.open(ModalTranslationFeatureItComponent, {
               context: {
               
                title: 'Edit Translation',
                categoryId:this.data[0].featureId,
                translationName : this.data[0].name,
                translationId: this.data[0].id,
                languageId:languageId
               },
             });

             ref.componentRef.instance["editFeatureTrans"].subscribe((data) => {
              ref.close();
      
              this.rowData.featureTranslationGetAllSub[this.indexSelected].featureId = data.featureId;
              this.rowData.featureTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
              this.rowData.featureTranslationGetAllSub[this.indexSelected].name = data.name;
              this.rowData.featureTranslationGetAllSub[this.indexSelected].id = data.id;
              this.saveTransFeat.emit(this.rowData);
            });
            ref.componentRef.instance["deleteFeatureTrans"].subscribe((data) => {
              ref.close();
              const indexToRemove =  this.rowData.featureTranslationGetAllSub.findIndex(res => res.id == data.id);
              this.rowData.featureTranslationGetAllSub.splice(indexToRemove,1);
              this.saveTransFeat.emit(this.rowData);
            });
             
           return;
        }
        else{
          const ref = this.dialogService.open(ModalTranslationFeatureItComponent, {
            context: {
              title: 'Create SeoCode',
              categoryId:this.rowData.id,
              translationName : "",
              translationId: null,
             languageId:languageId
            },
          });
          ref.componentRef.instance["addFeatureTrans"].subscribe((data) => {
            ref.close();
            this.rowData.featureTranslationGetAllSub.push(data);
            this.saveTransFeat.emit(this.rowData);
          });
          return;
          
        }
      
    }
    else{
      const ref = this.dialogService.open(ModalTranslationFeatureItComponent, {
        context: {
          title: 'Insert Translation',
          categoryId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId
        },
      });
      ref.componentRef.instance["addFeatureTrans"].subscribe((data) => {
        ref.close();
        this.rowData.featureTranslationGetAllSub.push(data);
        this.saveTransFeat.emit(this.rowData);
      });
      return;
    }
  }

}
