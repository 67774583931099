import { Component, OnInit,  } from '@angular/core';
import { Subscription } from 'apollo-client/util/Observable';
import { Apollo } from 'apollo-angular';
import { passwordResetConfirmation, passwordResetRequest } from '../queryMutation';
import { NbDialogService } from '@nebular/theme';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-request-pass',
  templateUrl: './request-pass.component.html',
  styleUrls: ['./request-pass.component.scss']
})
export class RequestPassComponent implements OnInit {
public querySubscriptionProfileUser : Subscription
requestPassData:any={};
  constructor(public apollo:Apollo,public dialogService: NbDialogService,public _location:Location) { }

  ngOnInit() {
  }
  requestPass(){
    this.querySubscriptionProfileUser =  this.apollo.mutate({
      mutation: passwordResetRequest.passwordResetRequest,
      variables: {
          'email': this.requestPassData.email,
          'emailDispatchComingFrom':'BACKOFFICE'
      },
    }).subscribe((result: any) => {
      if (result.data['passwordResetRequest']['success']) {
        console.log(result.data.passwordResetRequest);
        // this.openWithoutBackdropClick(dialog);
      } 
     },
     (err) => {
       alert("An error occurred.Try again later.")
      });
  }

  goBack(){
    this._location.back();
  }
}
