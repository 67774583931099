import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { featureCreate, macroFeatureGetAll, poiTypeCreate, poiMacroTypeGetAll } from '../../../queryMutation';
import { Apollo, QueryRef } from 'apollo-angular';
import { Route, Router} from '@angular/router';
import { RouterScroller } from '@angular/router/src/router_scroller';

import { ZipService } from '../../../service/zipFile.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ngx-poi-type-create',
  templateUrl: './poi-type-create.component.html',
  styleUrls: ['./poi-type-create.component.scss']
})
export class PoiTypeCreateComponent implements OnInit {
  public selectedPoiMacroType:any;
  public newsCategoryPagination:QueryRef<any>;
  public poiMacroTypeGetAll:any[];
  public queryGetMacroFeature;
  fileToUpload:FileList;
  model= {code:''};
  newsData:any;
  limit:number=25;
  datauri:any;
  myFile:Blob;
  response:any;
  selectedProduct:any;
  iconConvert:string;

  configMacroType = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing Selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not results found', // text to be displayed when no items are found while searching
    searchPlaceholder: ' Find Macro-Feature ', // label thats displayed in search input,
    searchOnKey: 'code', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  public querySubscriptionProfileUser: Subscription;
  constructor(public apollo:Apollo,public route:Router,public zipFile:ZipService,public _location:Location,public spinner: NgxSpinnerService) { 
  
    this.poiMacroTypeGetAll=[];
    this.queryGetMacroFeature= new poiMacroTypeGetAll({}, this.limit).getQuery();
    this.iconConvert="";
  }

  ngOnInit() {
     this.macroFutureGet();
  }
  handleFileInput(files: File) {
    this.fileToUpload = files[0];
    this.zipFile.generate(this.fileToUpload).then((data:any) => {

      this.iconConvert = data;
     console.log(this.iconConvert);
   });
  }
  createFeature(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );

      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiTypeCreate.poiTypeCreate,
        variables: {
          data:{
            poiMacroTypeId:this.selectedPoiMacroType.id,
            code:this.model.code,
            icon:this.iconConvert
          }
      },
      }).subscribe((result: any) => {
        if (result.data['poiTypeCreate']['success']) {

          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
           
            this.route.navigateByUrl("/pages/pointOfInterest/poi-type-list");
          }, 2000);
         
         }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
           
          this.spinner.hide();
          this.route.navigateByUrl("/pages/pointOfInterest/poi-type-list");

        }, 2000);
       })
    


   
  }
  async macroFutureGet(){
    this.poiMacroTypeGetAll = [];
    this.newsCategoryPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetMacroFeature,
      variables: {
      data: {
      },
    },
    });

    this.querySubscriptionProfileUser = this.newsCategoryPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.poiMacroTypeGetAll.length > 0 ? ((data.data['poiMacroTypeGetAll'][data.data['poiMacroTypeGetAll'].length - 1]['id'])) : null;
          this.queryGetMacroFeature = new poiMacroTypeGetAll({}, this.limit, lastId).getQuery();
          this.newsCategoryPagination.fetchMore({
            query: this.queryGetMacroFeature,
            variables: {
              data: {
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
          
              if (fetchMoreResult['poiMacroTypeGetAll'].length < 1) {
                this.poiMacroTypeGetAll.push(...prev['poiMacroTypeGetAll']);
                
                console.log(this.poiMacroTypeGetAll);
                this.poiMacroTypeGetAll = this.poiMacroTypeGetAll.map((item) => {
                  return item;
                })
                return prev;
              } else {
                return Object.assign({}, prev, {
                  poiMacroTypeGetAll: [...prev['poiMacroTypeGetAll'], ...fetchMoreResult['poiMacroTypeGetAll']]
                });
               
              }
            }
          });
     }); 

    
  }

  goBack(){
    this._location.back();
  }
}
