import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { LayoutService } from '../../../@core/data/layout.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  
  login: any;
  isActive = false;
  timeToRefresh = ""

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private route:Router,
              public spinner: NgxSpinnerService,
              public apollo: Apollo
              ) {
  }

  ngOnInit() {
    this.userService.getUsers()
      .subscribe((users: any) => this.user = users.nick);
      this.uiRefreshToken()
  }

  uiRefreshToken() {
    this.login = JSON.parse(localStorage.login)
    console.log("uiRefreshToken.login", this.login)
    var date = new Date(this.login.expiryDate * 1000)
    this.timeToRefresh = `${date.toLocaleDateString("it-IT")} - ${date.toLocaleTimeString("it-IT")}`
    var delta = this.login.expiryDate * 1000 - Date.now()
    if (delta != 0) {
      delta = delta / (1000 * 60 * 60) // ore
    }
    this.isActive = delta > 3
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  refreshToken(event):void {
    if (window.confirm('Do you want to refresh token?')) {

      this.spinner.show(undefined, {
        type: "square-spin",
        size: "medium",
        bdColor: "rgba(100,149,237, .8)",
        color: "white",
        fullScreen: true,
      })

      this.loginRefresh(localStorage.getItem('refreshToken'), parseInt(localStorage.getItem('userLoggedId'), 10))
        .subscribe((refreshLogin) => {
          console.log('Refreshing Login');
          if (!refreshLogin.data) {
            alert("Token is not refreshed");
            return;
          }
        alert("Token is successfully refreshed")
        console.log("localStorage", localStorage)
        console.log("refreshLogin", refreshLogin)
        localStorage.setItem('userId', refreshLogin.data.loginRefresh.userId.toString())
        localStorage.setItem('refreshToken', refreshLogin.data.loginRefresh.refreshToken)
        localStorage.setItem("userLoggedId", refreshLogin.data.loginRefresh.userId.toString())
        localStorage.setItem('token', JSON.stringify(refreshLogin.data.loginRefresh.token))
        console.log("localStorage", localStorage)

        this.login.expiryDate = refreshLogin.data.loginRefresh.expiryDate
        this.login.refreshToken = refreshLogin.data.loginRefresh.refreshToken
        this.login.token = refreshLogin.data.loginRefresh.token

        localStorage.setItem('login', JSON.stringify(this.login))

        this.uiRefreshToken()
      })


      setTimeout(() => this.spinner.hide(), 2000)

     }
 }

 public loginRefresh(
  refreshToken: string,
  baseUserId: number
): Observable<any> {
  const query = gql`
  query loginRefresh(
    $refreshToken: String!
    $baseUserId: Long!
) {
    loginRefresh(
        refreshToken: $refreshToken
        loginOptions: []
        baseUserId: $baseUserId
    ) {
        refreshToken
        token
        userId
        expiryDate
        scopeErrors {
            actionTaken
            scope
            errors
        }
    }
}
`;
  return this.apollo.query<any, any>({
    query: query,
    fetchPolicy:'network-only',
    variables: {
      'baseUserId': baseUserId,
      'refreshToken': refreshToken
    }
  });
}

   logout(event):void {
     
     if (window.confirm('Do you want to close this application?')) {
            localStorage.clear();
            this.route.navigate(['auth/login']);
      } else {
        event.confirm.reject();
      }
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  gotoMyProfile(){
    this.route.navigate(['auth/profile-user']);
  }

}
