import { Component, OnInit, Compiler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { ZipService } from '../../../service/zipFile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsTranslationComponent } from '../../news/news-translation/news-translation.component';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { imageGetAll, newsImageAssociationGetAll, imageGet, pointOfInterestImageGetAll, getAllImageFromParent } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { FileUploadService } from '../../../service/file-upload.service';
import { forkJoin, concat } from 'rxjs';
import { Location } from '@angular/common';
import { concatMap, last } from 'rxjs/operators';
import { PoiOpenCoverEditPositionActionComponent } from '../poi-open-cover-edit-position-action/poi-open-cover-edit-position-action.component';
@Component({
  selector: 'ngx-poi-edit-cover-image-upload-action',
  templateUrl: './poi-edit-cover-image-upload-action.component.html',
  styleUrls: ['./poi-edit-cover-image-upload-action.component.scss']
})
export class PoiEditCoverImageUploadActionComponent implements OnInit {

  limit = 25;
  selectedMode: boolean = true;
  // This will contain selected rows
  selectedRows: any;
  showbtnUpload: boolean = false;
  public querySubscriptionImageCreate: Subscription;

  modelData: any[];
  public data: any;
  public queryGetAllImage: any;
  public businessPagination: QueryRef<any>;

  public dataProvince: any[];
  public imageGetByPosition: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn: boolean = false;
  public imageAll: any[];
  public imageGetByTag: any[];
  source: LocalDataSource = new LocalDataSource();


  public querySubscriptionImageUpdate: Subscription;

  public queryGetAllImageByPosition: any;


  public businessPaginationImage: QueryRef<any>;

  public sendDataToServer: any;
  public newData: any;
  public oldData: any;

  poiId = 0;

  pointOfInterestImageGetAllSub = []
  limitImage = 50

  constructor(public apollo: Apollo, public _location: Location, public service: SmartTableService, public zipS: ZipService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler, public spinner: NgxSpinnerService, public fileUp: FileUploadService) {
    this.imageAll = [];
    this.imageGetByTag = [];
    this.imageGetByPosition = [];
    this.queryGetAllImageByPosition = new pointOfInterestImageGetAll({}, this.limit).getQuery();
    this.queryGetAllImage = new pointOfInterestImageGetAll({}, this.limit).getQuery();
    this.modelData = [];
  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: true,
      delete: false
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    columns: {

      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      positionCover: {
        title: 'positionCover',
        type: 'number',
        filter: false,
        editable: true,
        addable: false
      },
      backOfficeData: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.backOfficeData) {

            const data = JSON.parse(row.backOfficeData);
            if (data !== "") {

              if (data.imagePosition !== undefined) {
                data.imagePosition = (data.imagePosition == "") ? this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>") : data.imagePosition;

                return data.imagePosition
              } else {
                return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
              }
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
        }
      },
      Actions: {
        title: 'Nome',
        type: 'custom',
        renderComponent: PoiOpenCoverEditPositionActionComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            this.handleUpdatedUser(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.url) {

            row.UrlConverted = "<img class='imgRendering' src=" + row.url + " style='height:100px;width: 100px;'>";
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted)
          }
        }
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.mimeType) {

            return row.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      }

    },
  };

  private handleUpdatedUser(updatedUserData: any) {

    //this.source.refresh();

    this.source.update(updatedUserData, updatedUserData);

  }
  async ngOnInit() {
    this.poiId = parseInt(localStorage.getItem("poiId"))
    /*
    this.data = JSON.parse(localStorage.getItem("imageData"));
    this.data.selected = this.data.selected.filter(item => item.mimeType == "image/webp");
    this.source.load(this.data.selected);
    */

    await this.getAllImageByParent()

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    
  }

  async getAllImageByParent() {
    var length = this.pointOfInterestImageGetAllSub.length
    var lastId = length == 0 ? null : this.pointOfInterestImageGetAllSub[length-1].id
    this.apollo.query({
      query: getAllImageFromParent.pointOfInterestGet,
        fetchPolicy: 'network-only',
        variables: {
          id: this.poiId,
          limit: this.limitImage,
          cursor: lastId
        },
      })
      .subscribe(
        (result: any) => {
          var data = result.data.pointOfInterestGet.pointOfInterestImageGetAllSub
          console.log("pointOfInterestImageGetAllSub", data)
          this.pointOfInterestImageGetAllSub.push(...data)
          if (data.length == this.limitImage)
            this.getAllImageByParent();
          else {
            console.log("pointOfInterestImageGetAllSub", "END", this.pointOfInterestImageGetAllSub.length)
            setTimeout(() => {
              this.spinner.hide()

              this.createSource()

            }, 2000);
          }
        }
      );

  }

  createSource() {
    var filter = this.pointOfInterestImageGetAllSub.filter(item => item.imageGetSub.mimeType == "image/webp" && item.imageGetSub.role == "ORIGINAL")
    var map = filter.map(item => item.imageGetSub)

    console.log("filter", filter)
    console.log("map", map)
    this.source.load(map)
  }

  imagesSelected = []

  async userSelectRows(event) {
    console.log("userSelectRows", event)
    this.imageGetByTag = []
    this.imagesSelected = []
    this.imagesSelected.push(...event.selected)

    event.selected.forEach(element => {
      console.log("userSelectRows.element", element)
    });

  }

  uploadImage() {

    this._compiler.clearCache();
    localStorage.removeItem("poiSelectedAssociationId");
    localStorage.removeItem("imageData");
    this.router.navigateByUrl("/pages/pointOfInterest/cover-upload-image");
  }

  DeleteImageUpload() {

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    
    console.log("imagesSelected", this.imagesSelected)

    var filter = []
    var tasks$ = []
    this.imagesSelected.forEach(element => {
      var list = this.pointOfInterestImageGetAllSub.filter(item => item.imageGetSub.tags[0] == element.tags[0])
      filter.push(...list)
      list.forEach(element => tasks$.push(this.fileUp.pointOfInterestImageDelete(element.id)));
    })

    console.log(filter)
    console.log(tasks$)

    var end = tasks$.length;

    concat(...tasks$).subscribe((y : any) => {
      var id = y['data']['pointOfInterestImageDelete'].id
      console.log("subscribe", id)
      this.pointOfInterestImageGetAllSub = this.pointOfInterestImageGetAllSub.filter(item => item.id != id)

      if (--end <= 0) {
        this.createSource()

        setTimeout(() => {
          this.spinner.hide()
        }, 2000)
      }
    })
  }

  goBack() {
    this._location.back();
  }


  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
  async onEditConfirm(event) {

    this.sendDataToServer = [];
    if (event.data.positionCover !== null) {
      var indexOlDataPosition = this.data.selected.findIndex(item => parseInt(item.positionCover) === parseInt(event.newData.positionCover))
      if (indexOlDataPosition > -1) {
        this.data.selected[indexOlDataPosition].positionCover = event.data.positionCover;
        this.oldData = this.data.selected[indexOlDataPosition];
      }

      var newDatabyId = this.data.selected.findIndex(item => parseInt(item.id) === parseInt(event.newData.id))
      if (newDatabyId > -1) {
        this.data.selected[newDatabyId].positionCover = event.newData.positionCover;
        this.newData = this.data.selected[newDatabyId];
      }
    }
    if (event.data.positionCover !== null) {

      Promise.all([this.getAllImagebyPosition(this.newData), this.getAllImagebyPosition(this.oldData)]).then(([res1, res2]) => {

        const FilterNewData: any[] = res1.filter(item => item.pointOfInterestId == event.newData.pointOfInterestId);
        const FilterOldData: any[] = res2.filter(item => item.pointOfInterestId == event.newData.pointOfInterestId);
        const dataNew: any[] = this.removeDuplicates(FilterNewData, 'imageId');

        for (let index = 0; index < dataNew.length; index++) {
          dataNew[index].positionCover = this.newData.positionCover;
        }
        this.sendDataToServer = dataNew;

        const dataOld: any[] = this.removeDuplicates(FilterOldData, 'imageId');

        for (let index = 0; index < dataOld.length; index++) {
          dataOld[index].positionCover = this.oldData.positionCover;
        }

        this.sendDataToServer = dataOld;

        this.Edit(this.sendDataToServer);
      }).catch((err) => {

      });
    }

    if (event.data.positionCover === null) {
      var indexOlDataPosition = this.data.selected.findIndex(item => parseInt(item.id) === parseInt(event.newData.id))
      this.data.selected[indexOlDataPosition].positionCover = event.newData.positionCover;

      Promise.all([this.getAllImagebyPosition(event.newData)]).then(([res1]) => {

        const FilterNewData: any[] = res1.filter(item => item.pointOfInterestId == event.newData.pointOfInterestId);

        const dataNew: any[] = this.removeDuplicates(FilterNewData, 'imageId');

        for (let index = 0; index < dataNew.length; index++) {
          dataNew[index].positionCover = event.newData.positionCover;
        }
        this.sendDataToServer = dataNew;
        console.log(dataNew);

        this.Edit(this.sendDataToServer);
      }).catch((err) => {
        console.log(err);
      });
    }

  }

  async  getAllImagebyPosition(event): Promise<any> {
    this.imageGetByPosition=[];
    return new Promise((resolve, reject) => {
      this.businessPaginationImage = this.apollo.watchQuery<Response>({
        query: this.queryGetAllImageByPosition,
        variables: {
          data: {
            'tags': event.tags[0]

          },
        },
      });

      this.querySubscriptionImageUpdate = this.businessPaginationImage.valueChanges.subscribe((data: any) => {
        const lastId = data.data.pointOfInterestImageGetAll.length > 0 ? ((data.data['pointOfInterestImageGetAll'][data.data['pointOfInterestImageGetAll'].length - 1]['id'])) : null;
        this.queryGetAllImageByPosition = new pointOfInterestImageGetAll({}, this.limit, lastId).getQuery();
        this.businessPaginationImage.fetchMore({
          query: this.queryGetAllImageByPosition,
          variables: {
            data: {
              'tags': event.tags[0]

            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult['pointOfInterestImageGetAll'].length < 1) {

              this.imageGetByPosition.push(...prev['pointOfInterestImageGetAll']);

              for (let index = 0; index < this.imageGetByPosition.length; index++) {
                this.imageGetByPosition[index].positionCover = event.positionCover;
                this.imageGetByPosition[index].backOfficeData = event.backOfficeData;
              }
              resolve(this.imageGetByPosition);
              // return prev;
            } else {
              return Object.assign({}, prev, {
                pointOfInterestImageGetAll: [...prev['pointOfInterestImageGetAll'], ...fetchMoreResult['pointOfInterestImageGetAll']]
              });

            }
          }
        });
      });
    });
  }

  Edit(data) {

    var tasks$ = [];

    for (let index = 0; index < data.length; index++) {
      tasks$.push(this.fileUp.pointOfInterestImageUpdate(data[index].id, data[index].imageId, data[index].pointOfInterestId, data[index].positionCover, data[index].backOfficeData).pipe(last()));
    }

    return concat(...tasks$).subscribe(y => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();
        this.source.reset();
        this.source.load(this.data.selected);
      }, 2000);


    }, (err) => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();

      }, 2000);

    })
  }
}


