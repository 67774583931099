import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalOpenCittaSeoCodeComponent } from '../modal-open-citta-seo-code/modal-open-citta-seo-code.component';

@Component({
  selector: 'ngx-open-citta-seo-code',
  templateUrl: './open-citta-seo-code.component.html',
  styleUrls: ['./open-citta-seo-code.component.scss']
})
export class OpenCittaSeoCodeComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  public indexSelected : number;
  @Input() value: string | number;
  @Input() rowData: any;

  ddlLanguage = {}
  public data: any[];
  @Output() saveSeoCodeCitta: EventEmitter<any> = new EventEmitter();
  public translations: any;

  constructor(public dialogService: NbDialogService) {

    this.ddlLanguage = {
      availableOptions: [
        { id: "1", name: 'IT' },
        { id: '2', name: 'EN' },
        { id: '4', name: 'FR' },
      ]
    }
    this.data = [];
  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();

    this.translations = []
    this.translations['it'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0;
    this.translations['eng'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0;
    this.translations['fr'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
    this.saveSeoCodeCitta.emit(this.rowData);
  }
  openModal(languageId) {

    this.indexSelected = this.rowData.seoCodeGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == languageId);
    if (this.data.length > 0) {
      console.log(this.data[0].id);
      const ref = this.dialogService.open(ModalOpenCittaSeoCodeComponent, {
        context: {
          title: 'Edit SeoCode',
          seoCodeId: this.data[0].id,
          municipalityId: this.data[0].municipalityId,
          languageId: this.data[0].languageId,
          seoCodeName: this.data[0].code,
          redirectStatusCode: this.data[0].redirectStatusCode,
          staticRedirectUrl: this.data[0].staticRedirectUrl,
          redirectToId:this.data[0].redirectToId
        },
      });
      ref.componentRef.instance["editCittaSeoCode"].subscribe((data) => {
        ref.close();

        this.rowData.seoCodeGetAllSub[this.indexSelected].municipalityId = data.municipalityId;
        this.rowData.seoCodeGetAllSub[this.indexSelected].code = data.code;
        this.rowData.seoCodeGetAllSub[this.indexSelected].redirectToId = data.redirectToId;
        this.rowData.seoCodeGetAllSub[this.indexSelected].redirectStatusCode = data.redirectStatusCode;
        this.rowData.seoCodeGetAllSub[this.indexSelected].staticRedirectUrl = data.staticRedirectUrl;
        this.rowData.seoCodeGetAllSub[this.indexSelected].languageId = data.languageId;
        this.saveSeoCodeCitta.emit(this.rowData);
      });
      ref.componentRef.instance["deleteCittaSeoCode"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.seoCodeGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.seoCodeGetAllSub.splice(indexToRemove,1);
        this.saveSeoCodeCitta.emit(this.rowData);
      });
      return;
    }
    else {
      const ref = this.dialogService.open(ModalOpenCittaSeoCodeComponent, {
        context: {
          title: 'Create SeoCode',
          seoCodeId: '',
          municipalityId: this.rowData.id,
          languageId: languageId,
          seoCodeName: '',
          redirectStatusCode: null,
          staticRedirectUrl: '',
          redirectToId:''
        },
      });
      ref.componentRef.instance["addCittaSeoCode"].subscribe((data) => {
        ref.close();
        this.rowData.seoCodeGetAllSub.push(data);
        this.saveSeoCodeCitta.emit( this.rowData);
      })
      return;
    }

   
  }


}
