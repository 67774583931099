import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { Router } from '@angular/router';



@Component({
  selector: 'ngx-open-poi-image-upload-action',
  templateUrl: './open-poi-image-upload-action.component.html',
  styleUrls: ['./open-poi-image-upload-action.component.scss']
})
export class OpenPoiImageUploadActionComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";

  class = "btn-success"
  
  public setData:any[];
  public data = {
    data : {},
    isSelected : true,
    selected:[]
  
  };
  constructor(public dialogService:NbDialogService,public router:Router) {

    this.setData=[];
  }

  ngOnInit() {
    console.log("input", "value", this.value)
    console.log("input", "rowData", this.rowData)
      this.renderValue = this.value.toString().toUpperCase();
      if (this.rowData.id > 0) {
        if (this.rowData.pointOfInterestImageGetAllSub.filter(item => (item.imageGetSub.mimeType=="image/webp" && item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes("POI_IMAGE"))).length == 0) {
          this.class = "btn-danger"
        }
      }
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openAddModal() {
    console.log(this.rowData.id);
    if(this.rowData.id > 0){
  
          localStorage.setItem("poiId",this.rowData.id);
         this.router.navigateByUrl("/pages/pointOfInterest/upload-image");
        
      }
  }

  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
   }
  openEditModal() {

    localStorage.removeItem("imageData");
    if (this.rowData.id > 0) {

      var imageFilter = this.rowData.pointOfInterestImageGetAllSub.filter(item => (item.imageGetSub.mimeType=="image/webp" && item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes("POI_IMAGE")));
        for (let index = 0; index < imageFilter.length; index++) {

          imageFilter[index].imageGetSub.positionGallery = imageFilter[index].positionGallery;
          imageFilter[index].imageGetSub.imageId = imageFilter[index].imageId;
          imageFilter[index].imageGetSub.backOfficeData =  (imageFilter[index].backOfficeData == undefined) ?  "":imageFilter[index].backOfficeData;
          imageFilter[index].imageGetSub.pointOfInterestId = imageFilter[index].pointOfInterestId;
          this.setData.push(imageFilter[index].imageGetSub);
        }

        if(imageFilter.length > 0) {
          
          this.data = {
            data : imageFilter[0].imageGetSub,
            isSelected : true,
            selected:[]
          
          };
          var imageFilter2:any[] = this.removeDuplicates(this.setData, 'id');
          if (imageFilter2.length > 0) {

            for (let index = 0; index < imageFilter2.length; index++) {
    
              this.data.selected.push(imageFilter2[index]);
            }
            localStorage.setItem("poiSelectedAssociationId",imageFilter[0].id);
            localStorage.setItem("imageData",JSON.stringify(this.data));
        
            localStorage.setItem("poiId",this.rowData.id);
              
    
         this.router.navigateByUrl("/pages/pointOfInterest/edit-upload-image");
          }
        }
    

    }
  }

}
