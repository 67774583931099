import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { passwordChange } from '../queryMutation';
import { Subscription } from 'apollo-client/util/Observable';
import { Location } from '@angular/common';
@Component({
  selector: 'ngx-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  public querySubscriptionProfileUser: Subscription;
  changePass:any={};
  result:any={};
  constructor(public apollo: Apollo,public router: Router,public _location:Location) { }

  ngOnInit() {


  }
  resetPass(){
    this.querySubscriptionProfileUser =  this.apollo.use("base-user").mutate({
      mutation: passwordChange.passwordChange,
      variables: {
        'newPassword': this.changePass.newPassword,
        'oldPassword': this.changePass.oldPassword
      },
    }).subscribe((result: any) => {
      if (result.data['passwordChange']['success']) {
        console.log(result.data.passwordChange);
      } 
     });
  }
  goBack(){
    this._location.back();
  }
}
