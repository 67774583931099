import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';


class Info {
  getAllSub = ""
  navigateByUrlAdd = ""
  navigateByUrlEdit = ""
  nameId = ""
  tag = ""
  imageGetSub_BOId = ""
  associate_BOId = ""
}

@Component({
  selector: 'ngx-image-button-parameter'
})
export class ImageButtonParameter {

  public static TYPE_GALLERY = "GALLERY"
  public static TYPE_COVER = "COVER"

  public static BO_POI = "POI"

  constructor(type, bo) {
    this.type = type
    this.bo = bo
  }

  type: String
  bo: String
}

@Component({
  selector: 'ngx-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss']
})
export class ImageButtonComponent implements OnInit {

  @Input() value: any
  @Input() rowData: any
  ddlLanguage = {}
  @Output() save: EventEmitter<any> = new EventEmitter()

  private info: Info = new Info()

  class = "btn-success"
  getAllSub = "GetAllSub"
  setData = []

  constructor(public dialogService: NbDialogService, public router: Router) { }

  ngOnInit() {
    console.log("input", "value", this.value)
    console.log("input", "rowData", this.rowData)

    switch (this.value.bo) {
      case ImageButtonParameter.BO_POI:
        this.info.getAllSub = "pointOfInterestImage" + this.getAllSub
        this.info.navigateByUrlAdd = "/pages/pointOfInterest/upload-image"
        this.info.navigateByUrlEdit = "/pages/pointOfInterest/edit-upload-image"
        this.info.nameId = "poiId"
        this.info.imageGetSub_BOId = "pointOfInterestId"
        this.info.associate_BOId = "poiSelectedAssociationId"
        if (this.value.type == ImageButtonParameter.TYPE_GALLERY) {
          this.info.tag = "POI_IMAGE"
        } else {
          this.info.tag = "POI_COVER_IMAGE"
        }
        break;
    }

    if (this.rowData.id > 0) {
      if (this.rowData[this.info.getAllSub].filter(item => (item.imageGetSub.mimeType == "image/webp" && item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes(this.info.tag))).length == 0) {
        this.class = "btn-danger"
      }
    }


  }

  onClick() {
    console.log("onClick", this.rowData)
    this.save.emit(this.rowData);
  }

  openAddModal() {
    console.log("openAddModal", this.info.nameId, this.rowData.id)
    if (this.rowData.id > 0) {
      localStorage.setItem(this.info.nameId, this.rowData.id)
      localStorage.setItem("image-type", JSON.stringify(this.value))
      this.router.navigateByUrl(this.info.navigateByUrlAdd)
    }
  }

  openEditModal() {
    if (this.rowData.id > 0) {
      localStorage.setItem(this.info.nameId, this.rowData.id)
      localStorage.setItem("id", this.rowData.id);
      localStorage.setItem("image-type", JSON.stringify(this.value))
      this.router.navigateByUrl(this.info.navigateByUrlEdit)
    }
  }

  private removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }


}
