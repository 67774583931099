import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { QueryRef, Apollo } from 'apollo-angular';
import { FileUploadService } from '../../../service/file-upload.service';
import { pointOfInterestImageGetAll, newsImageAssociationCreate, imageCreateFromUpload, imageCreateUploadLink, pointOfInterestCreate, pointOfInterestImageCreate, poiBillingCreate, poiTravelCategoryCreate, travelCategoryGetAll, provinceGetAll, municipalityGetAll, poiOpeningPeriodCreate, pointOfInterestUpdate, seoCodeCreate, poiOpeningDayCreate, poiTypeGetAll, poiAssignedTypeCreate, poiFeatureCreate, poiFeatureGetAll, featureGetAll, poiLanguageCreate, getAuthor, getFirtsTravelCategories, municipalityGet, regionGetAll, poiOpeningPeriodCreateMany, poiOpeningDayCreateMany, setpoiOpeningDayCreateMany, poiOpeningPeriodCreateDate, superAdminLoggedInData, poiAssignedTypeDelete, travelTagGetAll, journeyAdminSelectedTravelTagCreate, poiTravelTagCreate, pointprova } from '../../../queryMutation';
import { HttpEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpRequestService } from '../../../service/http-request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { concat, from, Observable, forkJoin } from 'rxjs';
import { take, concatMap } from 'rxjs/operators';
import { ImageUploadType, GenericCursorQueryOutput, IBody, ResizeResponse } from '../../../service/Image';
import sortBy from 'lodash/sortBy';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';

var myReader: FileReader = new FileReader();


@Component({
  selector: "ngx-point-of-interest-create",
  templateUrl: "./point-of-interest-create.component.html",
  styleUrls: ["./point-of-interest-create.component.scss"],
})
export class PointOfInterestCreateComponent implements OnInit {
  public querySubscriptionProfileUser: Subscription;
  public businessPaginationPoiType: QueryRef<any>;
  public querySubscriptionAllPoiType: Subscription;
  public queryGetIsSuperAdminLoggedIn: Subscription;
  public superAdminId: number;

  public queryGetAllPoiImage: any;
  public queryGetNewsCategories: any;
  public queryGetProvince: any;
  public queryGetRegion: any;
  public dateF = new Date();
  public todayDate: any;

  public queryGetPoiFeature: any;
  public queryGetPoiType: any;
  showCoverImagefromServer: boolean = false;
  public imageToShowInBody: any[];
  previewImage: any;
  DataInizio: any[];
  DataInizioSetSpecialsHours: any[];
  isPoiTypeHotel: boolean = false;
  imageUpload: boolean = false;
  showPubblicaBtn: boolean = false;
  showOpeningDayBtn: boolean = false;
  showIsClosedPeriod: boolean = false;
  selectedDaysOfWeek: any;
  closedPeriod: boolean = false;
  public dayOfWeeks: any[];
  public selectedLingua: any;
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionAllFeature: Subscription;
  
  travelTagGetAll: any[] = [];
  public selectedTravelTag: any;
  public queryGetTravelTag: any;
  public querySubscriptionAllTravelTag: Subscription;
  public travelTagPagination: QueryRef<any>;

  public querySubscriptionAllRegion: Subscription;
  public queryGetMunicipalityById: any;
  public MunicipalityPagination: QueryRef<any>;
  public PoiImagePagination: QueryRef<any>;
  public pointOfInterestImageGetAll: any[];
  public ImageAssociationGetAll: any[];
  public newsCategoryPagination: QueryRef<any>;
  public businessPagination: QueryRef<any>;
  public businessPaginationRegion: QueryRef<any>;
  public businessPaginationFeature: QueryRef<any>;
  public imagesToShow: any[];
  public municipalityGetAll: any[];
  public featureGetAll: any[];
  typeOfLingua: any;
  public statusGetAll: any[];
  public alwaysOpenDays: any[];
  public validityGetAll: any[];
  daysOfWeekGetAll: any;

  public setSpecialHoursObj: any[];
  public giornoOrario: any[];
  public DataInizioOrarioGiornoSet: any[];

  public periodsOfPoi: any[];
  ImageSource: any;
  lastId = null;
  public provinceGetAll: any[];
  public regionGetAll: any[];

  public seoCodeGetAll: any[];
  public selectedAuthor: any;
  public selectedRegion: any;
  public selectedStatus: any;
  public selectedValidity: any;
  public selectedProvince: any;
  public poiTypeSelected: any;
  image: any;
  public travelCategoryGetAll: any[];
  public selectedTravelCategory: any;
  public selectedMunicipality: any;
  public selectedFeatureId: any;
  public dataInizioNewSpecialHour: any[];
  public DataInizioOrarioGiornoSpeciale: any[];

  dataInizioSpecialHour: any;
  public poiTypeGetAll: any[];
  public filesToUpload: any[];
  public imageToUpload: ImageUploadType;
  imagelink: Observable<{}>;
  modelToMapping: any;

  public openingPeriodId: any;
  public startingTime: Date;
  public endingTime: Date;

  public startingTimeSpecialHour: Date;
  public endingTimeSpecialHour: Date;
  selectedStatusId: any;

  notes: string;

  isCreatedPeriod: boolean = false;
  showSpecialHours: boolean = false;
  showIsDaysWeeks: boolean = false;
  showSetHoursOfWeek: boolean = false;
  closedPeriodSpecialHour: boolean = false;
  showAfterCreatePeriod: boolean = false;
  showImpostaValiditaBtn: boolean = false;

  model: any = {
    address: "",
    latitude: "",
    longitude: "",
    estimatedVisitLength: "",
    workInProgress: false,
    importance: "",
    openingPeriodValidated: false,
    municipalityId: "",
    starsAdmin: "",
    nearbyTypesFifteenKilometers: [],
    nearbyTypesFiveKilometers: [],
    nearbyTypesTenKilometers: [],
    prezzo: "",
    onlineUrl: "",
    features: [],
    poiOpeningPeriodGetAllSub: { startingDateTime: Date, endingDateTime: Date },
    municipalityCode: "",
  };
  configProvince = {
    limitTo: 0,
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find province", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configRegion = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find province", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configTravelCategory = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find category", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configLingua = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find language ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configDaysOfWeek = {
    displayKey: "value", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find days of week", // label thats displayed in search input,
    searchOnKey: "value", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configPoiFeature = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Feature", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configMunicipality = {
    limitTo: 0,
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find municipality ", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configPoiType = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Poitype", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configPoiStatus = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Status ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configValidityStatus = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Status ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configPoiValidity = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Status ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  limit: any = 25;
  fileToUpload: FileList;
  response: any;

  imageData = {
    image: "",
    resize: [
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
    ],
    moveryEnv: "",
  };

  public url: any;

  configAuthor = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Author ", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  constructor(
    public apollo: Apollo,
    public fileUp: FileUploadService,
    public _location: Location,
    public router: Router,
    public requestService: HttpRequestService,
    public _sanitizer: DomSanitizer,
    public spinner: NgxSpinnerService
  ) {
    this.queryGetTravelTag = new travelTagGetAll({}, this.limit).getQuery();
    this.queryGetMunicipalityById = new municipalityGetAll(
      {},
      this.limit
    ).getQuery();
    this.queryGetProvince = new provinceGetAll({}, this.limit).getQuery();
    this.queryGetRegion = new regionGetAll({}, this.limit).getQuery();
    this.queryGetPoiType = new poiTypeGetAll({}, this.limit).getQuery();
    this.queryGetPoiFeature = new featureGetAll({}, this.limit).getQuery();
    this.queryGetNewsCategories = new travelCategoryGetAll(
      {},
      this.limit
    ).getQuery();
    this.queryGetAllPoiImage = new pointOfInterestImageGetAll(
      {},
      this.limit
    ).getQuery();
    this.pointOfInterestImageGetAll = [];
    this.imagesToShow = [];
    this.travelCategoryGetAll = [];
    this.selectedTravelCategory = [];
    this.selectedDaysOfWeek = [];
    this.alwaysOpenDays = [];
    this.selectedMunicipality = [];
    this.municipalityGetAll = [];
    this.provinceGetAll = [];
    this.regionGetAll = [];
    this.poiTypeGetAll = [];
    this.featureGetAll = [];
    this.statusGetAll = [];
    this.DataInizio = [];
    this.DataInizioSetSpecialsHours = [];
    this.DataInizioOrarioGiornoSet = [];
    this.DataInizioOrarioGiornoSpeciale = [];
    this.filesToUpload = [];
    this.validityGetAll = [];
    this.giornoOrario = [];
    this.dayOfWeeks = [];
    this.dataInizioNewSpecialHour = [];
    this.imageToUpload = {
      fileName: "",
      size: 0,
      mimeType: "",
      belongsToId: 0,
      uploadedByBaseUserId: 0,
      role: "",
      belongsTo: "",
      tags: [],
    };
    this.periodsOfPoi = [
      {
        DataInizio: [],
        notValidAfterCreate: false,
        showCloseDerogaPeriodo: false,
        dataInizioSpecialHour: "",
        DataInizioSpecialPeriod: [],
        setSpecialHoursObj: [
          {
            name: "",
            id: "",
            startingTime: "",
            endingTime: "",
            DataInizioOrarioGiornoSet: [],
            isClosed: false,
            showAfterCreateOrarioGiorno: false,
            notValidAfterCreateSetttimanaOrari: false,
          },
        ],
        setOrarioSpecialeObj: [
          {
            notesSpecialHour: "",
            startingTimeSpecialHour: "",
            endingTimeSpecialHour: "",
            dataInizioSpecialHour: [],
            closedPeriodSpecialHour: false,
            showAfterCreateSpecialOrario: false,
            notValidAfterCreateOrario: false,
          },
        ],

        setGiornoSpecialeObj: [
          {
            DataInizioOrarioGiornoSpeciale: [],
            closedPeriodGiornoSpeciale: false,
            notValidAfterCreateSpecialGiorno: false,
          },
        ],
        setPeriodSpecialObj: [
          {
            dataInizioSpecialHour: [],
            isClosedInPeriod: false,
            showAfterCreateSpecialPeriod: false,
            showIsClosedPeriod: false,
            notValidAfterCreateSpecialPeriod: false,
            showIsActiveAfterCreatePeriodoSpecial: true,
          },
        ],

        DataInizioSetGiornoSpeciale: [],
        DataInizioSetSpecialsHours: [],
        startingTimeSpecialHour: new Date(),
        endingTimeSpecialHour: new Date(),

        setNewOpenindDayObj: [
          {
            startingTime: "",
            endingTime: "",
            notes: "",
            selectedDaysOfWeek: [],
            isCreatedPeriod: false,
            showAfterConfirm: false,
            isOrarioExtraShow: false,
            showIsActiveAfterCreateDay: true,
            showImpostaValiditaBtn: true,
            title: "Orario di apertura di base",
          },
        ],

        showAfterCreatePeriod: false,
        showOpeningDayBtn: false,
        showIsClosedPeriod: false,
        showSpecialHours: false,
        showIsDaysWeeks: false,
        showSetHoursOfWeek: false,
        closedPeriod: false,
        closedPeriodSpecialHour: false,
        closedPeriodGiornoSpeciale: false,
        notesSpecialHour: "",
        closeAllDerogatedPeriod: false,
      },
    ];

    this.todayDate = new Date(this.dateF.getFullYear(), 0, 1, 0, 0);
  }
  async getAllTravelTag() {
    console.log('getAllTravelTag()')
    this.travelTagPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetTravelTag,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllTravelTag = this.travelTagPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.travelTagGetAll.length > 0
            ? data.data["travelTagGetAll"][
                data.data["travelTagGetAll"].length - 1
              ]["id"]
            : null;
        console.log(lastId);
        this.queryGetTravelTag = new travelTagGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.travelTagPagination.fetchMore({
          query: this.queryGetTravelTag,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            console.log('getAllTravelTag().updateQuery', prev, fetchMoreResult)
            if (fetchMoreResult["travelTagGetAll"].length < 1) {
              if (this.travelTagGetAll === undefined) 
                this.travelTagGetAll = []
              if (prev !== undefined)
                this.travelTagGetAll.push(...prev["travelTagGetAll"]);

              this.travelTagGetAll.sort(function(a, b) {
                return a.code.localeCompare(b.code)
              });
              
              this.travelTagGetAll = this.travelTagGetAll.map((item) => {
                return item;
              });
              console.log('getAllTravelTag().updateQuery.fetchMoreResult["travelTagGetAll"].length < 1', this.travelTagGetAll)
              return prev;
            } else {
              return Object.assign({}, prev, {
                travelTagGetAll: [
                  ...prev["travelTagGetAll"],
                  ...fetchMoreResult["travelTagGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  async ngOnInit() {
    if (localStorage.getItem("clonedPoi")) {
      this.modelToMapping = JSON.parse(localStorage.getItem("clonedPoi"));

      console.log("clonedPoi", this.modelToMapping.pointOfInterestGet)
      this.selectedProvince = {id: this.modelToMapping.pointOfInterestGet.municipalityGetSub.provinceId};

      console.log("selectedProvince", this.selectedProvince)
      this.model = this.modelToMapping.pointOfInterestGet;
      this.model.prezzo =
        this.modelToMapping.pointOfInterestGet.poiBillingGetSub !== null
          ? this.modelToMapping.pointOfInterestGet.poiBillingGetSub.price
          : 0;
      this.model.onlineUrl =
        this.modelToMapping.pointOfInterestGet.poiBillingGetSub !== null
          ? this.modelToMapping.pointOfInterestGet.poiBillingGetSub.onlineUrl
          : "";

      //this.DataInizio = [(this.modelToMapping.pointOfInterestGet.poiOpeningPeriodGetAllSub.startingDateTime), (this.modelToMapping.pointOfInterestGet.poiOpeningPeriodGetAllSub.endingDateTime)];
    }

    await this.getAllRegion();
    await this.getLingua();
    await this.getStatus();
    await this.getAllTravelTag();

    await this.getDayOfWeeks();
    await this.poiTypeGet();
    await this.getTravelCategories();
    await this.poiFeatureGetRequest();
    await this.getIsAdminLoggedIn();
  }

  async getIsAdminLoggedIn() {
    this.queryGetIsSuperAdminLoggedIn = this.apollo
      .use("super-admin")
      .watchQuery<Response>({
        query: superAdminLoggedInData.superAdminLoggedInData,
        fetchPolicy: "no-cache",
        variables: {},
      })
      .valueChanges.subscribe((result: any) => {
        this.superAdminId = result.data.superAdminLoggedInData.id;
      });
  }
  async getAllAuthor() {
    if (this.seoCodeGetAll.length === 0) {
      this.apollo
        .watchQuery<Response>({
          query: getAuthor.getAuthor,
          fetchPolicy: "no-cache",
          variables: {
            data: {
              seoAssociation: "SUPER_ADMIN",
            },
            limit: 25,
            idCursor: this.lastId,
          },
        })
        .valueChanges.subscribe((data: any) => {
          this.lastId =
            data.data.seoCodeGetAll.length > 0
              ? data.data["seoCodeGetAll"][
                  data.data["seoCodeGetAll"].length - 1
                ]["id"]
              : null;

          this.seoCodeGetAll.push(...data.data.seoCodeGetAll);
          this.seoCodeGetAll = this.seoCodeGetAll.map((item) => {
            return item;
          });
        });
    }
  }
  async getLingua() {
    this.typeOfLingua = this.requestService.getLingua();
    this.typeOfLingua = this.typeOfLingua.map((item) => {
      return item;
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem("clonedPoi");
  }
  
  async getAllProvincebyId() {

    this.provinceGetAll = []
    this.municipalityGetAll = []
    this.selectedProvince = null
    this.selectedMunicipality = null

    if (this.selectedRegion.id === undefined)
      return
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetProvince,
      fetchPolicy: "no-cache",
      variables: {
        data: {
          parentId: this.selectedRegion.id,
        },
      },
    });
    this.travelTagGetAll = [];
    this.querySubscriptionAllProvince = this.businessPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.provinceGetAll.length > 0
            ? data.data["provinceGetAll"][
                data.data["provinceGetAll"].length - 1
              ]["id"]
            : null;
        this.queryGetProvince = new provinceGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPagination.fetchMore({
          query: this.queryGetProvince,

          variables: {
            data: {
              parentId: this.selectedRegion.id,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["provinceGetAll"].length < 1) {
              this.provinceGetAll.push(...prev["provinceGetAll"]);
              this.provinceGetAll = sortBy(this.provinceGetAll, "code");
              
              this.configProvince.limitTo = this.provinceGetAll.length
              this.provinceGetAll = this.provinceGetAll.map((item) => {
                return item;
              });

              if (localStorage.getItem("clonedPoi")) {
                this.selectProvince();
              }
              return prev;
            } else {
              return Object.assign({}, prev, {
                provinceGetAll: [
                  ...prev["provinceGetAll"],
                  ...fetchMoreResult["provinceGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  createTravelTagAssociation(event): void {
    let last: any = event.value[event.value.length - 1];
    console.log(last.id);

    this.querySubscriptionAllTravelTag = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiTravelTagCreate.poiTravelTagCreate,
        variables: {
          data: {
            pointOfInterestId: localStorage.getItem("poiID"),
            travelTagId: last.id,
          },
        },
      })
      .subscribe(
        (result: any) => {
          console.log(result.data);
          if (result.data["poiTravelTagCreate"]["success"]) {
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  async getMunicipalityByParentID() {
    this.municipalityGetAll = []
    this.selectedMunicipality = null
    if (this.selectedProvince.id === undefined)
      return
    this.MunicipalityPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetMunicipalityById,
      fetchPolicy: "no-cache",
      variables: {
        data: {
          parentId: this.selectedProvince.id,
        },
      },
    });

    this.querySubscriptionAllProvince = this.MunicipalityPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.municipalityGetAll.length > 0
            ? data.data["municipalityGetAll"][
                data.data["municipalityGetAll"].length - 1
              ]["id"]
            : null;
        console.log(lastId);
        this.queryGetMunicipalityById = new municipalityGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.MunicipalityPagination.fetchMore({
          query: this.queryGetMunicipalityById,
          variables: {
            data: {
              parentId: this.selectedProvince.id,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["municipalityGetAll"].length < 1) {
              this.municipalityGetAll.push(...prev["municipalityGetAll"]);

              this.municipalityGetAll = sortBy(this.municipalityGetAll, "code");

              this.configMunicipality.limitTo = this.municipalityGetAll.length
              this.municipalityGetAll = this.municipalityGetAll.map((item) => {
                return item;
              });
              console.log(this.municipalityGetAll);
              return prev;
            } else {
              return Object.assign({}, prev, {
                municipalityGetAll: [
                  ...prev["municipalityGetAll"],
                  ...fetchMoreResult["municipalityGetAll"],
                ],
              });
              console.log(this.municipalityGetAll);
            }
          },
        });
      }
    );
  }
  createBozza_() {
    console.log("createBozza")
    this.showPubblicaBtn = true;
    this.apollo.watchQuery<Response>({
      query: pointprova.pointprova,
      fetchPolicy: 'network-only',
      variables: {
        'data': {
        },
        'limit': 25,
      },
    }).valueChanges.subscribe((data: any) => {
      console.log("createBozza", data)
      localStorage.setItem("poiID", data.data.pointOfInterestGetAll[0].id)
      this.router.navigateByUrl("/pages/pointOfInterest/editPoi");
    });

  }
  createBozza() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: pointOfInterestCreate.pointOfInterestCreate,
        variables: {
          data: {
            address: this.model.address,
            estimatedVisitLength:
              this.isPoiTypeHotel == true
                ? -1
                : this.model.estimatedVisitLength,
            importance: 10,
            latitude: this.model.latitude,
            longitude: this.model.longitude,
            nearbyTypesFifteenKilometers: this.model
              .nearbyTypesFifteenKilometers,
            nearbyTypesFiveKilometers: this.model.nearbyTypesFiveKilometers,
            nearbyTypesTenKilometers: this.model.nearbyTypesTenKilometers,
            municipalityId: this.selectedMunicipality.id,
            starsAdmin: 5,
            workInProgress: this.model.workInProgress,
            openingPeriodValidated: this.model.openingPeriodValidated,
            backOfficeData: JSON.stringify(""),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["pointOfInterestCreate"]["success"]) {
            localStorage.setItem(
              "emptyPoi",
              result.data["pointOfInterestCreate"]["id"]
            );
            this.poiBillingCreate();
            this.showPubblicaBtn = true;

            setTimeout(() => {
              // if (this.isPoiTypeHotel == true)
              //   this.selectPoiType();

              this.spinner.hide();

              localStorage.setItem("poiID", result.data["pointOfInterestCreate"]["id"])
              this.router.navigateByUrl("/pages/pointOfInterest/editPoi");

              this.spinner.hide();
              //this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 3000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }
  async getAllRegion() {
    this.businessPaginationRegion = this.apollo.watchQuery<Response>({
      query: this.queryGetRegion,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllRegion = this.businessPaginationRegion.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.regionGetAll.length > 0
            ? data.data["regionGetAll"][data.data["regionGetAll"].length - 1][
                "id"
              ]
            : null;
        this.queryGetRegion = new regionGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPaginationRegion.fetchMore({
          query: this.queryGetRegion,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["regionGetAll"].length < 1) {
              this.regionGetAll.push(...prev["regionGetAll"]);

              this.regionGetAll = sortBy(this.regionGetAll, "code");

              this.regionGetAll = this.regionGetAll.map((item) => {
                return item;
              });

              console.log(this.regionGetAll.sort());
              return prev;
            } else {
              return Object.assign({}, prev, {
                regionGetAll: [
                  ...prev["regionGetAll"],
                  ...fetchMoreResult["regionGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  async poiImageAssociationAll() {
    this.PoiImagePagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllPoiImage,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllProvince = this.PoiImagePagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.pointOfInterestImageGetAll.length > 0
            ? data.data["pointOfInterestImageGetAll"][
                data.data["pointOfInterestImageGetAll"].length - 1
              ]["id"]
            : null;
        this.queryGetAllPoiImage = new pointOfInterestImageGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.PoiImagePagination.fetchMore({
          query: this.queryGetAllPoiImage,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["pointOfInterestImageGetAll"].length < 1) {
              this.pointOfInterestImageGetAll.push(
                ...prev["pointOfInterestImageGetAll"]
              );
              this.pointOfInterestImageGetAll = this.pointOfInterestImageGetAll.map(
                (item) => {
                  return item.imageGetSub;
                }
              );
              this.setImagetoBody();
              return prev;
            } else {
              return Object.assign({}, prev, {
                pointOfInterestImageGetAll: [
                  ...prev["pointOfInterestImageGetAll"],
                  ...fetchMoreResult["pointOfInterestImageGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }
  createPoi() {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: pointOfInterestUpdate.pointOfInterestUpdate,
        variables: {
          data: {
            id: parseInt(localStorage.getItem("emptyPoi")),
            address: this.model.address,
            estimatedVisitLength:
              this.isPoiTypeHotel == true
                ? -1
                : this.model.estimatedVisitLength,
            importance: 10,
            latitude: this.model.latitude,
            longitude: this.model.longitude,
            nearbyTypesFifteenKilometers: this.model
              .nearbyTypesFifteenKilometers,
            nearbyTypesFiveKilometers: this.model.nearbyTypesFiveKilometers,
            nearbyTypesTenKilometers: this.model.nearbyTypesTenKilometers,
            municipalityId: this.selectedMunicipality.id,
            starsAdmin: 5,
            workInProgress: this.model.workInProgress,
            openingPeriodValidated: this.model.openingPeriodValidated,
            backOfficeData: JSON.stringify(""),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["pointOfInterestUpdate"]["success"]) {
            if (this.isPoiTypeHotel === false) this.poiBillingCreate();

            setTimeout(() => {
              this.spinner.hide();
              this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 3000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  async selectProvince() {
    this.selectedProvince = [];
    for (let i = 0; i < this.provinceGetAll.length; i++) {
      if (this.provinceGetAll[i].id == this.modelToMapping.provinceId) {
        this.selectedProvince.push(this.provinceGetAll[i]);
      }
    }
    await this.getMunicipalityByParentID();
  }
  poiBillingCreate() {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiBillingCreate.poiBillingCreate,
        variables: {
          data: {
            onlineUrl: this.model.onlineUrl,
            price: this.model.prezzo,
            openingPeriodValidated: this.model.openingPeriodValidated,
            features:
              this.model.features == null || this.model.features == ""
                ? ""
                : this.model.features,
            pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiBillingCreate"]["success"]) {
            alert("PoiBilling save sucessfully");
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  poiTravelCategoryCreate(event): void {
    let last: any = event.value[event.value.length - 1];

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiTravelCategoryCreate.poiTravelCategoryCreate,
        variables: {
          data: {
            travelCategoryId: last.id,
            pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiTravelCategoryCreate"]["success"]) {
            alert("poiTravelCategory save sucessfully");
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }
  goBack() {
    this._location.back();
  }

  async travelCategoryGet() {
    this.travelCategoryGetAll = [];
    this.newsCategoryPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetNewsCategories,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionProfileUser = this.newsCategoryPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.travelCategoryGetAll.length > 0
            ? data.data["travelCategoryGetAll"][
                data.data["travelCategoryGetAll"].length - 1
              ]["id"] - data.data.travelCategoryGetAll.length
            : null;
        this.queryGetNewsCategories = new travelCategoryGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.newsCategoryPagination.fetchMore({
          query: this.queryGetNewsCategories,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["travelCategoryGetAll"].length < 1) {
              this.travelCategoryGetAll.push(...prev["travelCategoryGetAll"]);

              this.travelCategoryGetAll.sort(function(a, b) {
                  return a.code.localeCompare(b.code)
              });

              this.travelCategoryGetAll = this.travelCategoryGetAll.map(
                (item) => {
                  return item;
                }
              );
              return prev;
            } else {
              return Object.assign({}, prev, {
                travelCategoryGetAll: [
                  ...prev["travelCategoryGetAll"],
                  ...fetchMoreResult["travelCategoryGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  async getTravelCategories() {
    this.apollo
      .watchQuery<Response>({
        query: getFirtsTravelCategories.getFirtsTravelCategories,
        fetchPolicy: "no-cache",
        variables: {
          data: {},
          limit: 25,
          idCursor: null,
        },
      })
      .valueChanges.subscribe((data: any) => {
        this.lastId =
          data.data.travelCategoryGetAll.length > 0
            ? data.data["travelCategoryGetAll"][
                data.data["travelCategoryGetAll"].length - 1
              ]["id"]
            : null;

        this.travelCategoryGetAll.push(...data.data.travelCategoryGetAll);
        this.travelCategoryGetAll = this.travelCategoryGetAll.map((item) => {
          return item;
        });
        //this.getMunicipalityByID(data.data.pointOfInterestGetAll);
      });
  }
  async getDayOfWeeks() {
    this.daysOfWeekGetAll = this.requestService.getDayOfWeeks();
    this.daysOfWeekGetAll = this.daysOfWeekGetAll.map((item) => {
      return item;
    });
  }

  async getMapImageRequest() {
    this.requestService
      .getStaticMapForPoi(this.model.latitude, this.model.longitude)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.url = res;
          this.handleFileInput(res);
        },
        (ex) => {
          console.error(ex);
        }
      );
  }

  uploadMapsToAmazon(blob) {
    this.querySubscriptionProfileUser = this.apollo
      .use("base-user")
      .mutate({
        mutation: imageCreateUploadLink.imageCreateUploadLink,
        variables: {
          data: {
            fileName: "static_map",
            belongsToId: parseInt(localStorage.getItem("emptyPoi")),
            size: blob.size,
            mimeType: blob.type,
            role: "POI_IMAGE_MAP",
            belongsTo: "POINT_OF_INTEREST",
            uploadedBy: "BASE_USER",
            uploadedByBaseUserId: parseInt(
              localStorage.getItem("userLoggedId")
            ),
          },
        },
      })
      .subscribe(
        (result: any) => {
          this.response = result;
          console.log(result.data["imageCreateUploadLink"]);
          if (result.data["imageCreateUploadLink"]) {
            this.fileUp
              .uploadfileAWSS3(
                result.data.imageCreateUploadLink.signedUrl,
                blob.type,
                blob
              )
              .subscribe((event: HttpEvent<any>) => {
                if (event) {
                  this.querySubscriptionProfileUser = this.apollo
                    .use("base-user")
                    .mutate({
                      mutation: imageCreateFromUpload.imageCreateFromUpload,
                      variables: {
                        data: {
                          remoteFileUploadRequestId:
                            result.data.imageCreateUploadLink
                              .remoteFileUploadRequestId,
                          tags: [],
                        },
                      },
                    })
                    .subscribe((result: any) => {
                      if (result.data["imageCreateFromUpload"]["success"]) {
                        this.querySubscriptionProfileUser = this.apollo
                          .use("super-admin")
                          .mutate({
                            mutation:
                              pointOfInterestImageCreate.pointOfInterestImageCreate,
                            variables: {
                              data: {
                                imageId: parseInt(
                                  result.data["imageCreateFromUpload"]["id"]
                                ),
                                pointOfInterestId: parseInt(
                                  localStorage.getItem("emptyPoi")
                                ),
                                backOfficeData: JSON.stringify(""),
                              },
                            },
                          })
                          .subscribe((result: any) => {
                            if (
                              result.data["pointOfInterestImageCreate"][
                                "success"
                              ]
                            ) {
                              this.ImageSource = this._sanitizer.bypassSecurityTrustUrl(
                                window.URL.createObjectURL(blob)
                              );
                              this.imageUpload = true;
                            }
                          });
                      }
                    });
                }
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  handleFileInput(blob) {
    var tasks$ = [];
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    tasks$.push(this.fileUp.fileBlob(blob));

    return Promise.all(tasks$)
      .then((values: any) => {
        var strImage = values[0].replace(/^data:image\/[a-z]+;base64,/, "");

        this.imageData.image = strImage.toString();
        this.imageData.moveryEnv = environment.moveryEnv;

        var img = new Image();
        return new Promise((resolve, reject) => {
          img.onload = function () {
            img.height = img.height;
            img.width = img.width;
            resolve(img);
          };

          img.src = values;
        })
          .then((img: any) => {
            this.imageData.resize[0].fit = "cover";
            this.imageData.resize[0].width = img.width;
            this.imageData.resize[0].height = img.height;

            this.imageData.resize[1].fit = "cover";
            this.imageData.resize[1].width = environment.thumb_low_width;
            this.imageData.resize[1].height = this.fileUp.CalculateHeight(
              environment.thumb_low_width,
              img.width,
              img.height
            );

            this.imageData.resize[2].fit = "cover";
            this.imageData.resize[2].width = environment.thumb_medium_width;
            this.imageData.resize[2].height = this.fileUp.CalculateHeight(
              environment.thumb_medium_width,
              img.width,
              img.height
            );

            this.imageData.resize[3].fit = "cover";
            this.imageData.resize[3].width = environment.thumb_high_width;
            this.imageData.resize[3].height = this.fileUp.CalculateHeight(
              environment.thumb_high_width,
              img.width,
              img.height
            );

            this.executeSecondImagePromise();
          })
          .catch((erro) => {
            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          });
      })
      .catch((erro) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      });
  }

  //this method executed a base64 string and convert to a webP immage to upload to AWS
  executeSecondImagePromise() {
    var tasks$2 = [];
    var tasks$3 = [];
    var tasks$4 = [];
    tasks$2.push(this.requestService.cloudConvertImage(this.imageData));
    return forkJoin(tasks$2).subscribe(
      (datas: IBody<ResizeResponse>[]) => {
        for (let j = 0; j < datas[0].data.results.length; j++) {
          datas[0].data.results[j].jpg =
            "data:image/jpeg;base64," + datas[0].data.results[j].jpg;
          datas[0].data.results[j].webp =
            "data:image/webp;base64," + datas[0].data.results[j].webp;

          tasks$3.push(
            this.fileUp.b64toBlob(datas[0].data.results[j].jpg, "image/jpeg")
          );
          tasks$3.push(
            this.fileUp.b64toBlob(datas[0].data.results[j].webp, "image/webp")
          );
        }
        Promise.all(tasks$3).then((blobValue: any) => {
          for (let i = 0; i < blobValue.length; i++) {
            var fileName = "poi_static_map";
            const timespanValue = parseInt(
              moment().valueOf().toString().slice(6)
            );
            console.log(timespanValue);

            if (blobValue[0].type == "image/jpeg") {
              blobValue[0].name =
                fileName +
                "-" +
                timespanValue +
                "." +
                blobValue[0].type.slice(
                  blobValue[0].type.lastIndexOf("/") + 1,
                  blobValue[0].type.length
                );
              blobValue[0].role = "ORIGINAL";
              blobValue[0].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[1].type == "image/webp") {
              blobValue[1].name =
                fileName +
                "-" +
                timespanValue +
                "." +
                blobValue[1].type.slice(
                  blobValue[1].type.lastIndexOf("/") + 1,
                  blobValue[1].type.length
                );
              blobValue[1].role = "ORIGINAL";
              blobValue[1].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[2].size) {
              blobValue[2].name =
                fileName +
                "-" +
                timespanValue +
                "-l" +
                "." +
                blobValue[2].type.slice(
                  blobValue[2].type.lastIndexOf("/") + 1,
                  blobValue[2].type.length
                );
              blobValue[2].role = "LOW";
              blobValue[2].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[3].size) {
              blobValue[3].name =
                fileName +
                "-" +
                timespanValue +
                "-l" +
                "." +
                blobValue[3].type.slice(
                  blobValue[3].type.lastIndexOf("/") + 1,
                  blobValue[3].type.length
                );
              blobValue[3].role = "LOW";
              blobValue[3].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[4].size) {
              blobValue[4].name =
                fileName +
                "-" +
                timespanValue +
                "-m" +
                "." +
                blobValue[4].type.slice(
                  blobValue[4].type.lastIndexOf("/") + 1,
                  blobValue[4].type.length
                );
              blobValue[4].role = "MEDIUM";
              blobValue[4].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[5].size) {
              blobValue[5].name =
                fileName +
                "-" +
                timespanValue +
                "-m" +
                "." +
                blobValue[5].type.slice(
                  blobValue[5].type.lastIndexOf("/") + 1,
                  blobValue[5].type.length
                );
              blobValue[5].role = "MEDIUM";
              blobValue[5].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[6].size) {
              blobValue[6].name =
                fileName +
                "-" +
                timespanValue +
                "-h" +
                "." +
                blobValue[6].type.slice(
                  blobValue[6].type.lastIndexOf("/") + 1,
                  blobValue[6].type.length
                );
              blobValue[6].role = "HIGH";
              blobValue[6].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[7].size) {
              blobValue[7].name =
                fileName +
                "-" +
                timespanValue +
                "-h" +
                "." +
                blobValue[7].type.slice(
                  blobValue[7].type.lastIndexOf("/") + 1,
                  blobValue[7].type.length
                );
              blobValue[7].role = "HIGH";
              blobValue[7].tags = fileName + "-" + timespanValue;
            }
            console.log(blobValue[i]);
            this.filesToUpload.push(blobValue[i]);
          }
          console.log(this.filesToUpload);

          this.recursiveImageUpload();
        });
      },
      (err) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    );
  }
  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }

  // selectPoiType() {
  //   this.poiTypeSelected = [];
  //   var typeOfPoiType = [
  //     { id: "1", code: 'albergo' },
  //     { id: '2', code: 'bed-and-breakfast' },
  //     { id: '3', code: 'posto-tenda' },
  //     { id: '4', code: 'piazzola' },
  //     { id: '5', code: 'appartamento' },
  //     { id: '6', code: 'bar' },
  //     { id: '7', code: 'trattoria' },
  //     { id: '8', code: 'bottega-gastronomica' },
  //     { id: '9', code: 'agriturismo' },
  //     { id: '10', code: 'vineria-enoteca' },
  //     { id: '11', code: 'ristorante' }
  //   ]

  //   for (let index = 0; index < typeOfPoiType.length; index++) {
  //     var data: any[] = this.poiTypeGetAll.filter(item => item.code === typeOfPoiType[index].code);
  //     this.poiTypeSelected.push(...data);
  //   }
  //   this.model.prezzo = 0;
  //   this.model.features.push("INTERNAL_DO_NOT_SHOW_PRICE");

  //   this.automaticCreatePoiAssignedType(this.poiTypeSelected);
  //   this.poiBillingCreate();
  // }
  async poiTypeGet() {
    this.businessPaginationPoiType = this.apollo.watchQuery<Response>({
      query: this.queryGetPoiType,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllPoiType = this.businessPaginationPoiType.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.poiTypeGetAll.length > 0
            ? data.data["poiTypeGetAll"][data.data["poiTypeGetAll"].length - 1][
                "id"
              ] - data.data.poiTypeGetAll.length
            : null;
        this.queryGetPoiType = new poiTypeGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPaginationPoiType.fetchMore({
          query: this.queryGetPoiType,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["poiTypeGetAll"].length < 1) {
              this.poiTypeGetAll.push(...prev["poiTypeGetAll"]);

              this.poiTypeGetAll = this.poiTypeGetAll.map((item) => {
                return item;
              });

              return prev;
            } else {
              return Object.assign({}, prev, {
                poiTypeGetAll: [
                  ...prev["poiTypeGetAll"],
                  ...fetchMoreResult["poiTypeGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }
  automaticCreatePoiAssignedType(data): void {
    for (let index = 0; index < data.length; index++) {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiAssignedTypeCreate.poiAssignedTypeCreate,
          variables: {
            data: {
              poiTypeId: data[index].id,
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
            },
          },
        })
        .subscribe(
          (result: any) => {
            console.log(result.data);
            if (result.data["poiAssignedTypeCreate"]["success"]) {
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          }
        );
    }
  }

  CreatePoiAsssignedType(event): void {
    let last: any = event.value[event.value.length - 1];
    console.log(last.id);

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiAssignedTypeCreate.poiAssignedTypeCreate,
        variables: {
          data: {
            poiTypeId: last.id,
            pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
          },
        },
      })
      .subscribe(
        (result: any) => {
          console.log(result.data);
          if (result.data["poiAssignedTypeCreate"]["success"]) {
            alert("PoiType save sucessfully");
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  recursiveImageUpload() {
    var tasks$ = [];

    for (let index = 0; index < this.filesToUpload.length; index++) {
      this.imageToUpload.fileName = this.filesToUpload[index].name;
      this.imageToUpload.size = this.filesToUpload[index].size;
      this.imageToUpload.mimeType = this.filesToUpload[index].type;
      this.imageToUpload.belongsToId = this.superAdminId;
      this.imageToUpload.role = "POI_IMAGE_MAP";
      this.imageToUpload.tags = [""];
      this.imageToUpload.belongsTo = "SUPER_ADMIN";
      this.imageToUpload.uploadedByBaseUserId = parseInt(
        localStorage.getItem("userLoggedId")
      );
      tasks$.push(
        this.fileUp
          .imageUpload(this.imageToUpload, this.filesToUpload[index])
          .pipe(
            concatMap((first) => {
              console.log(first);
              return this.fileUp.pointOfInterestImageCreate(
                first.data["imageCreateFromUpload"].id,
                parseInt(localStorage.getItem("emptyPoi")),
                0,
                JSON.stringify("")
              );
            })
          )
      );
    }
    console.log(tasks$);

    return concat(...tasks$).subscribe(
      (y) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  setImagetoBody() {
    if (
      this.ImageAssociationGetAll.length > 0 &&
      this.imagesToShow.length > 0
    ) {
      for (let j = 0; j < this.ImageAssociationGetAll.length; j++) {
        for (let index = 0; index < this.imagesToShow.length; index++) {
          if (
            this.ImageAssociationGetAll[j].id == this.imagesToShow[index].id
          ) {
            if (
              this.ImageAssociationGetAll[j].imageGetSub.role ==
              "POI_COVER_IMAGE"
            ) {
              this.showCoverImagefromServer = true;
              this.previewImage = this.ImageAssociationGetAll[
                j
              ].imageGetSub.url;
            }
          }
        }
      }
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
      var first = `${a[property]}`.toLowerCase()
      var second = `${b[property]}`.toLowerCase()
      var result = (first < second) ? -1 : (first > second) ? 1 : 0;
      return result * sortOrder;
    }
  }
  
  async poiFeatureGetRequest(lastId = null) {
    this.apollo.query<Response>({
      query: new featureGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    }).subscribe((data: any) => {
      console.log("poiFeatureGetRequest", data.data.featureGetAll)
      var featureGetAll = data.data.featureGetAll
      if (featureGetAll.length > 0) {
        this.featureGetAll.push(...featureGetAll)
        this.poiFeatureGetRequest(featureGetAll[featureGetAll.length - 1].id)
      } else {
        this.featureGetAll = this.featureGetAll.map((item) => {
          item['code'] = item['macroFeatureGetSub']['code'] + " - " + item['code']
          return item;
        });
        this.featureGetAll.sort(this.dynamicSort("code"));
        console.log("poiFeatureGetRequest.map", this.featureGetAll)
      }
    })
    /*
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetPoiFeature,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllFeature = this.businessPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.featureGetAll.length > 0
            ? data.data["featureGetAll"][data.data["featureGetAll"].length - 1][
                "id"
              ]
            : null;
        this.queryGetPoiFeature = new featureGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPagination.fetchMore({
          query: this.queryGetPoiFeature,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["featureGetAll"].length < 1) {
              this.featureGetAll.push(...prev["featureGetAll"]);

              this.featureGetAll = this.featureGetAll.map((item) => {
                return item;
              });
            } else {
              return Object.assign({}, prev, {
                featureGetAll: [
                  ...prev["featureGetAll"],
                  ...fetchMoreResult["featureGetAll"],
                ],
              });
            }
          },
        });
      }
    );*/
  }
  poiFeatureCreate(event): void {
    let last: any = event.value[event.value.length - 1];
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiFeatureCreate.poiFeatureCreate,
        variables: {
          data: {
            pointOfInterestId: localStorage.getItem("emptyPoi"),
            featureId: last.id,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiFeatureCreate"]["success"]) {
            alert("Feature save sucessfully");
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }

  poiLanguageCreate(event): void {
    let last: any = event.value[event.value.length - 1];

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiLanguageCreate.poiLanguageCreate,
        variables: {
          data: {
            pointOfInterestId: localStorage.getItem("emptyPoi"),
            poiLanguageId: parseInt(last.id),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiLanguageCreate"]["success"]) {
            alert("Language save sucessfully");
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }

  //orari//
  async getStatus() {
    this.selectedStatus = [];
    this.statusGetAll = this.requestService.getStatus();
    this.statusGetAll = this.statusGetAll.map((item) => {
      return item;
    });

    this.selectedStatus.push(this.statusGetAll[0]);
  }
  async getValidity() {
    this.selectedValidity = [];
    this.validityGetAll = [];

    this.validityGetAll = this.requestService.getValidita();
    this.validityGetAll = this.validityGetAll.map((item) => {
      return item;
    });

    this.selectedValidity.push(this.validityGetAll[0]);
  }
  async statusChange(event) {
    console.log(moment().endOf("year").add("20", "years").format("YYYY-MM-DD"));
    console.log(
      moment().startOf("year").add("20", "years").format("YYYY-MM-DD")
    );

    // this.selectedStatus.id = event.value.id;
    this.selectedStatusId = event.value.id;
    switch (parseInt(event.value.id)) {
      case 1:
        this.model.backOfficeStatus = event.value.id;
        break;

      case 2:
        this.model.backOfficeStatus = event.value.id;
        await this.setIsAlwaysOpen();
        break;

      case 3:
        this.model.backOfficeStatus = event.value.id;
        await this.getValidity();
        break;
    }
  }
  async daysOfWeekChangeEvent(days) {
    if (days.length == 6) {
      this.validityGetAll.splice(-1, 1);
    }
  }

  validityChange(event, item, days): void {
    switch (parseInt(event.value.id)) {
      case 1:
        item.showSpecialHours = false;
        item.showIsClosedPeriod = false;
        item.showIsDaysWeeks = false;
        item.showSetHoursOfWeek = false;
        break;

      case 2:
        item.showSpecialHours = false;
        item.showIsClosedPeriod = true;
        item.showIsDaysWeeks = false;
        item.showSetHoursOfWeek = false;
        item.setPeriodSpecialObj = [];

        item.setPeriodSpecialObj.push({
          DataInizioSpecialPeriod: [item.DataInizio[0], item.DataInizio[1]],
          isClosedInPeriod: false,
          showAfterCreateSpecialPeriod: false,
          showIsClosedPeriod: true,
          notValidAfterCreateSpecialPeriod: false,
          showIsActiveAfterCreatePeriodoSpecial: true,
        });
        break;
      case 3:
        item.showIsClosedPeriod = false;
        item.showSpecialHours = true;
        item.showIsDaysWeeks = false;
        item.showSetHoursOfWeek = false;
        item.setOrarioSpecialeObj = [];

        item.setOrarioSpecialeObj.push({
          notesSpecialHour: "",
          startingTimeSpecialHour: "",
          endingTimeSpecialHour: "",
          dataInizioSpecialHour: [item.DataInizio[0], item.DataInizio[1]],
          closedPeriodSpecialHour: false,
          showAfterCreateSpecialOrario: false,
          notValidAfterCreateOrario: false,
        });

        break;

      case 4:
        item.showIsClosedPeriod = false;
        item.showSpecialHours = false;
        item.showIsDaysWeeks = true;
        item.showSetHoursOfWeek = false;

        break;

      case 5:
        this.giornoOrario = [];
        item.setSpecialHoursObj = [];
        this.giornoOrario = this.daysOfWeekGetAll.filter(
          (res) =>
            days.selectedDaysOfWeek.findIndex(
              (itemSelected) => itemSelected.id === res.id
            ) === -1
        );
        var startTimer = moment().startOf("year").format("HH:mm:ss");
        var endingTime = moment()
          .startOf("year")
          .subtract("1", "second")
          .format("HH:mm:ss");
        console.log(this.giornoOrario);

        for (let index = 0; index < this.giornoOrario.length; index++) {
          item.setSpecialHoursObj.push({
            name: this.giornoOrario[index].name,
            id: this.giornoOrario[index].id,
            startingTime: startTimer,
            endingTime: endingTime,
            DataInizioOrarioGiornoSet: [item.DataInizio[0], item.DataInizio[1]],
            isClosed: false,
            showAfterCreateOrarioGiorno: false,
            notValidAfterCreateSetttimanaOrari: false,
          });
          console.log(item.setSpecialHoursObj);
        }

        item.showIsClosedPeriod = false;
        item.showSpecialHours = false;
        item.showIsDaysWeeks = false;
        item.showSetHoursOfWeek = true;
        break;
    }
    item.showCloseDerogaPeriodo = true;
  }
  createPrincipalPeriod(item, periodsOfPoi) {
    console.log("ITEM from createPrincipalPeriod", item);
    console.log("periodOfPoi", periodsOfPoi);
    if (periodsOfPoi.length > 0) {
      for (let index = 0; index < periodsOfPoi.length; index++) {
        periodsOfPoi[index].dataInizioTimeSpan = moment(
          periodsOfPoi[index].DataInizio[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        periodsOfPoi[index].dataFineTimeSpan = moment(
          periodsOfPoi[index].DataInizio[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfPeriod = periodsOfPoi.slice(0, -1);
    if (arrayOfPeriod.length > 0) {
      var existePeriodo = arrayOfPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
          res.dataFineTimeSpan ===
            moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert("Hai già impostato un orario identico per questo periodo");
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizio[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizio[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                backOfficeData: 0,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                item.DataInizioSetSpecialsHours = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioOrarioGiornoSet = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.dataInizioNewSpecialHour = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizio = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioSpecialPeriod = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                this.openingPeriodId =
                  result.data["poiOpeningPeriodCreate"]["id"];
                localStorage.setItem(
                  "periodPrincipalId",
                  result.data["poiOpeningPeriodCreate"]["id"]
                );

                //console.log(item.DataInizio);

                item.showOpeningDayBtn = true;
              }
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.DataInizio[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.DataInizio[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
              backOfficeData: 0,
              backOfficeStatus: this.model.backOfficeStatus,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              item.DataInizioSetSpecialsHours = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioOrarioGiornoSet = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.dataInizioNewSpecialHour = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizio = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioSpecialPeriod = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              this.openingPeriodId =
                result.data["poiOpeningPeriodCreate"]["id"];
              localStorage.setItem(
                "periodPrincipalId",
                result.data["poiOpeningPeriodCreate"]["id"]
              );

              //console.log(item.DataInizio);

              item.showOpeningDayBtn = true;
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  setNewSpecialDays(data, newItem) {
    data.notValidAfterCreateOrari = true;

    console.log(data);
    newItem.setOrarioSpecialeObj.push({
      notesSpecialHour: "",
      startingTimeSpecialHour: "",
      endingTimeSpecialHour: "",
      dataInizioSpecialHour: [],
      closedPeriodSpecialHour: false,
      showAfterCreateSpecialOrario: false,
      notValidAfterCreateOrario: false,
    });
    console.log(data);
  }
  createPrincipalPeriodForAllYears(item, periodsOfPoi) {
    if (periodsOfPoi.length > 0) {
      for (let index = 0; index < periodsOfPoi.length; index++) {
        periodsOfPoi[index].dataInizioTimeSpan = moment(
          periodsOfPoi[index].DataInizio[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        periodsOfPoi[index].dataFineTimeSpan = moment(
          periodsOfPoi[index].DataInizio[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfPeriod = periodsOfPoi.slice(0, -1);
    if (arrayOfPeriod.length > 0) {
      var existePeriodo = arrayOfPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
          res.dataFineTimeSpan ===
            moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert("Hai già impostato un orario identico per questo periodo");
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizio[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizio[1],
                  "YYYY-MM-DD HH:mm:ss"
                )
                  .add(20, "years")
                  .format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                backOfficeData: 0,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                item.DataInizioSetSpecialsHours = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];

                item.DataInizio = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioOrarioGiornoSet = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.dataInizioNewSpecialHour = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                this.openingPeriodId =
                  result.data["poiOpeningPeriodCreate"]["id"];
                localStorage.setItem(
                  "periodPrincipalId",
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
                item.showOpeningDayBtn = true;
              }
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.DataInizio[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss")
                .add(20, "years")
                .format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
              backOfficeData: 0,
              backOfficeStatus: this.model.backOfficeStatus,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              item.DataInizioSetSpecialsHours = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];

              item.DataInizio = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioOrarioGiornoSet = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.dataInizioNewSpecialHour = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              this.openingPeriodId =
                result.data["poiOpeningPeriodCreate"]["id"];
              localStorage.setItem(
                "periodPrincipalId",
                result.data["poiOpeningPeriodCreate"]["id"]
              );
              item.showOpeningDayBtn = true;
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  setIsAlwaysOpen() {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
        variables: {
          data: {
            startingDateTime: moment()
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            endingDateTime: moment()
              .endOf("year")
              .add("20", "years")
              .format("YYYY-MM-DD HH:mm:ss"),
            pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
            backOfficeData: 0,
            backOfficeStatus: this.model.backOfficeStatus,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodCreate"]["success"]) {
            this.setIsAlwaysOpeningDay(
              result.data["poiOpeningPeriodCreate"]["id"]
            );
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }
  setIsAlwaysOpeningDay(idOpeningPeriod) {
    this.alwaysOpenDays = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    console.log(startTimer);
    console.log(endingTime);
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: idOpeningPeriod,
          daysOfWeek: this.alwaysOpenDays,
          notes: null,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            alert("Periodo  impostato con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  setCreateManyDays(data: any[]) {
    this.alwaysOpenDays = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    var tasks$ = [];
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }

    for (let index = 0; index < data.length; index++) {
      tasks$.push(
        this.apollo.use("super-admin").mutate({
          mutation: new poiOpeningDayCreate(
            moment.duration(startTimer).asSeconds(),
            moment.duration(endingTime).asSeconds()
          ).getQuery(),
          variables: {
            poiOpeningPeriodId: data[index].id,
            daysOfWeek: this.alwaysOpenDays,
            notes: null,
            openingDaySpecialNotes: [],
          },
        })
      );
    }
    return forkJoin(tasks$).subscribe((result) => {
      for (let index = 0; index < result.length; index++) {
        if (result[index].data["poiOpeningDayCreate"]["success"]) {
        }
      }
      alert("Deroga impostata con success");
    });
  }

  poiCreateOpeningDay(item, oldItem) {
    console.log(item, oldItem);
    this.dayOfWeeks = [];
    console.log(item);
    var startTimer = moment(item.startingTime, "YYYY-MM-DD HH:mm:ss").format(
      "HH:mm:ss"
    );
    var endingTime = moment(item.endingTime, "YYYY-MM-DD HH:mm:ss").format(
      "HH:mm:ss"
    );

    this.startingTimeSpecialHour = item.startingTime;
    this.endingTimeSpecialHour = item.endingTime;

    var data = this.daysOfWeekGetAll.filter(
      (res) =>
        item.selectedDaysOfWeek.findIndex(
          (itemSelected) => itemSelected.id === res.id
        ) !== -1
    );
    for (let index = 0; index < data.length; index++) {
      this.dayOfWeeks.push(data[index].name);
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: this.openingPeriodId,
          daysOfWeek: this.dayOfWeeks,
          notes: item.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            this.isCreatedPeriod = true;
            item.isCreatedPeriod = true;
            item.showImpostaValiditaBtn = true;
            oldItem.showAfterCreatePeriod = true;
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }

  setClosedPeriod(item, specialPeriodArray, data, i) {
    item.isCreatedPeriod = true;

    if (specialPeriodArray.length > 0) {
      for (let index = 0; index < specialPeriodArray.length; index++) {
        specialPeriodArray[index].dataInizioTimeSpan = moment(
          specialPeriodArray[index].DataInizioSpecialPeriod[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        specialPeriodArray[index].dataFineTimeSpan = moment(
          specialPeriodArray[index].DataInizioSpecialPeriod[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfSpecialPeriod = specialPeriodArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(
              item.DataInizioSpecialPeriod[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X") &&
          res.dataFineTimeSpan ===
            moment(
              item.DataInizioSpecialPeriod[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizioSpecialPeriod[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizioSpecialPeriod[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                isClosed: item.isClosedInPeriod,
                derogatedOpeningPeriodId: parseInt(
                  localStorage.getItem("periodPrincipalId")
                ),
                backOfficeData: 2,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                this.showPubblicaBtn = true;
                item.showAfterCreateSpecialPeriod = true;
                item.DataInizioSpecialPeriod = [
                  new Date(
                    moment(
                      item.DataInizioSpecialPeriod[0],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss")
                  ),
                  new Date(
                    moment(
                      item.DataInizioSpecialPeriod[1],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss")
                  ),
                ];
                data[0].showImpostaValiditaBtn = false;
                this.setIsAlwaysOpeningDay(
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.DataInizioSpecialPeriod[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.DataInizioSpecialPeriod[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
              isClosed: item.isClosedInPeriod,
              derogatedOpeningPeriodId: parseInt(
                localStorage.getItem("periodPrincipalId")
              ),
              backOfficeData: 2,
              backOfficeStatus: this.model.backOfficeStatus,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              this.showPubblicaBtn = true;
              item.showAfterCreateSpecialPeriod = true;
              item.DataInizioSpecialPeriod = [
                new Date(
                  moment(
                    item.DataInizioSpecialPeriod[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss")
                ),
                new Date(
                  moment(
                    item.DataInizioSpecialPeriod[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss")
                ),
              ];
              data[0].showImpostaValiditaBtn = false;
              this.setIsAlwaysOpeningDay(
                result.data["poiOpeningPeriodCreate"]["id"]
              );
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }
  createClosedPeriodForAllYears(item, specialPeriodArray, data, i) {
    var tasks$ = [];
    var tasks2 = [];
    //fare create many
    for (let index = 0; index <= 20; index++) {
      tasks$.push({
        startingDateTime: moment(item.DataInizioSpecialPeriod[0])
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        endingDateTime: moment(item.DataInizioSpecialPeriod[1])
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
        isClosed: item.isClosedInPeriod,
        derogatedOpeningPeriodId: parseInt(
          localStorage.getItem("periodPrincipalId")
        ),
        backOfficeData: 2,
        backOfficeStatus: this.model.backOfficeStatus,
      });
    }

    if (specialPeriodArray.length > 0) {
      for (let index = 0; index < specialPeriodArray.length; index++) {
        specialPeriodArray[index].dataInizioTimeSpan = moment(
          specialPeriodArray[index].DataInizioSpecialPeriod[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        specialPeriodArray[index].dataFineTimeSpan = moment(
          specialPeriodArray[index].DataInizioSpecialPeriod[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfSpecialPeriod = specialPeriodArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(
              item.DataInizioSpecialPeriod[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X") &&
          res.dataFineTimeSpan ===
            moment(
              item.DataInizioSpecialPeriod[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
            variables: {
              data: tasks$,
            },
          })
          .subscribe(
            (result: any) => {
              let data = result.data["poiOpeningPeriodCreateMany"].filter(
                (item) => item.success == true
              );
              data[0].showImpostaValiditaBtn = false;
              item.showAfterCreateSpecialPeriod = true;

              this.setCreateManyDays(data);
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
          variables: {
            data: tasks$,
          },
        })
        .subscribe(
          (result: any) => {
            let data = result.data["poiOpeningPeriodCreateMany"].filter(
              (item) => item.success == true
            );

            item.showAfterCreateSpecialPeriod = true;
            data[0].showImpostaValiditaBtn = false;
            this.setCreateManyDays(data);
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  //IMPOSTA GIORNO SETTIMANA

  //OK
  setDerogeDaysOfWeek(item, giornoOfWeekArray, dataItem, i) {
    console.log(giornoOfWeekArray);
    console.log(item);

    if (giornoOfWeekArray.length > 0) {
      for (let index = 0; index < giornoOfWeekArray.length; index++) {
        giornoOfWeekArray[index].dataInizioTimeSpan = moment(
          giornoOfWeekArray[index].DataInizioOrarioGiornoSpeciale[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        giornoOfWeekArray[index].dataFineTimeSpan = moment(
          giornoOfWeekArray[index].DataInizioOrarioGiornoSpeciale[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfSpecialPeriod = giornoOfWeekArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(
              item.DataInizioOrarioGiornoSpeciale[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X") &&
          res.dataFineTimeSpan ===
            moment(
              item.DataInizioOrarioGiornoSpeciale[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizioOrarioGiornoSpeciale[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizioOrarioGiornoSpeciale[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                isClosed: item.closedPeriodGiornoSpeciale,
                derogatedOpeningPeriodId: parseInt(
                  localStorage.getItem("periodPrincipalId")
                ),
                backOfficeData: 4,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                dataItem[0].showImpostaValiditaBtn = false;
                this.setNewDaysForPeriod(
                  result.data["poiOpeningPeriodCreate"]["id"]
                );

                this.validityGetAll.splice(2, 1);
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.DataInizioOrarioGiornoSpeciale[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.DataInizioOrarioGiornoSpeciale[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
              isClosed: item.closedPeriodGiornoSpeciale,
              derogatedOpeningPeriodId: parseInt(
                localStorage.getItem("periodPrincipalId")
              ),
              backOfficeData: 4,
              backOfficeStatus: this.model.backOfficeStatus,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              dataItem[0].showImpostaValiditaBtn = false;
              this.setNewDaysForPeriod(
                result.data["poiOpeningPeriodCreate"]["id"]
              );
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }
  setNewDaysForPeriod(openingPeriodId) {
    var tasks$ = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    var data = this.daysOfWeekGetAll.filter(
      (item) =>
        this.selectedDaysOfWeek.findIndex(
          (itemSelected) => itemSelected.id === item.id
        ) === -1
    );
    for (let index = 0; index < data.length; index++) {
      tasks$.push(data[index].name);
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: tasks$,
          notes: this.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            //this.isCreatedPeriod  = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  //OK OGNI ANNO
  setDerogeDaysOfWeekForAllYears(item, giornoOfWeekAllYearsArray, dataItem, i) {
    console.log(item);

    var tasks$ = [];
    var tasks2 = [];
    //fare create many
    for (let index = 0; index <= 20; index++) {
      tasks$.push({
        startingDateTime: moment(item.DataInizioOrarioGiornoSpeciale[0])
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        endingDateTime: moment(item.DataInizioOrarioGiornoSpeciale[1])
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
        isClosed: item.closedPeriodGiornoSpeciale,
        derogatedOpeningPeriodId: parseInt(
          localStorage.getItem("periodPrincipalId")
        ),
        backOfficeData: 4,
        backOfficeStatus: this.model.backOfficeStatus,
      });
    }

    console.log(giornoOfWeekAllYearsArray);
    console.log(item);

    if (giornoOfWeekAllYearsArray.length > 0) {
      for (let index = 0; index < giornoOfWeekAllYearsArray.length; index++) {
        giornoOfWeekAllYearsArray[index].dataInizioTimeSpan = moment(
          giornoOfWeekAllYearsArray[index].DataInizioOrarioGiornoSpeciale[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        giornoOfWeekAllYearsArray[index].dataFineTimeSpan = moment(
          giornoOfWeekAllYearsArray[index].DataInizioOrarioGiornoSpeciale[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfSpecialPeriod = giornoOfWeekAllYearsArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(
              item.DataInizioOrarioGiornoSpeciale[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X") &&
          res.dataFineTimeSpan ===
            moment(
              item.DataInizioOrarioGiornoSpeciale[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
            variables: {
              data: tasks$,
            },
          })
          .subscribe(
            (result: any) => {
              let data = result.data["poiOpeningPeriodCreateMany"].filter(
                (item) => item.success == true
              );

              dataItem[0].showImpostaValiditaBtn = false;
              this.validityGetAll.splice(2, 1);

              this.setCreateManyDaysoOfWeeks(data);
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
          variables: {
            data: tasks$,
          },
        })
        .subscribe(
          (result: any) => {
            let data = result.data["poiOpeningPeriodCreateMany"].filter(
              (item) => item.success == true
            );
            dataItem[0].showImpostaValiditaBtn = false;
            this.setCreateManyDaysoOfWeeks(data);
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  setCreateManyDaysoOfWeeks(data: any[]) {
    var tasks2 = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    var tasks$ = [];
    var dataOfWeek = this.daysOfWeekGetAll.filter(
      (item) =>
        this.selectedDaysOfWeek.findIndex(
          (itemSelected) => itemSelected.id === item.id
        ) === -1
    );
    for (let index = 0; index < dataOfWeek.length; index++) {
      tasks2.push(dataOfWeek[index].name);
    }

    for (let index = 0; index < data.length; index++) {
      tasks$.push(
        this.apollo.use("super-admin").mutate({
          mutation: new poiOpeningDayCreate(
            moment.duration(startTimer).asSeconds(),
            moment.duration(endingTime).asSeconds()
          ).getQuery(),
          variables: {
            poiOpeningPeriodId: data[index].id,
            daysOfWeek: tasks2,
            notes: null,
            openingDaySpecialNotes: [],
          },
        })
      );
    }
    return forkJoin(tasks$).subscribe((result) => {
      for (let index = 0; index < result.length; index++) {
        if (result[index].data["poiOpeningDayCreate"]["success"]) {
          console.log(result[index].data["poiOpeningDayCreate"]["id"]);
        }
      }
      alert("Deroga impostata con successo ");
    });
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  setImpostaOrarioGiornoGetAll(item, setImpostaOrarioGiorno, dataItem, i) {
    for (let i = 0; i < setImpostaOrarioGiorno.length; i++) {
      setImpostaOrarioGiorno[i].dataInizioTimeSpan = moment(
        setImpostaOrarioGiorno[i].DataInizioOrarioGiornoSet[0],
        "YYYY-MM-DD HH:mm:ss"
      ).format("X");
      setImpostaOrarioGiorno[i].endFineSpan = moment(
        setImpostaOrarioGiorno[i].DataInizioOrarioGiornoSet[1],
        "YYYY-MM-DD HH:mm:ss"
      ).format("X");

      setImpostaOrarioGiorno[i].startingTimeOrarioGiorno = moment(
        setImpostaOrarioGiorno[i].startingTime,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");
      setImpostaOrarioGiorno[i].endingTimeOrarioGiorno = moment(
        setImpostaOrarioGiorno[i].endingTime,
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm");

      setImpostaOrarioGiorno[i].startingTimeSpan = moment
        .duration(setImpostaOrarioGiorno[i].startingTimeOrarioGiorno)
        .asSeconds();
      setImpostaOrarioGiorno[i].endingTimeSpan = moment
        .duration(setImpostaOrarioGiorno[i].endingTimeOrarioGiorno)
        .asSeconds();
    }
    var existeOneorMoreDayOfWeek = setImpostaOrarioGiorno.filter(
      (data) => data.name === item.name
    );

    if (existeOneorMoreDayOfWeek.length == 1) {
      var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(
        (data) => data.id !== item.id
      );

      if (arrayOfSpecialPeriod.length > 0) {
        var existePeriodoGiorno = arrayOfSpecialPeriod.filter(
          (res) =>
            res.name === item.name &&
            res.dataInizioTimeSpan ===
              moment(
                item.DataInizioOrarioGiornoSet[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") &&
            res.endFineSpan ===
              moment(
                item.DataInizioOrarioGiornoSet[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X")
        );
        var resultFilterData = existePeriodoGiorno.filter(
          (result) =>
            moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
              result.startingTimeSpan &&
            moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
              result.endingTimeSpan
        );
        console.log(existePeriodoGiorno);

        if (resultFilterData.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                  isClosed: item.isClosed,
                  derogatedOpeningPeriodId: parseInt(
                    localStorage.getItem("periodPrincipalId")
                  ),
                  backOfficeData: 5,
                  backOfficeStatus: this.model.backOfficeStatus,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  dataItem[0].showImpostaValiditaBtn = false;
                  this.setImpostaGiornoXOrario(
                    result.data["poiOpeningPeriodCreate"]["id"],
                    item
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizioOrarioGiornoSet[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizioOrarioGiornoSet[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                isClosed: item.isClosed,
                derogatedOpeningPeriodId: parseInt(
                  localStorage.getItem("periodPrincipalId")
                ),
                backOfficeData: 5,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                dataItem[0].showImpostaValiditaBtn = false;
                this.setImpostaGiornoXOrario(
                  result.data["poiOpeningPeriodCreate"]["id"],
                  item
                );
              }
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    }
    if (existeOneorMoreDayOfWeek.length > 1) {
      var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(
        (data) => data.name === item.name
      );

      if (arrayOfSpecialPeriod.length > 0) {
        var deleteLastOfArray = arrayOfSpecialPeriod.slice(0, -1);
        var resultFilterData = deleteLastOfArray.filter(
          (result) =>
            moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
              result.startingTimeSpan &&
            moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
              result.endingTimeSpan
        );
        console.log(existePeriodoGiorno);

        if (resultFilterData.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                  isClosed: item.isClosed,
                  derogatedOpeningPeriodId: parseInt(
                    localStorage.getItem("periodPrincipalId")
                  ),
                  backOfficeData: 5,
                  backOfficeStatus: this.model.backOfficeStatus,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  dataItem[0].showImpostaValiditaBtn = false;
                  this.setImpostaGiornoXOrario(
                    result.data["poiOpeningPeriodCreate"]["id"],
                    item
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizioOrarioGiornoSet[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizioOrarioGiornoSet[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                isClosed: item.isClosed,
                derogatedOpeningPeriodId: parseInt(
                  localStorage.getItem("periodPrincipalId")
                ),
                backOfficeData: 5,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                dataItem[0].showImpostaValiditaBtn = false;
                this.setImpostaGiornoXOrario(
                  result.data["poiOpeningPeriodCreate"]["id"],
                  item
                );
              }
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    }

    console.log(item);
  }
  setImpostaGiornoXOrario(openingPeriodId, item) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: [item.name],
          notes: this.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            //this.isCreatedPeriod  = true;

            item.showAfterCreateOrarioGiorno = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  setImpostaOrarioGiornoAllYears(
    item,
    setSpecialOrarioGiornoArrayForAll,
    dataItem,
    i
  ) {
    var tasks$ = [];
    var tasks2 = [];
    //fare create many
    for (let index = 0; index <= 20; index++) {
      tasks$.push({
        startingDateTime: moment(
          item.DataInizioOrarioGiornoSet[0],
          "YYYY-MM-DD HH:mm:ss"
        )
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        endingDateTime: moment(
          item.DataInizioOrarioGiornoSet[1],
          "YYYY-MM-DD HH:mm:ss"
        )
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
        isClosed: item.isClosed,
        derogatedOpeningPeriodId: parseInt(
          localStorage.getItem("periodPrincipalId")
        ),
        backOfficeData: 5,
        backOfficeStatus: this.model.backOfficeStatus,
      });
    }

    if (setSpecialOrarioGiornoArrayForAll.length > 0) {
      for (
        let index = 0;
        index < setSpecialOrarioGiornoArrayForAll.length;
        index++
      ) {
        setSpecialOrarioGiornoArrayForAll[index].dataInizioTimeSpan = moment(
          setSpecialOrarioGiornoArrayForAll[index].DataInizioOrarioGiornoSet[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        setSpecialOrarioGiornoArrayForAll[index].endFineSpan = moment(
          setSpecialOrarioGiornoArrayForAll[index].DataInizioOrarioGiornoSet[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");

        setSpecialOrarioGiornoArrayForAll[
          index
        ].startingTimeOrarioGiorno = moment(
          setSpecialOrarioGiornoArrayForAll[index].startingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
        setSpecialOrarioGiornoArrayForAll[
          index
        ].endingTimeOrarioGiorno = moment(
          setSpecialOrarioGiornoArrayForAll[index].endingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");

        setSpecialOrarioGiornoArrayForAll[
          index
        ].startingTimeSpan = moment
          .duration(
            setSpecialOrarioGiornoArrayForAll[index].startingTimeOrarioGiorno
          )
          .asSeconds();
        setSpecialOrarioGiornoArrayForAll[
          index
        ].endingTimeSpan = moment
          .duration(
            setSpecialOrarioGiornoArrayForAll[index].endingTimeOrarioGiorno
          )
          .asSeconds();
      }
    }

    var existeOneorMoreDayOfWeek = setSpecialOrarioGiornoArrayForAll.filter(
      (data) => data.name === item.name
    );

    if (existeOneorMoreDayOfWeek.length == 1) {
      var arrayOfSpecialPeriod = setSpecialOrarioGiornoArrayForAll.filter(
        (data) => data.id !== item.id
      );
      if (arrayOfSpecialPeriod.length > 0) {
        var existePeriodo = arrayOfSpecialPeriod.filter(
          (res) =>
            res.name === item.name &&
            res.dataInizioTimeSpan ===
              moment(
                item.DataInizioOrarioGiornoSet[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") &&
            res.endFineSpan ===
              moment(
                item.DataInizioOrarioGiornoSet[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X")
        );

        var resultFilterData = existePeriodo.filter(
          (result) =>
            moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
              result.startingTimeSpan &&
            moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
              result.endingTimeSpan
        );

        if (resultFilterData.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
              variables: {
                data: tasks$,
              },
            })
            .subscribe(
              (result: any) => {
                let data = result.data["poiOpeningPeriodCreateMany"].filter(
                  (item) => item.success == true
                );

                dataItem[0].showImpostaValiditaBtn = false;
                this.setImpostaGiornoXOrarioForAllYears(data, item);
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
            variables: {
              data: tasks$,
            },
          })
          .subscribe(
            (result: any) => {
              let data = result.data["poiOpeningPeriodCreateMany"].filter(
                (item) => item.success == true
              );
              dataItem[0].showImpostaValiditaBtn = false;
              this.setImpostaGiornoXOrarioForAllYears(data, item);
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    }

    if (existeOneorMoreDayOfWeek.length > 1) {
      var arrayOfSpecialPeriod = setSpecialOrarioGiornoArrayForAll.filter(
        (data) => data.name === item.name
      );
      if (arrayOfSpecialPeriod.length > 0) {
        var deleteLastOfArray = arrayOfSpecialPeriod.slice(0, -1);
        var resultFilterData = deleteLastOfArray.filter(
          (result) =>
            moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
              result.startingTimeSpan &&
            moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
              result.endingTimeSpan
        );

        if (resultFilterData.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
              variables: {
                data: tasks$,
              },
            })
            .subscribe(
              (result: any) => {
                let data = result.data["poiOpeningPeriodCreateMany"].filter(
                  (item) => item.success == true
                );
                dataItem[0].showImpostaValiditaBtn = false;
                this.setImpostaGiornoXOrarioForAllYears(data, item);
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
            variables: {
              data: tasks$,
            },
          })
          .subscribe(
            (result: any) => {
              let data = result.data["poiOpeningPeriodCreateMany"].filter(
                (item) => item.success == true
              );
              dataItem[0].showImpostaValiditaBtn = false;
              this.setImpostaGiornoXOrarioForAllYears(data, item);
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    }
    console.log(item);
  }

  setImpostaGiornoXOrarioForAllYears(data: any[], item) {
    var tasks$ = [];
    var startTimer = moment(item.startingTime, "YYYY-MM-DD HH:mm:ss").format(
      "HH:mm:ss"
    );
    var endTimer = moment(item.endingTime, "YYYY-MM-DD HH:mm:ss").format(
      "HH:mm:ss"
    );

    for (let index = 0; index < data.length; index++) {
      tasks$.push(
        this.apollo.use("super-admin").mutate({
          mutation: new poiOpeningDayCreate(
            moment.duration(startTimer).asSeconds(),
            moment.duration(endTimer).asSeconds()
          ).getQuery(),
          variables: {
            poiOpeningPeriodId: data[index].id,
            daysOfWeek: [item.name],
            notes: null,
            openingDaySpecialNotes: [],
          },
        })
      );
    }
    return forkJoin(tasks$).subscribe((result) => {
      for (let index = 0; index < result.length; index++) {
        if (result[index].data["poiOpeningDayCreate"]["success"]) {
          item.showAfterCreateOrarioGiorno = true;
          console.log(result[index].data["poiOpeningDayCreate"]["id"]);
        }
      }
      alert("Deroga impostata con successo");
    });
  }

  //IMPOSTA ORARI SPECIALE
  setSpecialsDaysAllWeek(item, setSpecialOrariArray, dataItem, i) {
    if (setSpecialOrariArray.length > 0) {
      for (let index = 0; index < setSpecialOrariArray.length; index++) {
        setSpecialOrariArray[index].dataInizioTimeSpan = moment(
          setSpecialOrariArray[index].dataInizioSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");

        setSpecialOrariArray[index].startingTime = moment(
          setSpecialOrariArray[index].startingTimeSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
        setSpecialOrariArray[index].endingTime = moment(
          setSpecialOrariArray[index].endingTimeSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");

        setSpecialOrariArray[index].startingTimeSpan = moment
          .duration(setSpecialOrariArray[index].startingTime)
          .asSeconds();
        setSpecialOrariArray[index].endingTimeSpan = moment
          .duration(setSpecialOrariArray[index].endingTime)
          .asSeconds();
      }
    }
    console.log(setSpecialOrariArray);
    var arrayOfSpecialPeriod = setSpecialOrariArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
          moment(item.dataInizioSpecialHour, "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);

      var resultFilterData = existePeriodo.filter(
        (result) =>
          moment.duration(item.endingTime).asSeconds() >=
            result.startingTimeSpan &&
          moment.duration(item.startingTime).asSeconds() <=
            result.endingTimeSpan
      );

      if (resultFilterData.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.dataInizioSpecialHour,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.dataInizioSpecialHour,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                isClosed: item.closedPeriodSpecialHour,
                derogatedOpeningPeriodId: parseInt(
                  localStorage.getItem("periodPrincipalId")
                ),
                backOfficeData: 3,
                backOfficeStatus: this.model.backOfficeStatus,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                dataItem[0].showImpostaValiditaBtn = false;
                this.validityGetAll.splice(3, 1);
                item.setSpecialsDaysAllWeekForAllYears = true;

                this.setImpostaOrarioAllDays(
                  result.data["poiOpeningPeriodCreate"]["id"],
                  item
                );
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.dataInizioSpecialHour,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.dataInizioSpecialHour,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
              isClosed: item.closedPeriodSpecialHour,
              derogatedOpeningPeriodId: parseInt(
                localStorage.getItem("periodPrincipalId")
              ),
              backOfficeData: 3,
              backOfficeStatus: this.model.backOfficeStatus,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              this.validityGetAll.splice(3, 1);
              item.setSpecialsDaysAllWeekForAllYears = true;
              dataItem[0].showImpostaValiditaBtn = false;
              this.setImpostaOrarioAllDays(
                result.data["poiOpeningPeriodCreate"]["id"],
                item
              );
            }
          },
          (err) => {
            alert("La deroga non è impostata correttamente, riprova");
          }
        );
    }
  }

  setImpostaOrarioAllDays(openingPeriodId, item) {
    this.alwaysOpenDays = [];
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: this.alwaysOpenDays,
          notes: null,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            item.showAfterCreateSpecialOrario = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  setSpecialsDaysAllWeekForAllYears(item, setSpecialOrariArray, dataItem, i) {
    var tasks$ = [];
    var tasks2 = [];
    //fare create many
    for (let index = 0; index <= 20; index++) {
      tasks$.push({
        startingDateTime: moment(item.dataInizioSpecialHour)
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        endingDateTime: moment(item.dataInizioSpecialHour)
          .add(index.toString(), "years")
          .format("YYYY-MM-DD HH:mm:ss"),
        pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
        isClosed: item.closedPeriodSpecialHour,
        derogatedOpeningPeriodId: parseInt(
          localStorage.getItem("periodPrincipalId")
        ),
        backOfficeData: 3,
        backOfficeStatus: this.model.backOfficeStatus,
      });
    }
    if (setSpecialOrariArray.length > 0) {
      for (let index = 0; index < setSpecialOrariArray.length; index++) {
        setSpecialOrariArray[index].dataInizioTimeSpan = moment(
          setSpecialOrariArray[index].dataInizioSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        setSpecialOrariArray[index].startingTime = moment(
          setSpecialOrariArray[index].startingTimeSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
        setSpecialOrariArray[index].endingTime = moment(
          setSpecialOrariArray[index].endingTimeSpecialHour,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");

        setSpecialOrariArray[index].startingTimeSpan = moment
          .duration(setSpecialOrariArray[index].startingTime)
          .asSeconds();
        setSpecialOrariArray[index].endingTimeSpan = moment
          .duration(setSpecialOrariArray[index].endingTime)
          .asSeconds();
      }
    }
    console.log(setSpecialOrariArray);
    var arrayOfSpecialPeriod = setSpecialOrariArray.slice(0, -1);

    if (arrayOfSpecialPeriod.length > 0) {
      var existePeriodo = arrayOfSpecialPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
          moment(item.dataInizioSpecialHour, "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);

      var resultFilterData = existePeriodo.filter(
        (result) =>
          moment.duration(item.endingTime).asSeconds() >=
            result.startingTimeSpan &&
          moment.duration(item.startingTime).asSeconds() <=
            result.endingTimeSpan
      );

      if (resultFilterData.length > 0) {
        return alert(
          "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
        );
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
            variables: {
              data: tasks$,
            },
          })
          .subscribe(
            (result: any) => {
              item.setSpecialsDaysAllWeekForAllYears = true;
              this.validityGetAll.splice(3, 1);
              let data = result.data["poiOpeningPeriodCreateMany"].filter(
                (item) => item.success == true
              );
              dataItem[0].showImpostaValiditaBtn = false;
              this.setImpostaOrarioAllDaysForYears(data, item);
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreateMany.poiOpeningPeriodCreateMany,
          variables: {
            data: tasks$,
          },
        })
        .subscribe(
          (result: any) => {
            item.setSpecialsDaysAllWeekForAllYears = true;
            this.validityGetAll.splice(3, 1);
            let data = result.data["poiOpeningPeriodCreateMany"].filter(
              (item) => item.success == true
            );
            dataItem[0].showImpostaValiditaBtn = false;
            this.setImpostaOrarioAllDaysForYears(data, item);
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }
  setImpostaOrarioAllDaysForYears(data: any[], item) {
    var tasks$ = [];
    this.dayOfWeeks = [];
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.dayOfWeeks.push(this.daysOfWeekGetAll[index].name);
    }
    for (let index = 0; index < data.length; index++) {
      tasks$.push(
        this.apollo.use("super-admin").mutate({
          mutation: new poiOpeningDayCreate(
            item.startingTimeSpan,
            item.endingTimeSpan
          ).getQuery(),
          variables: {
            poiOpeningPeriodId: data[index].id,
            daysOfWeek: this.dayOfWeeks,
            notes: null,
            openingDaySpecialNotes: [],
          },
        })
      );
    }
    return forkJoin(tasks$).subscribe((result) => {
      item.showAfterCreateSpecialOrario = true;
      for (let index = 0; index < result.length; index++) {
        if (result[index].data["poiOpeningDayCreate"]["success"]) {
          console.log(result[index].data["poiOpeningDayCreate"]["id"]);
        }
      }
    });
  }

  async poiCreateNewOpeningPeriod(item, index) {
    this.DataInizio = [];
    this.selectedDaysOfWeek = [];
    item.selectedDaysOfWeek = [];
    item.closeAllDerogatedPeriod = false;
    item.showOpeningDayBtn = false;
    item.showAfterCreatePeriod = false;
    item.showIsClosedPeriod = false;
    item.showSpecialHours = false;
    item.showIsDaysWeeks = false;
    item.notValidAfterCreate = true;
    item.showSetHoursOfWeek = false;
    item.setNewOpenindDayObj[0].selectedDaysOfWeek = [];
    item.setNewOpenindDayObj[0].showImpostaValiditaBtn = false;
    this.periodsOfPoi.push({
      DataInizio: [],
      notValidAfterCreate: false,
      setOrarioSpecialeObj: [
        {
          notesSpecialHour: "",
          startingTimeSpecialHour: "",
          endingTimeSpecialHour: "",
          dataInizioSpecialHour: [],
          closedPeriodSpecialHour: false,
          showAfterCreateSpecialOrario: false,
          notValidAfterCreateOrario: false,
        },
      ],
      setGiornoSpecialeObj: [
        {
          DataInizioOrarioGiornoSpeciale: [],
          closedPeriodGiornoSpeciale: false,
          notValidAfterCreateSpecialGiorno: false,
        },
      ],
      dataInizioSpecialHour: "",
      DataInizioSetGiornoSpeciale: [],
      startingTimeSpecialHour: new Date(),
      endingTimeSpecialHour: new Date(),
      startingTime: "",
      endingTime: "",
      notes: "",
      showAfterCreatePeriod: false,
      showOpeningDayBtn: false,
      showIsClosedPeriod: false,
      showSpecialHours: false,
      showIsDaysWeeks: false,
      showSetHoursOfWeek: false,
      closeAllDerogatedPeriod: false,
      closedPeriod: false,
      closedPeriodSpecialHour: false,
      closedPeriodGiornoSpeciale: false,
      notesSpecialHour: "",
      setSpecialHoursObj: [
        {
          name: "",
          id: "",
          startingTime: "",
          endingTime: "",
          DataInizioOrarioGiornoSet: [],
          isClosed: "",
          showAfterCreateOrarioGiorno: false,
          notValidAfterCreateSetttimanaOrari: false,
        },
      ],

      setPeriodSpecialObj: [
        {
          DataInizioSpecialPeriod: [],
          isClosedInPeriod: false,
          showAfterCreateSpecialPeriod: false,
          showIsClosedPeriod: false,
          notValidAfterCreateSpecialPeriod: false,
          showIsActiveAfterCreatePeriodoSpecial: true,
        },
      ],

      setNewOpenindDayObj: [
        {
          startingTime: "",
          endingTime: "",
          notes: "",
          selectedDaysOfWeek: [],
          showAfterConfirm: false,
          isOrarioExtraShow: false,
          showIsActiveAfterCreateDay: true,
          showImpostaValiditaBtn: true,
          title: "Orario di apertura di base",
        },
      ],
    });

    this.validityGetAll = [];
    await this.getValidity();
    this.todayDate = new Date(
      moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss")
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss")
    );
  }

  poiCreateNewClosedPeriod(data, item, index) {
    console.log(data);

    item.setPeriodSpecialObj[index].showIsClosedPeriod = true;
    item.setPeriodSpecialObj[index].showAfterCreateSpecialPeriod = false;
    item.setPeriodSpecialObj[index].notValidAfterCreateSpecialPeriod = true;
    item.setPeriodSpecialObj[
      index
    ].showIsActiveAfterCreatePeriodoSpecial = true;
    item.setPeriodSpecialObj.push({
      DataInizioSpecialPeriod: [],
      isClosedInPeriod: false,
      showAfterCreateSpecialPeriod: false,
      showIsClosedPeriod: true,
      notValidAfterCreateSpecialPeriod: false,
      showIsActiveAfterCreatePeriodoSpecial: true,
    });
    console.log(data);
  }

  closeOldPeriod(data) {
    data.showIsClosedPeriod = false;
  }
  setNewDerogeDaysOfWeek(data, item) {
    console.log(item);
    data.notValidAfterCreateSpecialGiorno = true;
    item.setGiornoSpecialeObj.push({
      DataInizioOrarioGiornoSpeciale: [],
      closedPeriodGiornoSpeciale: false,
      notValidAfterCreateSpecialGiorno: false,
    });
  }
  setNewImpostaOrarioGiorno(item, data) {
    console.log(item);

    var index = item.setSpecialHoursObj.findIndex((res) => res.id == data.id);
    if (index !== null)
      item.setSpecialHoursObj[index].notValidAfterCreateSetttimanaOrari = true;

    var add_object = {
      name: data.name,
      id: data.id,
      startingTime: "",
      endingTime: "",
      DataInizioOrarioGiornoSet: [
        new Date(
          moment(
            data.DataInizioOrarioGiornoSet[0],
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss")
        ),
        new Date(
          moment(
            data.DataInizioOrarioGiornoSet[1],
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss")
        ),
      ],
      isClosed: data.isClosed,
      showAfterCreateOrarioGiorno: false,
      notValidAfterCreateSetttimanaOrari: false,
    };
    item.setSpecialHoursObj.splice(index + 1, 0, add_object);
  }
  poiAddNewOpeningDay(item, data) {
    console.log(item);
    item.showAfterCreatePeriod = true;
    item.setNewOpenindDayObj.push({
      startingTime: "",
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      isOrarioExtraShow: false,
      showIsActiveAfterCreateDay: true,
      showImpostaValiditaBtn: true,
      title: "Orario di apertura di base",
    });
  }
  poiAddNewOpeningAfternoon(item, i) {
    console.log("ITEMMM", item);
    var data = new Date(
      moment(item.setNewOpenindDayObj[i].endingTime, "YYYY-MM-DD HH:mm:ss")
        .add("1", "minutes")
        .format("YYYY-MM-DD HH:mm:ss")
    );

    console.log(data);

    item.setNewOpenindDayObj[i].showIsActiveAfterCreateDay = false;

    item.setNewOpenindDayObj.push({
      startingTime: data,
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      title: "Orario di apertura pomeridiana di base",
      isOrarioExtraShow: true,
      showIsActiveAfterCreateDay: true,
      showImpostaValiditaBtn: true,
    });
  }
  poiAddNewFasciaOraria(item, i) {
    var data = new Date(
      moment(item.setNewOpenindDayObj[i].endingTime, "YYYY-MM-DD HH:mm:ss")
        .add("1", "minutes")
        .format("YYYY-MM-DD HH:mm:ss")
    );
    item.setNewOpenindDayObj[i].showIsActiveAfterCreateDay = false;

    item.setNewOpenindDayObj.push({
      startingTime: data,
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      showIsActiveAfterCreateDay: true,
      title: "Fascia oraria extra di base",
      isOrarioExtraShow: true,
    });
  }
  showBtnAfterConfirm(res) {
    res.showAfterConfirm = true;
  }
  closeAllDerogatedPeriod(item, index) {
    this.periodsOfPoi[index].closeAllDerogatedPeriod = true;
  }
  public myFilter = (d: Date): boolean => {
    // const day = d.toLocaleString();

    console.log(this.todayDate);
    const day = new Date(
      moment(d, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(d);
    // Prevent Saturday and Sunday from being selected.
    // return day !== this.todayDate;
    return true;
  };
}
