import { Component, OnInit } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { onError } from 'apollo-link-error';

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoginUser } from "../queryMutation";

import { Subscription } from "apollo-client/util/Observable";
import { NgxSpinnerService } from "ngx-spinner";

interface Login {
  expiryDate: number;
  refreshToken: string;
  token: string;
  userId: string;
  roleScopes: any[];
}

interface Response {
  login: Login;
}
@Component({
  selector: "ngx-login-component",
  templateUrl: "./login-component.component.html",
  styleUrls: ["./login-component.component.scss"],
})
export class LoginComponentComponent implements OnInit {
  public querySubscriptionLogin: Subscription;
  public querySubscriptionGetMoveryUserId: Subscription;
  user = { email: "", password: "", otp: "", rememberMe: false };
  error: any;

  constructor(
    public apollo: Apollo,
    public router: Router,
    public spinner: NgxSpinnerService
  ) { }
  ngOnInit() { }
  gotoRecoverPassConfirmation() {
    let navigationExtras = {
      queryParams: {
        data: JSON.stringify(
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbmZvQG1vdmVyeS5pdCIsImp0aSI6IjBjMmUxOGM2LTgwZDUtNGE5NS04MjUyLWQ1OTMyNjEyNWZhOSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiMSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJCQVNFX1VTRVIiLCJNT1ZFUllfVVNFUiIsIk1VTklDSVBBTElUWV9PUEVSQVRPUiIsIlNVUEVSX0FETUlOIiwiQ09NUEFOWV9PUEVSQVRPUiJdLCJCYXNlVXNlcklkIjoiMSIsIk1vdmVyeVVzZXJJZCI6IjEiLCJNdW5pY2lwYWxpdHlPcGVyYXRvcklkIjoiMSIsIlN1cGVyQWRtaW5JZCI6IjEiLCJDb21wYW55T3BlcmF0b3JJZCI6IjEiLCJleHAiOjE1NjMyNjcxOTIsImlzcyI6Imh0dHBzOi8vbW92ZXJ5Lml0IiwiYXVkIjoiVXNlciJ9.gmzMjaU8C0lIwzC1iLTTQb1TirSbTjh6wVPOMbLccm4"
        ),
      },
    };
    setTimeout(() => {
      this.router.navigate(
        ["/auth/request-password-confirmation"],
        navigationExtras
      );
    }, 500);
  }
  login() {
    console.log(`login(${this.user.email})`);

    if (this.user.otp != "46726") {
      window.confirm(
        "OTP not valid"
      );
      //alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide()
      }, 2000)
      return
    }

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionLogin = this.apollo
      .watchQuery<Response>({
        query: LoginUser.LoginUser,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            email: this.user.email,
            password: this.user.password,
            addOnScopesRequested: [],
          },
        },
      })
      .valueChanges.subscribe(
        (result: any) => {
          console.log(result);
          console.log(result.data.login.roleScopes);
          if (
            result.data.login.roleScopes.indexOf("SUPER_ADMIN") !== -1 ||
            result.data.login.roleScopes.indexOf("MUNICIPALITY_OPERATOR") !== -1 ||
            result.data.login.roleScopes.indexOf("EDITOR") !== -1
          ) {
            console.log(`aggiungi roleScopes(${JSON.stringify(result.data.login.roleScopes)}))`);
            localStorage.setItem("login", JSON.stringify(result.data.login));
            localStorage.setItem(
              "roleScopes",
              JSON.stringify(result.data.login.roleScopes)
            );
            localStorage.setItem(
              "token",
              JSON.stringify(result.data.login.token)
            );
            localStorage.setItem(
              "userLoggedId",
              JSON.stringify(result.data.login.userId)
            );
            localStorage.setItem(
              "userId",
              JSON.stringify(result.data.login.userId)
            );
            localStorage.setItem(
              "refreshToken",
              result.data.login.refreshToken
            );

            this.router.navigated = false;
            this.router.navigate(["pages"]);

            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 2000);
          } else {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 2000);
            window.confirm(
              "Non hai permessi per accedere. Contatta l'amministratore"
            );
          }
        },
        (err) => {
          window.confirm(
            "Credenziali errate. Riprova"
          );
          //alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
  }
  getConfigValue(key: string) { }
}
