import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, macroFeatureTranslationCreate, macroFeatureTranslationUpdate, macroFeatureTranslationDelete, faqTranslationCreate, faqTranslationUpdate, faqTranslationDelete } from '../../../queryMutation';
import { CookieXSRFStrategy } from '@angular/http';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'ngx-faqs-modal-open-translation',
  templateUrl: './faqs-modal-open-translation.component.html',
  styleUrls: ['./faqs-modal-open-translation.component.scss']
})
export class FaqsModalOpenTranslationComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() faqId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() languageId:any;
  @Input() description:string;

  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Output("addFaqTrans") addFaqTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editFaqTrans") editFaqTrans: EventEmitter<any> = new EventEmitter();
  @Output("deleteFaqTrans") deleteFaqTrans: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<FaqsModalOpenTranslationComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  faqTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: faqTranslationDelete.faqTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['faqTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deleteFaqTrans.emit({ 
            'id':this.translationId
          });
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Do you want to delete this Translation?')) {

      this.faqTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: faqTranslationCreate.faqTranslationCreate,
        variables: {
          data:{
            faqId:parseInt(this.faqId),
            languageId: parseInt(this.languageId),
            name:this.translationName,
            description: this.description
          }
      },
      }).subscribe((result: any) => {
        if( result.data['faqTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addFaqTrans.emit({ 
              'faqId':parseInt(this.faqId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'description': this.description,
              'id':result.data['faqTranslationCreate']['id'],
            });
          }, 2000);
        }
       });
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: faqTranslationUpdate.faqTranslationUpdate,
        variables: {
          data:{
            faqId:parseInt(this.faqId),
            languageId: parseInt(this.languageId),
            name:this.translationName,
            id:this.translationId,
            description: this.description
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['faqTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.editFaqTrans.emit({ 
              'faqId':parseInt(this.faqId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'description': this.description,
              'id':this.translationId
            });
          }, 2000);
        }
       });
    } 
   }



