import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from '../auth/role-guard.service';
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResetCacheComponent } from './reset-cache/reset-cache.component';

// import { NotFoundComponent } from './miscellaneous/not-found/not-found.component';


const routes: Routes = [{
  path: '',
  component: PagesComponent,
  // canActivate: [AuthGuard],
  children: [{
    path: 'reset-cache/reset-cache',
    component: ResetCacheComponent,
  },{
    path: 'dashboard',
    component: DashboardComponent,
  },
   {
    path: 'iot-dashboard',
    component: DashboardComponent,
   
  }, {
    path: 'citta',
    loadChildren: './ui-features/ui-features.module#UiFeaturesModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, {
    path: 'macro-feature',
    loadChildren: './modal-overlays/modal-overlays.module#ModalOverlaysModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  },{
    path: 'charts',
    loadChildren: './charts/charts.module#ChartsModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, {
    path: 'articoli',
    loadChildren: './news/editors.module#EditorsModule',
    canActivate: [RoleGuard], 
        data: { 
          expectedRole: ['SUPER_ADMIN','MUNICIPALITY_OPERATOR','BASE_USER']
        } 
        
  }, 
  {
    path: 'categories',
    loadChildren: './categories/tables.module#TablesModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: 'travel-tag',
    loadChildren: './maps/maps.module#MapsModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: 'faqs',
    loadChildren: './faqs/faqs.module#FaqsModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  },
  {
    path: 'pointOfInterest',
    loadChildren: './miscellaneous/miscellaneous.module#MiscellaneousModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: 'userList',
    loadChildren: './forms/forms.module#FormsModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  },
  {
    path: 'imageManager',
    loadChildren: './immagini/immagini.module#ImmaginiModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
        
  }, 
  {
    path: 'notificationManager',
    loadChildren: './notification/notification.module#NotificationModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
        
  }, 
  {
    path: 'journey',
    loadChildren: './bootstrap/bootstrap.module#BootstrapModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: 'author',
    loadChildren: './author/author.module#AuthorModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: 'suppliers',
    loadChildren: './suppliers/suppliers.module#SuppliersModule',
    canActivate: [RoleGuard], 
    data: { 
      expectedRole: ['SUPER_ADMIN']
    } 
  }, 
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }],
  // }, {
  //   path: '**',
  //   component: NotFoundComponent,
  // }],


  //  canActivate: [RoleGuard], 
  // data: { 
  //   expectedRole: ['SUPER_ADMIN']
  // } 

}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    RoleGuard,
    JwtHelperService
  ]
})
export class PagesRoutingModule {
}
