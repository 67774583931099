import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { QueryRef, Apollo } from 'apollo-angular';
import { FileUploadService } from '../../../service/file-upload.service';
import { pointOfInterestImageGetAll, newsImageAssociationCreate, imageCreateFromUpload, imageCreateUploadLink, pointOfInterestCreate, pointOfInterestImageCreate, poiBillingCreate, poiTravelCategoryCreate, travelCategoryGetAll, provinceGetAll, municipalityGetAll, poiOpeningPeriodCreate, pointOfInterestUpdate, seoCodeCreate, poiOpeningDayCreate, travelTagGetAll, pointOfInterestGetAll, pointOfInterestAdminSelectedCreate, pointOfInterestAdminSelectedTravelTagCreate, poiAdminSelectedImageCreate } from '../../../queryMutation';
import { HttpEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpRequestService } from '../../../service/http-request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

import { concatMap } from 'rxjs/operators';
import { concat, forkJoin } from 'rxjs';
import { ImageUploadType } from '../../../service/Image';
import { NgxSpinnerService } from 'ngx-spinner';

var myReader: FileReader = new FileReader();
@Component({
  selector: 'ngx-poi-admin-selected-create',
  templateUrl: './poi-admin-selected-create.component.html',
  styleUrls: ['./poi-admin-selected-create.component.scss']
})
export class PoiAdminSelectedCreateComponent implements OnInit {
  value: number = 1;

  public queryGetTravelTag: any;
  public queryGetAllPointOfInterest : any;
  DataInizio :any[];
  imageUpload:boolean=false;
  showPubblicaBtn:boolean= false;
  public model = { dollarSigns:'',pointOfInterestId:''};
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionPoi: Subscription;
  public querySubscriptionProfileUser: Subscription;
  
  public PointOfInterestPagination:QueryRef<any>;
  public travelTagPagination:QueryRef<any>;

  public selectedPoi:any;
  public selectedTravelTag:any;
  typeOfLingua:any;
  daysOfWeekGetAll:any;
  ImageSource:any;
  
  public travelTagGetAll:any[];
  public pointOfInterestGetAll:any[];
  public ImageAssociationGetAll:any[];
  imageData = { image: '' };
  public filesToUpload: any[];
  public imageToUpload: ImageUploadType;
  public lastId: any;

  configPointOfInterest = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not Records Found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Find Point Of Interest', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  configTravelCategory = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not Records Found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Find Travel Category', // label thats displayed in search input,
    searchOnKey: 'code', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  limit:any=25;
  fileToUpload:FileList;
  response:any;
  constructor(public apollo: Apollo, public fileUp: FileUploadService,public _location:Location, public router:Router,public requestService:HttpRequestService,public _sanitizer:DomSanitizer,public spinner: NgxSpinnerService) {
    this.queryGetTravelTag = new travelTagGetAll({},this.limit).getQuery();
    this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit).getQuery();
    this.travelTagGetAll=[];
    this.pointOfInterestGetAll= [];
    this.ImageAssociationGetAll=[];

    this.filesToUpload = [];
    this.imageToUpload = {
      'fileName': '',
      'size': 0,
      'mimeType': '',
      'belongsToId': 0,
      'uploadedByBaseUserId': 0,
      'role': '',
      'belongsTo':'',
      'tags':[]
    };
   }

  async ngOnInit() {
    this.pointOfInterestAll();
    //this.getAllTravelTag();
  }


  async getAllTravelTag() {

    this.travelTagPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetTravelTag,
      variables: {
        data: {
          
        },
      },
    });

    this.querySubscriptionAllProvince = this.travelTagPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.travelTagGetAll.length > 0 ? ((data.data['travelTagGetAll'][data.data['travelTagGetAll'].length - 1]['id'])) : null;
      console.log(lastId);
      this.queryGetTravelTag = new travelTagGetAll({}, this.limit, lastId).getQuery();
      this.travelTagPagination.fetchMore({
        query: this.queryGetTravelTag,
        variables: {
          data: {
          
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          
          if (fetchMoreResult['travelTagGetAll'].length < 1) {
            this.travelTagGetAll.push(...prev['travelTagGetAll']);

            this.travelTagGetAll = this.travelTagGetAll.map((item) => {
           
                return item;
           
             
            })
            console.log(this.travelTagGetAll);
           return prev;
          } else {
            return Object.assign({}, prev, {
              travelTagGetAll: [...prev['travelTagGetAll'], ...fetchMoreResult['travelTagGetAll']]
            });

          }
        }
      });
    });


  }
  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
   }


  async pointOfInterestAll() {
    var lastId = null
    if (this.pointOfInterestGetAll.length > 0) {
      lastId = this.pointOfInterestGetAll[this.pointOfInterestGetAll.length-1].id
    }
    this.apollo.query<Response>({
      query: new pointOfInterestGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: 'network-only',
      variables: {
        'data': {
          'poiTranslationFilter':{
            'hasTranslation':true,
            'containsLanguageId':[]
          }
        },
      }
    }).subscribe((data: any) => {
      console.log("pointOfInterestAll", data)
      if (data.data.pointOfInterestGetAll.length > 0) {
        this.pointOfInterestGetAll.push(...data.data.pointOfInterestGetAll)
        this.pointOfInterestAll()
      }
      else {
        console.log("pointOfInterestAll", "Terminato", this.pointOfInterestGetAll)
        this.pointOfInterestGetAll = this.pointOfInterestGetAll.map(item => {return item.poiTranslationGetAllSub[0]})
      }
    });
  }
  async __pointOfInterestAll() {
    this.PointOfInterestPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllPointOfInterest,
      fetchPolicy: 'network-only',
      variables: {
        'data': {
          'poiTranslationFilter':{
            'hasTranslation':true,
            'containsLanguageId':[]
          }
        },
      }
    });
    this.PointOfInterestPagination.valueChanges.subscribe(({data}) => {
      this.PointOfInterestPagination.fetchMore({
        query: this.queryGetAllPointOfInterest,
        variables: {
          'data': {
            'poiTranslationFilter':{
              'hasTranslation':true,
              'containsLanguageId':[]
            }
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const p = prev != undefined ? prev.pointOfInterestGetAll : undefined
          const f = fetchMoreResult.pointOfInterestGetAll
          const f_puliti = f.map(item => {item.poiTranslationGetAllSub[0]})
          this.pointOfInterestGetAll.push(...f)


          this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit, f[f.length-1].id).getQuery();
          
          if (!fetchMoreResult) {
            return prev;
          }
          if (p !== undefined) {
            return Object.assign({}, prev, {
              pointOfInterestGetAll: [...p, ...f],
            });
          }
          else {
            this.pointOfInterestGetAll = this.pointOfInterestGetAll.map(item => {return item.poiTranslationGetAllSub[0]})
            return Object.assign({}, prev, {
              pointOfInterestGetAll: [...f],
            });
          }
        }
      });
    })    
  

    /*
    this.PointOfInterestPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllPointOfInterest,
      variables: {
        'data': {
          'poiTranslationFilter':{
            'hasTranslation':true,
            'containsLanguageId':[]
          }
        },
      },
    });

    this.querySubscriptionPoi = this.PointOfInterestPagination.valueChanges.subscribe((data: any) => {
      console.log(`pointOfInterestAll->PointOfInterestPagination`);
      console.log(data);
      const lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['poiTranslationGetAllSub'][0]['id']) - (data.data.pointOfInterestGetAll.length)) : null;
      this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit, lastId).getQuery();
      this.PointOfInterestPagination.fetchMore({
        query: this.queryGetAllPointOfInterest,
        variables: {
          'data': {
            'poiTranslationFilter':{
              'hasTranslation':true,
              'containsLanguageId':[]
            }
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {

          if (fetchMoreResult['pointOfInterestGetAll'].length < 1) {
            this.pointOfInterestGetAll.push(...prev['pointOfInterestGetAll']);
            this.pointOfInterestGetAll = this.pointOfInterestGetAll.map((item) => {
              return item['poiTranslationGetAllSub'][0];
            });
            console.log(this.pointOfInterestGetAll);

            //
            return prev;
          } else {
              return Object.assign({}, prev, {

                pointOfInterestGetAll: [...prev['pointOfInterestGetAll'], ...fetchMoreResult['pointOfInterestGetAll']] 
              });
            }
        }
      });
    });
*/

  }

  createAdminSelected(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
        mutation: pointOfInterestAdminSelectedCreate.pointOfInterestAdminSelectedCreate,
        variables: {
          'data': {
            'isActive':true, 
            'dollarSigns':1,
            'pointOfInterestId':this.selectedPoi.pointOfInterestId,
            'position':-1
          }
        },
      }).subscribe((result: any) => {
        if (result.data['pointOfInterestAdminSelectedCreate']['success']) {
          localStorage.setItem("PoiAdminSelectedCreate",result.data['pointOfInterestAdminSelectedCreate']['id']);
         this.showPubblicaBtn = true;
          setTimeout(() => {
            
            this.spinner.hide();
            this.router.navigateByUrl("/pages/pointOfInterest/admin");
            
          }, 2000);
         
        }
      });
  }

  
  createTravelTagAssociation(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    if(this.selectedTravelTag.length){
    for (let index = 0; index < this.selectedTravelTag.length; index++) {
      this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
        mutation: pointOfInterestAdminSelectedTravelTagCreate.pointOfInterestAdminSelectedTravelTagCreate,
        variables: {
          'data': {
           
            'pointOfInterestAdminSelectedId': localStorage.getItem("PoiAdminSelectedCreate"),
            'travelTagId':this.selectedTravelTag[index].id
          }
        },
      }).subscribe((result: any) => {
        if (result.data['pointOfInterestAdminSelectedTravelTagCreate']['success']) {
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.router.navigateByUrl("/pages/pointOfInterest/admin");
          }, 2000);
         
        }
      },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
      
    }
  }
  else{
    alert("An error occurred.Try again later.");
  }
  }


  goBack(){
    this._location.back();
  }
}
