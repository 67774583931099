import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, poiTranslationUpdate, poiTranslationCreate, poiTranslationDelete } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ngx-modal-poi-open-translation-en',
  templateUrl: './modal-poi-open-translation-en.component.html',
  styleUrls: ['./modal-poi-open-translation-en.component.scss']
})
export class ModalPoiOpenTranslationEnComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() categoryId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() isFromIt:boolean;

  constructor(protected ref: NbDialogRef<ModalPoiOpenTranslationEnComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 
    
  }

  ngOnInit() {
  }
  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiTranslationDelete.poiTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['poiTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.ref.close();
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
 
  onDeleteConfirmEN(event): void {
    if (window.confirm('Do you want to Delete this Translation?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }
  
  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiTranslationCreate.poiTranslationCreate,
        variables: {
          data:{
            pointOfInterestId:parseInt(this.categoryId),
            languageId:2,
            name:this.translationName
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.ref.close();
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiTranslationUpdate.poiTranslationUpdate,
        variables: {
          data:{
            pointOfInterestId:parseInt(this.categoryId),
            languageId:2,
            name:this.translationName,
            id:this.translationId
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.ref.close();
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
}
