import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-immagini-open-details',
  templateUrl: './immagini-open-details.component.html',
  styleUrls: ['./immagini-open-details.component.scss']
})
export class ImmaginiOpenDetailsComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router) {

  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal() {
    
    if(this.rowData.id > 0){  
        localStorage.setItem("imageId",this.rowData.id);
        this.router.navigateByUrl("/pages/imageManager/details")
    }
  }
}


