import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AuthorOpenModalTranslationActionComponent } from '../author-open-modal-translation-action/author-open-modal-translation-action.component';

@Component({
  selector: 'ngx-author-open-translation-action',
  templateUrl: './author-open-translation-action.component.html',
  styleUrls: ['./author-open-translation-action.component.scss']
})
export class AuthorOpenTranslationActionComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveAuthorTrans: EventEmitter<any> = new EventEmitter();
  translationIt="";
  data:any;
  translations: any;

  public indexSelected:any;
  constructor(public dialogService:NbDialogService ) {


  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
    
      this.translations = []
      this.translations['it'] = this.rowData.baseUserTranslationGetAll.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.baseUserTranslationGetAll.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.baseUserTranslationGetAll.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.saveAuthorTrans.emit(this.rowData);
  }
  openEditModal(languageId) {

    

    this.indexSelected = this.rowData.baseUserTranslationGetAll.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.baseUserTranslationGetAll.filter(item => item.languageId == languageId);
    
    console.log("openEditModal", languageId, this.indexSelected, this.data)

    if(this.data.length > 0){
        if(this.data.length > 0){

          console.log("this.data", this.data);
            const ref =  this.dialogService.open(AuthorOpenModalTranslationActionComponent, {
               context: {
                title: 'Edit Translation',
                authorId:this.data[0].baseUserId,
                translationName : this.data[0].name,
                translationId: this.data[0].id,
                languageId:languageId,
                description:this.data[0].description
      
               },
             });

             ref.componentRef.instance["editAuthorTrans"].subscribe((data) => {
              ref.close();
              this.rowData.baseUserTranslationGetAll[this.indexSelected].baseUserId = data.baseUserId;
              this.rowData.baseUserTranslationGetAll[this.indexSelected].description = data.description;
              this.rowData.baseUserTranslationGetAll[this.indexSelected].languageId = data.languageId;
              this.rowData.baseUserTranslationGetAll[this.indexSelected].name = data.name;
              this.rowData.baseUserTranslationGetAll[this.indexSelected].id = data.id;
              this.saveAuthorTrans.emit(this.rowData);
            });
            
            ref.componentRef.instance["deleteAuthorTrans"].subscribe((data) => {
              ref.close();
              const indexToRemove =  this.rowData.baseUserTranslationGetAll.findIndex(res => res.id == data.id);
              this.rowData.baseUserTranslationGetAll.splice(indexToRemove,1);
              this.saveAuthorTrans.emit(this.rowData);
            });
             return;
           
        }
        else{
       const ref =this.dialogService.open(AuthorOpenModalTranslationActionComponent, {
            context: {
              title: 'Insert Translation',
              authorId:this.rowData.baseUserId,
              translationName : "",
              translationId: null,
              languageId:languageId,
              description:""
            },
          });
          return;
        }
         
      
    }
    else{
     const ref = this.dialogService.open(AuthorOpenModalTranslationActionComponent, {
        context: {
          title: 'Insert Translation',
          authorId:this.rowData.baseUserId,
          translationName : "",
          translationId: null,
          languageId:languageId,
          description:""
        },
      });
      
      ref.componentRef.instance["addAuthorTrans"].subscribe((data) => {
     
        this.rowData.baseUserTranslationGetAll.push(data);
        this.saveAuthorTrans.emit(this.rowData);
      });
      return;
    }
  }


}
