import { NbMenuItem } from '@nebular/theme';
import * as _ from 'lodash';
import * as jwtDecode from 'jwt-decode';


export class OggettoMenu {
  menu() {
    return [

      {
        title: 'Opzioni',
        group: true,
      },
      {
        title: 'User List',
        icon: 'nb-bar-chart',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'User List',
            link: '/pages/userList/list-user',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],


      },
      {
        title: 'Municipality',
        icon: 'nb-keypad',
        link: '/pages/citta',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Municipality List',
            link: '/pages/citta/list',
            hidden: !findRole(['SUPER_ADMIN']),
          },
        ],

      },
      {
        title: 'MacroFeature & Feature',
        icon: 'nb-layout-default',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Macro-Feature List',
            link: '/pages/macro-feature/list-macro-feature',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Feature List',
            link: '/pages/macro-feature/list-feature',
            hidden: !findRole(['SUPER_ADMIN'])
          },

        ],
      },
      {
        title: 'Places Categories',
        icon: 'nb-bar-chart',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Places Categories List',
            link: '/pages/charts/list-travel-category',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'News',
        icon: 'nb-title',
        children: [
          {
            title: 'News List',
            link: '/pages/articoli/list',
            hidden: !findRole(['SUPER_ADMIN', 'MUNICIPALITY_OPERATOR', 'EDITOR'])
          },
          {
            title: 'News Categories List',
            link: '/pages/categories/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'Travel Tag',
        icon: 'nb-tables',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Travel Tag List',
            link: '/pages/travel-tag/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'Suppliers',
        icon: 'nb-tables',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Supplier List',
            link: '/pages/suppliers/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'Point of Interest',
        icon: 'nb-shuffle',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Point of Interest List',
            link: '/pages/pointOfInterest/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Expired',
            link: '/pages/pointOfInterest/expired',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Admin Selected',
            link: '/pages/pointOfInterest/admin',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Type List',
            link: '/pages/pointOfInterest/poi-type-list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Macro Type List',
            link: '/pages/pointOfInterest/poi-macro-type-list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Paypal Config for Poi',
            link: '/pages/pointOfInterest/paypal-config-poi',
            hidden: !findRole(['SUPER_ADMIN'])
          },

        ],
      },

      {
        title: 'Image Manager',
        icon: 'nb-tables',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Immagini List ',
            link: '/pages/imageManager/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'Notication',
        icon: 'nb-tables',
        children: [
          {
            title: 'Send Notication',
            link: '/pages/notificationManager/create',
            hidden: !findRole(['SUPER_ADMIN', 'MUNICIPALITY_OPERATOR'])
          },
        ],
      },
      {
        title: 'Journey',
        icon: 'nb-shuffle',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Journey List',
            link: '/pages/journey/list-journey',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Admin Selected',
            link: '/pages/journey/admin-selected-journey',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Admin Selected Type List',
            link: '/pages/journey/admin-selected-type',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Admin Selected Macro-Type List',
            link: '/pages/journey/admin-selected-macro-type',
            hidden: !findRole(['SUPER_ADMIN'])
          },
          {
            title: 'Paypal Config for Journey',
            link: '/pages/journey/journey-paypal-config',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },
      {
        title: 'Faqs',
        icon: 'nb-title',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Faqs List',
            link: '/pages/faqs/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },

        ],
      },

      {
        title: 'Author',
        icon: 'nb-tables',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Author List ',
            link: '/pages/author/list',
            hidden: !findRole(['SUPER_ADMIN'])
          },
        ],
      },

      {
        title: 'Auth',
        icon: 'nb-locked',
        children: [
          {
            title: 'Modify Password',
            link: '/auth/reset-password',
          },
          {
            title: 'My Profile',
            link: '/auth/profile-user',
          },
        ],
      },

      {
        title: 'Reset Cache',
        icon: 'nb-locked',
        hidden: !findRole(['SUPER_ADMIN']),
        children: [
          {
            title: 'Reset Cache',
            link: '/reset-cache/reset-cache',
          },
        ],
      },

    ];
  }
}
/*
export const MENU_ITEMS: NbMenuItem[] = [

  {
    title: 'Opzioni',
    group: true,
  },
  {
    title: 'User List',
    icon: 'nb-bar-chart',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'User List',
        link: '/pages/userList/list-user',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
    
    
  },
  {
    title: 'Municipality',
    icon: 'nb-keypad',
    link: '/pages/citta',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Municipality List',
        link: '/pages/citta/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },     
    ],
    
  },
  {
    title: 'MacroFeature & Feature',
    icon: 'nb-layout-default',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Macro-Feature List',
        link: '/pages/macro-feature/list-macro-feature',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Feature List',
        link: '/pages/macro-feature/list-feature',
        hidden: !findRole(['SUPER_ADMIN'])
      },

    ],
  },
  {
    title: 'Places Categories',
    icon: 'nb-bar-chart',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Places Categories List',
        link: '/pages/charts/list-travel-category',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
  },
  {
    title: 'News',
    icon: 'nb-title',
    children: [
      {
        title: 'News List',
        link: '/pages/articoli/list',
        hidden: !findRole(['SUPER_ADMIN','MUNICIPALITY_OPERATOR'])
      },
      {
        title: 'News Categories List',
        link: '/pages/categories/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
  },
  {
    title: 'Travel',
    icon: 'nb-tables',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Travel List ',
        link: '/pages/travel-tag/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
  },
  {
    title: 'Point of Interest',
    icon: 'nb-shuffle',
    hidden: !findRole(['SUPER_ADMIN','BASE_USER']),
    children: [
      {
        title: 'Point of Interest List',
        link: '/pages/pointOfInterest/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Admin Selected',
        link: '/pages/pointOfInterest/admin',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Type List',
        link: '/pages/pointOfInterest/poi-type-list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Macro Type List',
        link: '/pages/pointOfInterest/poi-macro-type-list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Paypal Config for Poi',
        link: '/pages/pointOfInterest/paypal-config-poi',
        hidden: !findRole(['BASE_USER'])
      },
      
    ],
  },
  
  {
    title: 'Image Manager',
    icon: 'nb-tables',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Immagini List ',
        link: '/pages/imageManager/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
  },
  {
    title: 'Notication',
    icon: 'nb-tables',
    children: [
      {
        title: 'Send Notication',
        link: '/pages/notificationManager/create',
        hidden: !findRole(['SUPER_ADMIN','MUNICIPALITY_OPERATOR'])
      },
    ],
  },
  {
    title: 'Journey',
    icon: 'nb-shuffle',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Journey List',
        link: '/pages/journey/list-journey',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Admin Selected',
        link: '/pages/journey/admin-selected-journey',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      {
        title: 'Paypal Config for Journey',
        link: '/pages/journey/journey-paypal-config',
        hidden: !findRole(['BASE_USER'])
      },
    ],
  },
  {
    title: 'Faqs',
    icon: 'nb-title',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Faqs List',
        link: '/pages/faqs/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
      
    ],
  },
  
  {
    title: 'Author',
    icon: 'nb-tables',
    hidden: !findRole(['SUPER_ADMIN']),
    children: [
      {
        title: 'Author List ',
        link: '/pages/author/list',
        hidden: !findRole(['SUPER_ADMIN'])
      },
    ],
  },

  {
    title: 'Auth',
    icon: 'nb-locked',
    children: [
      {
        title: 'Modify Password',
        link: '/auth/reset-password',
      },
      {
        title: 'My Profile',
        link: '/auth/profile-user',
      },
    ],
  },

];
*/

function findRole(allowedRoles): boolean {
  //console.log("findRole", `cerco ${allowedRoles} in ${localStorage.getItem('roleScopes')}`);
  if (localStorage.getItem('roleScopes')) {
    let roleScopes: any = {};
    roleScopes = localStorage.getItem('roleScopes');

    if (roleScopes !== null || roleScopes !== undefined) {
      /*  console.log("\t", _.find(allowedRoles, function (item: any) {
          return  roleScopes.indexOf(item) !== -1;
        }) ? true : false)
      */
      return (_.find(allowedRoles, function (item: any) {
        return roleScopes.indexOf(item) !== -1;
      })) ? true : false;
    }
  }
  //console.log("findRole", allowedRoles, false)
  return false;
}
