import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalTraduzioneComponent } from '../../../../modal-traduzione/modal-traduzione.component';
import { ModalTraduzioneEnComponent } from '../../../../modal-traduzione-en/modal-traduzione-en.component';

@Component({
  selector: 'ngx-traduzione',
  templateUrl: './traduzione.component.html',
  styleUrls: ['./traduzione.component.scss']
})
export class TraduzioneComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  public data:any[];
  @Output() saveCatTrans: EventEmitter<any> = new EventEmitter();
  translationIt="";
  translations: any;

  public indexSelected:any;

  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'FEMALE'},
      ]
    }
    this.data=[];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.newsCategoryTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.newsCategoryTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.newsCategoryTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.saveCatTrans.emit(this.rowData);
  }
  openEditModal(languageId) {
    
    this.indexSelected  = this.rowData.newsCategoryTranslationGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.newsCategoryTranslationGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          const ref = this.dialogService.open(ModalTraduzioneComponent, {
           context: {
            title: 'Edit Translation',
            categoryId:this.rowData.id,
            translationName : this.data[0].name,
            translationId: this.data[0].id,
            languageId:languageId,
            description:this.data[0].description

           },
         });

         ref.componentRef.instance["editCategoryTrans"].subscribe((data) => {
          ref.close();
  
          this.rowData.newsCategoryTranslationGetAllSub[this.indexSelected].categoryId = data.newsCategoryId;
          this.rowData.newsCategoryTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
          this.rowData.newsCategoryTranslationGetAllSub[this.indexSelected].name = data.name;
          this.rowData.newsCategoryTranslationGetAllSub[this.indexSelected].id = data.id;
          this.rowData.newsCategoryTranslationGetAllSub[this.indexSelected].description = data.description;
          this.saveCatTrans.emit(this.rowData);
        });
        ref.componentRef.instance["deleteCategoryTrans"].subscribe((data) => {
          ref.close();
          const indexToRemove =  this.rowData.newsCategoryTranslationGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.newsCategoryTranslationGetAllSub.splice(indexToRemove,1);
          this.saveCatTrans.emit(this.rowData);
        });
         return;
       
    }
    else{
      const ref =  this.dialogService.open(ModalTraduzioneComponent, {
        context: {
          title: 'Insert Translation',
          categoryId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          description:""
        },
      });
      ref.componentRef.instance["addCategoryTrans"].subscribe((data) => {
        ref.close();
        this.rowData.newsCategoryTranslationGetAllSub.push(data);
        this.saveCatTrans.emit(this.rowData);
      });
      return;
    }
  }


}
