import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate } from '../queryMutation';
import '../pages/news/list/ckeditor.loader';
import 'ckeditor';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ngx-modal-traduzione',
  templateUrl: './modal-traduzione.component.html',
  styleUrls: ['./modal-traduzione.component.scss']
})
export class ModalTraduzioneComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
   dataProvince: any[];
   newsAllCategories:any[];
   newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() categoryId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() languageId:any;
  @Input() description:string;
  
  configEditor = {
    removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
    extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
    height: '320',
    enterMode: 0,
    htmlEncodeOutput: false,
    entities: false,
    basicEntities: false,
    contentsCss: [
    'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
    'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
    ]
}

  @Output("addCategoryTrans") addCategoryTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editCategoryTrans") editCategoryTrans: EventEmitter<any> = new EventEmitter();
  @Output("deleteCategoryTrans") deleteCategoryTrans: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<ModalTraduzioneComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: newsCategoryTranslationDelete.newsCategoryTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['newsCategoryTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deleteCategoryTrans.emit({ 
            'id':parseInt(this.translationId)
          })
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: newsCategoryTranslationCreate.newsCategoryTranslationCreate,
        variables: {
          data:{
            newsCategoryId:parseInt(this.categoryId),
            languageId: parseInt(this.languageId),
            name:this.translationName,
            description:this.description
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['newsCategoryTranslationCreate']['success']){

       
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addCategoryTrans.emit({ 
              'languageId':this.languageId,
              'name':this.translationName,
              'description':this.description,
              'newsCategoryId':parseInt(this.categoryId),
              'id':result.data['newsCategoryTranslationCreate']['id']
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: newsCategoryTranslationUpdate.newsCategoryTranslationUpdate,
        variables: {
          data:{
            newsCategoryId:parseInt(this.categoryId),
            languageId:this.languageId,
            name:this.translationName,
            id:this.translationId,
            description:this.description
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['newsCategoryTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addCategoryTrans.emit({ 
              'languageId':this.languageId,
              'name':this.translationName,
              'description':this.description,
              'newsCategoryId':parseInt(this.categoryId),
              'id':this.translationId
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
   }

