import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MiscellaneousComponent } from './miscellaneous.component';

import { PointOfInterestComponent } from './point-of-interest/point-of-interest.component';
import { PointOfInterestCreateComponent } from './point-of-interest-create/point-of-interest-create.component';
import { PointOfInterestUpdateComponent } from './point-of-interest-update/point-of-interest-update.component';
import { PoiAdminSelectedListComponent } from './poi-admin-selected-list/poi-admin-selected-list.component';
import { PoiAdminSelectedEditComponent } from './poi-admin-selected-edit/poi-admin-selected-edit.component';
import { PoiAdminSelectedCreateComponent } from './poi-admin-selected-create/poi-admin-selected-create.component';
import { OpenPoiImageUploadActionComponent } from './open-poi-image-upload-action/open-poi-image-upload-action.component';
import { OpenPoiAdminImageUploadActionComponent } from './open-poi-admin-image-upload-action/open-poi-admin-image-upload-action.component';
import { PoiAdminImageUploadActionComponent } from './poi-admin-image-upload-action/poi-admin-image-upload-action.component';
import { PoiImageUploadActionComponent } from './poi-image-upload-action/poi-image-upload-action.component';
import { PoiCoverImageUploadActionComponent } from './poi-cover-image-upload-action/poi-cover-image-upload-action.component';
import { PoiAdminCoverImageUploadActionComponent } from './poi-admin-cover-image-upload-action/poi-admin-cover-image-upload-action.component';
import { PoiAdminEditCoverImageUploadActionComponent } from './poi-admin-edit-cover-image-upload-action/poi-admin-edit-cover-image-upload-action.component';
import { PoiEditCoverImageUploadActionComponent } from './poi-edit-cover-image-upload-action/poi-edit-cover-image-upload-action.component';
import { PoiAdminEditImageUploadActionComponent } from './poi-admin-edit-image-upload-action/poi-admin-edit-image-upload-action.component';
import { PoiEditImageUploadActionComponent } from './poi-edit-image-upload-action/poi-edit-image-upload-action.component';
import { PoiTypeListComponent } from './poi-type-list/poi-type-list.component';
import { PoiTypeCreateComponent } from './poi-type-create/poi-type-create.component';
import { PoiTypeUpdateComponent } from './poi-type-update/poi-type-update.component';
import { PoiMacroTypeListComponent } from './poi-macro-type-list/poi-macro-type-list.component';
import { PoiMacroTypeCreateComponent } from './poi-macro-type-create/poi-macro-type-create.component';
import { PoiMacroTypeUpdateComponent } from './poi-macro-type-update/poi-macro-type-update.component';
import { PointOfInterestPaypalConfigComponent } from './point-of-interest-paypal-config/point-of-interest-paypal-config.component';

import { ImagePositionComponent } from "../images/image-position/image-position.component"
import { PointOfInterestExpiredComponent } from './point-of-interest-expired/point-of-interest-expired.component';

const routes: Routes = [{
  path: '',
  component: MiscellaneousComponent,
  children: [{
    path: 'list',
    component: PointOfInterestComponent,
  }, {
    path: 'expired',
    component: PointOfInterestExpiredComponent,
  }, {
    path: 'createPoi',
    component: PointOfInterestCreateComponent,
  }, {
    path: 'editPoi',
    component: PointOfInterestUpdateComponent,
  },
  {
    path: 'admin',
    component: PoiAdminSelectedListComponent,
  },
  {
    path: 'adminSelected-update',
    component: PoiAdminSelectedEditComponent,
  },
  {
    path: 'adminSelected-create',
    component: PoiAdminSelectedCreateComponent,
  },
  {
    path: 'adminSelected-upload-Image',
    component: PoiAdminImageUploadActionComponent,
  },
  {
    path: 'cover-upload-image',
    component: PoiCoverImageUploadActionComponent,
  },
  {
    path: 'admin-cover-upload-image',
    component: PoiAdminCoverImageUploadActionComponent,
  },
  {
    path: 'edit-cover-upload-image',
    component: PoiEditCoverImageUploadActionComponent,
  },
  {
    path: 'edit-admin-cover-upload-image',
    component: PoiAdminEditCoverImageUploadActionComponent,
  },
  {
    path: 'upload-image',
    component: PoiImageUploadActionComponent,
  },
  {
    path: 'edit-upload-image',
    //component: PoiEditImageUploadActionComponent,
    component: ImagePositionComponent,
  },
  {
    path: 'admin-edit-upload-image',
    component: PoiAdminEditImageUploadActionComponent,
  },
  {
    path: 'poi-type-list',
    component: PoiTypeListComponent,
  },
  {
    path: 'poi-type-create',
    component: PoiTypeCreateComponent,
  },
  {
    path: 'poi-type-update',
    component: PoiTypeUpdateComponent,
  },
  {
    path: 'poi-macro-type-list',
    component: PoiMacroTypeListComponent,
  },
  {
    path: 'poi-macro-type-create',
    component: PoiMacroTypeCreateComponent,
  },
  {
    path: 'poi-macro-type-update',
    component: PoiMacroTypeUpdateComponent,
  },
  {
    path: 'paypal-config-poi',
    component: PointOfInterestPaypalConfigComponent,
  }
  ],



}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MiscellaneousRoutingModule { }

export const routedComponents = [
  MiscellaneousComponent,
  PointOfInterestComponent,
  PointOfInterestCreateComponent
];
