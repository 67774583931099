import { Component, OnInit, Compiler } from '@angular/core';
import { municipalityGet, newsGetAll, newsDelete, pointOfInterestGetAll, pointOfInterestDelete, pointOfInterestAdminSelectedGetAll, pointOfInterestAdminSelectedDelete, pointOfInterestAdminSelectedUpdate, poiAdminSelectedGetAll, poiTypeGetAll, poiTypeDelete } from '../../../queryMutation';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { LocalDataSource } from 'ng2-smart-table';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { Subscription } from 'rxjs/internal/Subscription';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OpenTranslationPoiComponent } from '../open-translation-poi/open-translation-poi.component';
import { OpenUpdatePointOfInterestComponent } from '../open-update-point-of-interest/open-update-point-of-interest.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenSeoCodePoiComponent } from '../open-seo-code-poi/open-seo-code-poi.component';
import { PoiAdminSelectedOpenActionComponent } from '../poi-admin-selected-open-action/poi-admin-selected-open-action.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpenPoiAdminImageUploadActionComponent } from '../open-poi-admin-image-upload-action/open-poi-admin-image-upload-action.component';
import { OpenPoiCoverImageUploadActionComponent } from '../open-poi-cover-image-upload-action/open-poi-cover-image-upload-action.component';
import { OpenAdminPoiCoverImageUploadActionComponent } from '../open-admin-poi-cover-image-upload-action/open-admin-poi-cover-image-upload-action.component';
import { bool } from 'aws-sdk/clients/signer';
import { OpenPoiTypeUpdateComponent } from '../open-poi-type-update/open-poi-type-update.component';
import { OpenPoiTypeTranslationComponent } from '../open-poi-type-translation/open-poi-type-translation.component';
import { forkJoin } from 'rxjs';
import { ZipService } from '../../../service/zipFile.service';

@Component({
  selector: 'ngx-poi-type-list',
  templateUrl: './poi-type-list.component.html',
  styleUrls: ['./poi-type-list.component.scss']
})
export class PoiTypeListComponent implements OnInit {

  public doNotAddPoiAdminSelected: boolean = false;
  public queryGetAllPointOfInterest: any;
  public querySubscriptionAllProvince: Subscription;
  public poiTypeGetAll: any[];
  public poiAdminSelectedPagination: QueryRef<any>;

  newsData: any;
  limit: number = 25;
  datauri: any;
  myFile: Blob;
  profile: any;
  fileToUpload: File;
  response: any;
  selectedProduct: any;
  lastId: null;

  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo: Apollo, public _compiler: Compiler, public service: SmartTableService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public spinner: NgxSpinnerService, public zipFile: ZipService) {
    this.queryGetAllPointOfInterest = new poiTypeGetAll({}, this.limit).getQuery();
    this.poiTypeGetAll = [];
  }

  async ngOnInit() {
    this._compiler.clearCache();
    this.source.reset();
    this.poiTypeGetAll = [];

    await this.getAllPontoDiIntereseAdminSelected();

  }

  settings = {
    actions: {
      add: false,
      edit: false
    },
    delete: {
      deleteButtonContent: "<img src='assets/images/deleteTable.png'>",
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      code: {
        title: 'Code',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          return row.code;

        },

      },
      iconConverted: {
        title: 'Icon',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => { 
          if(row.iconConverted !== undefined){ 
          return this._sanitizer.bypassSecurityTrustHtml(row.iconConverted)
        }
        else{ 
          return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");

        }
      }
      },
      poiMacroType: {
        title: 'Poi MacroType Code',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          if (row.poiMacroTypeGetSub !== null) {
            if (row.poiMacroTypeGetSub.code != null) {

              row.poiMacroType = row.poiMacroTypeGetSub.code;
              return row.poiMacroType;
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
      traduzzione: {
        title: 'Translation',
        type: 'custom',
        renderComponent: OpenPoiTypeTranslationComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      Actions: {
        title: 'Update Action',
        type: 'custom',
        renderComponent: OpenPoiTypeUpdateComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
    },
  };

  PointOfInterestingAdminSelectedDelete(idToDelete) {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.queryGetAllPointOfInterest = this.apollo.use('super-admin').mutate({
      mutation: poiTypeDelete.poiTypeDelete,
      variables: {
        'id': idToDelete
      },
    }).subscribe((result: any) => {
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        this.source.reset();
        this.poiTypeGetAll = [];
        this.getAllPontoDiIntereseAdminSelected();
      }, 2000);

    }, (err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();

      }, 2000);
    })

  }


  createPoiList() {

    this.router.navigateByUrl("/pages/pointOfInterest/poi-type-create");
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Do you want to Delete this row?')) {
      this.PointOfInterestingAdminSelectedDelete(event.data.id);
    } else {
      event.confirm.reject();
    }
  }
  async getAllPontoDiIntereseAdminSelected(lastId = null) {

    this.apollo.query<Response>({
      query: new poiTypeGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: 'no-cache',
      variables: {
        'data': {
        },
      },
    }).subscribe((data: any) => {
      var result = data.data.poiTypeGetAll
      this.poiTypeGetAll.push(...result)
      if (result.length < this.limit) {
        this.setIconToSvg(this.poiTypeGetAll)
      } else {
        this.getAllPontoDiIntereseAdminSelected(result[result.length-1].id)
      }
    })
  }



  setImageConverted() {
    for (let j = 0; j < this.poiTypeGetAll.length; j++) {
      this.zipFile.getZipContent(this.poiTypeGetAll[j].icon).then((data: any) => {
        data.file("text.svg").async("string").then((response: any) => {
          this.poiTypeGetAll[j].iconConverted = "<div class='imgRendering'>" + response + "</div>";
        });
      })
    }

  }


  setIconToSvg(data: any[]) {
    var tasks$ = [];
    var tasks$2 = [];
    for (let index = 0; index < data.length; index++) {
      if ((data[index].icon !== "")) {
        tasks$.push(this.zipFile.getZipContent(data[index].icon));
      }
    }
    if (tasks$.length > 0) {
      forkJoin(tasks$).subscribe((result: any) => {
        for (let index = 0; index < result.length; index++) {
          tasks$2.push(result[index].file("text.svg").async("string"));
        }
        forkJoin(tasks$2).subscribe((result2: any) => {
          for (let j = 0; j < result2.length; j++) {
            data[j].iconConverted = "<div class='imgRendering'>" + result2[j] + "</div>";

            console.log(result2);
          }
          this.source.load(data);
        });

      });

    }
    else {
      this.source.load(data);
    }
  }

}

