import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-open-update-user-list',
  templateUrl: './open-update-user-list.component.html',
  styleUrls: ['./open-update-user-list.component.scss']
})
export class OpenUpdateUserListComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt="";
  constructor(public router:Router) {

  
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.save.emit(this.rowData);
  }

  openEditModal() {
    
    if(this.rowData.id > 0){
      console.log(this.rowData.id);
        localStorage.setItem("baseUserId",this.rowData.id);
         this.router.navigateByUrl("/pages/userList/update-base-user");
         
  }
  }
}


