

import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OpenModalSeoCodeComponent } from '../open-modal-seo-code/open-modal-seo-code.component';

@Component({
  selector: 'ngx-open-seo-code',
  templateUrl: './open-seo-code.component.html',
  styleUrls: ['./open-seo-code.component.scss']
})

export class OpenSeoCodeComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  public  data:any[];
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  public indexSelected:any;
  @Output() saveSeoCod: EventEmitter<any> = new EventEmitter();
  translations: any;
  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
    this.data = [];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.saveSeoCod.emit(this.rowData);
  }
  openModal(languageId) {
    
    this.indexSelected = this.rowData.seoCodeGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          const ref = this.dialogService.open(OpenModalSeoCodeComponent, {
           context: {
           
            title: 'Edit SeoCode',
            seoCodeId:this.data[0].id,
            newsId:this.data[0].newsId,
            languageId: this.data[0].languageId,
            seoCodeName:this.data[0].code,
            redirectStatusCode:this.data[0].redirectStatusCode,
            staticRedirectUrl:this.data[0].staticRedirectUrl,
            redirectToId:this.data[0].redirectToId
           },
         });
         ref.componentRef.instance["editNewsSeoCode"].subscribe(data => {
          ref.close();
          this.rowData.seoCodeGetAllSub[this.indexSelected].id = data.id;
          this.rowData.seoCodeGetAllSub[this.indexSelected].newsId = data.newsId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].code = data.code;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectStatusCode = data.redirectStatusCode;
          this.rowData.seoCodeGetAllSub[this.indexSelected].staticRedirectUrl = data.staticRedirectUrl;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectToId = data.redirectToId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].languageId = data.languageId;
          this.saveSeoCod.emit(this.rowData);
         });
         ref.componentRef.instance["deleteNewsSeoCode"].subscribe(data => {
          ref.close();
          const indexToRemove =  this.rowData.seoCodeGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.seoCodeGetAllSub.splice(indexToRemove,1);
          this.saveSeoCod.emit(this.rowData);
         });
       return;
    }
    else{
     const ref= this.dialogService.open(OpenModalSeoCodeComponent, {
        context: {
          title: 'Create SeoCode',
          seoCodeId:null,
          newsId:this.rowData.id,
          languageId: languageId,
          seoCodeName:null,
          redirectStatusCode:null,
          staticRedirectUrl:"",
          redirectToId:''
        },
      });
      ref.componentRef.instance["addNewsSeoCode"].subscribe((data) => {
        ref.close();
        this.rowData.seoCodeGetAllSub.push(data);
        this.saveSeoCod.emit(this.rowData);
      })
      return;
    }
  }

}
