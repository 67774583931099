import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalTranslationMacroFutureItComponent } from '../../modal-translation-macro-future-it/modal-translation-macro-future-it.component';
import { ModalTranslationMacroFutureEnComponent } from '../../modal-translation-macro-future-en/modal-translation-macro-future-en.component';

@Component({
  selector: 'ngx-traduzione-modal',
  templateUrl: './traduzione-modal.component.html',
  styleUrls: ['./traduzione-modal.component.scss']
})
export class TraduzioneModalComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveTransMacroFeat: EventEmitter<any> = new EventEmitter();
  translationIt="";
  data:any;
  translations: any;

  public indexSelected:any;
  constructor(public dialogService:NbDialogService ) {


  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.macroFeatureTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.macroFeatureTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.macroFeatureTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.saveTransMacroFeat.emit(this.rowData);
  }
  openEditModal(languageId) {
    
    if(this.rowData.macroFeatureTranslationGetAllSub.length > 0){
    
       this.indexSelected = this.rowData.macroFeatureTranslationGetAllSub.findIndex(item => item.languageId == languageId);
        this.data = this.rowData.macroFeatureTranslationGetAllSub.filter(item => item.languageId == languageId);

        if(this.data.length > 0){
          console.log(this.data[0].id);
             const ref =  this.dialogService.open(ModalTranslationMacroFutureItComponent, {
               context: {
               
                title: 'Edit Translation',
                categoryId:this.data[0].macroFeatureId,
                translationName : this.data[0].name,
                translationId: this.data[0].id,
                languageId:languageId
               },
             });

             ref.componentRef.instance["editMacroFeatureTrans"].subscribe((data) => {
              ref.close();
      
              this.rowData.macroFeatureTranslationGetAllSub[this.indexSelected].macroFeatureId = data.macroFeatureId;
              this.rowData.macroFeatureTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
              this.rowData.macroFeatureTranslationGetAllSub[this.indexSelected].name = data.name;
              this.rowData.macroFeatureTranslationGetAllSub[this.indexSelected].id = data.id;
              this.saveTransMacroFeat.emit(this.rowData);
            });
            ref.componentRef.instance["deleteMacroFeatureTrans"].subscribe((data) => {
              ref.close();
              const indexToRemove =  this.rowData.macroFeatureTranslationGetAllSub.findIndex(res => res.id == data.id);
              this.rowData.macroFeatureTranslationGetAllSub.splice(indexToRemove,1);
              this.saveTransMacroFeat.emit(this.rowData);
            });
           return;
        }
        else{
          const ref = this.dialogService.open(ModalTranslationMacroFutureItComponent, {
            context: {
              title: 'Create Translation',
              categoryId:this.rowData.id,
              translationName : "",
              translationId: null,
             languageId:languageId
            },
          });
          ref.componentRef.instance["addMacroFeatureTrans"].subscribe((data) => {
            ref.close();
            this.rowData.macroFeatureTranslationGetAllSub.push(data);
            this.saveTransMacroFeat.emit(this.rowData);
          });
          return;
        }

    }
    else{
      const ref = this.dialogService.open(ModalTranslationMacroFutureItComponent, {
        context: {
          title: 'Insert Translation',
          categoryId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId
        },
      });
      ref.componentRef.instance["addMacroFeatureTrans"].subscribe((data) => {
        ref.close();
        this.rowData.macroFeatureTranslationGetAllSub.push(data);
        this.saveTransMacroFeat.emit(this.rowData);
      });
      return;
    }
  }


}
