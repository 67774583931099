import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, poiTranslationUpdate, poiTranslationCreate, poiTranslationDelete, poiTypeTranslationCreate, poiTypeTranslationUpdate, poiTypeTranslationDelete, poiMacroTypeTranslationUpdate, poiMacroTypeTranslationCreate, poiMacroTypeTranslationDelete, poiDayNoteGet, poiDayNoteDelete, poiDayNoteCreate, poiDayNoteUpdate } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigBase } from 'aws-sdk/lib/config';

@Component({
  selector: 'ngx-modal-poi-day-note-translation',
  templateUrl: './modal-poi-day-note-translation.component.html',
  styleUrls: ['./modal-poi-day-note-translation.component.scss']
})
export class ModalPoiDayNoteTranslationComponent  {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];
  DataInizio: any[];
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }
  
  @Input() title: string;
  @Input() pointOfInterestId:any;
  
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() isFromIt:boolean;
  @Input() languageId:any;

  @Input() startingDateTime:string;
  @Input() endingDateTime:string;

  @Output("addPoiDayNoteTrans") addPoiDayNoteTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editPoiDayNoteTrans") editPoiDayNoteTrans: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiDayNoteTrans") deletePoiDayNoteTrans: EventEmitter<any> = new EventEmitter();

  constructor(protected ref: NbDialogRef<ModalPoiDayNoteTranslationComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 

    this.DataInizio =[];

  }

  ngOnInit() {
    
    this.DataInizio =[this.startingDateTime,this.endingDateTime];
  }
  trackByFn(index: any, item: any) {
    return index;
 }


  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiDayNoteDelete.poiDayNoteDelete,
      fetchPolicy:'no-cache',
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['poiDayNoteDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deletePoiDayNoteTrans.emit({ 
            'id':this.translationId,
          });
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
 
  onDeleteConfirm(event): void {
    if (window.confirm('Conferma che voi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }
  
  Salva() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiDayNoteCreate.poiDayNoteCreate,
        fetchPolicy:'no-cache',
        variables: {
          data:{
            pointOfInterestId:parseInt(this.pointOfInterestId),
            languageId:this.languageId,
            name:this.translationName,
            startingDateTime:this.DataInizio[0],
            endingDateTime:this.DataInizio[1],

          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiDayNoteCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.addPoiDayNoteTrans.emit({ 
              'pointOfInterestId':parseInt(this.pointOfInterestId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'startingDateTime':this.DataInizio[0],
              'endingDateTime':this.DataInizio[1],
              'id':result.data['poiDayNoteCreate']['id'],
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiDayNoteUpdate.poiDayNoteUpdate,
        fetchPolicy:'no-cache',
        variables: {
          data:{
            languageId:parseInt(this.languageId),
            name:this.translationName,
            id:this.translationId,
            startingDateTime:this.DataInizio[0],
            endingDateTime:this.DataInizio[1],
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiDayNoteUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.editPoiDayNoteTrans.emit({ 
              'pointOfInterestId':parseInt(this.pointOfInterestId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'startingDateTime':this.DataInizio[0],
              'endingDateTime':this.DataInizio[1],
              'id':this.translationId,
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
}

