import { Component, OnInit, Compiler } from '@angular/core';
import { Subscription, concat, Observable, forkJoin } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { baseUserGet, updateBaseUser, updatePhoneNumberUser, updateEmailDataBaseUser, imageCreateUploadLink, imageCreateFromUpload, remoteFileUploadRequestDelete, baseUserImageCreate } from '../queryMutation';
import * as AWS from 'aws-sdk';
import { FileUploadService } from '../service/file-upload.service';
import { HttpEvent } from '@angular/common/http';
import { FileMetadata } from 'aws-sdk/clients/codecommit';
import { Location } from '@angular/common';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpRequestService } from '../service/http-request.service';
import { ImageUploadType, ResizeResponse, IBody } from '../service/Image';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment.prod';
import * as moment from 'moment';

var myReader: FileReader = new FileReader();

@Component({
  selector: 'ngx-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})
export class ProfileUserComponent implements OnInit {
  public querySubscriptionProfileUser: Subscription;
  public queryGetUserProfile: any;
  profile={email:'',phoneNumber:'',name:'',gender:'',surname:''};

  availableOptions:any;
  response:any;
file:File;

public dataProfile:any;
public filesToUpload: any[];
public profileModelMapping:any={};
public imageToUpload: ImageUploadType;
imagelink: Observable<{}>;
fileToUpload: FileList;
public ImageAssociationGetAll: any[];

imageData =
    {
      image: '',
      resize: [
        {
          width: 0,
          height: 0,
          fit: ""

        },
        {
          width: 0,
          height: 0,
          fit: ""

        },
        {
          width: 0,
          height: 0,
          fit: ""

        },
        {
          width: 0,
          height: 0,
          fit: ""

        },

      ],
      moveryEnv: ""
    };
  datauri:any;
  myFile:Blob;
  constructor(public apollo:Apollo,public fileUpload:FileUploadService,public _location:Location,public fileUp: FileUploadService,public router:Router,public requestService:HttpRequestService,public _sanitizer:DomSanitizer,public _compiler: Compiler,public spinner: NgxSpinnerService) { 
    let userid = localStorage.getItem("userLoggedId");
    console.log(userid);
    this.availableOptions= [
        {id: "MALE", name: 'MALE'},
        {id: 'FEMALE', name: 'FEMALE'},
      ];
    
    this.filesToUpload = [];
    this.imageToUpload = {
      'fileName': '',
      'size': 0,
      'mimeType': '',
      'belongsToId': 0,
      'uploadedByBaseUserId': 0,
      'role': '',
      'belongsTo':'',
      'tags':[]
    };
  }

  ngOnInit() {

    this.getProfile();

} 
goBack(){
  this._location.back();
}
//Convert Image from base64 File Image
public dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
for (var i = 0; i < binary.length; i++) {
   array.push(binary.charCodeAt(i));
}
return new Blob([new Uint8Array(array)], {
  type: 'image/jpg'
});
}
  getProfile(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use("super-admin")
    .watchQuery<Response>({
      query: baseUserGet.baseUserGet,
      fetchPolicy:'network-only',
      variables: {
        'id': parseInt(localStorage.getItem("userLoggedId"))
    },
    })
    .valueChanges.subscribe((result: any) => {

      this.profile.name = result.data.baseUserGet.name;
      this.profile.email = result.data.baseUserGet.email;
      this.profile.gender = result.data.baseUserGet.gender;
      this.profile.phoneNumber = result.data.baseUserGet.phoneNumber;
      this.profile.surname = result.data.baseUserGet.surname;

      this.profileModelMapping = result.data.baseUserGet;

      this.dataProfile = this.profileModelMapping.baseUserImageGetAllSub.filter(item => item.imageGetSub.url !== undefined);

      this._compiler.clearCache();
      setTimeout(() => {
        
        this.spinner.hide();
        
      }, 2000);
     });
  }
  refreshProfile(){



    this.profile={email:'',phoneNumber:'',name:'',gender:'',surname:''};
    this.profileModelMapping = {};


    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );

    this.querySubscriptionProfileUser = this.apollo.use("super-admin")
    .watchQuery<Response>({
      query: baseUserGet.baseUserGet,
      fetchPolicy:'network-only',
      variables: {
        'id': parseInt(localStorage.getItem("userLoggedId"))
    },
    })
    .valueChanges.subscribe((result: any) => {
      this.profile.name = result.data.baseUserGet.name;
      this.profile.email = result.data.baseUserGet.email;
      this.profile.gender = result.data.baseUserGet.gender;
      this.profile.phoneNumber = result.data.baseUserGet.phoneNumber;
      this.profile.surname = result.data.baseUserGet.surname;

      this.profileModelMapping = result.data.baseUserGet;
      
      this._compiler.clearCache();
      setTimeout(() => {
        
        this.spinner.hide();
        
      }, 2000);


     });
  }
  updateProfile(){

    var tasks$2 = [];

    
   

    if(this.profile.phoneNumber != this.profileModelMapping.phoneNumber){
      tasks$2.push(this.apollo.use("base-user").mutate({
      mutation: updatePhoneNumberUser.baseUserPhoneNumber,
      variables: {
        'data': this.profile.phoneNumber
      },
        }));
    }

    if(this.profile.name != this.profileModelMapping.name){

      tasks$2.push(this.apollo.use("base-user").mutate({
        mutation: updateBaseUser.baseUserUpdateInfo,
        variables: {
          'data': {
            'id': parseInt(localStorage.getItem("userLoggedId")),
            'name': this.profile.name,
            'surname': this.profile.surname,
            'gender':this.profile.gender
          }
        },
      }));

    }

    
     if(this.profile.email != this.profileModelMapping.email){

      tasks$2.push(this.apollo.use("base-user").mutate({
        mutation: updateEmailDataBaseUser.baseUseChangeEmail,
        variables: {
          'data': {
            'newEmail': this.profile.email,
            'emailDispatchComingFrom':"BACKOFFICE"
          }
        },
      }));
    }
    return forkJoin(...tasks$2).subscribe((result: any) => {
        console.log(result);
    });
   

  }
  onChange(value){
    console.log(value);
    this.profile.gender=value;
  }







handleFileInput(files: FileList) {

  this.fileToUpload = files;

  var tasks$ = [];
  for (let index = 0; index < files.length; index++) {

    tasks$.push(this.fileUp.fileToString(files[index]));
  }
  return Promise.all(tasks$).then((values: any) => {

    var strImage = values[0].replace(/^data:image\/[a-z]+;base64,/, "");

    this.imageData.image = strImage.toString();
    this.imageData.moveryEnv = environment.moveryEnv;

    var img = new Image;
    return new Promise((resolve, reject) => {
      img.onload = function () {
        img.height = img.height;
        img.width = img.width;
        resolve(img);
      };

      img.src = values;
    }).then((img: any) => {

      this.imageData.resize[0].fit = "cover";
      this.imageData.resize[0].width = img.width;
      this.imageData.resize[0].height = img.height;

      this.imageData.resize[1].fit = "cover";
      this.imageData.resize[1].width = environment.thumb_low_width;
      this.imageData.resize[1].height = this.fileUp.CalculateHeight(environment.thumb_low_width,img.width,img.height) ;

      this.imageData.resize[2].fit = "cover";
      this.imageData.resize[2].width = environment.thumb_medium_width;
      this.imageData.resize[2].height = this.fileUp.CalculateHeight(environment.thumb_medium_width,img.width,img.height) ;

      this.imageData.resize[3].fit = "cover";
      this.imageData.resize[3].width = environment.thumb_high_width;
      this.imageData.resize[3].height =this.fileUp.CalculateHeight(environment.thumb_high_width,img.width,img.height);

      this.executeSecondImagePromise();

    }).catch(erro => {
    });
  }).catch(erro => {
  });
}

//this method executed a base64 string and convert to a webP immage to upload to AWS
executeSecondImagePromise() {
  var tasks$2 = [];
  var tasks$3 = [];
  var tasks$4 = [];
  tasks$2.push(this.requestService.cloudConvertImage(this.imageData));
  return forkJoin(tasks$2).subscribe((datas: IBody<ResizeResponse>[]) => {
    for (let j = 0; j < datas[0].data.results.length; j++) {

      datas[0].data.results[j].jpg = 'data:image/jpeg;base64,' + datas[0].data.results[j].jpg
      datas[0].data.results[j].webp = 'data:image/webp;base64,' + datas[0].data.results[j].webp


      tasks$3.push(this.fileUp.b64toBlob(datas[0].data.results[j].jpg, "image/jpeg"));
      tasks$3.push(this.fileUp.b64toBlob(datas[0].data.results[j].webp, "image/webp"));

    }
     Promise.all(tasks$3).then((blobValue: any) => {
      for (let i = 0; i < blobValue.length; i++) {

        console.log(this.fileToUpload[0].name);
        
        var fileName = this.fileToUpload[0].name.slice(0,this.fileToUpload[0].name.lastIndexOf("."));
        var timespanValue = moment().valueOf();
        if (blobValue[0].type == "image/jpeg") {
          blobValue[0].name = "JPEG-" + fileName.toUpperCase() + "-" +  timespanValue  + "." + blobValue[0].type.slice(blobValue[0].type.lastIndexOf("/") + 1,blobValue[0].type.length) ;
          blobValue[0].role = "ORIGINAL";
          blobValue[0].tags = fileName.toUpperCase()  + "-" +  timespanValue;

          blobValue[0].tags = blobValue[0].tags.push("PROFILE_IMAGE");

          
        }
        if (blobValue[1].type == "image/webp") {
          blobValue[1].name = "WEBP-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[1].type.slice(blobValue[1].type.lastIndexOf("/") + 1,blobValue[1].type.length);
          blobValue[1].role = "ORIGINAL";
          blobValue[1].tags = fileName.toUpperCase()  + "-" +  timespanValue;

          blobValue[1].tags = blobValue[1].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[2].size) {
          blobValue[2].name = "THUMB-LOW-JPEG-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[2].type.slice(blobValue[2].type.lastIndexOf("/") + 1,blobValue[2].type.length); 
          blobValue[2].role = "LOW";
          blobValue[2].tags = fileName.toUpperCase()  + "-" +  timespanValue;

          blobValue[2].tags = blobValue[2].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[3].size) {
          blobValue[3].name = "THUMB-LOW-WEBP-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[3].type.slice(blobValue[3].type.lastIndexOf("/") + 1,blobValue[3].type.length);
          blobValue[3].role = "LOW";
          blobValue[3].tags = fileName.toUpperCase()  + "-" +  timespanValue;
          blobValue[3].tags = blobValue[3].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[4].size) {
          blobValue[4].name = "THUMB-MEDIUM-JPEG-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[4].type.slice(blobValue[4].type.lastIndexOf("/") + 1 ,blobValue[4].type.length);
          blobValue[4].role = "MEDIUM";
          blobValue[4].tags = fileName.toUpperCase()  + "-" +  timespanValue;
          blobValue[4].tags = blobValue[4].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[5].size) {
          blobValue[5].name = "THUMB-MEDIUM-WEBP-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[5].type.slice(blobValue[5].type.lastIndexOf("/") + 1,blobValue[5].type.length);
          blobValue[5].role = "MEDIUM";
          blobValue[5].tags = fileName.toUpperCase()  + "-" +  timespanValue;
          blobValue[5].tags = blobValue[5].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[6].size) {
          blobValue[6].name = "THUMB-HIGH-JPEG-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[6].type.slice(blobValue[6].type.lastIndexOf("/") + 1,blobValue[6].type.length);
          blobValue[6].role = "HIGH";
          blobValue[6].tags = fileName.toUpperCase()  + "-" +  timespanValue;
          blobValue[6].tags = blobValue[6].tags.push("PROFILE_IMAGE");
        }
        if (blobValue[7].size) {
          blobValue[7].name = "THUMB-HIGH-WEBP-" + fileName.toUpperCase() + "-" +  timespanValue + "." + blobValue[7].type.slice(blobValue[7].type.lastIndexOf("/") + 1 ,blobValue[7].type.length);
          blobValue[7].role = "HIGH";
          blobValue[7].tags = fileName.toUpperCase()  + "-" +  timespanValue;
          blobValue[7].tags = blobValue[7].tags.push("PROFILE_IMAGE");
        }
        console.log(blobValue[i])
        this.filesToUpload.push(blobValue[i]);
      }
      console.log(this.filesToUpload);

      this.recursiveImageUpload();
    });
  }, (err) => {
    console.log(err);

  });
}


recursiveImageUpload() {

  var tasks$ = [];
  this.spinner.show(undefined,
    {
      type: 'square-spin',
      size: 'medium',
      bdColor: 'rgba(100,149,237, .8)',
      color: 'white',
      fullScreen: true
    }
  );
  for (let index = 0; index < this.filesToUpload.length; index++) {

   
    this.imageToUpload.fileName = this.filesToUpload[index].name;
    this.imageToUpload.size = this.filesToUpload[index].size;
    this.imageToUpload.mimeType = this.filesToUpload[index].type;
    this.imageToUpload.belongsToId = parseInt(localStorage.getItem("userLoggedId"));
    this.imageToUpload.uploadedByBaseUserId = parseInt(localStorage.getItem("userLoggedId"));
    this.imageToUpload.role = this.filesToUpload[index].role
    this.imageToUpload.tags = this.filesToUpload[index].tags;
    this.imageToUpload.belongsTo = "BASE_USER",

        tasks$.push(this.fileUp
          .imageUploadManager(this.imageToUpload, this.filesToUpload[index]).pipe(
            concatMap((first) => {
              console.log(first);
              return this.fileUp.baseUserImageCreate(first.data['imageCreateFromUpload'].id,parseInt(localStorage.getItem("userLoggedId")));
            })
          ));
  }

  return concat(...tasks$).subscribe(y => {
    this.getProfile();
  }, (err) => {
    this._compiler.clearCache();
    setTimeout(() => {

      this.spinner.hide();
      this.router.navigateByUrl("/pages/dashboard");

    }, 2000);
  })

}

}
