import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogRef } from '@nebular/theme';
import { seoCodeDelete, seoCodeCreate, seoCodeUpdate, seoTagUpdate, seoTagCreate, seoTagDelete } from '../../../queryMutation';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';


@Component({
  selector: 'ngx-open-modal-seo-tag-poi',
  templateUrl: './open-modal-seo-tag-poi.component.html',
  styleUrls: ['./open-modal-seo-tag-poi.component.scss']
})
export class OpenModalSeoTagPoiComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];
  @Input() title: string;
  @Input() description: string;
  @Input() seoCodeId:any;
  @Input() seoTagName:any[];
  @Input() languageId:any;
  @Input() poiId:any;
  @Input() seoTagTitle:string;
  @Input() noFollow:boolean;

  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Output("addPoiTag") addPoiTag: EventEmitter<any> = new EventEmitter();
    
  @Output("editPoiTag") editPoiTag: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiTag") deletePoiTag: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<OpenModalSeoTagPoiComponent>,public apollo:Apollo) { 
    this.seoTagName = []
  }

  ngOnInit() {
    this.seoTagName.push("");
  }
  
  dismiss() {
    this.ref.close();
  }
  trackByFn(index: any, item: any) {
    return index;
 }
  seoCodeDeleteEvent(){
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: seoTagDelete.seoTagDelete,
      variables: {
        'id':parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if( result.data['seoTagDelete']['success']){
        this.deletePoiTag.emit({ 
          'id':this.seoCodeId,
        });
      }
     });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
     event.confirm.reject();
    }
  }
  addInput(){


    this.seoTagName.push("");

    console.log(this.seoTagName);
  }
  Salva() {
 
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagCreate.seoTagCreate,
        variables: {
          data:{
            'pointOfInterestId':parseInt(this.poiId),
            'languageId':this.languageId,
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'noFollow':this.noFollow
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoTagCreate']['success']){
          this.addPoiTag.emit({ 
            'pointOfInterestId':parseInt(this.poiId),
            'languageId':parseInt(this.languageId),
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'id':result.data['seoTagCreate']['id'],
            'noFollow':this.noFollow
          });
        }
       });
    } 
    Edit() {
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagUpdate.seoTagUpdate,
        variables: {
          data:{
            'pointOfInterestId':parseInt(this.poiId),
            'languageId': parseInt(this.languageId),
            'openGraphTags':[""],
            'id':parseInt(this.seoCodeId),
            'title':this.seoTagTitle,
            'description':this.description,
            'noFollow':this.noFollow
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoTagUpdate']['success']){
          this.editPoiTag.emit({ 
            'pointOfInterestId':parseInt(this.poiId),
            'languageId':parseInt(this.languageId),
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'id':this.seoCodeId,
            'noFollow':this.noFollow
          });
        }
       });
    } 
   }


