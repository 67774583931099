import { Component, OnInit, Compiler } from '@angular/core';
import { pointprova, municipalityGet, newsGetAll, newsDelete, pointOfInterestGetAll, pointOfInterestDelete, poiDayNoteGet, poiDayNoteGetAll, poiFindHintGetAll, imageGet } from '../../../queryMutation';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { LocalDataSource } from 'ng2-smart-table';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { Subscription } from 'rxjs/internal/Subscription';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OpenTranslationPoiComponent } from '../open-translation-poi/open-translation-poi.component';
import { OpenUpdatePointOfInterestComponent } from '../open-update-point-of-interest/open-update-point-of-interest.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenSeoCodePoiComponent } from '../open-seo-code-poi/open-seo-code-poi.component';
import { forkJoin } from "rxjs/observable/forkJoin";
import { Observable } from 'rxjs/Observable';
import { fromPromise } from 'apollo-link';
import { OpenSeoTagPoiComponent } from '../open-seo-tag-poi/open-seo-tag-poi.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClonePointOfInterestComponent } from '../clone-point-of-interest/clone-point-of-interest.component';
import { OpenTranslationBillingComponent } from '../open-translation-billing/open-translation-billing.component';
import { OpenPoiImageUploadActionComponent } from '../open-poi-image-upload-action/open-poi-image-upload-action.component';
import { OpenPoiCoverImageUploadActionComponent } from '../open-poi-cover-image-upload-action/open-poi-cover-image-upload-action.component';
import { OpenPoiDayNoteTranslationComponent } from '../open-poi-day-note-translation/open-poi-day-note-translation.component';
import { OpenPoiFindHintComponent } from '../open-poi-find-hint/open-poi-find-hint.component';
import { SuppliersOpenSearchComponent } from '../../suppliers/suppliers-open-search/suppliers-open-search.component';
import { NbDialogService } from '@nebular/theme';
import { ImageButtonComponent, ImageButtonParameter } from '../../table/image-button/image-button.component';

@Component({
  selector: 'ngx-point-of-interest',
  templateUrl: './point-of-interest.component.html',
  styleUrls: ['./point-of-interest.component.scss']
})
export class PointOfInterestComponent implements OnInit {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionDeletePoi: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetAllPointOfInterest: any;
  public queryGetNewsTranslationCategories: any;
  public PointOfInterestPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsGetAll: any[];
  public pointOfInterestGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  lastId = null;
  teste = [];
  newsData: any;
  limit: number = 50;
  datauri: any;
  myFile: Blob;
  profile: any;
  fileToUpload: File;
  response: any;
  selectedProduct: any;
  pageSize = 50;
  selectedRows: any;
  data: any;
  isMunicipalityFilter: boolean;
  isSearch: boolean = false
  keyword: any;

  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo: Apollo, public _compiler: Compiler, public service: SmartTableService,
    public router: Router, public datePipe: DatePipe
    , public _sanitizer: DomSanitizer, public spinner: NgxSpinnerService, public dialogService: NbDialogService) {

    this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit).getQuery();
    this.pointOfInterestGetAll = [];
  }


  async onSearch(query: string = '') {
    console.log(`onSearch=${query}`)
    if (query === "")
      this.source.setFilter(null)
    else
      this.source.setFilter([
        {
          field: 'id',
          search: query
        },
        {
          field: 'name',
          search: query
        }
      ], false);
  }

  async ngOnInit() {
    this._compiler.clearCache();
    this.source.reset();
    this.pointOfInterestGetAll = [];
    this.data = {}
    var municipality_id = parseInt(localStorage.getItem('municipality.id'))
    if (!isNaN(municipality_id) && municipality_id > 0) {
      this.data['municipalityId'] = municipality_id
      localStorage.removeItem('municipality.id')
      this.isMunicipalityFilter = true;
    } else {
      this.isMunicipalityFilter = false;
    }
    await this.getAllPontoDiInterese();
  }

  deleteMunicipalityFilter() {
    this.ngOnInit()
  }

  onRowSelect(event) {
    for (let index = 0; index < event.selected.length; index++) {
      event.selected[index].isSelected = true;
      event.data.isSelected = true;
    }

  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: false
    },
    // rowClassFunction: (row) => {
    //   console.log(row.data);

    //   if (row.data.isSelected == true) {
    //     return 'solved';
    //   } 
    // },
    delete: {
      deleteButtonContent: "<img src='assets/images/deleteTable.png'>",
      confirmDelete: true,
    },
    columns: {
      Clone: {
        title: 'Clone Action',
        type: 'custom',
        renderComponent: ClonePointOfInterestComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      name: {
        title: 'Translation Name',
        type: 'html',
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiTranslationGetAllSub.length > 0) {


            var data = row.poiTranslationGetAllSub.filter(item => item.languageId == 1);

            if (data.length > 0) {

              row.name = data[0].name;
              return row.name;
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
        filterFunction(obj?: any, search?: string): boolean {

          console.log("obj", obj, "search", search, obj.indexOf(search) > -1);

          if (obj.indexOf(search) > -1)
            return true;

          return false;
        },
      },
      workInProgress: {
        title: 'Draft',
        type: 'checkbox',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { return row.workInProgress == true ? "SI" : "NO" },

      },
      travelTagCode: {
        title: 'Travel Tag Code',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          console.log("ROWWWWW", row);
          if (row.poiTravelTagGetAllSub.length > 0) {
            if (
              row.poiTravelTagGetAllSub[0].id != null
            ) {
              row.code =
                row.poiTravelTagGetAllSub[0].travelTagGetSub.code;
              return row.code;
            } else {
              return this._sanitizer.bypassSecurityTrustHtml(
                "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>"
              );
            }
          } else {
            return this._sanitizer.bypassSecurityTrustHtml(
              "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>"
            );
          }
        },

      },
      estimatedVisitLength: {
        title: 'Estimated Visit',
        type: 'string',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          return row.estimatedVisitLength + " min"
        },
      },
      updatedAt: {
        title: 'Updated Date',
        type: 'date',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          var raw = new Date(row.updatedAt);

          var formatted = this.datePipe.transform(raw, 'dd MMM yyyy');
          return formatted;
        },
      },
      openingPeriodValidated: {
        title: 'Openining Period ',
        type: 'checkbox',
        valuePrepareFunction: (cell, row) => { return row.openingPeriodValidated == true ? "SI" : "NO" },
      },

      municipalityCode: {
        title: 'Municipality Code',
        type: 'html',
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          return row.municipalityCode
        },
        filterFunction(obj?: any, search?: string): boolean {

          console.log(obj);
          console.log(search);

          if (obj.indexOf(search.toUpperCase()) > -1)
            return true;

          return false;
        },
      },
      dayOfWeek: {
        title: 'Day of the Week',
        type: 'string',
        filter: true,
        editable: false,
        addable: false
      },
      languageId: {

        title: 'Language',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.poiTranslationGetAllSub.length > 0) {

            row.languageId = row.poiTranslationGetAllSub.filter(item => item.languageId == 1) ? "IT" : "EN";
            // row.languageId = row.poiTranslationGetAllSub[0].languageId == 1 ? "IT" : "EN";
            return row.languageId;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },

      },
      traduzzione: {
        title: 'Nome',
        type: 'custom',
        renderComponent: OpenTranslationPoiComponent,
        onComponentInitFunction: (instance: any) => {
          instance.savePoiTranslation.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      seoCode: {
        title: 'Canonical',
        type: 'custom',
        renderComponent: OpenSeoCodePoiComponent,
        onComponentInitFunction: (instance: any) => {
          instance.saveSeoCodePoi.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      seoTag: {
        title: 'Meta tag',
        type: 'custom',
        renderComponent: OpenSeoTagPoiComponent,
        onComponentInitFunction: (instance: any) => {
          instance.saveSeoTagPoi.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },

      Actions: {
        title: 'Update Action',
        type: 'custom',
        renderComponent: OpenUpdatePointOfInterestComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      // keyWord: {
      //   title: 'keyWord ',
      //   type: 'html',
      //   filter: true,
      //   editable: false,
      //   addable: false,
      //   valuePrepareFunction: (cell, row) => {
      //     if (row.poiFindHintGetAllSub.length > 0) {
      //       var data = row.poiFindHintGetAllSub.filter(item => item.id !== null);
      //       if (data.length > 0) {
      //         row.keyWord = "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/success.png' style='width: 30px'></div>";

      //         return this._sanitizer.bypassSecurityTrustHtml(row.keyWord);
      //       }
      //       else {
      //         return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
      //       }

      //     } else {
      //       return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
      //     }
      //   }
      // },
      // findHint: {
      //   title: 'Trova Ricerca per parola ',
      //   type: 'custom',
      //   renderComponent: OpenPoiFindHintComponent,
      //   onComponentInitFunction: (instance: any) => {
      //     instance.savePoiFindHint.subscribe(row => {
      //       this.source.update(row, row);
      //     });
      //   },
      //   filter: false,
      //   editable: false,
      //   addable: false
      // },
      poiBillingTranslation: {
        title: 'Create Billing Translation',
        type: 'custom',
        renderComponent: OpenTranslationBillingComponent,
        onComponentInitFunction: (instance: any) => {
          instance.savePoiBilling.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },

      // "servono per assegnare dei testi ai pointOfInterest in determinati giorni"
      /*
      existPoiDayNoteTranslationIt: {
        title: 'PoiDayNote Translation It',
        type: 'html',
        filter: true,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiDayNoteGetAllSub !== null) {
            var data = row.poiDayNoteGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.existPoiDayNoteTranslationIt = "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/success.png' style='width: 30px'></div>";

              return this._sanitizer.bypassSecurityTrustHtml(row.existPoiDayNoteTranslationIt);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }


        },
      },
      existPoiDayNoteTranslationEn: {
        title: 'PoiDayNote Translation En',
        type: 'html',
        filter: true,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiBillingGetSub !== null) {
            var data = row.poiDayNoteGetAllSub.filter(item => item.languageId == 2);
            if (data.length > 0) {
              row.existPoiDayNoteTranslationEn = "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/success.png' style='width: 30px'></div>";

              return this._sanitizer.bypassSecurityTrustHtml(row.existPoiDayNoteTranslationEn);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
      existPoiDayNoteTranslationFr: {
        title: 'PoiDayNote Translation Fr',
        type: 'html',
        filter: true,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiDayNoteGetAllSub !== null) {
            var data = row.poiDayNoteGetAllSub.filter(item => item.languageId == 4);
            if (data.length > 0) {
              row.existPoiDayNoteTranslationFr = "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/success.png' style='width: 30px'></div>";

              return this._sanitizer.bypassSecurityTrustHtml(row.existPoiDayNoteTranslationFr);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },

      },
      poiDayNote: {
        title: 'poiDayNote',
        type: 'custom',
        renderComponent: OpenPoiDayNoteTranslationComponent,
        onComponentInitFunction: (instance: any) => {
          instance.savePoiDayNote.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      */
      UploadCoverImage: {
        title: 'Cover',
        type: 'custom',
        renderComponent: OpenPoiCoverImageUploadActionComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      /*UploadImage: {
        title: 'Gallery',
        type: 'custom',
        renderComponent: OpenPoiImageUploadActionComponent,
        valuePrepareFunction: (value, row, cell) => {
          return "CIAO"
        },
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log("input", "subscribe", row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },*/
      UploadImage1: {
        title: 'Gallery',
        type: 'custom',
        renderComponent: ImageButtonComponent,
        valuePrepareFunction: (value, row, cell) => {
          return new ImageButtonParameter(ImageButtonParameter.TYPE_GALLERY, ImageButtonParameter.BO_POI)
        },
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log("input", "subscribe", row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      /*
      ShowCoverImage: {
        title: 'Show Cover Image',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          console.log("cell", cell)
          console.log("row", row)

          var toReturn = ""
          var images = row['pointOfInterestImageGetAllSub']
          console.log(images)

          images.forEach(image => {
            var imageGetSub = image['imageGetSub']
            console.log("imageGetSub", imageGetSub.fileName, imageGetSub)
            toReturn += `<a style="padding: 5px" class="btn btn-success" href="${imageGetSub.url}">${imageGetSub.fileName} - TAGS:${imageGetSub.tags} MimeType:${imageGetSub.mimeType} Role:${imageGetSub.role}</a><br/>`
          });
          return this._sanitizer.bypassSecurityTrustHtml(`<div>${toReturn}</div>`);
        }
      },*/
    },
  };

  PointOfInterestingToDelete(idToDelete) {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );


    this.querySubscriptionDeletePoi = this.apollo.use('super-admin').mutate({
      mutation: pointOfInterestDelete.pointOfInterestDelete,
      variables: {
        'id': idToDelete
      },
    }).subscribe((result: any) => {
      this.source.reset();
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.lastId = null;
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        this.pointOfInterestGetAll = [];
        this.getAllPontoDiInterese();
      }, 2000);



    });



  }

  createPoi() {
    this.router.navigateByUrl("/pages/pointOfInterest/createPoi");
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Do you want to Delete this row?')) {
      console.log(event.data.id)
      this.PointOfInterestingToDelete(event.data.id);
    } else {
      event.confirm.reject();
    }
  }

  async getCodeMunicipalityId() {
    for (let index = 0; index < this.pointOfInterestGetAll.length; index++) {
      if (this.pointOfInterestGetAll.length > 0) {
        this.querySubscriptionProfileUser = this.apollo.watchQuery<Response>({
          query: municipalityGet.municipalityGet,
          variables: {
            'id': this.pointOfInterestGetAll[index].municipalityId
          },
        })
          .valueChanges.subscribe((result: any) => {

            this.pointOfInterestGetAll[index].municipalityCode = result.data.municipalityGet.code;
            console.log(this.pointOfInterestGetAll);
          });
      }
    }

  }

  async loadAll() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    })
    this._loadAll()
  }

  async _loadAll() {

    var lastId = this.pointOfInterestGetAll.length > 0
      ? this.pointOfInterestGetAll[this.pointOfInterestGetAll.length - 1]['id']
      : null

    this.apollo.query<Response>({
      query: pointprova.pointprova,
      variables: {
        'data': this.data,
        'limit': this.limit,
        'idCursor': lastId
      },
      fetchPolicy: "no-cache",
    }).subscribe((data: any) => {
      var result = data.data.pointOfInterestGetAll
      console.log("pageChange.data", result)
      result.forEach(element => {
        element.municipalityCode = element.municipalityGetSub.code
        element.isSelected = false;
      })
      this.pointOfInterestGetAll.push(...result)
      console.log("pageChange.pointOfInterestGetAll", this.pointOfInterestGetAll)
      if (result.length != this.limit) {
        this.source.load(this.pointOfInterestGetAll)
        setTimeout(() => this.spinner.hide(), 2000)
      } else {
        this._loadAll()
      }
    })
  }

  async pageChange() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    })
    var lastId = this.pointOfInterestGetAll.length > 0
      ? this.pointOfInterestGetAll[this.pointOfInterestGetAll.length - 1]['id']
      : null
    console.log("pageChange", lastId)
    //this.apollo.watchQuery<Response>({
    //}).valueChanges.subscribe((data: any) => {
    this.apollo.query<Response>({
      query: pointprova.pointprova,
      variables: {
        'data': this.data,
        'limit': this.limit,
        'idCursor': lastId
      },
      fetchPolicy: "no-cache",
    }).subscribe((data: any) => {
      console.log("pageChange.data", data.data.pointOfInterestGetAll)
      data.data.pointOfInterestGetAll.forEach(element => {
        element.municipalityCode = element.municipalityGetSub.code
        element.isSelected = false;
      })
      this.pointOfInterestGetAll.push(...data.data.pointOfInterestGetAll)
      console.log("pageChange.pointOfInterestGetAll", this.pointOfInterestGetAll)
      this.source.load(this.pointOfInterestGetAll);

      setTimeout(() => {
        this.spinner.hide();
      }, 2000);


      /*
      if (data.data.pointOfInterestGetAll.length > 0) {
        this.pageChange()
              } else {
                for (var poi of this.pointOfInterestGetAll) {
                  if (poi.poiTranslationGetAllSub !== undefined && poi.poiTranslationGetAllSub.length > 0) {
                    for (var trans of poi.poiTranslationGetAllSub) {
                      console.log("facilitations", poi.id, trans.facilitations)
                    }
                  }
                }
              setTimeout(() => {
                this.spinner.hide();
              }, 2000);
              }
              */

      // script per verificare se ci sono errori
      /*
      
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
      
      */

      //this.getPoiDayNoteByID(data.data.pointOfInterestGetAll)

      /*
      this.pointOfInterestGetAll.push(...data.data.pointOfInterestGetAll)
      console.log("pointOfInterestGetAll", this.pointOfInterestGetAll)
      this.source.load(this.pointOfInterestGetAll);
      */

      /*
      this.lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['id'])) : null;

      if (this.source.count() > 0) {
        this.pointOfInterestGetAll.push(...data.data.pointOfInterestGetAll);
        this.getMunicipalityByID(data.data.pointOfInterestGetAll);
      }
      else {
        this.pointOfInterestGetAll.push(...data.data.pointOfInterestGetAll);
        this.getMunicipalityByID(data.data.pointOfInterestGetAll);
      }
      */
    })
  };

  async getAllPontoDiInterese() {
    this.pageChange()
    /*
    if (this.pointOfInterestGetAll.length === 0) {
      this.apollo.watchQuery<Response>({
        query: pointprova.pointprova,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
          },
          'limit': 25,
          'idCursor': this.lastId
        },
      }).valueChanges.subscribe((data: any) => {
        console.log("getAllPontoDiInterese")
        console.log(data.data)
        this.lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['id'])) : null;
        console.log(data.data.pointOfInterestGetAll);
        if (this.source.count() > 0) {
          this.getMunicipalityByID(data.data.pointOfInterestGetAll)
        }
        else {
          this.getMunicipalityByID(data.data.pointOfInterestGetAll);
        }
      });
    }*/
  }
  getMunicipalityByID(data: any[]) {
    var tasks$ = [];
    for (let index = 0; index < data.length; index++) {

      tasks$.push(this.apollo.query({
        query: municipalityGet.municipalityGet,
        variables: {
          'id': parseInt(data[index].municipalityId)
        },
      }));
    }

    forkJoin(tasks$).subscribe((result: any) => {
      for (let index = 0; index < result.length; index++) {
        data[index].municipalityCode = result[index].data.municipalityGet.code;
        data[index].isSelected = false;
      }
      this.getPoiDayNoteByID(data);
    });
  }

  getPoiFindHintGetAll(data: any[]) {
    var tasks$ = [];
    for (let index = 0; index < data.length; index++) {
      tasks$.push(this.apollo.use("super-admin").query({
        query: poiFindHintGetAll.poiFindHintGetAll,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
            'parentId': parseInt(data[index].id),
          },
          'limit': this.limit,
          'idCursor': this.lastId
        },
      }));
    }

    forkJoin(tasks$).subscribe((result: any) => {


      for (let index = 0; index < result.length; index++) {
        console.log(result[index].data.poiFindHintGetAll);
        data[index].poiFindHintGetAllSub = result[index].data.poiFindHintGetAll;
      }
      this.source.load(data);
    });
  }

  getPoiDayNoteByID(data: any[]) {
    var tasks$ = [];
    for (let index = 0; index < data.length; index++) {
      tasks$.push(this.apollo.query({
        query: poiDayNoteGetAll.poiDayNoteGetAll,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
            'parentId': parseInt(data[index].id),
          },
          'limit': this.limit,
          'idCursor': this.lastId
        },
      }));
    }

    forkJoin(tasks$).subscribe((result: any) => {


      for (let index = 0; index < result.length; index++) {
        data[index].poiDayNoteGetAllSub = result[index].data.poiDayNoteGetAll;
      }

      this.pointOfInterestGetAll.push(...data)
      console.log("pointOfInterestGetAll", this.pointOfInterestGetAll)
      this.source.load(this.pointOfInterestGetAll);



      //this.source.load(data);
      //this.getPoiFindHintGetAll(data);
    });
  }

  async doSearch() {
    this.pointOfInterestGetAll = []
    this.source.load(this.pointOfInterestGetAll);
    if (this.keyword.trim() == "") {
      this.data = {}
    } else {
      this.data = {
        localizedNameQuery: {
          languageId: 1,
          name: this.keyword
        }
      }
    }
    await this.getAllPontoDiInterese();
  }

  closeSearch() {
    this.isSearch = false;
    this.keyword = ""
    this.doSearch();
  }

  /*
    search() {
  
      const ref = this.dialogService.open(SuppliersOpenSearchComponent, {
        context: {
          title: 'Search PointOfInterest',
          type: 'PointOfInterest',
        },
      });
  
      ref.componentRef.instance["searchEmitter"].subscribe((data) => {
        ref.close();
        console.log("searchEmitter", data)
        //this.pointOfInterest.push(...data.searchEmitter)
      });
    }*/

}
