import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'sectodate'
})
export class SectodatePipe implements PipeTransform {

  transform(value: number, args?: any): any {
    const hours = Math.floor(value / 3600);
    const minutes: number = Math.floor(value / 60);

    if (hours > 0) {
      console.log("orario convertito:" + hours + ' h ' + (minutes - hours * 60) + ' min');
     
      
      // Min moment: February 12 2018, 10:30
    var min = new Date(2018, 1, 12, hours, (minutes - hours * 60));
    console.log(min);

      return min;
    }
    else{
      var min = new Date(2018, 1, 12, hours, (minutes - hours * 60));
      console.log(min);
  
    }

  }
}
