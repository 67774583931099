import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { JourneyAdminSelectedOpenModalSeoCodeComponent } from '../journey-admin-selected-open-modal-seo-code/journey-admin-selected-open-modal-seo-code.component';



@Component({
  selector: 'ngx-journey-admin-selected-open-seo-code',
  templateUrl: './journey-admin-selected-open-seo-code.component.html',
  styleUrls: ['./journey-admin-selected-open-seo-code.component.scss']
})
export class JourneyAdminSelectedOpenSeoCodeComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage = {}
  data: any[];
  translations: any;

  public indexSelected: any;
  @Output() saveJourneyAdminSeoCode: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: NbDialogService) {

  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();

    this.translations = []
    this.translations['it'] = 
      this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0
      || this.rowData.journeyGetSub.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0
    this.translations['eng'] = 
      this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0
      || this.rowData.journeyGetSub.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0
    this.translations['fr'] = 
    this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0
    || this.rowData.journeyGetSub.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0
  }

  onClick() {
    this.saveJourneyAdminSeoCode.emit(this.rowData);
  }


  openModal(languageId) {

    console.log("ROW", this.rowData)
    this.indexSelected = null
    this.data = null
    var index = this.rowData.seoCodeGetAllSub.findIndex(item => item.languageId == languageId);
    var index_j = this.rowData.journeyGetSub.seoCodeGetAllSub.findIndex(item => item.languageId == languageId)
   
    var isJourneyAdminSelected = false

    if (index_j >= 0) {
      console.log("prendo da j")
      this.indexSelected = index_j;
      this.data = this.rowData.journeyGetSub.seoCodeGetAllSub.filter(item => item.languageId == languageId);
    } else if (index >= 0) {
      console.log("prendo da admin")
      this.indexSelected = index;
      this.data = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == languageId);
    } 
    


    var journeyId = this.rowData.journeyId
    var journeyAdminSelectedId = this.rowData.id

    if (this.data != null && this.data.length > 0) {
      isJourneyAdminSelected = this.data[0].journeyAdminSelectedId !== null && this.data[0].journeyAdminSelectedId !== undefined
      console.log("seo-code", this.data[0]);
      const ref = this.dialogService.open(JourneyAdminSelectedOpenModalSeoCodeComponent, {
        context: {

          title: 'Edit SeoCode',
          seoCodeId: this.data[0].id,
          journeyId: journeyId,
          journeyAdminSelectedId: journeyAdminSelectedId,
          isJourneyAdminSelected: isJourneyAdminSelected,
          languageId: this.data[0].languageId,
          seoCodeName: this.data[0].code,
          redirectStatusCode: this.data[0].redirectStatusCode,
          staticRedirectUrl: this.data[0].staticRedirectUrl,
          redirectToId: this.data[0].redirectToId
        },
      });

      ref.componentRef.instance["editJourneyAdminCode"].subscribe((data) => {
        ref.close();

        
        var originale = null;
        if(isJourneyAdminSelected) {
          originale = this.rowData.seoCodeGetAllSub[this.indexSelected]
        } else {
          originale = this.rowData.journeyGetSub.seoCodeGetAllSub[this.indexSelected]
        }

        console.log ("PRE", `is= ${isJourneyAdminSelected} admin, ${data.journeyAdminSelectedId} nor, ${data.journeyId}`, data)
        
        console.log(data.journeyAdminSelectedId)
        if (data.journeyAdminSelectedId !== undefined && data.journeyAdminSelectedId !== "undefined") {
          console.log ("SOLO ADMIN")
          originale.journeyId = undefined;
          originale.journeyAdminSelectedId = data.journeyAdminSelectedId;
        } else {
          console.log ("SOLO J")
          originale.journeyId = data.journeyId;
          originale.journeyAdminSelectedId = undefined;
        }
        
        originale.id = data.id;
        originale.languageId = data.languageId;
        originale.redirectToId = data.redirectToId;
        originale.openGraphTags = data.openGraphTags;
        originale.description = data.description;
        originale.title = data.title;
        originale.code = data.code;

        console.log ("POS", `is= ${isJourneyAdminSelected} admin, ${data.journeyAdminSelectedId} nor, ${data.journeyId}`, data)
        this.saveJourneyAdminSeoCode.emit(this.rowData);
      });
      ref.componentRef.instance["deleteJourneyAdminCode"].subscribe((data) => {
        ref.close();

        if(isJourneyAdminSelected) {
          const indexToRemove = this.rowData.seoCodeGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.seoCodeGetAllSub.splice(indexToRemove, 1);
        } else {
          const indexToRemove = this.rowData.journeyGetSub.seoCodeGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.journeyGetSub.seoCodeGetAllSub.splice(indexToRemove, 1);
        }
        this.saveJourneyAdminSeoCode.emit(this.rowData);

      });
      return;
    }
    else {
      const ref = this.dialogService.open(JourneyAdminSelectedOpenModalSeoCodeComponent);
      const instance = ref.componentRef.instance
      instance.title = 'Create SeoCode';
      instance.seoCodeId = '';
      instance.journeyId = journeyId;
      instance.journeyAdminSelectedId = journeyAdminSelectedId;
      instance.languageId = languageId;
      instance.seoCodeName = '';
      instance.redirectStatusCode = null;
      instance.staticRedirectUrl = '';
      instance.redirectToId = '';

      ref.componentRef.instance["addJourneyAdminCode"].subscribe((data) => {
        ref.close();

        if(isJourneyAdminSelected) {
          this.rowData.seoCodeGetAllSub.push(data);
        } else {
          this.rowData.journeyGetSub.seoCodeGetAllSub.push(data);
        }
        this.saveJourneyAdminSeoCode.emit(this.rowData);

      });
      return;
    }
  }


}
