import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponentComponent } from './login-component/login-component.component';
import { AuthService } from './auth/auth.service';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import * as JSZip from 'jszip';

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { SmartTableComponent } from './movery-all-users/list-users/smart-table.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { RequestPassComponent } from './request-pass/request-pass.component';
import { RequestPassConfirmationComponent } from './request-pass-confirmation/request-pass-confirmation.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor, HttpRequestService } from './service/http-request.service';





const routes: Routes = [

  { path: 'pages', 
    loadChildren: 'app/pages/pages.module#PagesModule',
    canActivate: [AuthGuard], 
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: LoginComponentComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
        canActivate: [AuthGuard], 
      },
      {
        path: 'request-password',
        component: RequestPassComponent,
      },
      {
        path: 'request-password-confirmation',
        component: RequestPassConfirmationComponent,
      
      },
      {
        path: 'reset-password',
        component: ChangePassComponent,
        
      },
      {
        path: 'movery-list-user',
        component: SmartTableComponent,
        canActivate: [RoleGuard, AuthGuard], 
        data: { 
          expectedRole: ['SUPER_ADMIN']
        } 
      },
      {
        path: 'profile-user',
        component: ProfileUserComponent,
        canActivate: [AuthGuard], 
        
      },
    ],
  },
 
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
 providers: [
    AuthService,
    AuthGuard,
    RoleGuard,
    CustomInterceptor,
    HttpRequestService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }
  ]
})
export class AppRoutingModule {
  constructor() {
    console.log('AppRoutingModule.constructor');
  }
}
