import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { QueryRef, Apollo } from 'apollo-angular';
import { FileUploadService } from '../../../service/file-upload.service';
import { pointOfInterestImageGetAll, newsImageAssociationCreate, imageCreateFromUpload, imageCreateUploadLink, pointOfInterestCreate, pointOfInterestImageCreate, poiBillingCreate, poiTravelCategoryCreate, travelCategoryGetAll, provinceGetAll, municipalityGetAll, poiOpeningPeriodCreate, pointOfInterestUpdate, getPoiById, municipalityGet, municipalityTranslationGetAll, poiOpeningPeriodUpdate, poiBillingUpdate, pointOfInterestImageDelete, poiTypeGetAll, poiAssignedTypeCreate, poiAssignedTypeDelete, poiFeatureUpdate, featureGetAll, poiFeatureCreate, poiTravelCategoryUpdate, getFirtsTravelCategories, poiOpeningDayCreate, poiTravelCategoryDelete, poiFeatureDelete, regionGetAll, superAdminLoggedInData, poiOpeningPeriodCreateMany, poiOpeningDayUpdate, poiOpeningPeriodUpdateMany, poiOpeningPeriodDelete, poiOpeningDayDelete, poiAssignedTypeGetAll, travelTagGetAll,journeyAdminSelectedTravelTagCreate,journeyAdminSelectedDelete, poiTravelTagCreate, poiTravelTagDelete, pointOfInterestDelete, pointOfInterestAdminSelectedGet, poiOpeningPeriodGet, poiOpeningPeriodGetAllSub, poiOpeningPeriodDeleteMany, supplierCRUD } from '../../../queryMutation';
import { HttpEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpRequestService } from '../../../service/http-request.service';
import { DomSanitizer } from '@angular/platform-browser';
import sortBy from 'lodash/sortBy';
import { concatMap } from 'rxjs/operators';
import { forkJoin, concat, interval } from 'rxjs';
import { ImageUploadType, IBody, ResizeResponse } from '../../../service/Image';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment.prod';
import { SectodatePipe } from '../../../service/sectodate.pipe';
import { bool } from 'aws-sdk/clients/signer';
import { time } from 'console';


var myReader: FileReader = new FileReader();
@Component({
  selector: "ngx-point-of-interest-update",
  templateUrl: "./point-of-interest-update.component.html",
  styleUrls: ["./point-of-interest-update.component.scss"],
})
export class PointOfInterestUpdateComponent implements OnInit {
  travelTagGetAll: any[] = [];
  public _reload: Boolean = true;
  public selectedTravelTag: any;
  public querySubscriptionJourneyCreate: Subscription;
  public queryGetTravelTag: any;
  public travelTagPagination: QueryRef<any>;
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllPoiType: Subscription;
  public queryGetIsSuperAdminLoggedIn: Subscription;
  public queryGetAllPoiImage: any;
  public queryGetNewsCategories: any;
  public queryGetProvince: any;
  public selectedStatus: any;
  showCoverImagefromServer: boolean = false;
  previewImage: any;
  DataInizio: any[];
  DataFine = new Date();
  ImageSource: any;
  lastId = null;

  daysOfWeekGetAll: any;
  imageUpload: boolean = false;
  showPubblicaBtn: boolean = true;
  showOpeningDayBtn: boolean = false;
  public periodi_original: any;
  public dayOfWeeks: any[];
  public filesToUpload: any[];
  public translationName: any;
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionAllFeature: Subscription;
  public queryGetRegion: Subscription;
  public querySubscription: Subscription;

  public queryGetMunicipalityById: any;
  public queryGetPoiType: any;
  public queryGetPoiFeature: any;
  public queryGetAllNewsImageAssociation: any;
  public MunicipalityPagination: QueryRef<any>;
  public PoiImagePagination: QueryRef<any>;
  public businessPaginationPoiType: QueryRef<any>;
  public pointOfInterestImageGetAll: any[];
  public ImageAssociationGetAll: any[];
  public newsCategoryPagination: QueryRef<any>;
  public businessPagination: QueryRef<any>;
  public businessPaginationFeature: QueryRef<any>;
  public imagesToShow: any[];
  public regionGetAll: any[];
  public municipalityGetAll: any[];
  public modelToMapping: any;
  public travelTagList: any[];
  public provinceGetAll: any[];
  public selectedProvince: any;
  public travelCategoryGetAll: any[];
  public selectedTravelCategory: any;
  public selectedMunicipality: any;
  public selectedImageAssociation: any;
  public selectedFeatureId: any;
  public selectedRegion: any;
  public PoiImageAssociationGetAll: any[];
  public poiTypeGetAll: any[];
  public periodsOfPoi: any[];
  public setNewOpenindDayObj: any[];

  public featureGetAll: any[];
  public poiTypeSelected: any;

  public estimatedReopeningDate: any;
  showProvinceList: boolean = false;
  showOrariWhenIsLoaded: boolean = false;
  public startingTime: Date;
  public endingTime: Date;
  public todayDate: Date;
  public showSpecialHours: boolean = false;
  public showIsClosedPeriod: boolean = false;
  public showIsDaysWeeks: boolean = false;
  public showSetHoursOfWeek: boolean = false;

  public setSpecialHoursObj: any;

  public setOrarioOfWeek: any;

  public setGiornoSpeciale: any;
  public setClosedPeriodObj: any;

  public openingPeriodId: any;

  public startingTimeSpecialHour: Date;
  public endingTimeSpecialHour: Date;
  selectedStatusId: any;

  notes: string;

  isCreatedPeriod: boolean = false;

  closedPeriodSpecialHour: boolean = false;
  showAfterCreatePeriod: boolean = false;

  public statusGetAll: any[];
  public alwaysOpenDays: any[];
  public validityGetAll: any[];
  public supplierGetAll: any[];

  public periodi: any[];

  imageData = {
    image: "",
    resize: [
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
      {
        width: 0,
        height: 0,
        fit: "",
      },
    ],
    moveryEnv: "",
  };

  public selectedValidity: any;

  public imageToUpload: ImageUploadType;

  public url: any;
  public superAdminId: number;

  model = {
    address: "",
    latitude: "",
    estimatedReopeningDate: "",
    longitude: "",
    estimatedVisitLength: "",
    workInProgress: false,
    importance: "",
    openingPeriodValidated: false,
    municipalityId: "",
    starsAdmin: "",
    nearbyTypesFifteenKilometers: [],
    nearbyTypesFiveKilometers: [],
    nearbyTypesTenKilometers: [],
    prezzo: "",
    onlineUrl: "",
    features: [],
    poiOpeningPeriodGetAllSub: { startingDateTime: Date, endingDateTime: Date },
    municipalityCode: "",
    supplierId: null,
  };
  configProvince = {
    limitTo: 0,
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Found province ", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configTravelCategory = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Found category", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configDaysOfWeek = {
    displayKey: "value", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find days of week", // label thats displayed in search input,
    searchOnKey: "value", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configMunicipality = {
    limitTo: 0,
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Found municipality", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configImageAssociation = {
    displayKey: "fileName", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Found image", // label thats displayed in search input,
    searchOnKey: "fileName", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configPoiFeature = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find province", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configPoiStatus = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Status ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configValidityStatus = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find Status ", // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  configRegion = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Find province", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  configPoiType = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "auto", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Nothing selected", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "Not  results found", // text to be displayed when no items are found while searching
    searchPlaceholder: "Found PoiType", // label thats displayed in search input,
    searchOnKey: "code", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };

  limit: any = 25;
  fileToUpload: FileList;
  response: any;

  constructor(
    public apollo: Apollo,
    public fileUp: FileUploadService,
    public _location: Location,
    public router: Router,
    public requestService: HttpRequestService,
    public _sanitizer: DomSanitizer,
    public spinner: NgxSpinnerService
  ) {
    this.queryGetTravelTag = new travelTagGetAll({}, this.limit).getQuery();
    this.queryGetPoiFeature = new featureGetAll({}, this.limit).getQuery();
    this.queryGetMunicipalityById = new municipalityGetAll(
      {},
      this.limit
    ).getQuery();
    this.queryGetProvince = new provinceGetAll({}, this.limit).getQuery();
    this.queryGetNewsCategories = new travelCategoryGetAll(
      {},
      this.limit
    ).getQuery();
    this.queryGetAllPoiImage = new pointOfInterestImageGetAll(
      {},
      this.limit
    ).getQuery();
    this.queryGetPoiType = new poiTypeGetAll({}, this.limit).getQuery();
    this.queryGetRegion = new regionGetAll({}, this.limit).getQuery();
    this.provinceGetAll = [];
    this.regionGetAll = [];
    this.DataInizio = [];
    this.pointOfInterestImageGetAll = [];
    this.imagesToShow = [];
    this.travelCategoryGetAll = [];
    this.selectedTravelCategory = [];
    this.selectedMunicipality = [];
    this.selectedFeatureId = [];
    this.municipalityGetAll = [];
    this.provinceGetAll = [];
    this.PoiImageAssociationGetAll = [];
    this.poiTypeGetAll = [];
    this.filesToUpload = [];
    this.dayOfWeeks = [];
    this.featureGetAll = [];
    this.selectedValidity = [];
    this.statusGetAll = [];
    this.validityGetAll = [];
    this.setSpecialHoursObj = [];
    this.travelTagList = [];
    this.imageToUpload = {
      fileName: "",
      size: 0,
      mimeType: "",
      belongsToId: 0,
      uploadedByBaseUserId: 0,
      role: "",
      belongsTo: "",
      tags: [],
    };

    this.periodsOfPoi = [
      {
        derogatedOpeningPeriodId: null,
        endingDateTime: "",
        id: "",
        isClosed: false,
        derogatingOpeningPeriodsGetAll: [
          {
            backOfficeData: "",
            createdAt: "",
            derogatedOpeningPeriodId: "",
            endingDateTime: "",
            id: "",
            isClosed: "",
            poiOpeningDayGetAllSub: [
              {
                daysOfWeek: [],
                endingTime: "",
                id: "",
                notes: null,
                poiOpeningPeriodId: "",
                startingTime: "",
              },
            ],
            startingDateTime: "",
            updatedAt: "",
          },
        ],
        poiOpeningDayGetAllSub: [
          {
            daysOfWeek: [],
            endingTime: "",
            id: "",
            notes: null,
            poiOpeningPeriodId: "",
            startingTime: "",
          },
        ],
        DataInizio: [],
        startingDateTime: "",
      },
    ];

    this.setNewOpenindDayObj = [
      {
        startingTime: "",
        endingTime: "",
        notes: "",
        selectedDaysOfWeek: [],
        isCreatedPeriod: false,
        showAfterConfirm: false,
        isOrarioExtraShow: false,
        showIsActiveAfterCreateDay: true,
        showImpostaValiditaBtn: true,
        title: "Orario di apertura di base",
      },
    ];
  }
  //TODO
  async getStatus() {
    this.selectedStatus = [];
    this.statusGetAll = this.requestService.getStatus();
    this.statusGetAll = this.statusGetAll.map((item) => {
      return item;
    });
    //this.selectedStatus.push(this.statusGetAll[0]);
  }
  async ngOnInit() {
    ////alert("ngOnInit")
    console.log(JSON.parse(localStorage.getItem("openingPeriodDate")));

    if (localStorage.getItem("openingPeriodDate")) {
      this.DataInizio = JSON.parse(localStorage.getItem("openingPeriodDate"));
    }

    await this.getStatus();
    await this.getValidity();
    await this.getDayOfWeeks();
    await this.getPoibyId();
    await this.getAllRegion();
    await this.getIsAdminLoggedIn();
    await this.getAllSupplier();

    await this.PoiTypeGetAll()

  }

  async PoiTypeGetAll(cursor = null) {
    this.apollo.query<Response>({
      query: new poiTypeGetAll({}, this.limit, cursor).getQuery(),
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    }).subscribe((result) => {
      var list = result.data['poiTypeGetAll']
      console.log("poiTypeGetAll", "cursor " + cursor, list)

      this.poiTypeGetAll.push(...list)


      if (list.length > 0) {
        this.PoiTypeGetAll(list[list.length-1].id)
      } else {
        console.log("poiTypeGetAll", "end", "size: " + this.poiTypeGetAll.length)
        this.poiTypeGetAll = this.poiTypeGetAll.map((item) => item);
        this.selectPoiType()
      }

    });
  }

  async ngAfterViewInit() {
    //await this.poiTypeGet();
  }

  async getAllProvincebyId() {

    this.provinceGetAll = []
    this.municipalityGetAll = []
    this.selectedProvince = null
    this.selectedMunicipality = null
    
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetProvince,
      fetchPolicy: "no-cache",
      variables: {
        data: {
          parentId: this.selectedRegion.id,
        },
      },
    });

    this.querySubscription = this.businessPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.provinceGetAll.length > 0
            ? data.data["provinceGetAll"][
                data.data["provinceGetAll"].length - 1
              ]["id"]
            : null;
        this.queryGetProvince = new provinceGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPagination.fetchMore({
          query: this.queryGetProvince,
          variables: {
            data: {
              parentId: this.selectedRegion.id,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["provinceGetAll"].length < 1) {
              this.provinceGetAll.push(...prev["provinceGetAll"]);

              this.provinceGetAll = sortBy(this.provinceGetAll, "code");
              this.configProvince.limitTo = this.provinceGetAll.length
              
              this.provinceGetAll = this.provinceGetAll.map((item) => {
                return item;
              });

              console.log(this.provinceGetAll.sort());
              return prev;
            } else {
              return Object.assign({}, prev, {
                provinceGetAll: [
                  ...prev["provinceGetAll"],
                  ...fetchMoreResult["provinceGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }
  async getAllTravelTag() {
    this.travelTagPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetTravelTag,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionJourneyCreate = this.travelTagPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data !== undefined && 
          data.data.travelTagGetAll !== undefined && 
          data.data.travelTagGetAll.length > 0
            ? data.data["travelTagGetAll"][
                data.data["travelTagGetAll"].length - 1
              ]["id"]
            : null;
        console.log(`lastId ${lastId}`);
        this.queryGetTravelTag = new travelTagGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.travelTagPagination.fetchMore({
          query: this.queryGetTravelTag,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["travelTagGetAll"].length < 1) {
              if (this.travelTagGetAll === undefined) 
                this.travelTagGetAll = []
              if (prev !== undefined)
                this.travelTagGetAll.push(...prev["travelTagGetAll"]);

              this.travelTagGetAll.sort(function(a, b) {
                return a.code.localeCompare(b.code)
              });
              
              this.travelTagGetAll = this.travelTagGetAll.map((item) => {
                return item;
              });

              this.selectedTravelTagAdmin();
              return prev;
            } else {
              return Object.assign({}, prev, {
                travelTagGetAll: [
                  ...prev["travelTagGetAll"],
                  ...fetchMoreResult["travelTagGetAll"],
                ],
              });
            }
          },
        });
        this.selectedTravelTagAdmin();
      }
    );
  }
  selectedTravelTagAdmin() {
    /*
    console.log('selectedTravelTagAdmin')
    console.log(`this.travelTagGetAll.length ${this.travelTagGetAll.length}`)
    console.log(`this.travelTagList.length ${this.travelTagList.length}`)
    */
    this.selectedTravelTag = [];

    for (var i=0; i<this.travelTagList.length; i++) {
      var singolo = this.travelTagList[i]
      for (let j=0; j < this.travelTagGetAll.length; j++) {
        var totale = this.travelTagGetAll[j]
        /*console.log('confronto')
        console.log(singolo)
        console.log(singolo.travelTagGetSub)
        console.log(singolo.travelTagGetSub.id)
        console.log(totale.id)*/
        if (singolo.travelTagGetSub.id == totale.id) {
          this.selectedTravelTag.push(totale)
          continue;
        }
      }
    }

    this.selectedTravelTag = this.removeDuplicates(this.selectedTravelTag, 'id')

     /*for (var i=0; i<this.travelTagList.length; i++)
      this.selectedTravelTag.push(this.travelTagList[i])
   
    
    for (let i = 0; i < this.travelTagGetAll.length; i++) {
      //console.log(`analizzo travelTagGetAll[${i}] ${this.travelTagGetAll[i]}`)
      for (let index = 0; index < this.travelTagList.length; index++) {
        //console.log(`analizzo travelTagList[${index}] ${this.travelTagList[index]}`)
        if (this.travelTagGetAll[i] !== undefined) {
          if (
            this.travelTagGetAll[i].travelTagId ==
            this.travelTagList[index].travelTagId
          ) {
            console.log(this.travelTagGetAll[i]);
            this.selectedTravelTag.push(this.travelTagGetAll[i]);
          }
        }
      }
    }*/
  }
  createTravelTagAssociation(event): void {
    console.log("createTravelTagAssociation", event.value, this.travelTagList);
    if (event.value.length > this.travelTagList.length) {
      let last: any = event.value[event.value.length - 1];
      console.log(last.id);
      this.querySubscriptionJourneyCreate = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiTravelTagCreate.poiTravelTagCreate,
          variables: {
            data: {
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              travelTagId: last.id,
            },
          },
        })
        .subscribe((result: any) => {
          if (result.data["poiTravelTagCreate"]["success"]) {
            var nuovo = {
              'id': result.data.poiTravelTagCreate.id,
              'travelTagGetSub': this.selectedTravelTag[this.selectedTravelTag.length - 1]
            }
            
            this.travelTagList.push(nuovo)
            //this.travelTagList.push(result.data.poiTravelTagCreate)
            console.log("this.travelTagList", this.travelTagList, this.selectedTravelTag)
          }
        });
    } else {
      for (var i=0; i<this.travelTagList.length; i++) {
        var daMantenere = false
        for (var j=0; j<event.value.length; j++) {
          console.log(`cerco`, this.travelTagList[i].travelTagGetSub, event.value[j])
          if (this.travelTagList[i].travelTagGetSub.id === event.value[j].id)
          daMantenere = true
        }
        if (!daMantenere) {
          console.log(`devo cancellare ${this.travelTagList[i].id}`)
          this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiTravelTagDelete.poiTravelTagDelete,
                variables: {
                  id: this.travelTagList[i].id,
                },
              })
              .subscribe((result: any) => {
                console.log(`cancellato indice:${i} id:${result.data}`, result, result.data.poiTravelTagDelete.id )
                var id = result.data.poiTravelTagDelete.id
                for (var i=0; i<this.travelTagList.length; i++) {
                  console.log(`verifico this.travelTagList[i].id(${this.travelTagList[i].id} con id(${id})`)
                  if (this.travelTagList[i].id == id) {
                    console.log(`devo cancellare ${id}`)
                    this.travelTagList.splice(i, 1)
                    return;
                  }
                    
                }
                if (result.data["poiTravelTagDelete"]["success"]) {
                  console.log("prima di splice", this.travelTagList);
                  this.travelTagList.splice(i);
                  console.log("dopo di splice", this.travelTagList);
                }
              }), (err) => {
                console.log('err');
                console.log(err);
              }
        }
      }
    }
    /*
    if (event.value.length < this.modelToMapping.length) {
      console.log("model to map", this.modelToMapping)
      if (event.value.length > 0) {
        let temp = event.value.map((item) => {
          return item.id;
        });
        for (let index = 0; index < this.modelToMapping.length; index++ ) {
          if (temp.indexOf(this.modelToMapping[index].id) == -1) {
            this.querySubscriptionJourneyCreate = this.apollo
              .use("super-admin")
              .mutate({
                mutation: pointOfInterestDelete.pointOfInterestDelete,
                variables: {
                  id: this.modelToMapping.poiTravelTagGetAllSub[index].id,
                },
              })
              .subscribe((result: any) => {
                if (result.data["pointOfInterestDelete"]["success"]) {
                }
              });
          }
        }
      } else {
        this.querySubscriptionJourneyCreate = this.apollo
          .use("super-admin")
          .mutate({
            mutation: pointOfInterestDelete.pointOfInterestDelete,
            variables: {
              id: this.selectedTravelTag.poiTravelTagGetAllSub[0].id,
            },
          })
          .subscribe((result: any) => {
            if (result.data["pointOfInterestDelete"]["success"]) {
            }
          });
      }
    }*/
  }

  async getAllRegion() {
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetRegion,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscription = this.businessPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.regionGetAll.length > 0
            ? data.data["regionGetAll"][data.data["regionGetAll"].length - 1][
                "id"
              ]
            : null;
        this.queryGetRegion = new regionGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPagination.fetchMore({
          query: this.queryGetRegion,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["regionGetAll"].length < 1) {
              this.regionGetAll.push(...prev["regionGetAll"]);

              this.regionGetAll = sortBy(this.regionGetAll, "code");

              this.regionGetAll = this.regionGetAll.map((item) => {
                return item;
              });

              console.log(this.regionGetAll.sort());
              return prev;
            } else {
              return Object.assign({}, prev, {
                regionGetAll: [
                  ...prev["regionGetAll"],
                  ...fetchMoreResult["regionGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
  async getTravelCategories() {
    this.travelCategoryGetAll = [];
    this.querySubscriptionProfileUser = this.apollo
      .watchQuery<Response>({
        query: getFirtsTravelCategories.getFirtsTravelCategories,
        fetchPolicy: "no-cache",
        variables: {
          data: {},
          limit: 25,
          idCursor: null,
        },
      })
      .valueChanges.subscribe((data: any) => {
        this.lastId =
          data.data.travelCategoryGetAll.length > 0
            ? data.data["travelCategoryGetAll"][
                data.data["travelCategoryGetAll"].length - 1
              ]["id"]
            : null;

        this.travelCategoryGetAll.push(...data.data.travelCategoryGetAll);

        this.travelCategoryGetAll.sort(function(a, b) {
          return a.code.localeCompare(b.code)
        });
    
        this.travelCategoryGetAll = this.travelCategoryGetAll.map((item) => {
          return item;
        });

        this.selectTravelCategory();
      });
  }
  async getPoibyId() {
    this.apollo
      .query<Response>({
        query: getPoiById.getPoiById,
        fetchPolicy: "no-cache",
        variables: {
          id: parseInt(localStorage.getItem("poiID")),
        },
      }).subscribe(async(result:any) => {
        ////alert("getPoibyId.valueChanges");
        console.log("model", result.data.pointOfInterestGet)
        this.model = result.data.pointOfInterestGet;
        this.modelToMapping = result.data.pointOfInterestGet;
        
        this.travelTagList = result.data.pointOfInterestGet.poiTravelTagGetAllSub;
        
        var data = result.data.pointOfInterestGet.poiTranslationGetAllSub.filter(
          (item) => item.languageId == 1
        );

        if (data.length > 0) {
          this.translationName = data[0].name;
        } else {
          this.translationName = "Non ce traduzione";
        }

        this.model.prezzo =
          this.modelToMapping.poiBillingGetSub !== null
            ? this.modelToMapping.poiBillingGetSub.price
            : 0;
        this.model.onlineUrl =
          this.modelToMapping.poiBillingGetSub !== null
            ? this.modelToMapping.poiBillingGetSub.onlineUrl
            : "";


        

        this.periodi = []
        this.periodi_original = []
        await this.orariDB2Page(this.modelToMapping.poiOpeningPeriodGetAllSub);
        //await this.orariDB2Page(this.model.poiOpeningPeriodGetAllSub.slice);


            
        await this.getAllTravelTag();
        await this.getMunicipalityByID();
        await this.poiFeatureGetRequest();
        await this.getTravelCategories();
        await this.mappingOrariObject();

        this.selectedStatus = [];
        if (this.modelToMapping.poiOpeningPeriodGetAllSub.length > 0) {
//TODO
          var sempreAperto : boolean = false;
          if (this.modelToMapping.poiOpeningPeriodGetAllSub.length == 1) {
            console.log(`verifico se si tratta di apertura per 20 anni`);
            var inizio = moment(this.model.poiOpeningPeriodGetAllSub[0].startingDateTime)
            var fine = moment(this.model.poiOpeningPeriodGetAllSub[0].endingDateTime)
            console.log('inizio', inizio.year(), inizio.month(), inizio.date())
            console.log('fine', fine.year(), fine.month(), fine.date())
            if (inizio.date() === 1 && inizio.month() === 0 && 
              fine.date() === 1 && fine.month() === 0 && 
              fine.year() - inizio.year() === 21
            ) {
              console.log(`SEMPRE APERTO`);
              this.selectedStatusId = 2
              this.selectedStatus.push(this.statusGetAll[1]);
              sempreAperto = true;
            } else {
              console.log(`NON SEMPRE APERTO`);
            }
              
          }//QUI
          if (!sempreAperto) {
            console.log("IMPOSTA PERIODI")
            this.selectedStatusId = 3
            this.selectedStatus.push(this.statusGetAll[2]);

            console.log('poiOpeningPeriodGetAllSub', this.model, this.model.poiOpeningPeriodGetAllSub)
          }
            this.DataInizio = [
              this.model.poiOpeningPeriodGetAllSub[0].startingDateTime,
              this.model.poiOpeningPeriodGetAllSub[0].endingDateTime,
            ];
        } else {
          console.log(`SEMPRE CHIUSO`);
          this.selectedStatusId = 1
          this.selectedStatus.push(this.statusGetAll[0]);
        }
      });
  }
  async getValidity() {
    console.log('statusChange.getValidity')
    this.selectedValidity = [];
    this.validityGetAll = [];

    this.validityGetAll = this.requestService.getValidita();
    this.validityGetAll = this.validityGetAll.map((item) => {
      return item;
    });
    this.selectedValidity.push(this.validityGetAll[0]);
  }

  //TODO
  async mappingOrariObject() {
    //alert("mappingOrariObject")
    const filterPipe = new SectodatePipe();

    this.setSpecialHoursObj = [];

    var data = this.modelToMapping.poiOpeningPeriodGetAllSub.filter(
      (item) => item.id !== null
    );
    console.log(this.periodsOfPoi, data);
    if (data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        //this.statusChange(data[index].backOfficeStatus);
        data[index].DataInizio = [
          new Date(
            moment(data[index].startingDateTime, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          ),
          new Date(
            moment(data[index].endingDateTime, "YYYY-MM-DD HH:mm:ss").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          ),
        ];
        if (data[index].poiOpeningDayGetAllSub.length == 0) {
          //data[index].poiOpeningDayGetAllSub.push();
          this.poiCreateNewOpeningPeriod(data[index], 0);
          
        } else {
          for (let poiOpeningDayGetAllSub of data[index].poiOpeningDayGetAllSub) {
            console.log("start.!isNaN", Number.isInteger(poiOpeningDayGetAllSub.startingTime), !isNaN(poiOpeningDayGetAllSub.startingTime), poiOpeningDayGetAllSub.startingTime)

            if (poiOpeningDayGetAllSub.daysOfWeek !== undefined) {
              if (poiOpeningDayGetAllSub.daysOfWeek.length > 0) {
                poiOpeningDayGetAllSub.showAfterConfirm = true;
                console.log("Seleziono i giorni della settimana -> ", poiOpeningDayGetAllSub.id, poiOpeningDayGetAllSub.daysOfWeek)
                poiOpeningDayGetAllSub.selectedDaysOfWeek = []
                poiOpeningDayGetAllSub.selectedDaysOfWeek = poiOpeningDayGetAllSub.daysOfWeek;
              }
            }
            console.log("qui", poiOpeningDayGetAllSub, poiOpeningDayGetAllSub.daysOfWeek, poiOpeningDayGetAllSub.showAfterConfirm)

            if (!isNaN(poiOpeningDayGetAllSub.startingTime) && Number.isInteger(poiOpeningDayGetAllSub.startingTime)) {
              console.log("start", poiOpeningDayGetAllSub)
              poiOpeningDayGetAllSub.formatStartingTime = filterPipe.transform(poiOpeningDayGetAllSub.startingTime);
              poiOpeningDayGetAllSub.formatEndingTime = filterPipe.transform(poiOpeningDayGetAllSub.endingTime)
              var start_h = Math.floor(poiOpeningDayGetAllSub.startingTime / 3600);
              var start_m =  Math.floor((poiOpeningDayGetAllSub.startingTime - (start_h * 3600)) / 60);
              var start = new Date(2020, 1, 1, start_h, start_m)
              console.log("start", start, start_h, start_m, poiOpeningDayGetAllSub.startingTime);
              poiOpeningDayGetAllSub.startingTime = start;
              start_h = Math.floor(poiOpeningDayGetAllSub.endingTime / 3600);
              start_m =  Math.floor((poiOpeningDayGetAllSub.endingTime - (start_h * 3600)) / 60);
              start = new Date(2020, 1, 1, start_h, start_m)
              console.log("start", start, start_h, start_m, poiOpeningDayGetAllSub.endingTime);
              poiOpeningDayGetAllSub.endingTime = start;
              data[index].days = poiOpeningDayGetAllSub.daysOfWeek;
            }
          }
          console.log('data[index].days', data[index].days)
        }
        for (let derogatingOpeningPeriodsGetAll of data[index].derogatingOpeningPeriodsGetAll) {
          derogatingOpeningPeriodsGetAll.poiDerogatedPeriodData = [
            new Date(
              moment(derogatingOpeningPeriodsGetAll.startingDateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
            ),
            new Date(
              moment(derogatingOpeningPeriodsGetAll.endingDateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
            ),
          ];
          derogatingOpeningPeriodsGetAll.backOfficeData = JSON.parse(derogatingOpeningPeriodsGetAll.backOfficeData);
          derogatingOpeningPeriodsGetAll.backOfficeStatus = JSON.parse(derogatingOpeningPeriodsGetAll.backOfficeStatus);

          if (derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub.length > 0) {
            derogatingOpeningPeriodsGetAll.daysOfWeek = derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub[0].daysOfWeek;
            derogatingOpeningPeriodsGetAll.formatStartingTime = filterPipe.transform(derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub[0].startingTime);
            derogatingOpeningPeriodsGetAll.formatEndingTime = filterPipe.transform(derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub[0].endingTime);
            derogatingOpeningPeriodsGetAll.notes = derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub[0].notes;
            derogatingOpeningPeriodsGetAll.openingDayId = derogatingOpeningPeriodsGetAll.poiOpeningDayGetAllSub[0].id;
          }
        }
      }
      this.showOrariWhenIsLoaded = true;
      this.periodsOfPoi = data;
      console.log('mappingOrariObject.periodsOfPoi', this.periodsOfPoi);
    } else {
      this.showOrariWhenIsLoaded = true;
    }
  }

  selectMunicipality() {
    this.selectedMunicipality = [];

    this.selectedMunicipality = this.municipalityGetAll.filter(
      (item) => this.modelToMapping.municipalityId === item.id
    );
  }
  async getAllProvince() {
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetProvince,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllProvince = this.businessPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.provinceGetAll.length > 0
            ? data.data["provinceGetAll"][
                data.data["provinceGetAll"].length - 1
              ]["id"]
            : null;
        this.queryGetProvince = new provinceGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPagination.fetchMore({
          query: this.queryGetProvince,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["provinceGetAll"].length < 1) {
              this.provinceGetAll.push(...prev["provinceGetAll"]);

              this.provinceGetAll = sortBy(this.provinceGetAll, "code");

              this.provinceGetAll = this.provinceGetAll.map((item) => {
                return item;
              });
              this.selectProvince();

              return prev;
            } else {
              return Object.assign({}, prev, {
                provinceGetAll: [
                  ...prev["provinceGetAll"],
                  ...fetchMoreResult["provinceGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  showProvince() {
    this.showProvinceList = true;
  }
  async getMunicipalityByID() {
    this.querySubscriptionProfileUser = this.apollo
      .watchQuery<Response>({
        query: municipalityGet.municipalityGet,
        fetchPolicy: "no-cache",
        variables: {
          id: parseInt(this.model.municipalityId),
        },
      })
      .valueChanges.subscribe((result: any) => {
        console.log(result.data.municipalityGet);
        this.model.municipalityCode = result.data.municipalityGet.code;
        //this.getAllProvince();
      });
  }
  async getMunicipalityByParentID() {
    this.MunicipalityPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetMunicipalityById,
      fetchPolicy: "no-cache",
      variables: {
        data: {
          parentId: this.selectedProvince.id,
        },
      },
    });

    this.querySubscriptionAllProvince = this.MunicipalityPagination.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.municipalityGetAll.length > 0
            ? data.data["municipalityGetAll"][
                data.data["municipalityGetAll"].length - 1
              ]["id"]
            : null;
        this.queryGetMunicipalityById = new municipalityGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.MunicipalityPagination.fetchMore({
          query: this.queryGetMunicipalityById,
          variables: {
            data: {
              parentId: this.selectedProvince.id,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["municipalityGetAll"].length < 1) {
              this.municipalityGetAll.push(...prev["municipalityGetAll"]);

              this.municipalityGetAll = sortBy(this.municipalityGetAll, "code");
              
              this.configMunicipality.limitTo = this.municipalityGetAll.length
              this.municipalityGetAll = this.municipalityGetAll.map((item) => {
                return item;
              });
              console.log(this.municipalityGetAll);
              return prev;
            } else {
              return Object.assign({}, prev, {
                municipalityGetAll: [
                  ...prev["municipalityGetAll"],
                  ...fetchMoreResult["municipalityGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }
  validityChange(event, item, days): void {
    console.log(days);
    switch (parseInt(event.value.id)) {
      case 1:
        break;

      case 2:
        this.showSpecialHours = false;
        this.showIsClosedPeriod = true;
        this.showIsDaysWeeks = false;
        this.showSetHoursOfWeek = false;

        var FilterData: any[] = item.derogatingOpeningPeriodsGetAll.filter(
          (item) => item.backOfficeData == 2
        );
        this.setClosedPeriodObj = this.removeDuplicates(FilterData, "id");

        if (this.setClosedPeriodObj.length > 0) {
          for (let index = 0; index < this.setClosedPeriodObj.length; index++) {
            console.log(
              moment(
                this.setClosedPeriodObj[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X")
            );
            console.log(
              moment(
                this.setClosedPeriodObj[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") <=
                moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
            );

            if (
              (moment(
                this.setClosedPeriodObj[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setClosedPeriodObj[index].poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "X"
                  )) ||
              (moment(
                this.setClosedPeriodObj[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setClosedPeriodObj[index].poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X"))
            ) {
              this.setClosedPeriodObj[index].isDeleteItem = false;
            } else {
              this.setClosedPeriodObj[index].isDeleteItem = true;
              alert("derogha non fanno parte dil periodo, devi cancellarle");
            }
          }
        }

        if (this.setClosedPeriodObj.length === 0)
          this.setClosedPeriodObj = [
            {
              poiDerogatedPeriodData: [],
              isClosed: false,
              derogatedOpeningPeriodId: item.id,
              isDeleteItem: false,
            },
          ];

        break;
      case 3:
        this.showSpecialHours = false;
        this.showIsClosedPeriod = false;
        this.showIsDaysWeeks = true;
        this.showSetHoursOfWeek = false;

        var FilterData: any[] = item.derogatingOpeningPeriodsGetAll.filter(
          (item) => item.backOfficeData == 3
        );
        this.setOrarioOfWeek = this.removeDuplicates(FilterData, "id");

        if (this.setOrarioOfWeek.length > 0) {
          for (let index = 0; index < this.setOrarioOfWeek.length; index++) {
            console.log(
              moment(
                this.setOrarioOfWeek[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X")
            );
            console.log(
              moment(
                this.setOrarioOfWeek[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") <=
                moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
            );

            if (
              (moment(
                this.setOrarioOfWeek[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setOrarioOfWeek[index].poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "X"
                  )) ||
              (moment(
                this.setOrarioOfWeek[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setOrarioOfWeek[index].poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X"))
            ) {
              this.setOrarioOfWeek[index].isDeleteItem = false;
            } else {
              this.setOrarioOfWeek[index].isDeleteItem = true;
              alert("derogha non fanno parte dil periodo, devi cancellarle");
            }
          }
        }

        if (this.setOrarioOfWeek.length === 0)
          this.setOrarioOfWeek = [
            {
              notes: "",
              formatStartingTime: "",
              formatEndingTime: "",
              poiDerogatedPeriodData: [],
              isClosed: false,
              daysOfWeek: days.daysOfWeek,
              derogatedOpeningPeriodId: item.id,
              deleteItem: false,
            },
          ];

        break;
      case 4:
        this.showSpecialHours = false;
        this.showIsClosedPeriod = false;
        this.showIsDaysWeeks = false;
        this.showSetHoursOfWeek = true;

        var FilterData: any[] = item.derogatingOpeningPeriodsGetAll.filter(
          (item) => item.backOfficeData == 4
        );
        this.setGiornoSpeciale = this.removeDuplicates(FilterData, "id");

        if (this.setGiornoSpeciale.length > 0) {
          for (let index = 0; index < this.setGiornoSpeciale.length; index++) {
            console.log(
              moment(
                this.setGiornoSpeciale[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X")
            );
            console.log(
              moment(
                this.setGiornoSpeciale[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") <=
                moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
            );

            if (
              (moment(
                this.setGiornoSpeciale[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setGiornoSpeciale[index].poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "X"
                  )) ||
              (moment(
                this.setGiornoSpeciale[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setGiornoSpeciale[index].poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X"))
            ) {
              this.setGiornoSpeciale[index].isDeleteItem = false;
            } else {
              this.setGiornoSpeciale[index].isDeleteItem = true;
              alert("derogha non fanno parte dil periodo, devi cancellarle");
            }
          }
        }

        if (this.setGiornoSpeciale.length === 0)
          this.setGiornoSpeciale = [
            {
              poiDerogatedPeriodData: [],
              isClosed: false,
              derogatedOpeningPeriodId: item.id,
              isDeleteItem: false,
            },
          ];

        break;
      case 5:
        this.showSpecialHours = true;
        this.showIsClosedPeriod = false;
        this.showIsDaysWeeks = false;
        this.showSetHoursOfWeek = false;

        var FilterData: any[] = item.derogatingOpeningPeriodsGetAll.filter(
          (item) => item.backOfficeData == 5
        );
        this.setSpecialHoursObj = this.removeDuplicates(FilterData, "id");

        if (this.setSpecialHoursObj.length > 0) {
          for (let index = 0; index < this.setSpecialHoursObj.length; index++) {
            console.log(
              moment(
                this.setSpecialHoursObj[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X")
            );
            console.log(
              moment(
                this.setSpecialHoursObj[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") <=
                moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
            );

            if (
              (moment(
                this.setSpecialHoursObj[index].poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setSpecialHoursObj[index].poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "X"
                  )) ||
              (moment(
                this.setSpecialHoursObj[index].poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") >=
                moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
                moment(
                  this.setSpecialHoursObj[index].poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") <=
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X"))
            ) {
              this.setSpecialHoursObj[index].isDeleteItem = false;
            } else {
              this.setSpecialHoursObj[index].isDeleteItem = true;
              alert("derogha non fanno parte dil periodo, devi cancellarle");
            }
          }
        }

        if (this.setSpecialHoursObj.length === 0)
          this.setSpecialHoursObj = [
            {
              notes: "",
              formatStartingTime: "",
              formatEndingTime: "",
              poiDerogatedPeriodData: [],
              isClosed: false,
              daysOfWeek: [],
              derogatedOpeningPeriodId: item.id,
              showAfterCreateOrarioGiorno: false,
              isDeleteItem: false,
            },
          ];

        break;
    }
  }
  async createPoi() {
    if (parseInt(this.selectedStatusId) === 3 && !this.controntaPeriodi()) {
      alert("Devi prima salvare i periodi")
      return;
    }
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    var data = {
      id: parseInt(localStorage.getItem("poiID")),
      address: this.model.address,
      supplierId: this.model.supplierId == "" ? -1 : parseInt(this.model.supplierId),
      estimatedVisitLength: this.model.estimatedVisitLength,
      importance: 10,
      latitude: this.model.latitude,
      longitude: this.model.longitude,
      nearbyTypesFifteenKilometers: this.model.nearbyTypesFifteenKilometers,
      nearbyTypesFiveKilometers: this.model.nearbyTypesFiveKilometers,
      nearbyTypesTenKilometers: this.model.nearbyTypesTenKilometers,
      municipalityId: this.selectedMunicipality.id,
      starsAdmin: 5,
      workInProgress: this.model.workInProgress,
      openingPeriodValidated: this.model.openingPeriodValidated,
      estimatedReopeningDate: this.model.estimatedReopeningDate,
      backOfficeData: JSON.stringify(""),
    }
    console.log("data", data)
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: pointOfInterestUpdate.pointOfInterestUpdate,
        variables: {
          data: data,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["pointOfInterestUpdate"]["success"]) {
            this.poiBillingCreate();
            setTimeout(() => {
              this.spinner.hide();
              //this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        }
      );
  }
  async poiBillingCreate() {
    if (this.modelToMapping.poiBillingGetSub !== null) {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiBillingUpdate.poiBillingUpdate,
          variables: {
            data: {
              id: this.modelToMapping.poiBillingGetSub.id,
              onlineUrl: this.model.onlineUrl,
              price: this.model.prezzo,
              openingPeriodValidated: this.model.openingPeriodValidated,
              features:
                this.model.features == undefined ? "" : this.model.features,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe((result: any) => {
          if (result.data["poiBillingUpdate"]["success"]) {
          }
        });
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiBillingCreate.poiBillingCreate,
          variables: {
            data: {
              onlineUrl: this.model.onlineUrl,
              price: this.model.prezzo,
              openingPeriodValidated: this.model.openingPeriodValidated,
              features:
                this.model.features == undefined ? "" : this.model.features,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiBillingCreate"]["success"]) {
              alert("PoiBilling save sucessfully");
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          }
        );
    }
  }
  poiTravelCategoryUpdate(event): void {
    if (
      event.value.length > this.modelToMapping.poiTravelCategoryGetAllSub.length
    ) {
      let last: any = event.value[event.value.length - 1];
      console.log(last.id);
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiTravelCategoryCreate.poiTravelCategoryCreate,
          fetchPolicy: "no-cache",
          variables: {
            data: {
              travelCategoryId: last.id,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe((result: any) => {
          console.log(result.data);
          if (result.data["poiTravelCategoryCreate"]["success"]) {
            this.modelToMapping.poiTravelCategoryGetAllSub.push({
              id: parseInt(last.id),
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              travelCategoryId: parseInt(last.id),
            });
          }
        });
    }

    if (
      event.value.length < this.modelToMapping.poiTravelCategoryGetAllSub.length
    ) {
      if (event.value.length > 0) {
        let temp = event.value.map((item) => {
          return item.id;
        });
        for (
          let index = 0;
          index < this.modelToMapping.poiTravelCategoryGetAllSub.length;
          index++
        ) {
          if (
            temp.indexOf(
              this.modelToMapping.poiTravelCategoryGetAllSub[index].id
            ) == -1
          ) {
            this.querySubscriptionProfileUser = this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiTravelCategoryDelete.poiTravelCategoryDelete,
                fetchPolicy: "no-cache",
                variables: {
                  id: this.modelToMapping.poiTravelCategoryGetAllSub[index].id,
                },
              })
              .subscribe((result: any) => {
                if (result.data["poiTravelCategoryDelete"]["success"]) {
                  // this.modelToMapping.poiTravelCategoryGetAllSub = [];
                  this.modelToMapping.poiTravelCategoryGetAllSub = this.modelToMapping.poiTravelCategoryGetAllSub.filter(
                    (item) => temp.indexOf(item.travelCategoryId) !== -1
                  );
                  console.log();
                }
              });
          }
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiTravelCategoryDelete.poiTravelCategoryDelete,
            fetchPolicy: "no-cache",
            variables: {
              id: this.modelToMapping.poiTravelCategoryGetAllSub[0].id,
            },
          })
          .subscribe((result: any) => {
            if (result.data["poiTravelCategoryDelete"]["success"]) {
              this.modelToMapping.poiTravelCategoryGetAllSub = [];
            }
          });
      }
    }
  }

  goBack() {
    this._location.back();
  }

  selectTravelCategory() {
    this.selectedTravelCategory = [];

    if (this.modelToMapping.poiTravelCategoryGetAllSub.length > 0) {
      var data = this.travelCategoryGetAll.filter(
        (item) =>
          this.modelToMapping.poiTravelCategoryGetAllSub.findIndex(
            (itemSelected) => itemSelected.travelCategoryGetSub.id === item.id
          ) !== -1
      );

      this.selectedTravelCategory = this.removeDuplicates(data, "id");
    }
  }

  selectedFeature() {
    this.selectedFeatureId = [];
    if (this.modelToMapping.poiFeatureGetAllSub.length > 0) {
      var data: any[] = this.featureGetAll.filter(
        (item) =>
          this.modelToMapping.poiFeatureGetAllSub.findIndex(
            (itemSelected) => itemSelected.featureId === item.id
          ) !== -1
      );
      this.selectedFeatureId = this.removeDuplicates(data, "id");
      console.log("this.selectedFeatureId", this.selectedFeatureId)
    }
  }
  selectProvince() {
    this.selectedProvince = [];
    var data: any[] = this.provinceGetAll.filter(
      (item) => this.modelToMapping.provinceId === item.id
    );
    this.selectedProvince = this.removeDuplicates(data, "id");
  }

  selectPoiType() {
    this.poiTypeSelected = [];
    if(this.modelToMapping['poiAssignedTypeGetAllSub'] !== undefined)
      if (this.modelToMapping.poiAssignedTypeGetAllSub.length > 0) {
        var data: any[] = this.poiTypeGetAll.filter(
          (item) =>
            this.modelToMapping.poiAssignedTypeGetAllSub.findIndex(
              (itemSelected) => itemSelected.poiTypeId === item.id
            ) !== -1
        );
        this.poiTypeSelected = this.removeDuplicates(data, "id");
      }
  }
  async poiTypeGet() {
    this.businessPaginationPoiType = this.apollo.watchQuery<Response>({
      query: this.queryGetPoiType,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllPoiType = this.businessPaginationPoiType.valueChanges.subscribe(
      (data: any) => {
        console.log("DATA", data);
        const listLength = data.data.poiTypeGetAll.length;
        let lastIdFetchMore;
        const lastId =
          listLength > 0
            ? data.data["poiTypeGetAll"][data.data["poiTypeGetAll"].length - 1][
                "id"
              ]
            : null;

        this.queryGetPoiType = new poiTypeGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();

        this.businessPaginationPoiType.fetchMore({
          query: this.queryGetPoiType,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["poiTypeGetAll"].length < 1) {
              this.poiTypeGetAll.push(...prev["poiTypeGetAll"]);

              this.poiTypeGetAll = this.poiTypeGetAll.map((item) => item);

              this.selectPoiType();

              return prev;
            } else {
              return Object.assign({}, prev, {
                poiTypeGetAll: [
                  ...prev["poiTypeGetAll"],
                  ...fetchMoreResult["poiTypeGetAll"],
                ],
              });
            }
          },
        });
      }
    );
  }

  getPoiAsssignedType(event): void {
    if (
      event.value.length > this.modelToMapping.poiAssignedTypeGetAllSub.length
    ) {
      let last: any = event.value[event.value.length - 1];
      console.log(last.id);
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiAssignedTypeCreate.poiAssignedTypeCreate,
          variables: {
            data: {
              poiTypeId: last.id,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe((result: any) => {
          console.log(result.data);
          if (result.data["poiAssignedTypeCreate"]["success"]) {
            this.modelToMapping.poiAssignedTypeGetAllSub.push({
              id: result.data["poiAssignedTypeCreate"]["id"],
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              poiTypeId: parseInt(last.id),
            });
          }
        });
    }
    if (
      event.value.length < this.modelToMapping.poiAssignedTypeGetAllSub.length
    ) {
      if (event.value.length > 0) {
        let temp = event.value.map((item) => {
          return item.id;
        });
        for (
          let index = 0;
          index < this.modelToMapping.poiAssignedTypeGetAllSub.length;
          index++
        ) {
          if (
            temp.indexOf(
              this.modelToMapping.poiAssignedTypeGetAllSub[index].poiTypeId
            ) == -1
          ) {
            this.querySubscriptionProfileUser = this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiAssignedTypeDelete.poiAssignedTypeDelete,
                variables: {
                  id: this.modelToMapping.poiAssignedTypeGetAllSub[index].id,
                },
              })
              .subscribe((result: any) => {
                if (result.data["poiAssignedTypeDelete"]["success"]) {
                  this.modelToMapping.poiAssignedTypeGetAllSub = this.modelToMapping.poiAssignedTypeGetAllSub.filter(
                    (item) => temp.indexOf(item.poiTypeId) !== -1
                  );
                  console.log();
                }
              });
          }
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiAssignedTypeDelete.poiAssignedTypeDelete,
            variables: {
              id: this.modelToMapping.poiAssignedTypeGetAllSub[0].id,
            },
          })
          .subscribe((result: any) => {
            if (result.data["poiAssignedTypeDelete"]["success"]) {
              this.modelToMapping.poiAssignedTypeGetAllSub = [];
            }
          });
      }
    }
  }

  async getMapImageRequest() {
    this.requestService
      .getStaticMapForPoi(this.model.latitude, this.model.longitude)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.url = res;
          this.handleFileInput(res);
        },
        (ex) => {
          console.error(ex);
        }
      );
  }
  handleFileInput(blob) {
    var tasks$ = [];
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    tasks$.push(this.fileUp.fileBlob(blob));

    return Promise.all(tasks$)
      .then((values: any) => {
        var strImage = values[0].replace(/^data:image\/[a-z]+;base64,/, "");

        this.imageData.image = strImage.toString();
        this.imageData.moveryEnv = environment.moveryEnv;

        var img = new Image();
        return new Promise((resolve, reject) => {
          img.onload = function () {
            img.height = img.height;
            img.width = img.width;
            resolve(img);
          };

          img.src = values;
        })
          .then((img: any) => {
            this.imageData.resize[0].fit = "cover";
            this.imageData.resize[0].width = img.width;
            this.imageData.resize[0].height = img.height;

            this.imageData.resize[1].fit = "cover";
            this.imageData.resize[1].width = environment.thumb_low_width;
            this.imageData.resize[1].height = this.fileUp.CalculateHeight(
              environment.thumb_low_width,
              img.width,
              img.height
            );

            this.imageData.resize[2].fit = "cover";
            this.imageData.resize[2].width = environment.thumb_medium_width;
            this.imageData.resize[2].height = this.fileUp.CalculateHeight(
              environment.thumb_medium_width,
              img.width,
              img.height
            );

            this.imageData.resize[3].fit = "cover";
            this.imageData.resize[3].width = environment.thumb_high_width;
            this.imageData.resize[3].height = this.fileUp.CalculateHeight(
              environment.thumb_high_width,
              img.width,
              img.height
            );

            this.executeSecondImagePromise();
          })
          .catch((erro) => {
            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          });
      })
      .catch((erro) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      });
  }

  //this method executed a base64 string and convert to a webP immage to upload to AWS
  executeSecondImagePromise() {
    var tasks$2 = [];
    var tasks$3 = [];
    var tasks$4 = [];
    tasks$2.push(this.requestService.cloudConvertImage(this.imageData));
    return forkJoin(tasks$2).subscribe(
      (datas: IBody<ResizeResponse>[]) => {
        for (let j = 0; j < datas[0].data.results.length; j++) {
          datas[0].data.results[j].jpg =
            "data:image/jpeg;base64," + datas[0].data.results[j].jpg;
          datas[0].data.results[j].webp =
            "data:image/webp;base64," + datas[0].data.results[j].webp;

          tasks$3.push(
            this.fileUp.b64toBlob(datas[0].data.results[j].jpg, "image/jpeg")
          );
          tasks$3.push(
            this.fileUp.b64toBlob(datas[0].data.results[j].webp, "image/webp")
          );
        }
        Promise.all(tasks$3).then((blobValue: any) => {
          for (let i = 0; i < blobValue.length; i++) {
            var fileName = "poi_static_map";
            const timespanValue = parseInt(
              moment().valueOf().toString().slice(6)
            );
            console.log(timespanValue);

            if (blobValue[0].type == "image/jpeg") {
              blobValue[0].name =
                fileName +
                "-" +
                timespanValue +
                "." +
                blobValue[0].type.slice(
                  blobValue[0].type.lastIndexOf("/") + 1,
                  blobValue[0].type.length
                );
              blobValue[0].role = "ORIGINAL";
              blobValue[0].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[1].type == "image/webp") {
              blobValue[1].name =
                fileName +
                "-" +
                timespanValue +
                "." +
                blobValue[1].type.slice(
                  blobValue[1].type.lastIndexOf("/") + 1,
                  blobValue[1].type.length
                );
              blobValue[1].role = "ORIGINAL";
              blobValue[1].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[2].size) {
              blobValue[2].name =
                fileName +
                "-" +
                timespanValue +
                "-l" +
                "." +
                blobValue[2].type.slice(
                  blobValue[2].type.lastIndexOf("/") + 1,
                  blobValue[2].type.length
                );
              blobValue[2].role = "LOW";
              blobValue[2].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[3].size) {
              blobValue[3].name =
                fileName +
                "-" +
                timespanValue +
                "-l" +
                "." +
                blobValue[3].type.slice(
                  blobValue[3].type.lastIndexOf("/") + 1,
                  blobValue[3].type.length
                );
              blobValue[3].role = "LOW";
              blobValue[3].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[4].size) {
              blobValue[4].name =
                fileName +
                "-" +
                timespanValue +
                "-m" +
                "." +
                blobValue[4].type.slice(
                  blobValue[4].type.lastIndexOf("/") + 1,
                  blobValue[4].type.length
                );
              blobValue[4].role = "MEDIUM";
              blobValue[4].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[5].size) {
              blobValue[5].name =
                fileName +
                "-" +
                timespanValue +
                "-m" +
                "." +
                blobValue[5].type.slice(
                  blobValue[5].type.lastIndexOf("/") + 1,
                  blobValue[5].type.length
                );
              blobValue[5].role = "MEDIUM";
              blobValue[5].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[6].size) {
              blobValue[6].name =
                fileName +
                "-" +
                timespanValue +
                "-h" +
                "." +
                blobValue[6].type.slice(
                  blobValue[6].type.lastIndexOf("/") + 1,
                  blobValue[6].type.length
                );
              blobValue[6].role = "HIGH";
              blobValue[6].tags = fileName + "-" + timespanValue;
            }
            if (blobValue[7].size) {
              blobValue[7].name =
                fileName +
                "-" +
                timespanValue +
                "-h" +
                "." +
                blobValue[7].type.slice(
                  blobValue[7].type.lastIndexOf("/") + 1,
                  blobValue[7].type.length
                );
              blobValue[7].role = "HIGH";
              blobValue[7].tags = fileName + "-" + timespanValue;
            }
            console.log(blobValue[i]);
            this.filesToUpload.push(blobValue[i]);
          }
          console.log(this.filesToUpload);

          this.recursiveImageUpload();
        });
      },
      (err) => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      }
    );
  }
  async getIsAdminLoggedIn() {
    this.queryGetIsSuperAdminLoggedIn = this.apollo
      .use("super-admin")
      .watchQuery<Response>({
        query: superAdminLoggedInData.superAdminLoggedInData,
        fetchPolicy: "no-cache",
        variables: {},
      })
      .valueChanges.subscribe((result: any) => {
        this.superAdminId = result.data.superAdminLoggedInData.id;
      });
  }

  async getAllSupplier() {
    var lastId = null
    if (this.supplierGetAll === undefined)
      this.supplierGetAll = []
    else
      lastId = this.supplierGetAll[this.supplierGetAll.length - 1].id
    this.apollo.use("super-admin").query<Response>({
        query: supplierCRUD.supplierGetAll,
        fetchPolicy: "no-cache",
        variables: {
          data: {},
          limit: 25,
          idCursor: lastId
        },
      }).subscribe((result: any) => {
        var supplierGetAll = result.data.supplierGetAll
        console.log("supplierGetAll", result.data.supplierGetAll)
        if (supplierGetAll.length > 0) {
          this.supplierGetAll.push(...supplierGetAll);
          this.getAllSupplier()
        }

      });
    
  }

  recursiveImageUpload() {
    var tasks$ = [];

    for (let index = 0; index < this.filesToUpload.length; index++) {
      this.imageToUpload.fileName = this.filesToUpload[index].name;
      this.imageToUpload.size = this.filesToUpload[index].size;
      this.imageToUpload.mimeType = this.filesToUpload[index].type;
      this.imageToUpload.belongsToId = this.superAdminId;
      this.imageToUpload.role = "POI_IMAGE_MAP";
      this.imageToUpload.tags = [""];
      this.imageToUpload.belongsTo = "SUPER_ADMIN";
      this.imageToUpload.uploadedByBaseUserId = parseInt(
        localStorage.getItem("userLoggedId")
      );

      tasks$.push(
        this.fileUp
          .imageUpload(this.imageToUpload, this.filesToUpload[index])
          .pipe(
            concatMap((first) => {
              console.log(first);
              return this.fileUp.pointOfInterestImageCreate(
                first.data["imageCreateFromUpload"].id,
                parseInt(localStorage.getItem("poiID")),
                0,
                JSON.stringify("")
              );
            })
          )
      );
    }

    return concat(...tasks$).subscribe(
      (y) => {
        this.ImageSource = this._sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(this.url)
        );
        this.imageUpload = true;
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      },
      (err) => {
        //this._compiler.clearCache();
        setTimeout(() => {
          this.spinner.hide();
          this.router.navigateByUrl("/pages/pointOfInterest/list");
        }, 2000);
      }
    );
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
      var first = `${a[property]}`.toLowerCase()
      var second = `${b[property]}`.toLowerCase()
      var result = (first < second) ? -1 : (first > second) ? 1 : 0;
      return result * sortOrder;
    }
  }

  async poiFeatureGetRequest(lastId = null) {
    this.apollo.query<Response>({
      query: new featureGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    }).subscribe((data: any) => {
      console.log("poiFeatureGetRequest", data.data.featureGetAll)
      var featureGetAll = data.data.featureGetAll
      if (featureGetAll.length > 0) {
        this.featureGetAll.push(...featureGetAll)
        this.poiFeatureGetRequest(featureGetAll[featureGetAll.length - 1].id)
      } else {
        this.featureGetAll = this.featureGetAll.map((item) => {
          item['code'] = item['macroFeatureGetSub']['code'] + " - " + item['code']
          return item;
        });
        this.featureGetAll.sort(this.dynamicSort("code"));
        console.log("poiFeatureGetRequest.map", this.featureGetAll)
        this.selectedFeature()
      }
    })
    /*
    this.apollo.watchQuery<Response>({
      query: new featureGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    }).valueChanges.subscribe((data: any) => {
      console.log("poiFeatureGetRequest", data.data.featureGetAll)
      var featureGetAll = data.data.featureGetAll
      if (featureGetAll.length > 0) {
        this.featureGetAll.push(...featureGetAll)
        this.poiFeatureGetRequest(featureGetAll[featureGetAll.length - 1].id)
      } else {
        this.featureGetAll = this.featureGetAll.map((item) => {
          item['code'] = item['macroFeatureGetSub']['code'] + " - " + item['code']
          return item;
        });
        this.featureGetAll.sort(this.dynamicSort("code"));
        console.log("poiFeatureGetRequest.map", this.featureGetAll)
      }
    })
*/
/*
    this.businessPaginationFeature = this.apollo.watchQuery<Response>({
      query: this.queryGetPoiFeature,
      fetchPolicy: "no-cache",
      variables: {
        data: {},
      },
    });

    this.querySubscriptionAllFeature = this.businessPaginationFeature.valueChanges.subscribe(
      (data: any) => {
        const lastId =
          data.data.featureGetAll.length > 0
            ? data.data["featureGetAll"][data.data["featureGetAll"].length - 1][
                "id"
              ]
            : null;
        this.queryGetPoiFeature = new featureGetAll(
          {},
          this.limit,
          lastId
        ).getQuery();
        this.businessPaginationFeature.fetchMore({
          query: this.queryGetPoiFeature,
          variables: {
            data: {},
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult["featureGetAll"].length < 1) {
              this.featureGetAll.push(...prev["featureGetAll"]);

              // var data:any[] = this.removeDuplicates(feature,'id');
              // this.featureGetAll.push(data);

              this.featureGetAll = this.featureGetAll.map((item) => {
                return item;
              });
              this.selectedFeature();
              return prev;
            } else {
              return Object.assign({}, prev, {
                featureGetAll: [
                  ...prev["featureGetAll"],
                  ...fetchMoreResult["featureGetAll"],
                ],
              });
            }
          },
        });
      }
    );
    */
  }
  async poiCreateOpeningPeriod() {
    localStorage.setItem("openingPeriodDate", JSON.stringify(this.DataInizio));
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    var data = this.modelToMapping.poiOpeningPeriodGetAllSub.filter(
      (item) => item.id !== null
    );
    if (data.length > 0) {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
          variables: {
            data: {
              id: data[0].id,
              startingDateTime: this.DataInizio[0],
              endingDateTime: this.DataInizio[1],
              backOfficeData: 0,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodUpdate"]["success"]) {
              setTimeout(() => {
                this.spinner.hide();
                this.showOpeningDayBtn = true;
              }, 2000);
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
            setTimeout(() => {
              this.spinner.hide();
              this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 2000);
          }
        );
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: this.DataInizio[0],
              endingDateTime: this.DataInizio[1],
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              setTimeout(() => {
                this.modelToMapping.poiOpeningPeriodId =
                  result.data["poiOpeningPeriodCreate"]["id"];

                this.endingTime = new Date(
                  moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
                );
                this.startingTime = new Date(
                  moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
                );

                console.log(this.endingTime);
                console.log(this.startingTime);
                this.spinner.hide();
                this.showOpeningDayBtn = true;
              }, 2000);
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
            setTimeout(() => {
              this.spinner.hide();
              this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 2000);
          }
        );
    }
  }
  async getDayOfWeeks() {
    this.daysOfWeekGetAll = this.requestService.getDayOfWeeks();
    this.daysOfWeekGetAll = this.daysOfWeekGetAll.map((item) => {
      return item;
    });
  }

  poiFeatureCreate(event): void {
    if (event.value.length > this.modelToMapping.poiFeatureGetAllSub.length) {
      let last: any = event.value[event.value.length - 1];
      console.log(last.id);
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiFeatureCreate.poiFeatureCreate,
          variables: {
            data: {
              featureId: last.id,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
            },
          },
        })
        .subscribe((result: any) => {
          console.log(result.data);
          if (result.data["poiFeatureCreate"]["success"]) {
            this.modelToMapping.poiFeatureGetAllSub.push({
              id: result.data["poiFeatureCreate"]["id"],
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              featureId: parseInt(last.id),
            });
          }
        });
    }
    if (event.value.length < this.modelToMapping.poiFeatureGetAllSub.length) {
      if (event.value.length > 0) {
        let temp = event.value.map((item) => {
          return item.featureId;
        });
        for (
          let index = 0;
          index < this.modelToMapping.poiFeatureGetAllSub.length;
          index++
        ) {
          if (
            temp.indexOf(
              this.modelToMapping.poiFeatureGetAllSub[index].featureId
            ) == -1
          ) {
            this.querySubscriptionProfileUser = this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiFeatureDelete.poiFeatureDelete,
                variables: {
                  id: this.modelToMapping.poiFeatureGetAllSub[index].id,
                },
              })
              .subscribe((result: any) => {
                if (result.data["poiFeatureDelete"]["success"]) {
                  this.modelToMapping.poiFeatureGetAllSub = this.modelToMapping.poiFeatureGetAllSub.filter(
                    (item) => temp.indexOf(item.featureId) !== -1
                  );
                }
              });
          }
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiFeatureDelete.poiFeatureDelete,
            variables: {
              id: this.modelToMapping.poiFeatureGetAllSub[0].id,
            },
          })
          .subscribe((result: any) => {
            if (result.data["poiFeatureDelete"]["success"]) {
              this.modelToMapping.poiFeatureGetAllSub = [];
            }
          });
      }
    }
  }

  createPrincipalPeriodForAllYears(item, periodsOfPoi) {
    if (periodsOfPoi.length > 0) {
      for (let index = 0; index < periodsOfPoi.length; index++) {
        periodsOfPoi[index].dataInizioTimeSpan = moment(
          periodsOfPoi[index].DataInizio[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        periodsOfPoi[index].dataFineTimeSpan = moment(
          periodsOfPoi[index].DataInizio[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfPeriod = periodsOfPoi.slice(0, -1);
    if (arrayOfPeriod.length > 0) {
      console.log("POI ID", localStorage.getItem("poiID"));
      var existePeriodo = arrayOfPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
          res.dataFineTimeSpan ===
            moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);

      if (existePeriodo.length > 0) {
        return alert("Hai già impostato un orario identico per questo periodo");
      } else {
        console.log("POI ID", localStorage.getItem("poiID"));
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.DataInizio[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizio[1],
                  "YYYY-MM-DD HH:mm:ss"
                )
                  .add(20, "years")
                  .format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: localStorage.getItem("poiID"),
                backOfficeData: 0,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                item.DataInizioSetSpecialsHours = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];

                item.DataInizio = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioOrarioGiornoSet = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.dataInizioNewSpecialHour = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                this.openingPeriodId =
                  result.data["poiOpeningPeriodCreate"]["id"];
                localStorage.setItem(
                  "periodPrincipalId",
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
                item.showOpeningDayBtn = true;
              }
            },
            (err) => {
              console.log(err);
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.DataInizio[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss")
                .add(20, "years")
                .format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              backOfficeData: 0,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              item.DataInizioSetSpecialsHours = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];

              item.DataInizio = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioOrarioGiornoSet = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.dataInizioNewSpecialHour = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              this.openingPeriodId =
                result.data["poiOpeningPeriodCreate"]["id"];
              localStorage.setItem(
                "periodPrincipalId",
                result.data["poiOpeningPeriodCreate"]["id"]
              );
              item.showOpeningDayBtn = true;
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  // setNewSpecialDays(data, newItem) {

  //   data.notValidAfterCreateOrari = true;

  //   console.log(data);
  //   newItem.setOrarioSpecialeObj.push({
  //     notesSpecialHour: "",
  //     startingTimeSpecialHour: "",
  //     endingTimeSpecialHour: "",
  //     dataInizioSpecialHour: [],
  //     closedPeriodSpecialHour: false,
  //     showAfterCreateSpecialOrario: false,
  //     notValidAfterCreateOrario: false
  //   });
  //   console.log(data);

  // }
  // setCreateManyDays(data: any[]) {
  //   this.alwaysOpenDays = [];
  //   var startTimer = moment().startOf("year").format("HH:mm:ss");
  //   var endingTime = moment().startOf("year").subtract("1", "second").format("HH:mm:ss");

  //   var tasks$ = [];
  //   for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
  //     this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
  //     console.log(this.alwaysOpenDays);
  //   }

  //   for (let index = 0; index < data.length; index++) {
  //     tasks$.push(this.apollo.use("super-admin").mutate({
  //       mutation: new poiOpeningDayCreate(moment.duration(startTimer).asSeconds(), moment.duration(endingTime).asSeconds()).getQuery(),
  //       variables: {
  //         'poiOpeningPeriodId': data[index].id,
  //         'daysOfWeek': this.alwaysOpenDays,
  //         'notes': null,
  //         'openingDaySpecialNotes': []
  //       },
  //     }))

  //   }
  //   return forkJoin(tasks$).subscribe((result) => {
  //     for (let index = 0; index < result.length; index++) {
  //       if (result[index].data['poiOpeningDayCreate']['success']) {
  //       }
  //     }
  //     alert("Deroga impostata con success");
  //   })

  // }
  // createPrincipalPeriodForAllYears(item, periodsOfPoi) {

  //   if (periodsOfPoi.length > 0) {
  //     for (let index = 0; index < periodsOfPoi.length; index++) {

  //       periodsOfPoi[index].dataInizioTimeSpan = moment(periodsOfPoi[index].DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X");
  //       periodsOfPoi[index].dataFineTimeSpan = moment(periodsOfPoi[index].DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
  //     }
  //   }
  //   var arrayOfPeriod = periodsOfPoi.slice(0, -1);

  //   if (arrayOfPeriod.length > 0) {
  //     var existePeriodo = arrayOfPeriod.filter(res => res.dataInizioTimeSpan === moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") && res.dataFineTimeSpan === moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X"));

  //     console.log(existePeriodo);

  //     if (existePeriodo.length > 0) {
  //       return alert("Hai già impostato un orario identico per questo periodo");

  //     }
  //     else {
  //       this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
  //         mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
  //         variables: {
  //           'data': {

  //             'startingDateTime': moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
  //             'endingDateTime': moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").add(20, 'years').format("YYYY-MM-DD HH:mm:ss"),
  //             'backOfficeData': 0
  //           }
  //         },
  //       }).subscribe((result: any) => {
  //         if (result.data['poiOpeningPeriodUpdate']['success']) {

  //           item.DataInizioSetSpecialsHours = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];

  //           item.DataInizio = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))]
  //           item.DataInizioOrarioGiornoSet = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];
  //           item.dataInizioNewSpecialHour = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];

  //           item.showOpeningDayBtn = true;

  //         }
  //       }, (err) => {
  //         alert("An error occurred.Try again later.");

  //       })
  //     }
  //   }
  //   else {
  //     this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
  //       mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
  //       variables: {
  //         'data': {

  //           'startingDateTime': moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
  //           'endingDateTime': moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").add(20, 'years').format("YYYY-MM-DD HH:mm:ss"),
  //           'backOfficeData': 0
  //         }
  //       },
  //     }).subscribe((result: any) => {
  //       if (result.data['poiOpeningPeriodUpdate']['success']) {

  //         item.DataInizioSetSpecialsHours = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];

  //         item.DataInizio = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))]
  //         item.DataInizioOrarioGiornoSet = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];
  //         item.dataInizioNewSpecialHour = [new Date(moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")), new Date(moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))];

  //         item.showOpeningDayBtn = true;

  //       }
  //     }, (err) => {
  //       alert("An error occurred.Try again later.");

  //     })
  //   }

  // }

  //TODO
  createPrincipalPeriod(item, periodsOfPoi) {
    console.log('createPrincipalPeriod', item, periodsOfPoi);
    if (periodsOfPoi.length > 0) {
      for (let index = 0; index < periodsOfPoi.length; index++) {
        periodsOfPoi[index].dataInizioTimeSpan = moment(
          periodsOfPoi[index].DataInizio[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        periodsOfPoi[index].dataFineTimeSpan = moment(
          periodsOfPoi[index].DataInizio[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }
    var arrayOfPeriod = periodsOfPoi.slice(0, -1);
    console.log('createPrincipalPeriod', `(arrayOfPeriod.length > 0)=${arrayOfPeriod.length}`, arrayOfPeriod);
    if (arrayOfPeriod.length > 0) {
      console.log('createPrincipalPeriod', `if (arrayOfPeriod.length > 0)`);
      var existePeriodo = arrayOfPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
          res.dataFineTimeSpan ===
            moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log('createPrincipalPeriod', `esite il periodo? ${existePeriodo}`, existePeriodo.length > 0);

      if (existePeriodo.length > 0) {
        return alert("Hai già impostato un orario identico per questo periodo");
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                startingDateTime: moment(
                  item.DataInizio[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.DataInizio[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                backOfficeData: 0,
              },
            },
          })
          .subscribe(
            (result: any) => {
              console.log("RESULT", result);
              alert('Periodo creato con successo');
              item.id = result.data.poiOpeningPeriodCreate.id
              this.poiCreateNewOpeningPeriod(item, 0);

              //this.ricaricaPeriodi();
              /*if (result.data["poiOpeningPeriodCreate"]["success"]) {
                item.DataInizioSetSpecialsHours = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioOrarioGiornoSet = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.dataInizioNewSpecialHour = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizio = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioSpecialPeriod = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];

                item.showOpeningDayBtn = true;
                console.log("showOpeningDayBtn", item.showOpeningDayBtn);
                item.openingPeriodId =
                  result.data["poiOpeningPeriodCreate"]["id"];
                localStorage.setItem(
                  "periodPrincipalId",
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
              }*/
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {

      console.log('createPrincipalPeriod', `else (arrayOfPeriod.length > 0)`);
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              startingDateTime: moment(
                item.DataInizio[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.DataInizio[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              backOfficeData: 0,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              alert('Periodo creato con successo');
              item.id = result.data.poiOpeningPeriodCreate.id
              this.poiCreateNewOpeningPeriod(item, 0);
              //this.ricaricaPeriodi();
              /*
              item.DataInizioSetSpecialsHours = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioOrarioGiornoSet = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.dataInizioNewSpecialHour = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizio = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioSpecialPeriod = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];

              item.id = result.data["poiOpeningPeriodCreate"]["id"];
              item.openingPeriodId =
                result.data["poiOpeningPeriodCreate"]["id"];
              localStorage.setItem(
                "periodPrincipalId",
                result.data["poiOpeningPeriodCreate"]["id"]
              );
              */

              //console.log(item.DataInizio);
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  //TODO
  modificaPrincipalPeriod(item, periodsOfPoi) {

    console.log("ORARI.setNewOpenindDayObj", item.setNewOpenindDayObj)
    /*
    for (let data of item.setNewOpenindDayObj) {
      //moment('12:10:12: PM', 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');

      data.startingTimeSpan = moment(data.startingTime, "YYYY-MM-DD HH:mm:ss").diff(moment().startOf('day'), 'seconds');
      data.endingTimeSpan = moment(data.endingTime, "YYYY-MM-DD HH:mm:ss").diff(moment().startOf('day'), 'seconds');


      console.log("ORARI.data", data)
      console.log("ORARI.data.startingTime", data.startingTime, data.startingTimeSpan)
      console.log("ORARI.data.endingTime", data.endingTime, data.endingTimeSpan)
      //this.poiModificaOpeningDay(data)
      this.setImpostaOrarioAllDays(item.id, data)
    }

    if (periodsOfPoi.length > 0) {
      for (let index = 0; index < periodsOfPoi.length; index++) {
        periodsOfPoi[index].dataInizioTimeSpan = moment(
          periodsOfPoi[index].DataInizio[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        periodsOfPoi[index].dataFineTimeSpan = moment(
          periodsOfPoi[index].DataInizio[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
    }*/

    var startingDateTime = moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    var endingDateTime = moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

    var arrayOfPeriod = periodsOfPoi.slice(0, -1);
    if (arrayOfPeriod.length > 0) {
      var existePeriodo = arrayOfPeriod.filter(
        (res) =>
          res.dataInizioTimeSpan ===
            moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format("X") &&
          res.dataFineTimeSpan ===
            moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format("X")
      );

      console.log(existePeriodo);



      if (existePeriodo.length > 0) {
        return alert("Hai già impostato un orario identico per questo periodo");
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
            variables: {
              data: {
                id: item.id,
                startingDateTime: startingDateTime,
                endingDateTime: endingDateTime,
                backOfficeData: 0,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodUpdate"]["success"]) {
                alert('Modifica effettuata con sucesso');
                item.startingDateTime = startingDateTime;
                item.endingDateTime = endingDateTime;
                //this.ricaricaPeriodi();
                /*item.DataInizioSetSpecialsHours = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioOrarioGiornoSet = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.dataInizioNewSpecialHour = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizio = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];
                item.DataInizioSpecialPeriod = [
                  new Date(
                    moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                  new Date(
                    moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  ),
                ];*/

                item.showOpeningDayBtn = true;
              }
            },
            (err) => {
              alert("An error occurred.Try again later.");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
          variables: {
            data: {
              id: item.id,
              startingDateTime: startingDateTime,
              endingDateTime: endingDateTime,
              backOfficeData: 0,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodUpdate"]["success"]) {
              alert('Modifica effettuata con sucesso');
              //TODO tolgo ricarica periodi che altrimenti qualche volta mi cancella il periodo selezionato
              //this.ricaricaPeriodi();
              item.startingDateTime = startingDateTime;
              item.endingDateTime = endingDateTime;
              /*
              item.DataInizioSetSpecialsHours = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioOrarioGiornoSet = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.dataInizioNewSpecialHour = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizio = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];
              item.DataInizioSpecialPeriod = [
                new Date(
                  moment(item.DataInizio[0], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
                new Date(
                  moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ),
              ];

              localStorage.setItem("periodPrincipalId", item.id);
              item.poiOpeningPeriodId = item.id;
              */
              //console.log(item.DataInizio);
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  async daysOfWeekChangeEvent(days) {
    if (days.length == 6) {
      this.validityGetAll.splice(-1, 1);
    }
  }

  CancellaPoiOpeningDayGetAllSub(item, padre) {
    console.log('CancellaPoiOpeningDayGetAllSub', item.id, item, padre);
    if (item.id === undefined) {
      //this.ricaricaPeriodi();
      padre.poiOpeningDayGetAllSub = padre.poiOpeningDayGetAllSub.filter(function(singolo) {return singolo.id !== undefined});
      if (padre.poiOpeningDayGetAllSub.length == 0)
        this.poiCreateNewOpeningPeriod(padre, 0);
      return;
    }
    this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningDayDelete.poiOpeningDayDelete,
        variables: {
          id: item.id
        },
      })
      .subscribe(
        (result: any) => {
          alert('Cancellazione effettuata con successo');
          this.ricaricaPeriodi();
        },
        (err) => {
          console.log(err);
          alert("An error occurred.Try again later.");
        });
  }

  poiCreateOpeningDay(item, padre) {
    console.log("ITEM from createOpeningDay", item, padre);
    item.dayOfWeeks = item.selectedDaysOfWeek.map(v => v.name);
    console.log("item", item);

    console.log("preso", moment(item.startingTime).format("HH:mm:ss"));
    var startTimer = moment(item.startingTime).toDate().getHours() * 3600 + moment(item.startingTime).toDate().getMinutes() * 60;
    var endingTime = moment(item.endingTime).toDate().getHours() * 3600 + moment(item.endingTime).toDate().getMinutes() * 60;

    /*
    console.log('this.daysOfWeekGetAll.', this.daysOfWeekGetAll)
    var data = this.daysOfWeekGetAll.filter(
      (res) =>
        item.selectedDaysOfWeek.findIndex(
          (itemSelected) => itemSelected.id === res.id
        ) !== -1
    );
    for (let index = 0; index < data.length; index++) {
      this.dayOfWeeks.push(data[index].name);
    }*/

    var query = null;
    var operazione = "";
    var variabili = {
      poiOpeningPeriodId: padre.id,//localStorage.getItem("periodPrincipalId"),
      daysOfWeek: item.dayOfWeeks,
      notes: item.notes,
      openingDaySpecialNotes: [],
    };
    if (item.id === undefined) {
      query = new poiOpeningDayCreate(startTimer, endingTime).getQuery();
      operazione = "poiOpeningDayCreate";
    } else {
      query = new poiOpeningDayUpdate(startTimer, endingTime).getQuery();
      operazione = "poiOpeningDayUpdate";
      variabili['id'] = item.id;
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: query,
        variables: variabili,
      })
      .subscribe(
        (result: any) => {
          if (result.data[operazione]["success"]) {
            this.isCreatedPeriod = true;
            item.isCreatedPeriod = true;
            alert('Periodo impostato con successo')
            // oldItem.showAfterCreatePeriod = true;
            /*localStorage.setItem(
              "poiOpeningDayCreateId",
              result.data["poiOpeningDayCreate"]["id"]
            );*/
          }
        },
        (err) => {
          console.log(err);
          alert("An error occurred.Try again later.");
        }
      );
  }

  dateCheck = () =>
    localStorage.getItem("periodPrincipalId") != undefined ? true : false;

  poiModificaOpeningDay(item) {
    this.dayOfWeeks = [];
    console.log(item);
    var startTimer = moment(
      item.formatStartingTime,
      "YYYY-MM-DD HH:mm:ss"
    ).format("HH:mm:ss");
    var endingTime = moment(
      item.formatEndingTime,
      "YYYY-MM-DD HH:mm:ss"
    ).format("HH:mm:ss");

    var data = this.daysOfWeekGetAll.filter(
      (res) =>
        item.daysOfWeek.findIndex(
          (itemSelected) => itemSelected.id === res.id
        ) !== -1
    );
    for (let index = 0; index < data.length; index++) {
      this.dayOfWeeks.push(data[index].name);
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayUpdate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId:
            item.poiOpeningPeriodId === ""
              ? localStorage.getItem("periodPrincipalId")
              : item.poiOpeningPeriodId,
          daysOfWeek: this.dayOfWeeks,
          notes: item.notes,
          openingDaySpecialNotes: [],
          id:
            item.id === ""
              ? localStorage.getItem("poiOpeningDayCreateId")
              : item.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayUpdate"]["success"]) {
            console.log(item);
            this.isCreatedPeriod = true;
            item.isCreatedPeriod = true;
            // oldItem.showAfterCreatePeriod = true;
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }
  //IMPOSTO PERIODO CLOSED
  setClosedPeriod(item, specialPeriodArray) {
    if (specialPeriodArray.length > 0) {
      for (let index = 0; index < specialPeriodArray.length; index++) {
        specialPeriodArray[index].dataInizioTimeSpan = moment(
          specialPeriodArray[index].poiDerogatedPeriodData[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        specialPeriodArray[index].dataFineTimeSpan = moment(
          specialPeriodArray[index].poiDerogatedPeriodData[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
      var arrayOfSpecialPeriod = specialPeriodArray.slice(0, -1);

      if (arrayOfSpecialPeriod.length > 0) {
        var existePeriodo = arrayOfSpecialPeriod.filter(
          (res) =>
            res.dataInizioTimeSpan ===
              moment(
                item.poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") &&
            res.dataFineTimeSpan ===
              moment(
                item.poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X")
        );

        console.log(existePeriodo);

        if (existePeriodo.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.poiDerogatedPeriodData[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.poiDerogatedPeriodData[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  isClosed: item.isClosedInPeriod,
                  pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                  derogatedOpeningPeriodId: parseInt(
                    item.derogatedOpeningPeriodId
                  ),
                  backOfficeData: 2,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  this.setIsAlwaysOpeningDayCreate(
                    result.data["poiOpeningPeriodCreate"]["id"]
                  );
                }
              },
              (err) => {
                alert("An error occurred.Try again later.");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                isClosed: item.isClosedInPeriod,
                pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                derogatedOpeningPeriodId: parseInt(
                  item.derogatedOpeningPeriodId
                ),
                backOfficeData: 2,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                this.setIsAlwaysOpeningDayCreate(
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    }
  }

  setClosedPeriodUpdate(item, specialPeriodArray) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
        variables: {
          data: {
            startingDateTime: moment(
              item.poiDerogatedPeriodData[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            endingDateTime: moment(
              item.poiDerogatedPeriodData[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            isClosed: item.isClosedInPeriod,
            id: item.id,
            backOfficeData: 2,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodUpdate"]["success"]) {
            this.setIsAlwaysOpeningDay(item.id, item.openingDayId);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }
  setIsAlwaysOpeningDay(idOpeningPeriod, id) {
    this.alwaysOpenDays = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    console.log(startTimer);
    console.log(endingTime);
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayUpdate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: idOpeningPeriod,
          daysOfWeek: this.alwaysOpenDays,
          notes: null,
          openingDaySpecialNotes: [],
          id: id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayUpdate"]["success"]) {
            alert("Deroga impostata con success");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  setIsAlwaysOpeningDayCreate(idOpeningPeriod) {
    this.alwaysOpenDays = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    console.log(startTimer);
    console.log(endingTime);
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: idOpeningPeriod,
          daysOfWeek: this.alwaysOpenDays,
          notes: null,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            alert("Deroga impostata con success");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  // FIM IMPOSTA PERIODO CLOSED

  //IMPOSTA GIORNO SETTIMANA

  setDerogeDaysOfWeek(item, giornoOfWeekArray) {
    if (giornoOfWeekArray.length > 0) {
      for (let index = 0; index < giornoOfWeekArray.length; index++) {
        giornoOfWeekArray[index].dataInizioTimeSpan = moment(
          giornoOfWeekArray[index].poiDerogatedPeriodData[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        giornoOfWeekArray[index].dataFineTimeSpan = moment(
          giornoOfWeekArray[index].poiDerogatedPeriodData[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
      }
      var arrayOfSpecialPeriod = giornoOfWeekArray.slice(0, -1);

      if (arrayOfSpecialPeriod.length > 0) {
        var existePeriodo = arrayOfSpecialPeriod.filter(
          (res) =>
            res.dataInizioTimeSpan ===
              moment(
                item.poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X") &&
            res.dataFineTimeSpan ===
              moment(
                item.poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("X")
        );

        console.log(existePeriodo);

        if (existePeriodo.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.poiDerogatedPeriodData[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.poiDerogatedPeriodData[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                  isClosed: item.isClosed,
                  derogatedOpeningPeriodId: parseInt(
                    item.derogatedOpeningPeriodId
                  ),
                  backOfficeData: 4,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  this.setNewDaysForPeriod(
                    result.data["poiOpeningPeriodCreate"]["id"]
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                isClosed: item.isClosed,
                derogatedOpeningPeriodId: parseInt(
                  item.derogatedOpeningPeriodId
                ),
                backOfficeData: 4,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                this.setNewDaysForPeriod(
                  result.data["poiOpeningPeriodCreate"]["id"]
                );
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.poiDerogatedPeriodData[0],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.poiDerogatedPeriodData[1],
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              isClosed: item.isClosed,
              derogatedOpeningPeriodId: parseInt(
                localStorage.getItem("periodPrincipalId")
              ),
              backOfficeData: 4,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              this.setNewDaysForPeriod(
                result.data["poiOpeningPeriodCreate"]["id"]
              );
            }
          },
          (err) => {
            alert("An error occurred.Try again later.");
          }
        );
    }
  }

  setDerogeDaysOfWeekUpdate(item, giornoOfWeekArray) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
        variables: {
          data: {
            startingDateTime: moment(
              item.poiDerogatedPeriodData[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            endingDateTime: moment(
              item.poiDerogatedPeriodData[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            id: item.id,
            isClosed: item.isClosed,
            backOfficeData: 4,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodUpdate"]["success"]) {
            this.setNewDaysForPeriodUpdate(item.openingDayId, item.id);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
    // if (giornoOfWeekArray.length > 0) {
    //   for (let index = 0; index < giornoOfWeekArray.length; index++) {

    //     giornoOfWeekArray[index].dataInizioTimeSpan = moment(giornoOfWeekArray[index].poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("X");
    //     giornoOfWeekArray[index].dataFineTimeSpan = moment(giornoOfWeekArray[index].poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("X")

    //   }
    //   var arrayOfSpecialPeriod = giornoOfWeekArray.slice(0, -1);

    //   if (arrayOfSpecialPeriod.length > 0) {
    //     var existePeriodo = arrayOfSpecialPeriod.filter(res => res.dataInizioTimeSpan === moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("X") && res.dataFineTimeSpan === moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("X"));

    //     console.log(existePeriodo);

    //     if (existePeriodo.length > 0) {
    //       return alert("Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente");

    //     }
    //     else {
    //     }
    //   }
    //   else {
    //     this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //       mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //       variables: {
    //         'data': {

    //           'startingDateTime': moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //           'endingDateTime': moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //           'id': item.id,
    //           'isClosed': item.isClosed,
    //           'derogatedOpeningPeriodId': parseInt(item.derogatedOpeningPeriodId),
    //           'backOfficeData': 4
    //         }
    //       },
    //     }).subscribe((result: any) => {
    //       if (result.data['poiOpeningPeriodUpdate']['success']) {

    //         this.setNewDaysForPeriodUpdate(item.openingDayId, item.id);
    //       }
    //     }, (err) => {
    //       alert("An error occurred.Try again later.");

    //     })
    //   }
    // }
    // else {
    //   this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //     mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //     variables: {
    //       'data': {

    //         'startingDateTime': moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //         'endingDateTime': moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //         'id': item.id,
    //         'isClosed': item.isClosed,
    //         'derogatedOpeningPeriodId': parseInt(item.derogatedOpeningPeriodId),
    //         'backOfficeData': 4
    //       }
    //     },
    //   }).subscribe((result: any) => {
    //     if (result.data['poiOpeningPeriodUpdate']['success']) {

    //       this.setNewDaysForPeriodUpdate(item.openingDayId, item.id);
    //     }
    //   }, (err) => {
    //     alert("An error occurred.Try again later.");

    //   })
    // }
  }
  setNewDaysForPeriod(openingPeriodId) {
    var tasks$ = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    var data = this.daysOfWeekGetAll.filter(
      (data) =>
        this.alwaysOpenDays.findIndex(
          (itemSelected) => itemSelected.id === data.id
        ) === -1
    );
    for (let index = 0; index < data.length; index++) {
      tasks$.push(data[index].name);
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: this.alwaysOpenDays,
          notes: this.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            //this.isCreatedPeriod  = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  setNewDaysForPeriodUpdate(id, openingPeriodId) {
    var tasks$ = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    var data = this.daysOfWeekGetAll.filter(
      (item) =>
        this.alwaysOpenDays.findIndex(
          (itemSelected) => itemSelected.id === item.id
        ) === -1
    );
    for (let index = 0; index < data.length; index++) {
      tasks$.push(data[index].name);
    }

    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayUpdate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: this.alwaysOpenDays,
          notes: this.notes,
          openingDaySpecialNotes: [],
          id: id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayUpdate"]["success"]) {
            //this.isCreatedPeriod  = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  // FIM GIORNO DELLA SETTIMANA

  // setCreateManyDaysoOfWeeks(data: any[]) {

  //   var tasks2 = [];
  //   var startTimer = moment().startOf("year").format("HH:mm:ss");
  //   var endingTime = moment().startOf("year").subtract("1", "second").format("HH:mm:ss");

  //   var tasks$ = [];
  //   var dataOfWeek = this.daysOfWeekGetAll.filter(item => this.selectedDaysOfWeek.findIndex((itemSelected) => itemSelected.id === item.id) === -1)
  //   for (let index = 0; index < dataOfWeek.length; index++) {
  //     tasks2.push(dataOfWeek[index].name);
  //   }

  //   for (let index = 0; index < data.length; index++) {
  //     tasks$.push(this.apollo.use("super-admin").mutate({
  //       mutation: new poiOpeningDayCreate(moment.duration(startTimer).asSeconds(), moment.duration(endingTime).asSeconds()).getQuery(),
  //       variables: {
  //         'poiOpeningPeriodId': data[index].id,
  //         'daysOfWeek': tasks2,
  //         'notes': null,
  //         'openingDaySpecialNotes': []
  //       },
  //     }))

  //   }
  //   return forkJoin(tasks$).subscribe((result) => {
  //     for (let index = 0; index < result.length; index++) {
  //       if (result[index].data['poiOpeningDayCreate']['success']) {
  //         console.log(result[index].data['poiOpeningDayCreate']['id']);
  //       }
  //     }
  //     alert("Deroga impostata con successo ");
  //   })

  // }

  trackByFn(index: any, item: any) {
    return index;
  }

  //IMPOSTA ORARI DELLA SETTIMANA
  setImpostaOrarioGiornoGetAll(item, setImpostaOrarioGiorno) {
    if (setImpostaOrarioGiorno.length > 0) {
      for (let i = 0; i < setImpostaOrarioGiorno.length; i++) {
        setImpostaOrarioGiorno[i].dataInizioTimeSpan = moment(
          setImpostaOrarioGiorno[i].poiDerogatedPeriodData[0],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        setImpostaOrarioGiorno[i].endFineSpan = moment(
          setImpostaOrarioGiorno[i].poiDerogatedPeriodData[1],
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");

        setImpostaOrarioGiorno[i].startingTimeOrarioGiorno = moment(
          setImpostaOrarioGiorno[i].startingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
        setImpostaOrarioGiorno[i].endingTimeOrarioGiorno = moment(
          setImpostaOrarioGiorno[i].endingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");

        setImpostaOrarioGiorno[i].startingTimeSpan = moment
          .duration(setImpostaOrarioGiorno[i].startingTimeOrarioGiorno)
          .asSeconds();
        setImpostaOrarioGiorno[i].endingTimeSpan = moment
          .duration(setImpostaOrarioGiorno[i].endingTimeOrarioGiorno)
          .asSeconds();
      }
      var existeOneorMoreDayOfWeek = setImpostaOrarioGiorno.filter(
        (data) => data.name === item.name
      );

      if (existeOneorMoreDayOfWeek.length == 1) {
        var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(
          (data) => data.id !== item.id
        );

        if (arrayOfSpecialPeriod.length > 0) {
          var existePeriodoGiorno = arrayOfSpecialPeriod.filter(
            (res) =>
              res.name === item.name &&
              res.dataInizioTimeSpan ===
                moment(
                  item.poiDerogatedPeriodData[0],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X") &&
              res.endFineSpan ===
                moment(
                  item.poiDerogatedPeriodData[1],
                  "YYYY-MM-DD HH:mm:ss"
                ).format("X")
          );
          var resultFilterData = existePeriodoGiorno.filter(
            (result) =>
              moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
                result.startingTimeSpan &&
              moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
                result.endingTimeSpan
          );
          console.log(existePeriodoGiorno);

          if (resultFilterData.length > 0) {
            return alert(
              "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
            );
          } else {
            this.querySubscriptionProfileUser = this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
                variables: {
                  data: {
                    startingDateTime: moment(
                      item.DataInizioOrarioGiornoSet[0],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss"),
                    endingDateTime: moment(
                      item.DataInizioOrarioGiornoSet[1],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss"),
                    pointOfInterestId: parseInt(
                      localStorage.getItem("emptyPoi")
                    ),
                    isClosed: item.isClosed,
                    derogatedOpeningPeriodId: parseInt(
                      localStorage.getItem("periodPrincipalId")
                    ),
                    backOfficeData: 5,
                  },
                },
              })
              .subscribe(
                (result: any) => {
                  if (result.data["poiOpeningPeriodCreate"]["success"]) {
                    this.setImpostaGiornoXOrarioCreate(
                      result.data["poiOpeningPeriodCreate"]["id"],
                      item
                    );
                  }
                },
                (err) => {
                  alert("La deroga non è impostata correttamente, riprova");
                }
              );
          }
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                  isClosed: item.isClosed,
                  derogatedOpeningPeriodId: parseInt(
                    localStorage.getItem("periodPrincipalId")
                  ),
                  backOfficeData: 5,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  this.setImpostaGiornoXOrarioCreate(
                    result.data["poiOpeningPeriodCreate"]["id"],
                    item
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      }
      if (existeOneorMoreDayOfWeek.length > 1) {
        var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(
          (data) => data.name === item.name
        );

        if (arrayOfSpecialPeriod.length > 0) {
          var deleteLastOfArray = arrayOfSpecialPeriod.slice(0, -1);
          var resultFilterData = deleteLastOfArray.filter(
            (result) =>
              moment.duration(item.endingTimeOrarioGiorno).asSeconds() >=
                result.startingTimeSpan &&
              moment.duration(item.startingTimeOrarioGiorno).asSeconds() <=
                result.endingTimeSpan
          );
          console.log(existePeriodoGiorno);

          if (resultFilterData.length > 0) {
            return alert(
              "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
            );
          } else {
            this.querySubscriptionProfileUser = this.apollo
              .use("super-admin")
              .mutate({
                mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
                variables: {
                  data: {
                    startingDateTime: moment(
                      item.DataInizioOrarioGiornoSet[0],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss"),
                    endingDateTime: moment(
                      item.DataInizioOrarioGiornoSet[1],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss"),
                    pointOfInterestId: parseInt(
                      localStorage.getItem("emptyPoi")
                    ),
                    isClosed: item.isClosed,
                    derogatedOpeningPeriodId: parseInt(
                      localStorage.getItem("periodPrincipalId")
                    ),
                    backOfficeData: 5,
                  },
                },
              })
              .subscribe(
                (result: any) => {
                  if (result.data["poiOpeningPeriodCreate"]["success"]) {
                    this.setImpostaGiornoXOrarioCreate(
                      result.data["poiOpeningPeriodCreate"]["id"],
                      item
                    );
                  }
                },
                (err) => {
                  alert("La deroga non è impostata correttamente, riprova");
                }
              );
          }
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[0],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.DataInizioOrarioGiornoSet[1],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  pointOfInterestId: parseInt(localStorage.getItem("emptyPoi")),
                  isClosed: item.isClosed,
                  derogatedOpeningPeriodId: parseInt(
                    localStorage.getItem("periodPrincipalId")
                  ),
                  backOfficeData: 5,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  this.setImpostaGiornoXOrarioCreate(
                    result.data["poiOpeningPeriodCreate"]["id"],
                    item
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      }
    }
  }

  setImpostaOrarioGiornoUpdate(item, setImpostaOrarioGiorno) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
        variables: {
          data: {
            startingDateTime: moment(
              item.DataInizioOrarioGiornoSet[0],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            endingDateTime: moment(
              item.DataInizioOrarioGiornoSet[1],
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),

            isClosed: item.isClosed,
            backOfficeData: 5,
            id: item.id,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodUpdate"]["success"]) {
            this.setImpostaGiornoXOrario(item.derogatedOpeningPeriodId, item);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
    // if (setImpostaOrarioGiorno.length > 0) {
    //   for (let i = 0; i < setImpostaOrarioGiorno.length; i++) {
    //     setImpostaOrarioGiorno[i].dataInizioTimeSpan = moment(setImpostaOrarioGiorno[i].poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("X");
    //     setImpostaOrarioGiorno[i].endFineSpan = moment(setImpostaOrarioGiorno[i].poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("X");

    //     setImpostaOrarioGiorno[i].startingTimeOrarioGiorno = moment(setImpostaOrarioGiorno[i].startingTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
    //     setImpostaOrarioGiorno[i].endingTimeOrarioGiorno = moment(setImpostaOrarioGiorno[i].endingTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

    //     setImpostaOrarioGiorno[i].startingTimeSpan = moment.duration(setImpostaOrarioGiorno[i].startingTimeOrarioGiorno).asSeconds();
    //     setImpostaOrarioGiorno[i].endingTimeSpan = moment.duration(setImpostaOrarioGiorno[i].endingTimeOrarioGiorno).asSeconds();
    //   }
    //   var existeOneorMoreDayOfWeek = setImpostaOrarioGiorno.filter(data => data.name === item.name);

    //   if (existeOneorMoreDayOfWeek.length == 1) {
    //     var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(data => data.id !== item.id);

    //     if (arrayOfSpecialPeriod.length > 0) {
    //       var existePeriodoGiorno = arrayOfSpecialPeriod.filter(res => (res.name === item.name) && (res.dataInizioTimeSpan === moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("X")) && (res.endFineSpan === moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("X")));
    //       var resultFilterData = existePeriodoGiorno.filter(result => (moment.duration(item.endingTimeOrarioGiorno).asSeconds() >= result.startingTimeSpan) && (moment.duration(item.startingTimeOrarioGiorno).asSeconds() <= result.endingTimeSpan));
    //       console.log(existePeriodoGiorno);

    //       if (resultFilterData.length > 0) {

    //         return alert("Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente");

    //       }
    //       else {
    //         this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //           mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //           variables: {
    //             'data': {

    //               'startingDateTime': moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //               'endingDateTime': moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),

    //               'isClosed': item.isClosed,
    //               'derogatedOpeningPeriodId': parseInt(item.derogatedOpeningPeriodId),
    //               'backOfficeData': 5,
    //               'id': item.id
    //             }
    //           },
    //         }).subscribe((result: any) => {
    //           if (result.data['poiOpeningPeriodUpdate']['success']) {

    //             this.setImpostaGiornoXOrario(item.derogatedOpeningPeriodId, item);
    //           }
    //         }, (err) => {
    //           alert("La deroga non è impostata correttamente, riprova");

    //         })
    //       }
    //     }
    //     else {
    //       this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //         mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //         variables: {
    //           'data': {

    //             'startingDateTime': moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //             'endingDateTime': moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),

    //             'isClosed': item.isClosed,
    //             'derogatedOpeningPeriodId': parseInt(item.derogatedOpeningPeriodId),
    //             'backOfficeData': 5,
    //             'id': item.id
    //           }
    //         },
    //       }).subscribe((result: any) => {
    //         if (result.data['poiOpeningPeriodUpdate']['success']) {

    //           this.setImpostaGiornoXOrario(item.derogatedOpeningPeriodId, item);
    //         }
    //       }, (err) => {
    //         alert("La deroga non è impostata correttamente, riprova");

    //       })
    //     }
    //   }
    //   if (existeOneorMoreDayOfWeek.length > 1) {

    //     var arrayOfSpecialPeriod = setImpostaOrarioGiorno.filter(data => data.name === item.name);

    //     if (arrayOfSpecialPeriod.length > 0) {

    //       var deleteLastOfArray = arrayOfSpecialPeriod.slice(0, -1);
    //       var resultFilterData = deleteLastOfArray.filter(result => (moment.duration(item.endingTimeOrarioGiorno).asSeconds() >= result.startingTimeSpan) && (moment.duration(item.startingTimeOrarioGiorno).asSeconds() <= result.endingTimeSpan));
    //       console.log(existePeriodoGiorno);

    //       if (resultFilterData.length > 0) {

    //         return alert("Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente");

    //       }
    //       else {
    //         this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //           mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //           variables: {
    //             'data': {

    //               'startingDateTime': moment(item.poiDerogatedPeriodData[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //               'endingDateTime': moment(item.poiDerogatedPeriodData[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //               'isClosed': item.isClosed,
    //               'derogatedOpeningPeriodId': parseInt(item.derogatedOpeningPeriodId),
    //               'backOfficeData': 5,
    //               'id': item.id
    //             }
    //           },
    //         }).subscribe((result: any) => {
    //           if (result.data['poiOpeningPeriodUpdate']['success']) {

    //             this.setImpostaGiornoXOrario(item.derogatedOpeningPeriodId, item);
    //           }
    //         }, (err) => {
    //           alert("La deroga non è impostata correttamente, riprova");

    //         })
    //       }
    //     }
    //     else {
    //       this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //         mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //         variables: {
    //           'data': {

    //             'startingDateTime': moment(item.DataInizioOrarioGiornoSet[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //             'endingDateTime': moment(item.DataInizioOrarioGiornoSet[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),

    //             'isClosed': item.isClosed,
    //             'derogatedOpeningPeriodId': item.derogatedOpeningPeriodId,
    //             'backOfficeData': 5,
    //             'id': item.id
    //           }
    //         },
    //       }).subscribe((result: any) => {
    //         if (result.data['poiOpeningPeriodUpdate']['success']) {

    //           this.setImpostaGiornoXOrario(item.derogatedOpeningPeriodId, item);
    //         }
    //       }, (err) => {
    //         alert("An error occurred.Try again later.");

    //       })
    //     }
    //   }
    // }
  }
  setImpostaGiornoXOrario(openingPeriodId, item) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayUpdate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: [item.name],
          notes: item.notes,
          openingDaySpecialNotes: [],
          id: item.openingDayId,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayUpdate"]["success"]) {
            //this.isCreatedPeriod  = true;

            //item.showAfterCreateOrarioGiorno = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  setImpostaGiornoXOrarioCreate(openingPeriodId, item) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: [item.name],
          notes: item.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            //this.isCreatedPeriod  = true;

            //item.showAfterCreateOrarioGiorno = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  // FIM ORARIO DELLA SETIMANA

  //IMPOSTA ORARI SPECIALE
  setSpecialsDaysAllWeek(item, setSpecialOrariArray) {
    if (setSpecialOrariArray.length > 0) {
      for (let index = 0; index < setSpecialOrariArray.length; index++) {
        setSpecialOrariArray[index].dataInizioTimeSpan = moment(
          setSpecialOrariArray[index].poiDerogatedPeriodData,
          "YYYY-MM-DD HH:mm:ss"
        ).format("X");
        setSpecialOrariArray[index].startingTime = moment(
          setSpecialOrariArray[index].formatStartingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
        setSpecialOrariArray[index].endingTime = moment(
          setSpecialOrariArray[index].formatEndingTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");

        setSpecialOrariArray[index].startingTimeSpan = moment
          .duration(setSpecialOrariArray[index].startingTime)
          .asSeconds();
        setSpecialOrariArray[index].endingTimeSpan = moment
          .duration(setSpecialOrariArray[index].endingTime)
          .asSeconds();
      }

      console.log(setSpecialOrariArray);
      var arrayOfSpecialPeriod = setSpecialOrariArray.slice(0, -1);

      if (arrayOfSpecialPeriod.length > 0) {
        var existePeriodo = arrayOfSpecialPeriod.filter(
          (res) =>
            res.dataInizioTimeSpan ===
            moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format(
              "X"
            )
        );

        console.log(existePeriodo);

        var resultFilterData = existePeriodo.filter(
          (result) =>
            moment.duration(item.endingTime).asSeconds() >=
              result.startingTimeSpan &&
            moment.duration(item.startingTime).asSeconds() <=
              result.endingTimeSpan
        );

        if (resultFilterData.length > 0) {
          return alert(
            "Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente"
          );
        } else {
          this.querySubscriptionProfileUser = this.apollo
            .use("super-admin")
            .mutate({
              mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
              variables: {
                data: {
                  startingDateTime: moment(
                    item.poiDerogatedPeriodData,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  endingDateTime: moment(
                    item.poiDerogatedPeriodData,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss"),
                  isClosed: item.isClosed,
                  pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                  derogatedOpeningPeriodId: item.derogatedOpeningPeriodId,
                  backOfficeData: 3,
                },
              },
            })
            .subscribe(
              (result: any) => {
                if (result.data["poiOpeningPeriodCreate"]["success"]) {
                  this.setImpostaOrarioAllDays(
                    result.data["poiOpeningPeriodCreate"]["id"],
                    item
                  );
                }
              },
              (err) => {
                alert("La deroga non è impostata correttamente, riprova");
              }
            );
        }
      } else {
        this.querySubscriptionProfileUser = this.apollo
          .use("super-admin")
          .mutate({
            mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
            variables: {
              data: {
                startingDateTime: moment(
                  item.poiDerogatedPeriodData,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                endingDateTime: moment(
                  item.poiDerogatedPeriodData,
                  "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss"),
                isClosed: item.isClosed,
                pointOfInterestId: parseInt(localStorage.getItem("poiID")),
                derogatedOpeningPeriodId: item.derogatedOpeningPeriodId,
                backOfficeData: 3,
              },
            },
          })
          .subscribe(
            (result: any) => {
              if (result.data["poiOpeningPeriodCreate"]["success"]) {
                this.setImpostaOrarioAllDays(
                  result.data["poiOpeningPeriodCreate"]["id"],
                  item
                );
              }
            },
            (err) => {
              alert("La deroga non è impostata correttamente, riprova");
            }
          );
      }
    } else {
      this.querySubscriptionProfileUser = this.apollo
        .use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
          variables: {
            data: {
              startingDateTime: moment(
                item.poiDerogatedPeriodData,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              endingDateTime: moment(
                item.poiDerogatedPeriodData,
                "YYYY-MM-DD HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss"),
              isClosed: item.isClosed,
              pointOfInterestId: parseInt(localStorage.getItem("poiID")),
              derogatedOpeningPeriodId: item.derogatedOpeningPeriodId,
              backOfficeData: 3,
            },
          },
        })
        .subscribe(
          (result: any) => {
            if (result.data["poiOpeningPeriodCreate"]["success"]) {
              this.setImpostaOrarioAllDays(
                result.data["poiOpeningPeriodCreate"]["id"],
                item
              );
            }
          },
          (err) => {
            alert("La deroga non è impostata correttamente, riprova");
          }
        );
    }
  }

  setSpecialsDaysAllWeekUpdate(item, setSpecialOrariArray) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
        variables: {
          data: {
            startingDateTime: moment(
              item.poiDerogatedPeriodData,
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            endingDateTime: moment(
              item.poiDerogatedPeriodData,
              "YYYY-MM-DD HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            id: item.id,
            isClosed: item.isClosed,
            backOfficeData: 3,
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodUpdate"]["success"]) {
            this.setImpostaOrarioAllDaysUpdate(
              item.openingDayId,
              item.id,
              item
            );
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );

    // if (setSpecialOrariArray.length > 0) {
    //   for (let index = 0; index < setSpecialOrariArray.length; index++) {

    //     setSpecialOrariArray[index].dataInizioTimeSpan = moment(setSpecialOrariArray[index].poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("X");
    //     setSpecialOrariArray[index].startingTime = moment(setSpecialOrariArray[index].formatStartingTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
    //     setSpecialOrariArray[index].endingTime = moment(setSpecialOrariArray[index].formatEndingTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm");

    //     setSpecialOrariArray[index].startingTimeSpan = moment.duration(setSpecialOrariArray[index].startingTime).asSeconds();
    //     setSpecialOrariArray[index].endingTimeSpan = moment.duration(setSpecialOrariArray[index].endingTime).asSeconds();
    //   }

    //   console.log(setSpecialOrariArray);
    //   var arrayOfSpecialPeriod = setSpecialOrariArray.slice(0, -1);

    //   if (arrayOfSpecialPeriod.length > 0) {

    //     var existePeriodo = arrayOfSpecialPeriod.filter(res => (res.dataInizioTimeSpan === moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("X")));

    //     console.log(existePeriodo);

    //     var resultFilterData = existePeriodo.filter(result => (moment.duration(item.endingTime).asSeconds() >= result.startingTimeSpan) && (moment.duration(item.startingTime).asSeconds() <= result.endingTimeSpan))

    //     if (resultFilterData.length > 0) {
    //       return alert("Hai già usato questo tipo di deroga per questo periodo, aggiungila alla precedente");

    //     }
    //     else {
    //       this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //         mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //         variables: {
    //           'data': {

    //             'startingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //             'endingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //             'id': item.id,
    //             'isClosed': item.isClosed,
    //             'derogatedOpeningPeriodId': item.derogatedOpeningPeriodId,
    //             'backOfficeData': 3
    //           }
    //         },
    //       }).subscribe((result: any) => {
    //         if (result.data['poiOpeningPeriodUpdate']['success']) {

    //           this.setImpostaOrarioAllDaysUpdate(item.openingDayId, item.id, item);
    //         }
    //       }, (err) => {
    //         alert("La deroga non è impostata correttamente, riprova");

    //       })
    //     }
    //   }
    //   else {
    //     this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //       mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //       variables: {
    //         'data': {

    //           'startingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //           'endingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //           'id': item.id,
    //           'isClosed': item.isClosed,
    //           'derogatedOpeningPeriodId': item.derogatedOpeningPeriodId,
    //           'backOfficeData': 3
    //         }
    //       },
    //     }).subscribe((result: any) => {
    //       if (result.data['poiOpeningPeriodUpdate']['success']) {

    //         this.setImpostaOrarioAllDaysUpdate(item.openingDayId, item.id, item);
    //       }
    //     }, (err) => {
    //       alert("La deroga non è impostata correttamente, riprova");

    //     })
    //   }
    // }
    // else {
    //   this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
    //     mutation: poiOpeningPeriodUpdate.poiOpeningPeriodUpdate,
    //     variables: {
    //       'data': {

    //         'startingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //         'endingDateTime': moment(item.poiDerogatedPeriodData, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
    //         'id': item.id,
    //         'isClosed': item.isClosed,
    //         'derogatedOpeningPeriodId': item.derogatedOpeningPeriodId,
    //         'backOfficeData': 3
    //       }
    //     },
    //   }).subscribe((result: any) => {
    //     if (result.data['poiOpeningPeriodUpdate']['success']) {

    //       this.setImpostaOrarioAllDaysUpdate(item.openingDayId, item.id, item);
    //     }
    //   }, (err) => {
    //     alert("La deroga non è impostata correttamente, riprova");

    //   })
    // }
  }
  setImpostaOrarioAllDays(openingPeriodId, item) {
    this.alwaysOpenDays = [];
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: this.alwaysOpenDays,
          notes: item.notes,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            item.showAfterCreateSpecialOrario = true;
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  setImpostaOrarioAllDaysUpdate(id, openingPeriodId, item) {
    this.alwaysOpenDays = [];
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayUpdate(
          item.startingTimeSpan,
          item.endingTimeSpan
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: openingPeriodId,
          daysOfWeek: this.alwaysOpenDays,
          notes: item.notes,
          openingDaySpecialNotes: [],
          id: id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayUpdate"]["success"]) {
            alert("Deroga impostata con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  //FIM  ORARI SPECIALE

  public myFilter = (d: Date): boolean => {
    // const day = d.toLocaleString();

    console.log(this.todayDate);
    const day = new Date(
      moment(d, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(d);
    // Prevent Saturday and Sunday from being selected.
    // return day !== this.todayDate;
    return true;
  };

  DeleteOrarioGiorno(array, itemData) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: itemData.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodDelete"]["success"]) {
            var dataIndex: any[] = array.findIndex(
              (item) => item.id === itemData.id
            );
            this.setSpecialHoursObj.splice(dataIndex, 1);
            alert("La deroga ha stato eliminata correttamente.");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  DeleteClosedPeriod(array, itemData) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: itemData.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodDelete"]["success"]) {
            var dataIndex: any[] = array.findIndex(
              (item) => item.id === itemData.id
            );
            this.setClosedPeriodObj.splice(dataIndex, 1);

            this.setClosedPeriodObj.push({
              poiDerogatedPeriodData: [],
              isClosed: false,
              derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId,
            });

            alert("La deroga ha stato eliminata correttamente.");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  DeleteOrariSpeciale(array, itemData) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: itemData.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodDelete"]["success"]) {
            var dataIndex: any[] = array.findIndex(
              (item) => item.id === itemData.id
            );
            this.setOrarioOfWeek.splice(dataIndex, 1);

            this.setOrarioOfWeek.push({
              notes: "",
              formatStartingTime: "",
              formatEndingTime: "",
              poiDerogatedPeriodData: [],
              isClosed: false,
              daysOfWeek: [],
              derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId,
            });

            alert("La deroga ha stato eliminata correttamente.");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  DeleteGiornoSpeciale(array, itemData) {
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: itemData.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodDelete"]["success"]) {
            var dataIndex: any[] = array.findIndex(
              (item) => item.id === itemData.id
            );
            this.setGiornoSpeciale.splice(dataIndex, 1);

            this.setGiornoSpeciale.push({
              poiDerogatedPeriodData: [],
              isClosed: false,
              derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId,
            });

            alert("La deroga ha stato eliminata correttamente.");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }

  // DeletePeriodoPrincipale(array, itemData) {

  //   this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
  //     mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
  //     variables: {
  //       'id': itemData.id
  //     },
  //   }).subscribe((result: any) => {
  //     if (result.data['poiOpeningPeriodDelete']['success']) {

  //       var dataIndex: any[] = array.findIndex(item => item.id === itemData.id);
  //       this.setGiornoSpeciale.splice(dataIndex, 1)

  //       this.setGiornoSpeciale.push({
  //         poiDerogatedPeriodData: [],
  //         isClosed: false,
  //         derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId
  //       });

  //       alert("La deroga ha stato eliminata correttamente.");
  //     }
  //   }, (err) => {
  //     alert("La deroga non è impostata correttamente, riprova");

  //   })
  // }

  setNewClosedPeriod(item, array) {
    array.push({
      poiDerogatedPeriodData: [],
      isClosed: false,
      derogatedOpeningPeriodId: item.derogatedOpeningPeriodId,
    });
  }
  setNewSpecialDays(itemData, array) {
    array.push({
      notes: "",
      formatStartingTime: "",
      formatEndingTime: "",
      poiDerogatedPeriodData: [],
      isClosed: false,
      daysOfWeek: [],
      derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId,
    });
  }

  setNewDerogeDaysOfWeek(itemData, array) {
    array.push({
      poiDerogatedPeriodData: [],
      isClosed: false,
      derogatedOpeningPeriodId: itemData.derogatedOpeningPeriodId,
    });
  }

  setNewImpostaOrarioGiorno(data, array) {
    console.log(data);

    var index = array.setSpecialHoursObj.findIndex((res) => res.id == data.id);

    var add_object = {
      name: data.name,
      id: data.id,
      startingTime: "",
      endingTime: "",
      poiDerogatedPeriodData: [],
      isClosed: data.isClosed,
      showAfterCreateOrarioGiorno: false,
      derogatedOpeningPeriodId: data.derogatedOpeningPeriodId,
    };
    array.splice(index + 1, 0, add_object);
  }

  // setImpostaOrarioAllDaysForYears(data: any[], item) {

  //   var tasks$ = [];
  //   this.dayOfWeeks = [];
  //   for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
  //     this.dayOfWeeks.push(this.daysOfWeekGetAll[index].name);
  //   }
  //   for (let index = 0; index < data.length; index++) {
  //     tasks$.push(this.apollo.use("super-admin").mutate({
  //       mutation: new poiOpeningDayCreate(item.startingTimeSpan, item.endingTimeSpan).getQuery(),
  //       variables: {
  //         'poiOpeningPeriodId': data[index].id,
  //         'daysOfWeek': this.dayOfWeeks,
  //         'notes': null,
  //         'openingDaySpecialNotes': []
  //       },
  //     }))

  //   }
  //   return forkJoin(tasks$).subscribe((result) => {

  //     item.showAfterCreateSpecialOrario = true;
  //     for (let index = 0; index < result.length; index++) {
  //       if (result[index].data['poiOpeningDayCreate']['success']) {
  //         console.log(result[index].data['poiOpeningDayCreate']['id']);
  //       }
  //     }
  //   })

  // }


  AggiungiNuovoPeriodo() {
    this.periodsOfPoi.push(this.nuovoPeriodsOfPoi());
  }
  //TODO
  nuovoPeriodsOfPoi() {
    return {
         DataInizio: [],
         notValidAfterCreate: false,
         setOrarioSpecialeObj: [{
           notesSpecialHour: "",
           startingTimeSpecialHour: "",
           endingTimeSpecialHour: "",
           dataInizioSpecialHour: [],
           closedPeriodSpecialHour: false,
           showAfterCreateSpecialOrario: false,
           notValidAfterCreateOrario: false
  
         }],
         setGiornoSpecialeObj: [{
           DataInizioOrarioGiornoSpeciale: [],
           closedPeriodGiornoSpeciale: false,
           notValidAfterCreateSpecialGiorno: false
         }],
         dataInizioSpecialHour: "",
         DataInizioSetGiornoSpeciale: [],
         startingTimeSpecialHour: new Date,
         endingTimeSpecialHour: new Date,
         startingTime: "",
         endingTime: "",
         notes: "",
         showAfterCreatePeriod: false,
         showOpeningDayBtn: false,
         showIsClosedPeriod: false,
         showSpecialHours: false,
         showIsDaysWeeks: false,
         showSetHoursOfWeek: false,
         closeAllDerogatedPeriod: false,
         closedPeriod: false,
         closedPeriodSpecialHour: false,
         closedPeriodGiornoSpeciale: false,
         notesSpecialHour: "",
         setSpecialHoursObj: [{
           name: "",
           id: "",
           startingTime: "",
           endingTime: "",
           DataInizioOrarioGiornoSet: [],
           isClosed: "",
           showAfterCreateOrarioGiorno: false,
           notValidAfterCreateSetttimanaOrari: false
         }],
  
         setPeriodSpecialObj: [{
           DataInizioSpecialPeriod: [],
           isClosedInPeriod: false,
           showAfterCreateSpecialPeriod: false,
           showIsClosedPeriod: false,
           notValidAfterCreateSpecialPeriod: false
         }],
         setNewOpenindDayObj: [{
           startingTime: "",
           endingTime: "",
           notes: "",
           selectedDaysOfWeek: [],
           showAfterConfirm: false,
           isOrarioExtraShow: false,
           title: "Orario di apertura di base"
         }],
  
       };
  }

  async poiCreateNewOpeningPeriod(item, index) {
    this.DataInizio = [];
    item.selectedDaysOfWeek = [];
    item.closeAllDerogatedPeriod = false;
    item.showOpeningDayBtn = false;
    item.showAfterCreatePeriod = false;
    item.showIsClosedPeriod = false;
    item.showSpecialHours = false;
    item.showIsDaysWeeks = false;
    item.notValidAfterCreate = true;
    item.showSetHoursOfWeek = false;
    /*if (item.setNewOpenindDayObj === undefined) {
      item.setNewOpenindDayObj = [{
           startingTime: "",
           endingTime: "",
           notes: "",
           selectedDaysOfWeek: [],
           showAfterConfirm: false,
           isOrarioExtraShow: false,
           title: "Orario di apertura di base",
           daysOfWeek: []
         }];
    }*/
    if (item.poiOpeningDayGetAllSub === undefined) {
      item.poiOpeningDayGetAllSub = [];
    }
    //item.setNewOpenindDayObj[0].selectedDaysOfWeek = [];
    item.poiOpeningDayGetAllSub.push({
      startingTime: "",
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      isOrarioExtraShow: false,
      title: "Orario di apertura di base",
      daysOfWeek: []
    });

    // this.periodsOfPoi.push({
    //   DataInizio: [],
    //   notValidAfterCreate: false,
    //   setOrarioSpecialeObj: [{
    //     notesSpecialHour: "",
    //     startingTimeSpecialHour: "",
    //     endingTimeSpecialHour: "",
    //     dataInizioSpecialHour: [],
    //     closedPeriodSpecialHour: false,
    //     showAfterCreateSpecialOrario: false,
    //     notValidAfterCreateOrario: false

    //   }],
    //   setGiornoSpecialeObj: [{
    //     DataInizioOrarioGiornoSpeciale: [],
    //     closedPeriodGiornoSpeciale: false,
    //     notValidAfterCreateSpecialGiorno: false
    //   }],
    //   dataInizioSpecialHour: "",
    //   DataInizioSetGiornoSpeciale: [],
    //   startingTimeSpecialHour: new Date,
    //   endingTimeSpecialHour: new Date,
    //   startingTime: "",
    //   endingTime: "",
    //   notes: "",
    //   showAfterCreatePeriod: false,
    //   showOpeningDayBtn: false,
    //   showIsClosedPeriod: false,
    //   showSpecialHours: false,
    //   showIsDaysWeeks: false,
    //   showSetHoursOfWeek: false,
    //   closeAllDerogatedPeriod: false,
    //   closedPeriod: false,
    //   closedPeriodSpecialHour: false,
    //   closedPeriodGiornoSpeciale: false,
    //   notesSpecialHour: "",
    //   setSpecialHoursObj: [{
    //     name: "",
    //     id: "",
    //     startingTime: "",
    //     endingTime: "",
    //     DataInizioOrarioGiornoSet: [],
    //     isClosed: "",
    //     showAfterCreateOrarioGiorno: false,
    //     notValidAfterCreateSetttimanaOrari: false
    //   }],

    //   setPeriodSpecialObj: [{
    //     DataInizioSpecialPeriod: [],
    //     isClosedInPeriod: false,
    //     showAfterCreateSpecialPeriod: false,
    //     showIsClosedPeriod: false,
    //     notValidAfterCreateSpecialPeriod: false
    //   }],
    //   setNewOpenindDayObj: [{
    //     startingTime: "",
    //     endingTime: "",
    //     notes: "",
    //     selectedDaysOfWeek: [],
    //     showAfterConfirm: false,
    //     isOrarioExtraShow: false,
    //     title: "Orario di apertura di base"
    //   }]

    // })

    this.validityGetAll = [];
    await this.getValidity();
    /*this.todayDate = new Date(
      moment(item.DataInizio[1], "YYYY-MM-DD HH:mm:ss")
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss")
    );*/
  }

  poiCreateNewClosedPeriod(data, item) {
    console.log(data);

    data.showIsClosedPeriod = true;
    data.showAfterCreateSpecialPeriod = false;
    data.notValidAfterCreateSpecialPeriod = true;
    item.setPeriodSpecialObj.push({
      DataInizioSpecialPeriod: [],
      isClosedInPeriod: false,
      showAfterCreateSpecialPeriod: false,
      showIsClosedPeriod: true,
      notValidAfterCreateSpecialPeriod: false,
    });
    console.log(data);
  }

  closeOldPeriod(data) {
    data.showIsClosedPeriod = false;
  }

  poiAddNewOpeningDay(item, data) {
    console.log(item);
    item.showAfterCreatePeriod = true;
    item.setNewOpenindDayObj.push({
      startingTime: "",
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      isOrarioExtraShow: false,
      title: "Orario di apertura di base",
    });
  }
  poiAddNewOpeningAfternoon(item, i, a) {
    console.log(item);
    console.log(i);
    console.log(a);
    console.log("ITEM from cretePrincipalPeriod", item);
    item.setNewOpenindDayObj.push({
      startingTime: "",
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      title: "Orario di apertura pomeridiana di base",
      isOrarioExtraShow: true,
    });
  }
  poiAddNewFasciaOraria(item) {
    item.setNewOpenindDayObj.push({
      startingTime: "",
      endingTime: "",
      notes: "",
      selectedDaysOfWeek: [],
      showAfterConfirm: false,
      title: "Fascia oraria extra di base",
      isOrarioExtraShow: true,
    });
  }
  showBtnAfterConfirm(res) {
    res.showAfterConfirm = true;
  }

  //TODO
  async cancellaTuttiIPeriodi() {
    for (var index=0; index<this.modelToMapping.poiOpeningPeriodGetAllSub.length; index++) {
      var id = this.modelToMapping.poiOpeningPeriodGetAllSub[index].id;
      var check = isNaN(id) || id === "" || id === " " || id === null || id === "null" || id === undefined;
      console.log("dentro, cancello", id, index, check, this.modelToMapping.poiOpeningPeriodGetAllSub[index])
      if (check) {
        console.log(`dentro, volevo cancellare ${id}, ma è vuoto`)
        continue;
      }
      this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: id,
        },
      })
      .subscribe(
        (result: any) => {
          var data = result.data.poiOpeningPeriodDelete
          if (data.success) {
            console.log("ho cancellato periodsOfPoi ", result)
            var dataIndex: any = this.modelToMapping.poiOpeningPeriodGetAllSub.findIndex(
              (item) => item.id === data.id
            );
            this.periodsOfPoi.splice(dataIndex, 1);
          } else {
            alert(`Errore nella cancellazione di periodsOfPoi ID=${data.id}`)
          }
      }), (err) => {
        console.log(`Errore nella cancellazione di periodsOfPoi=${id}`);
        alert(`Errore nella cancellazione di periodsOfPoi ID=${id}`)
      };
    }
  }

  //TODO
  async statusChange(event) {
    console.log('statusChange')
    console.log('statusChange', moment().endOf("year").add("20", "years").format("YYYY-MM-DD"));
    console.log('statusChange', 
      moment().startOf("year").add("20", "years").format("YYYY-MM-DD")
    );

    //this.periodsOfPoi

    if (event && event.value !== undefined && event.value !== null) {
      this.selectedStatusId = event.value.id;
      console.log("statusChange dentro if", event.value.id)

      //this.cancellaTuttiIPeriodi()
      let ids = this.modelToMapping.poiOpeningPeriodGetAllSub.map(item=>item.id)
      
        console.log("statusChange dentro if ids", event.value.id, ids)
      this.apollo.use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDeleteMany.poiOpeningPeriodDeleteMany,
        variables: {
          ids: ids,
        },
      })
      .subscribe(
        (result: any) => {
          this.modelToMapping.poiOpeningPeriodGetAllSub = [];
          this.periodi = [];
          this.periodi_original = [];
          switch (parseInt(event.value.id)) {
            case 1:
              break;

            case 2:
              this.setIsAlwaysOpen();
              break;

            case 3:
              //this.periodsOfPoi = [this.nuovoPeriodsOfPoi()]
              this.getValidity();
              this.aggiungiPeriodo();
              break;
          }
        });
    } else {
      console.log("statusChange.dentro else", parseInt(event))
      this.selectedStatusId = event;
      switch (parseInt(event)) {
        case 1:
          break;

        case 2:
          // await this.setIsAlwaysOpen();
          break;

        case 3:
          await this.getValidity();
          break;
      }
    }
  }
  setIsAlwaysOpen() {
    let data = {
      startingDateTime: moment()
        .startOf("year")
        .format("YYYY-MM-DD HH:mm:ss"),
      endingDateTime: moment()
        .endOf("year")
        .add("20", "years")
        .format("YYYY-MM-DD HH:mm:ss"),
      pointOfInterestId: parseInt(localStorage.getItem("poiID")),
      backOfficeData: 0,
    };
    console.log('setIsAlwaysOpen', data)
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodCreate.poiOpeningPeriodCreate,
        variables: {
          data: data,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodCreate"]["success"]) {
            //TODO
            console.log("setIsAlwaysOpen", result, result.data.poiOpeningPeriodCreate.id)
            this.ricaricaPeriodi();
            this.setIsAlwaysOpeningDayForCreate(result.data["poiOpeningPeriodCreate"]["id"]);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
        }
      );
  }

  //TODO
  ricaricaPeriodi() {
    this.apollo.watchQuery<Response>({
      query: poiOpeningPeriodGetAllSub.pointOfInterestGet,
      //query: poiOpeningPeriodGet.poiOpeningPeriodGet,
      fetchPolicy: "no-cache",
      variables: {
        //id: result.data.poiOpeningPeriodCreate.id
        id: this.modelToMapping.id
      },
    }).valueChanges.subscribe(
      (data: any) => {
        console.log('setIsAlwaysOpen.valueChanges', data.data.pointOfInterestGet.poiOpeningPeriodGetAllSub)
        this.modelToMapping = data.data.pointOfInterestGet;
        this.model = data.data.pointOfInterestGet;
        this.periodsOfPoi = data.data.pointOfInterestGet.poiOpeningPeriodGetAllSub;
        this.mappingOrariObject();

        this.periodi = []
        this.orariDB2Page(this.model.poiOpeningPeriodGetAllSub);
      });
  }
  setIsAlwaysOpeningDayForCreate(idOpeningPeriod) {
    this.alwaysOpenDays = [];
    var startTimer = moment().startOf("year").format("HH:mm:ss");
    var endingTime = moment()
      .startOf("year")
      .subtract("1", "second")
      .format("HH:mm:ss");

    console.log(startTimer);
    console.log(endingTime);
    for (let index = 0; index < this.daysOfWeekGetAll.length; index++) {
      this.alwaysOpenDays.push(this.daysOfWeekGetAll[index].name);
      console.log(this.alwaysOpenDays);
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: new poiOpeningDayCreate(
          moment.duration(startTimer).asSeconds(),
          moment.duration(endingTime).asSeconds()
        ).getQuery(),
        variables: {
          poiOpeningPeriodId: idOpeningPeriod,
          daysOfWeek: this.alwaysOpenDays,
          notes: null,
          openingDaySpecialNotes: [],
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningDayCreate"]["success"]) {
            alert("Periodo  impostato con successo");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
          console.log("errore nella creazione setIsAlwaysOpeningDayForCreate", err)
        }
      );
  }

  DeletePrincipalPeriod(data, periodsOfPoi) {
    console.log('DeletePrincipalPeriod', data, data.id)
    if (data.id === undefined) {
      var dataIndex: any = periodsOfPoi.findIndex(
        (item) => item.id === undefined
      );
      this.periodsOfPoi.splice(dataIndex, 1);
      if (this.periodsOfPoi.length == 0)
        this.periodsOfPoi.push(this.nuovoPeriodsOfPoi());
      return;
    }
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
        variables: {
          id: data.id,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiOpeningPeriodDelete"]["success"]) {
            var dataIndex: any = periodsOfPoi.findIndex(
              (item) => item.id === data.id
            );
            this.periodsOfPoi.splice(dataIndex, 1);
            if (this.periodsOfPoi.length == 0)
              this.periodsOfPoi.push(this.nuovoPeriodsOfPoi());
            alert("La deroga ha stato eliminata correttamente.");
          }
        },
        (err) => {
          alert("La deroga non è impostata correttamente, riprova");
        }
      );
  }
  // closeAllDerogatedPeriod(item, index) {
  //   this.periodsOfPoi[index].closeAllDerogatedPeriod = true;
  // }

  Date2String(date) {
    return new Date(date).toISOString().split('T')[0]
  }

  DateAddYear(date) {
    return date.setFullYear(date.getFullYear()+1)
  }

  duplicaPeriodo(periodo) {

    if (!this.controntaSingoloPeriodo(periodo.id)) {
      alert("ATTENZIONE!!! Devi prima salvare il periodo corrente!");
      return;
    }
    /*
    console.log("data", periodo)
    var nuovo = this.nuovoPeriodo();
    var DateRange = []
    periodo.DateRange.forEach(element => {
      console.log("data.DateRange", element)
      DateRange.push(element)
    })
    nuovo.DateRange = DateRange

    console.log("data", nuovo)
    */

    //this.salvaPeriodo(periodo)
    var index = 0;
    var i = 1;
    console.log("duplicaPeriodo.periodo_esistente", periodo)
    var json = JSON.stringify(periodo)
    console.log("duplicaPeriodo.json", json)
    var nuovo = JSON.parse(json)
    console.log("duplicaPeriodo.nuovo.pre_null", nuovo)
    nuovo.id = undefined
    nuovo._time = "-" + new Date().getTime() + "-" + index++
    var DateRange = []
//TODo


    nuovo.DateRange.forEach(element => {
      var newDate = this.DateAddYear(new Date(element));
      console.log('element', newDate)
      DateRange.push(this.Date2String(newDate));
    });
    nuovo.DateRange = DateRange
    nuovo.TimeRange.forEach(element => {
      element.id = undefined
      element._time = "-" + new Date().getTime() + "-" + index++
    });
    nuovo.Exceptions.forEach(element => {
      element.id = undefined
      element.idTimeRange = undefined
      element._time = "-" + new Date().getTime() + "-" + index++
      var Days = []
      element.Days.forEach(element1 => {
        Days.push(element1)
      })
      element.Days = Days
      var DateRange_Exception = []
      element.DateRange.forEach(element1 => {
        var newDate = this.DateAddYear(new Date(element1));
        DateRange_Exception.push(this.Date2String(newDate))
        console.log("element1", element.id, element1, DateRange_Exception);
      });
      element.DateRange = DateRange_Exception
      if (element.Date !== undefined) {
        var newDate = this.DateAddYear(new Date(element.Date));
        element.Date = this.Date2String(newDate)
      }
    });
    console.log("duplicaPeriodo.nuovo", nuovo)
    this.periodi.splice(0, 0, nuovo);

    this.salvaPeriodo(nuovo)
  }


  setFromDateSelected(index_periodo, index_orario, oggetto) {
    this.periodi[index_periodo].TimeRange[index_orario].TimeRange = []
    oggetto.selecteds.forEach(element => {
      this.periodi[index_periodo].TimeRange[index_orario].TimeRange.push(element)
    });
  }

  setExceptionsTimeSelected(index_periodo, index_exceptions, oggetto) {
    this.periodi[index_periodo].Exceptions[index_exceptions].TimeRange = []
    oggetto.selecteds.forEach(element => {
      this.periodi[index_periodo].Exceptions[index_exceptions].TimeRange.push(element)
    });
  }


  controntaSingoloPeriodo(id) {
    
    var preso = this.periodi.filter(item => item.id === id)
    var original = this.periodi_original.filter(item => item.id === id)
    console.log("controntaSingoloPeriodo", preso, original)
    if (preso.length === 0) {
      return false;
    }
    return JSON.stringify(preso) === JSON.stringify(original)
  }

  controntaPeriodi() {
    console.log("confrontaPeriodi", JSON.stringify(this.periodi) === JSON.stringify(this.periodi_original))
    console.log("confrontaPeriodi", JSON.stringify(this.periodi))
    console.log("confrontaPeriodi", JSON.stringify(this.periodi_original))
    return JSON.stringify(this.periodi) === JSON.stringify(this.periodi_original)
  }
  _controntaPeriodi() {
    console.log("controntaPeriodi")
    let data = this.periodi
    let data1 = this.periodi_original
    if (data === undefined || data1 === undefined) {
      console.log("controntaPeriodi", "periodi", data, data1)
      return false;
    }
    let dim = data.length;
    let dim1 = data1.length;
    if (dim !== dim1){
      console.log("controntaPeriodi", `length: ${dim}, ${dim1}`)
      return false;
    }
    let empty = data.filter(obj => obj['id'] === undefined)
    let empty1 = data1.filter(obj => obj['id'] === undefined)
    if (empty.length > 0 || empty1.length > 0){
      console.log("controntaPeriodi", "empty", empty, empty1)
      return false;
    }
    let ids = data.map(obj => obj['id'])
    let ids1 = data1.map(obj => obj['id'])
    ids.sort(function(a, b) {
      return a - b;
    });
    ids1.sort(function(a, b) {
      return a - b;
    });
    for (var i=0; i<dim; i++) {
      if (ids[i] !== ids1[1]){
        console.log("controntaPeriodi", `ids non conguenti con indice=${i}`, ids[i], ids1[i])
        return false
      }
      var id = ids[i];
      var periodo = data.filter(obj => obj['id'] === id)[0]
      var periodo1 = data1.filter(obj => obj['id'] === id)[0]
      /*DateRange: [],
      TimeRange: [],
      Exceptions*/

      if (periodo['DateRange'][0] !== periodo1['DateRange'][0] ||
        periodo['DateRange'][1] !== periodo1['DateRange'][1]) {
        console.log("controntaPeriodi", "DateRange inconguenti", 
          periodo['DateRange'][0] === periodo1['DateRange'][0],
          periodo['DateRange'][1] === periodo1['DateRange'][1])
        return false;
      }

      if (periodo['TimeRange'] === undefined)
        periodo['TimeRange'] = []
      if (periodo1['TimeRange'] === undefined)
        periodo1['TimeRange'] = []

      let dim_t = periodo['TimeRange'].length;
      let dim1_t = periodo1['TimeRange'].length;
      if (dim_t !== dim1_t){
        console.log(`controntaPeriodi.id=${id}.TimeRange`, `length: ${dim_t}, ${dim1_t}`)
        return false;
      }
      let ids_t = periodo['TimeRange'].map(obj => obj['id'])
      let ids1_t= periodo1['TimeRange'].map(obj => obj['id'])
      ids_t.sort(function(a, b) {
        return a - b;
      });
      ids1_t.sort(function(a, b) {
        return a - b;
      });
      for (var i=0; i<dim_t; i++) {
        if (ids_t[i] !== ids1_t[1]){
          console.log(`controntaPeriodi.id=${id}.TimeRange`, `ids_t non conguenti con indice=${i}`, ids_t[i], ids1_t[i])
          return false
        }
      }
    }
    return true;
  }

  async orariDB2Page(data) {
    console.log('orariDB2Page', data);
    if (data.length == 0) {
      this.aggiungiPeriodo();
      return;
    }

    console.log("data", data)
    data.sort(function(a, b){
      return new Date(moment(b.endingDateTime).toDate()).getTime() - new Date(moment(a.endingDateTime).toDate()).getTime();
    });

    console.log("data", data)

    for (let periodo of data) {
      this.creaPeriodo(periodo);
    }

    this.periodi_original = JSON.parse(JSON.stringify(this.periodi));
    console.log("CONTRONTO", JSON.stringify(this.periodi) === JSON.stringify(this.periodi_original))
  }

  _SecondsFromDate(date) {
    let d = new Date(date);
    console.log("_SecondsFromDate.before", d)
    d = moment(d).add(-1, "h").toDate();
    console.log("_SecondsFromDate.after", d)
    let seconds = d.getHours() * 3600 + d.getMinutes() * 60;
    return seconds
  }
  _SecondsFromDate_standard(time) {
    var d = new Date();
    d.setHours(parseInt(time.split(":")[0]))
    d.setMinutes(parseInt(time.split(":")[1]))
    d.setSeconds(0)
    console.log("_SecondsFromDate.before", d)
    d = moment(d).add(-1, "h").toDate();
    console.log("_SecondsFromDate.after", d)
    let seconds = d.getHours() * 3600 + d.getMinutes() * 60;
    return seconds
  }
  _DateFromSeconds(seconds) {
    if (seconds === undefined || seconds == "")
      return undefined;
    var h = Math.floor(seconds / 3600);
    var m =  Math.floor((seconds - (h * 3600)) / 60);
    var d = new Date(2020, 1, 1, h, m)
    console.log("_DateFromSeconds.before", d)
    d = moment(d).add(1, "h").toDate();
    console.log("_DateFromSeconds.after", d)
    return d
  }
  _DateFromSeconds_standard(seconds) {
    if (seconds === undefined || seconds == "")
      return undefined;
    var h = Math.floor(seconds / 3600);
    var m =  Math.floor((seconds - (h * 3600)) / 60);
    var d = new Date(2020, 1, 1, h, m)
    console.log("_DateFromSeconds.before", d)
    d = moment(d).add(1, "h").toDate();
    console.log("_DateFromSeconds.after", d)
    return moment(d).format("HH:mm")
  }

  //nuovo è undefined quando sto creando la prima volta il periodo
  ripristinaPeriodo(nuovo, periodo=undefined) {
    console.log("ripristinaPeriodo", nuovo, periodo);
    let ripristinato = undefined;
    if (periodo === undefined) {
      this.ricaricaPeriodi();
      /*
      console.log("ripristinaPeriodo.model", this.modelToMapping.poiOpeningPeriodGetAllSub)
      ripristinato = this.modelToMapping.poiOpeningPeriodGetAllSub.filter(item => {return item.id===nuovo.id});
      if (ripristinato.length > 0)
        periodo = ripristinato[0];
      */
    }
    console.log("ripristinaPeriodo", nuovo, periodo);
    if (periodo !== undefined) {
      nuovo.id = periodo.id
      //per input standard
      //nuovo.DateRange = [new Date(Date.parse(periodo.startingDateTime)), new Date(Date.parse(periodo.endingDateTime))]
      nuovo.DateRange = [new Date(Date.parse(periodo.startingDateTime)).toISOString().split('T')[0], 
                          new Date(Date.parse(periodo.endingDateTime)).toISOString().split('T')[0]]
      nuovo.TimeRange = [];
      nuovo.Exceptions = [];
      nuovo.isClosed = periodo.isClosed
      for (let orario of periodo.poiOpeningDayGetAllSub) {
        let nuovo_orario = this.nuovoOrario(orario.id);
        nuovo_orario.notes = orario.notes;
        //per input standard
        //nuovo_orario.TimeRange=[this._DateFromSeconds(orario.startingTime), this._DateFromSeconds(orario.endingTime)];
        nuovo_orario.TimeRange=[this._DateFromSeconds_standard(orario.startingTime), 
          this._DateFromSeconds_standard(orario.endingTime)];
        nuovo_orario.Days = [];
        if (orario.daysOfWeek.length > 0) {
          nuovo_orario.showDays = true;
          for (let giorno of orario.daysOfWeek) {
            nuovo_orario.Days.push(...this.daysOfWeekGetAll.filter(item=>{return item.name===giorno}));
          }
        }
        nuovo.TimeRange.push(nuovo_orario);
      }
      //TODO DEROGHE
      for (let deroga of periodo.derogatingOpeningPeriodsGetAll) {
        console.log(`ripristinaPeriodo.deroga=${deroga.id}`, deroga);

        let tipo = deroga.backOfficeData;
        let nuovaDeroga = this.nuovaDeroga(deroga.id);
        nuovaDeroga.type=tipo;
        nuovaDeroga.typeName=this.validityGetAll.filter(item=> {return parseInt(tipo)===parseInt(item.id);})[0].name;
        nuovaDeroga.isClosed = deroga.isClosed;
        
        //per input standard
        //nuovaDeroga.DateRange = [new Date(Date.parse(deroga.startingDateTime)), new Date(Date.parse(deroga.endingDateTime))]
        //nuovaDeroga.Date = new Date(Date.parse(deroga.startingDateTime));
        nuovaDeroga.DateRange = [
          new Date(Date.parse(deroga.startingDateTime)).toISOString().split('T')[0], 
          new Date(Date.parse(deroga.endingDateTime)).toISOString().split('T')[0]
        ]
        nuovaDeroga.Date = new Date(Date.parse(deroga.startingDateTime)).toISOString().split('T')[0];
        for (let orario of deroga.poiOpeningDayGetAllSub) {
          nuovaDeroga.Days = [];
          if (orario.daysOfWeek.length > 0) {
            nuovaDeroga.showDays = true;
            for (let giorno of orario.daysOfWeek) {
              nuovaDeroga.Days.push(...this.daysOfWeekGetAll.filter(item=>{return item.name===giorno}));
            }
            nuovaDeroga.selectedDay = nuovaDeroga.Days[0].id;
            console.log("giorni.inseriti", nuovaDeroga.Days)
          }
          console.log("ripristinaPeriodo.deroga.time", deroga.formatStartingTime, Date.parse(deroga.formatStartingTime), new Date(Date.parse(deroga.formatStartingTime)));
          nuovaDeroga.idTimeRange = orario.id;
          //per input standard
          //nuovaDeroga.TimeRange = [this._DateFromSeconds(orario.startingTime), this._DateFromSeconds(orario.endingTime)];
          nuovaDeroga.TimeRange = [this._DateFromSeconds_standard(orario.startingTime), 
            this._DateFromSeconds_standard(orario.endingTime)];
          nuovaDeroga.notes = orario.notes;
        }
        console.log(`ripristinaPeriodo.deroga=${deroga.id}`, nuovaDeroga);
        nuovo.Exceptions.push(nuovaDeroga);
      }
    } else {
      nuovo.DateRange = [];
      nuovo.TimeRange = [];
    }
    console.log("rispristinaPeriodi", nuovo)
  }
  async creaPeriodo(periodo) {
    console.log("periodo.creaPeriodo", periodo, this.daysOfWeekGetAll);
    let nuovo = this.nuovoPeriodo(periodo.id);
    nuovo.json = JSON.stringify(periodo, undefined, 5);
    this.ripristinaPeriodo(nuovo, periodo);
    this.periodi.push(nuovo);
    console.log(`periodo.creaPeriodo.nuovoPeriodo._time=${nuovo._time}`);
  }

  salvaPeriodo(item) {
    console.log("periodo.salvaPeriodo.periodo", item)
    let mutation = undefined;
    let data = {
      startingDateTime: moment(item.DateRange[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      endingDateTime: moment(item.DateRange[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      isClosed: item.isClosed,
      backOfficeData: 0,
    }
    if (item.id === undefined) {
      data['pointOfInterestId'] = parseInt(localStorage.getItem("poiID"));
      mutation = poiOpeningPeriodCreate.poiOpeningPeriodCreate;
    } else {
      data['id'] = item.id
      mutation = poiOpeningPeriodUpdate.poiOpeningPeriodUpdate;
    }
    console.log('periodo.salvaPeriodo', data)
    this.querySubscriptionProfileUser = this.apollo.use("super-admin")
      .mutate({
        mutation: mutation,
        variables: {
          data: data,
        },
      })
      .subscribe(
        (result: any) => {
          console.log("periodo.salvaPeriodo.subscribe", result);
          if (item.id === undefined) {
            item.id = parseInt(result.data['poiOpeningPeriodCreate'].id)
            alert('Periodo creato con successo');
          } else {
            alert('Periodo modificato con successo');
          }

          let index = this.periodi_original.map(function(e) { return e.id; }).indexOf(item.id);
          let index1 = this.periodi.map(function(e) { return e.id; }).indexOf(item.id);
          console.log(`periodi_original.trovati index periodi=${index1} this.periodi_original=${index}`)
          if (index<0) {
            this.periodi_original.push(JSON.parse(JSON.stringify(this.periodi[index1])))
          } else {
            this.periodi_original[index]=(JSON.parse(JSON.stringify(this.periodi[index1])))
          }
          console.log(`periodi_original`, JSON.stringify(this.periodi))
          console.log(`periodi_original`, JSON.stringify(this.periodi_original))
          console.log(`CONFRONTO`, JSON.stringify(this.periodi) === JSON.stringify(this.periodi_original))
          
          //salvo gli orari
          for (let orario of item.TimeRange) {
            this.salvaOrario(orario, item);
          }
          //salvo le deroghe
          for (let deroga of item.Exceptions) {
            this.salvaDeroga(deroga, item);
          }
        },
        (err) => { 
          console.log("periodo.salvaPeriodo.err", err);
          alert("Errore nella creazione del periodo principale");
        });
  }
  salvaPeriodoOgniAnno(item) {
    console.log("periodo.salvaPeriodoOgniAnno.periodi=", this.periodi, "item=", item);
    alert("non ancora implementato")
  }
  aggiungiPeriodo() {
    console.log("periodo.aggiungiPeriodo");
    let nuovo = this.nuovoPeriodo();
    this.periodi.splice(0, 0, nuovo);
    //this.periodi.push(nuovo);
    console.log(`periodo.aggiungiPeriodo.nuovoPeriodo._time=${nuovo._time}`);
  }
  cancellaPeriodo(item) {
    console.log(`periodo.cancellaPeriodo=${item._time}`, item);
    if (item.id === undefined) {
      this.periodi = this.periodi.filter(res => res._time !== item._time)
      if (this.periodi.length == 0)
        this.aggiungiPeriodo();
    } else {
      console.log("periodo.cancellaPeriodo cancello dal db");
      this.apollo.use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
          variables: {
            id: item.id
          },
        })
      .subscribe(
        (result: any) => {
          alert('Cancellazione effettuata con successo');
          this.periodi = this.periodi.filter(res => res._time !== item._time)
          this.periodi_original = this.periodi_original.filter(res => res._time === item._time);
          if (this.periodi.length == 0)
            this.aggiungiPeriodo();
        },
        (err) => {
          console.log(err);
          alert("An error occurred.Try again later.");
        });
    }
  }
  salvaOrario(item, periodo) {
    console.log(`periodo.salvaPeriodo.salvaOrario=${item.id}`, item);
    let mutation = undefined;
    /*let s = new Date(item.TimeRange[0]);
    let e = new Date(item.TimeRange[1]);
    let startTimer = s.getHours() * 3600 + s.getMinutes() * 60;
    let endingTime = e.getHours() * 3600 + e.getMinutes() * 60;*/

    //per input standard
    //let startTimer = this._SecondsFromDate(item.TimeRange[0])
    //let endingTime = this._SecondsFromDate(item.TimeRange[1])
    let startTimer = this._SecondsFromDate_standard(item.TimeRange[0])
    let endingTime = this._SecondsFromDate_standard(item.TimeRange[1])
    console.log(`periodo.salvaPeriodo.salvaOrario=${item.id},time`, startTimer, endingTime);
    var daysOfWeek = [];
    if (item.Days.length == 0)
      daysOfWeek.push(...this.daysOfWeekGetAll);
    else {
      daysOfWeek.push(...item.Days);
    }
    let data = {
      poiOpeningPeriodId: periodo.id,
      daysOfWeek: daysOfWeek.map(res => res.name),
      notes: item.notes,
      openingDaySpecialNotes: [],
    }
    if (item.id === undefined) {
      data['pointOfInterestId'] = parseInt(localStorage.getItem("poiID"));
      mutation = new poiOpeningDayCreate(startTimer, endingTime).getQuery();
    } else {
      data['id'] = item.id
      mutation = new poiOpeningDayUpdate(startTimer, endingTime).getQuery();
    }
    console.log('periodo.salvaPeriodo.salvaOrario', data)
    this.querySubscriptionProfileUser = this.apollo.use("super-admin")
      .mutate({
        mutation: mutation,
        variables: data,
      })
      .subscribe(
        (result: any) => {
          if (item.id === undefined) {
            alert('Orario impostato con successo')
            item.id = result.data["poiOpeningDayCreate"]["id"];
          } else {
            alert('Orario impostato con successo')
          }

          let index = this.periodi_original.map(function(e) { return e.id; }).indexOf(periodo.id);
          let index1 = this.periodi.map(function(e) { return e.id; }).indexOf(periodo.id);
          console.log(`periodi_original.trovati index periodi=${index1} this.periodi_original=${index}`)
          if (index<0) {
            this.periodi_original.push(JSON.parse(JSON.stringify(this.periodi[index1])))
          } else {
            this.periodi_original[index]=(JSON.parse(JSON.stringify(this.periodi[index1])))
          }
          console.log('CONFRONTO', JSON.stringify(this.periodi_original) === JSON.stringify(this.periodi))
        },
        (err) => {
          console.log("periodo.salvaPeriodo.salvaOrario.err", err);
          alert("Errore nella creazione del periodo principale");
        }
      );
  }
  aggiungiOrario(item) {
    console.log(`orario.aggiungiOrario`);
    let nuovo = this.nuovoOrario();
    item.TimeRange.splice(0, 0, nuovo);
    //item.TimeRange.push(nuovo);
    console.log(`orario.aggiungiOrario.nuovoOrario=${nuovo._time}`, nuovo);
  }
  cancellaOrario(item, periodo) {
    console.log(`orario.cancellaOrario=${item._time}`, item, periodo);
    if (item.id === undefined) {
      periodo['TimeRange'] = periodo['TimeRange'].filter(res => res._time !== item._time)
    } else {
      console.log("orario.cancellaOrario cancello dal db");
      this.apollo.use("super-admin")
        .mutate({
          mutation: poiOpeningDayDelete.poiOpeningDayDelete,
          variables: {
            id: item.id
          },
        })
      .subscribe(
        (result: any) => {
          alert('Cancellazione effettuata con successo');
          periodo['TimeRange'] = periodo['TimeRange'].filter(res => res._time !== item._time)

          for (let originale of this.periodi_original.filter(res => res.id === periodo.id)) {
            originale['TimeRange'] = originale['TimeRange'].filter(res => res._time !== item._time)
          }
        },
        (err) => {
          console.log(err);
          alert("An error occurred.Try again later.");
        });
    }
  }
  mySubscription: Subscription;
  cambiaImpostaGiorni(orario) {
    /*orario.showDays = !orario.showDays;
    if (orario.showDays === false) {
      orario.Days = [];
    }*/
    orario.showDays = false;
    orario.Days = [];
    this.mySubscription = interval(0).subscribe(x => {
      this.unsubscribeImpostaGiorni(orario);
      
    });
  }
  unsubscribeImpostaGiorni(orario) {
    orario.showDays = true;
    this.mySubscription.unsubscribe();
  }
  aggiungiDeroga(tipo, periodo) {
    console.log(`deroga.aggiungiDeroga.periodo`, periodo);
    let nuovo = this.nuovaDeroga();
    nuovo.type=tipo;
    nuovo.typeName=this.validityGetAll.filter(item=> {return parseInt(tipo)===parseInt(item.id);})[0].name;
    periodo.Exceptions.splice(0, 0, nuovo);
    //periodo.Exceptions.push(nuovo);
    console.log(`deroga.aggiungiDeroga.nuovaDeroga=${nuovo._time}`, nuovo);
  }
  cancellaDeroga(item, periodo) {
    console.log(`deroga.cancellaDeroga=${item._time}`, item, periodo);
    if (item.id === undefined) {
      periodo['Exceptions'] = periodo['Exceptions'].filter(res => res._time !== item._time)
    } else {
      console.log("deroga.cancellaDeroga cancello dal db");
      
      this.apollo.use("super-admin")
        .mutate({
          mutation: poiOpeningPeriodDelete.poiOpeningPeriodDelete,
          variables: {
            id: item.id
          },
        })
      .subscribe(
        (result: any) => {
          alert('Cancellazione effettuata con successo');
          periodo['Exceptions'] = periodo['Exceptions'].filter(res => res._time !== item._time)

          for (let originale of this.periodi_original.filter(res => res.id === periodo.id)) {
            originale['Exceptions'] = originale['Exceptions'].filter(res => res._time !== item._time)
          }
        },
        (err) => {
          console.log(err);
          alert("An error occurred.Try again later.");
        });
    }
  }
  salvaDeroga(item, periodo) {
    console.log(`periodo.salvaPeriodo.salvaDeroga=${item.id}`, "item", item, periodo)
    let mutation = undefined;
    //nel caso di type = 3 esiste lo item.Date
    if (item.type === "3") {
      console.log("IF item.DateRange.length == 0", item.Date, moment(item.Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"))
      item.DateRange = [];
      item.DateRange.push(item.Date);
      item.DateRange.push(item.Date);
    }
    let data = {
      isClosed: item.isClosed,
      backOfficeData: item.type,
      startingDateTime: moment(item.DateRange[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      endingDateTime: moment(item.DateRange[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
      //derogatedOpeningPeriodId
      //derogatedOpeningPeriodId: periodo.id,
    }
    if (item.id === undefined) {
      mutation = poiOpeningPeriodCreate.poiOpeningPeriodCreate;
      data['pointOfInterestId'] = parseInt(localStorage.getItem("poiID"));
      data['derogatedOpeningPeriodId'] = periodo.id;
    } else {
      mutation = poiOpeningPeriodUpdate.poiOpeningPeriodUpdate;
      data['id'] = item.id;
    }

    console.log(`periodo.salvaPeriodo.salvaDeroga.data=`, data, `mutation=`, mutation, periodo);

    this.apollo.use("super-admin")
      .mutate({
        mutation: mutation,
        variables: {
          data: data
        }
       })
        .subscribe((result: any) => {
          if (item.id === undefined) {
            //alert('Orario impostato con successo')
            item.id = result.data["poiOpeningPeriodCreate"]["id"];
          }
          console.log(`periodo.salvaPeriodo.salvaDeroga.success=${item.id}`, item, result)
          
          let index = this.periodi_original.map(function(e) { return e.id; }).indexOf(periodo.id);
          let index1 = this.periodi.map(function(e) { return e.id; }).indexOf(periodo.id);
          console.log(`periodi_original.trovati index periodi=${index1} this.periodi_original=${index}`)
          if (index<0) {
            this.periodi_original.push(JSON.parse(JSON.stringify(this.periodi[index1])))
          } else {
            this.periodi_original[index]=(JSON.parse(JSON.stringify(this.periodi[index1])))
          }
          console.log('CONFRONTO', JSON.stringify(this.periodi_original) === JSON.stringify(this.periodi))

          if (item.TimeRange.length > 0) {
            /*let s = new Date(item.TimeRange[0]);
            let e = new Date(item.TimeRange[1]);
            let startTimer = s.getHours() * 3600 + s.getMinutes() * 60;
            let endingTime = e.getHours() * 3600 + e.getMinutes() * 60;*/
            //per input standard
            //let startTimer = this._SecondsFromDate(item.TimeRange[0])
            //let endingTime = this._SecondsFromDate(item.TimeRange[1])
            let startTimer = this._SecondsFromDate_standard(item.TimeRange[0])
            let endingTime = this._SecondsFromDate_standard(item.TimeRange[1])
            let selectedDays = undefined;
            if (item.selectedDay != undefined || item.selectedDay !== "") {
              selectedDays = this.daysOfWeekGetAll.filter(res => {

              console.log(`periodo.salvaPeriodo.salvaDeroga.success=${item.id}`, 
                `res.id=${res.id} === selectedDays=${item.selectedDay} = ${parseInt(res.id) === parseInt(item.selectedDay)}`)
                return parseInt(res.id) === parseInt(item.selectedDay)
              });
              console.log(`periodo.salvaPeriodo.salvaDeroga.success=${item.id}`, "dentro if", selectedDays)
              console.log(`periodo.salvaPeriodo.salvaDeroga.success=${item.id}`, "dentro if->map", selectedDays.map(item=>item.name))
            } else {
              selectedDays = item.Days;
              console.log(`periodo.salvaPeriodo.salvaDeroga.success=${item.id}`, "dentro else")
            }
            let mutation1 = undefined;
            let data1 = {
              notes: item.notes,
              daysOfWeek: selectedDays.map(item=>item.name),
              poiOpeningPeriodId: item.id,
              openingDaySpecialNotes: []
            };

            if (item.idTimeRange === undefined ||  item.idTimeRange === "") {
              mutation1 = new poiOpeningDayCreate(startTimer, endingTime).getQuery();
            } else {
              data1['id'] = item.idTimeRange;
              mutation1 = new poiOpeningDayUpdate(startTimer, endingTime).getQuery();
            }

            console.log(`periodo.salvaPeriodo.salvaDeroga.salvaOrario.data1=${data1['id']}`, data1, mutation1);

            this.apollo.use("super-admin")
              .mutate({
                mutation: mutation1,
                variables: data1
              })
              .subscribe(
                (result: any) => {
                  if (item.idTimeRange === undefined) {
                    item.idTimeRange = result.data["poiOpeningDayCreate"]["id"];
                  }
                  console.log(`periodo.salvaPeriodo.salvaDeroga.salvaOrario.data1.success=${item.id}`, data1, item);

                  let index = this.periodi_original.map(function(e) { return e.id; }).indexOf(periodo.id);
                  let index1 = this.periodi.map(function(e) { return e.id; }).indexOf(periodo.id);
                  console.log(`periodi_original.trovati index periodi=${index1} this.periodi_original=${index}`)
                  if (index<0) {
                    this.periodi_original.push(JSON.parse(JSON.stringify(this.periodi[index1])))
                  } else {
                    this.periodi_original[index]=(JSON.parse(JSON.stringify(this.periodi[index1])))
                  }
                  console.log('CONFRONTO', JSON.stringify(this.periodi_original) === JSON.stringify(this.periodi))
                  
                },
                (err) => {
                  console.log(`periodo.salvaPeriodo.salvaDeroga.salvaOrario.data1.err=${item.id}`, data1, item, err);
                  alert("Errore nella creazione della deroga");
                }
              );
          }
        },
        (err) => {
          //alert("La deroga non è impostata correttamente, riprova");
          console.log(`periodo.salvaPeriodo.salvaDeroga.errore=${item.id}`, item, err);
        }
      );
        
    //derogatingOpeningPeriodsGetAll
  }
  nuovaDeroga(_id=undefined) {
    return {
      _time: _id + "-" + new Date().getTime(),
      type: "",
      typeName: "",
      DateRange: [],
      Date: undefined,
      TimeRange: [],
      idTimeRange: "",
      isClosed: false,
      notes: "",
      selectedDay: "",
      showDays:false,
      Days: [],
      id: _id,
    }
  }
  nuovoOrario(_id=undefined) {
    return {
      _time: _id + "-" + new Date().getTime(),
      TimeRange: [],
      notes: "",
      showDays:true,
      Days: [],
      showExceptions:false,
      id: _id,
    }
  }
  nuovoPeriodo(_id=undefined) {
    return {
      _time: _id + "-" + new Date().getTime(),
      DateRange: [],
      TimeRange: [],
      isClosed: false,
      Exceptions: [],
      id: _id,
      json: "",
    };
  }

}