import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { ArrayBuffer } from '@angular/http/src/static_request';
import { MapsAPILoader } from '@agm/core';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


const API_URL = environment.staticMapUrl;
const NODE_SERVER_URL = environment.nodeServerUrl;
const apiKeyMaps=environment.mapsKey;


@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
   headers = new HttpHeaders();
   tagsImage:any[] = ["NEWS_IMAGE","NEWS_COVER_IMAGE","POI_IMAGE","POI_COVER_IMAGE","POI_ADMIN_IMAGE","POI_ADMIN_COVER_IMAGE","JOURNEY_ADMIN_IMAGE","JOURNEY_ADMIN_COVER_IMAGE","MUNICIPALITY_IMAGE","MUNICIPALITY_COVER_IMAGE"]

  constructor(public http: HttpClient) { 
    
  }
   
   public getStaticMapForPoi(latitude,longitude) {
     console.log();
    
    return this.http.get(API_URL + "center="+latitude+","+longitude+ "&zoom=15&size=640x300%20&markers=color:blue%7Clabel:S%7C"+latitude+","+longitude+"&markers=size:tiny%7Ccolor:green%7CDelta+Junction,AK%20&scale=2&format=jpg&markers=size:mid%7Ccolor:0xFFFF00%7Clabel:C%7CTok,AK%22&key="+apiKeyMaps,{ responseType: 'blob' });
  };
   
  public getLingua() {
    var typeOfLingua = [
      { id: "1", name: 'IT' },
      { id: '2', name: 'EN' },
      { id: '4', name: 'FR' },
    ]
   
   return typeOfLingua;
 };
 public getImageRole() {

  var typeOfRole = [
    { id: "1", name: 'DESKTOP' },
    { id: '2', name: 'MOBILE' },
    { id: '3', name: 'THUMBNAIL' },
  ]
 
 return typeOfRole;
};
public getNotificationType() {

  var typeOfNotification = [
    { id: "1", name: 'MOBILE_PUSH' },
    { id: '2', name: 'EMAIL' },
    { id: '3', name: 'MOVERY_GUI' },
  ]
 
 return typeOfNotification;
};
 public getDayOfWeeks() {
  var dayOfWeeks = [
    { name: "SUNDAY", value: 'DOMENICA',id:"1" },
    { name: "MONDAY", value: 'LUNEDI',id:"2"   },
    {  name: "TUESDAY", value: 'MARTEDI',id:"3"    },
    {  name: "WEDNESDAY", value: 'MERCOLEDI',id:"4"    },
    {  name: "THURSDAY", value: 'GIOVEDI',id:"5"    },
    {  name: "FRIDAY", value: 'VENERDI',id:"6"    },
    {  name: "SATURDAY", value: 'SABATO',id:"7"    },
  ] 
 return dayOfWeeks;
};
public convertImageWebP(data) {
 
  
  return this.http.post(NODE_SERVER_URL + "api/v1/toWebp",data,{responseType:'blob'});
};


public cloudConvertImage(data){
  return this.http.post(NODE_SERVER_URL + "resize-image",data);
}
public getRole() {
  var typeOfRole= [

    { id: '1', name: 'Movery User' },
    { id: '2', name: 'Municipality Operator' },
    { id: '3', name: 'Editor' },
  ]
 
 return typeOfRole;
};


public getStatus() {
  var typeOfStatus = [
    { id: "1", name: 'SEMPRE CHIUSO' },
    { id: '2', name: 'SEMPRE APERTO' },
    { id: '3', name: 'IMPOSTA PERIODO' },
  ]
 
 return typeOfStatus;
};


public getValidita() {
  var typeOfValidity = [
    { id: "1", name: 'APERTURA ANNUALE' },
    { id: '2', name: 'IMPOSTA PERIODI SPECIALI' },
    { id: '3', name: 'IMPOSTA ORARI SPECIALI' },
    { id: '4', name: 'IMPOSTA GIORNO SPECIALE' },
    { id: '5', name: 'IMPOSTA ORARI DEI GIORNI DELLA SETTIMANA SPECIALI' }
  ]
 
 return typeOfValidity;
};
public getLicenseKey(data){
 

  return this.http.post("https://vendors.paddle.com/api/2.0/product/generate_license",data);
}

public filterImageByTags(data){
  console.log("filterImageByTags", "data", data);
  var dataImage:any[]= data.filter(item => item.tags.findIndex((itemSelected) => this.tagsImage.includes(itemSelected)) === -1);
  console.log("filterImageByTags", "dataImage", dataImage);
  return dataImage;
}

public clearCache() {
  console.log("clearCache")
  const token = JSON.parse(localStorage.getItem("token"));
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  });
  return this.http.get("https://clearcache.movery.it/api/cacheclear", { headers: headers} )
}

}



@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor() { 
  }
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     
      const token = JSON.parse(localStorage.getItem("token"));

      if(req.url == NODE_SERVER_URL + "resize-image"){
        req = req.clone({ headers: req.headers.set('Authorization', token ? `Bearer ${token}` : ``)});
      }

     
      return next.handle(req);
    }
}