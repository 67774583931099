import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { OggettoMenu } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-sample-layout>
      <nb-menu  [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-sample-layout>
  `,
})
export class PagesComponent {
  constructor(public router: Router) {
    console.log('PagesComponent');
    console.log(localStorage);
    let userLoggedId = localStorage.getItem('userLoggedId');
    if (userLoggedId == null) {
      console.log('NON SONO COLLEGATO, VADO A FARE IL LOGIN');
      this.router.navigate(['auth/login']);
    } else {
      console.log('sono collegato con id ' + userLoggedId);
    }
    this.menu = new OggettoMenu().menu();
  }

  menu = new OggettoMenu().menu();

}
