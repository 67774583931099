import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-modal-edit-tags',
  templateUrl: './modal-edit-tags.component.html',
  styleUrls: ['./modal-edit-tags.component.scss']
})
export class ModalEditTagsComponent implements OnInit {

  constructor(protected ref: NbDialogRef<ModalEditTagsComponent>,) { }

  @Input() title: string
  @Input() id: number
  @Input() tags: string[]
  
  private visibles: boolean[] = Array()
  private newTags: string[] = Array()

  @Output() newItemEvent = new EventEmitter<any>();
  
  ngOnInit() {
    if(this.tags) {
      this.tags.forEach(tag => {
        this.visibles.push(true)
        this.newTags.push(tag)
      })
    }
  }

  edit() {
    var toReturn: string[] = Array()
    this.newTags.forEach(tag => {
      if (tag != null)
        toReturn.push(tag)
    })
    this.newItemEvent.emit({
      id: this.id,
      tags: toReturn//this.newTags
    })
  }

  delete(index) {
    this.visibles[index] = false
    this.newTags[index] = null
  }

  dismiss() {
    this.ref.close();
  }

}
