import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  // Get the content
  public getZipContent(file) {
    const jszip = new JSZip();
    return jszip.loadAsync(file, { base64: true });
    //console.log(jszip.file("content.txt").asText());

  }
  public generate(file) {
    const jszip = new JSZip();
    let options = { compression: "DEFLATE" };
    jszip.file("text.svg", file, options);
    return jszip.generateAsync({ type: "base64" });

    //location.href="data:application/zip;base64," + base64;



  }

  // Get the actua blobs from the zip
  public unzip(file) {
    const jszip = new JSZip();
    return jszip.loadAsync(file).then((zip) => {
      Object.keys(zip).forEach((filename) => {
        zip[filename].async('blob').then((blobFile) => {
          console.log(blobFile);
        });
      });
    });
  }

  async  generateThumbnailImage(source) {

    try {
     
  } catch (e) {
      console.log("Error: ", e);
  }
  }


}

