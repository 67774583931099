import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { OpenModalSeoTagPoiComponent } from '../open-modal-seo-tag-poi/open-modal-seo-tag-poi.component';
@Component({
  selector: 'ngx-open-seo-tag-poi',
  templateUrl: './open-seo-tag-poi.component.html',
  styleUrls: ['./open-seo-tag-poi.component.scss']
})
export class OpenSeoTagPoiComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  public data:any;
  ddlLanguage={}
  @Output() saveSeoTagPoi: EventEmitter<any> = new EventEmitter();
  public indexSelected:any;
  translations: any;
  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
      
      this.translations = []
      this.translations['it'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.saveSeoTagPoi.emit(this.rowData);
  }
  openModal(languageId) {
    
    this.indexSelected =  this.rowData.seoTagGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoTagGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
     
         const ref =  this.dialogService.open(OpenModalSeoTagPoiComponent, {
           context: {
            title: 'Edit SeoTag',
            seoCodeId:this.data[0].id,
            poiId:this.data[0].pointOfInterestId,
            languageId: languageId,
            seoTagName:this.data[0].openGraphTags,
            description: this.data[0].description,
            seoTagTitle: this.data[0].title,
            noFollow:this.data[0].noFollow
           },
         });
         ref.componentRef.instance["editPoiTag"].subscribe((data) => {
          ref.close();
  
          this.rowData.seoTagGetAllSub[this.indexSelected].pointOfInterestId = data.poiId;
          this.rowData.seoTagGetAllSub[this.indexSelected].id = data.id;
          this.rowData.seoTagGetAllSub[this.indexSelected].languageId = data.languageId;
          this.rowData.seoTagGetAllSub[this.indexSelected].openGraphTags = data.openGraphTags;
          this.rowData.seoTagGetAllSub[this.indexSelected].description = data.description;
          this.rowData.seoTagGetAllSub[this.indexSelected].title = data.title;
          this.rowData.seoTagGetAllSub[this.indexSelected].noFollow = data.noFollow;

          this.saveSeoTagPoi.emit(this.rowData);
        });
        ref.componentRef.instance["deletePoiTag"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.seoTagGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.seoTagGetAllSub.splice(indexToRemove,1);
        
        this.saveSeoTagPoi.emit(this.rowData);
  
        });


         return;
       
    }
    else{
      const ref =  this.dialogService.open(OpenModalSeoTagPoiComponent, {
        context: {
          title: 'Create SeoTag',
          seoCodeId:null,
          poiId:this.rowData.id,
          languageId: languageId,
          seoTagName:[],
          description: "",
          seoTagTitle:"",
          noFollow:false
        },
      });

      ref.componentRef.instance["addPoiTag"].subscribe((data) => {
        ref.close();
        this.rowData.seoTagGetAllSub.push(data);
        
        this.saveSeoTagPoi.emit(this.rowData);
        });
     return;
    }
  }
 
}


