import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

import { QueryRef, Apollo } from "apollo-angular";
import { Subscription } from "rxjs/internal/Subscription";
import "../../../pages/news/list/ckeditor.loader";
import "ckeditor";
import {
  newsCategoryTranslationCreate,
  newsCategoryTranslationDelete,
  newsCategoryTranslationUpdate,
  travelCategoryTranslationDelete,
  travelCategoryTranslationCreate,
  travelCategoryTranslationUpdate,
  poiTranslationUpdate,
  poiTranslationCreate,
  poiTranslationDelete,
} from "../../../queryMutation";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfigBase } from "aws-sdk/lib/config";
import { first } from "rxjs/operators";

export interface List {
  key?: any;
  value: any[];
}

@Component({
  selector: "ngx-modal-poi-open-translation-it",
  templateUrl: "./modal-poi-open-translation-it.component.html",
  styleUrls: ["./modal-poi-open-translation-it.component.scss"],
})
export class ModalPoiOpenTranslationItComponent {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories: any[];
  public newsCategoryTranslationGetAll: any[];
  public inputFirtsColona1: any[];
  public inputFirtsColona2: any[];
  public block: any;
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Input() title: string;
  @Input() categoryId: any;
  @Input() preview: string;

  @Input() translationName: string;
  @Input() translation_name: string;
  @Input() translationId: any;
  @Input() isFromIt: boolean;
  @Input() languageId: any;
  @Input() additionalInformation: string;
  @Input() indicationsContent: string;
  @Input() indicationsTitle: string;
  @Input() description: string;
  @Input() limitations: string;
  @Input() facilitations: string;
  @Input() isAdditionalInformation: boolean = true;

  @Input() fac: any;
  @Input() lim: any;

  @Output("addPoiTrans") addPoiTrans: EventEmitter<any> = new EventEmitter();

  @Output("editPoiTrans") editPoiTrans: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiTrans") deletePoiTrans: EventEmitter<any> = new EventEmitter();

  minPreviewLength = 60
  maxPreviewLength = 178

  constructor(
    protected ref: NbDialogRef<ModalPoiOpenTranslationItComponent>,
    public apollo: Apollo,
    public spinner: NgxSpinnerService
  ) {
    this.block = {
      lists: [],
    };
  }
  

  showFac() {
    alert("showFac")
    console.log("showFac", this.block)
  }
  ngOnInit() {
    if (this.limitations !== "" || this.facilitations !== "") {
      this.fac = JSON.parse(this.facilitations);
      this.lim = JSON.parse(this.limitations);

      console.log(this.facilitations);

      console.log("is undefined", this.fac.lists !== undefined, "length>0", this.fac.lists.length > 0)
      if (this.fac.lists !== undefined && this.fac.lists.length > 0) {
        console.log("IF", this.fac.lists)
        this.block = {
          lists: this.fac.lists,
        }
      }

      console.log("QUI");
      console.log("block ->", this.block, "facilitations ->", this.fac);
    }
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  trackByFunction(index: any, item: any) {
    return index;
  }
  trackByBloc(index: any, item: any) {
    return index;
  }
  trackByRow(index: any, item: any) {
    return index;
  }

  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiTranslationDelete.poiTranslationDelete,
        fetchPolicy: "no-cache",
        variables: {
          id: this.translationId,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiTranslationDelete"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.deletePoiTrans.emit({
                id: this.translationId,
              });
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  onDeleteConfirm(event): void {
    if (window.confirm("Conferma che voi eliminare questa Traduzione?")) {
      this.newsCategoryTranslationDelete();
    } else {
      event.confirm.reject();
    }
  }

  Salva() {

    var newBlock = {"lists": []}
    for (var item of this.block.lists) {
      if (item.value.length > 0) {
        var single = item.value[0]
        if (single.value.length > 0) {
          var single_2 = single.value[0]
          if (single_2.trim().length > 0) {
            console.log("Edit.item.IF.trim.IF", item)
            newBlock.lists.push(item)
          } else {
          console.log("Edit.item.IF.trim_.ELSE", item)
          }
        } else {
          console.log("Edit.item.IF.single.ELSE", item)
        }
      } else 
        console.log("Edit.item.ELSE", item)
    }
    console.log("Edit.item.newBlock", newBlock)
    this.block = newBlock

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiTranslationCreate.poiTranslationCreate,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            pointOfInterestId: parseInt(this.categoryId),
            languageId: this.languageId,
            name: this.translationName,
            additionalInformation: this.additionalInformation,
            indicationsContent: this.indicationsContent,
            indicationsTitle: this.indicationsTitle,
            description: this.description,
            preview: this.preview,
            facilitations: JSON.stringify(this.block),
            limitations: JSON.stringify(null),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiTranslationCreate"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.addPoiTrans.emit({
                pointOfInterestId: parseInt(this.categoryId),
                languageId: parseInt(this.languageId),
                name: this.translationName,
                additionalInformation: this.additionalInformation,
                indicationsContent: this.indicationsContent,
                indicationsTitle: this.indicationsTitle,
                description: this.description,
                preview: this.preview,
                facilitations: JSON.stringify(this.block),
                limitations: JSON.stringify(null),
                id: result.data["poiTranslationCreate"]["id"],
              });
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
    // setTimeout(() => location.reload(), 3000);
  }
  Edit() {
    console.log("Edit", JSON.stringify(this.block));

    var newBlock = {"lists": []}
    for (var item of this.block.lists) {
      if (item.value.length > 0) {
        var single = item.value[0]
        if (single.value.length > 0) {
          var single_2 = single.value[0]
          if (single_2.trim().length > 0) {
            console.log("Edit.item.IF.trim.IF", item)
            newBlock.lists.push(item)
          } else {
          console.log("Edit.item.IF.trim_.ELSE", item)
          }
        } else {
          console.log("Edit.item.IF.single.ELSE", item)
        }
      } else 
        console.log("Edit.item.ELSE", item)
    }
    console.log("Edit.item.newBlock", newBlock)
    this.block = newBlock


    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation: poiTranslationUpdate.poiTranslationUpdate,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            pointOfInterestId: parseInt(this.categoryId),
            languageId: this.languageId,
            name: this.translationName,
            id: this.translationId,
            additionalInformation: this.additionalInformation,
            indicationsContent: this.indicationsContent,
            indicationsTitle: this.indicationsTitle,
            description: this.description,
            preview: this.preview,
            facilitations: JSON.stringify(this.block),
            limitations: JSON.stringify(null),
          },
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["poiTranslationUpdate"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.editPoiTrans.emit({
                pointOfInterestId: parseInt(this.categoryId),
                languageId: parseInt(this.languageId),
                name: this.translationName,
                additionalInformation: this.additionalInformation,
                indicationsContent: this.indicationsContent,
                indicationsTitle: this.indicationsTitle,
                description: this.description,
                preview: this.preview,
                facilitations: JSON.stringify(this.block),
                limitations: JSON.stringify(null),
                id: this.translationId,
              });
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
    // setTimeout(() => location.reload(), 3000);
  }


  getLengthWithoutHtmlTags(html) {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    //console.log("getLengthWithoutHtmlTags", html, text)
    return text.length
  }

  verifyLength() {
    var length = this.getLengthWithoutHtmlTags(this.preview)
    /*if (length < this.minPreviewLength) {
      alert(`Per il campo Preview: Il numero di caratteri previsto è almeno ${this.minPreviewLength}, attualmente è ${length}`)
      return false
    } else*/ if(length > this.maxPreviewLength){
      alert(`Per il campo Preview: Il numero di caratteri previsto non deve essere maggiore di ${this.maxPreviewLength}, attualmente è ${length}`)
      return false
    }
    return true
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

  moveUp(obj, list) {
    console.log("moveUp: ", obj, list);
    var size = list.length
    if (size > 1) {
      var old = -1;
      for (var i=0; i<size; i++) {
        if (list[i] == obj) {
          console.log("moveUp: findIndex=", i)
          old = i
        }
      }
      if (old > 0) {
        list = this.array_move(list, old, old-1)
      }
    }
  }

  moveDown(obj, list) {
    console.log("moveDown: ", obj, list);
    var size = list.length
    if (size > 1) {
      var old = -1;
      for (var i=0; i<size; i++) {
        if (list[i] == obj) {
          console.log("moveDown: findIndex=", i)
          old = i
        }
      }
      if (old >= 0 && old < size-1) {
        list = this.array_move(list, old, old+1)
      }
    }
  }

  addSubList(obj) {
    console.log("addSubList: ", obj, this.block);
    obj.push({key: "", value: ['']})
  } 

  // Create new list
  addList() {
    const constValue = {value: []}
    const newList = {
      key: "",
      value: [
        {key: "", value: ['']}
      ],
    };
    this.block.lists.push(newList)
    console.log("Added new list to: ", this.block);
  }

  deleteList(obj) {
    console.log("delete: ", obj);
    this.block.lists = this.block.lists.filter((data) => {
      console.log("filter", data, data != obj)
      return data != obj
    })
    console.log("new list", this.block.lists)
  }

  // Removes item from list
  removeList = (list, i) => {
    list.value.splice(i, 1);
    console.log(list, list.length);
  };

  // Create new input when hit enter on last input
  addItemToList(list, i) {
    const newListItem: string = "";
    if (i + 1 === list.value.length) {
      list.value.push(newListItem);
    }
    console.log(list, this.block);
  }

  // Removes item from list
  removeItemFromList = (list, i) => {
    list.value.splice(i, 1);
    console.log(list, list.length);
  };

  // Update block title
  updateBlockTitle = (event, block) => (block.key = event.target.value);

  // Update list title
  updateTitle = (event, list) => (list.key = event.target.value);

  // Update list value
  updateValue = (event, list, i) => (list.value[i] = event.target.value);
}
