import { Component, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { QueryRef, Apollo } from 'apollo-angular';
import { FileUploadService } from '../../../service/file-upload.service';
import { pointOfInterestImageGetAll, newsImageAssociationCreate, imageCreateFromUpload, imageCreateUploadLink, pointOfInterestCreate, pointOfInterestImageCreate, poiBillingCreate, poiTravelCategoryCreate, travelCategoryGetAll, provinceGetAll, municipalityGetAll, poiOpeningPeriodCreate, pointOfInterestUpdate, seoCodeCreate, poiOpeningDayCreate, travelTagGetAll, pointOfInterestGetAll, pointOfInterestAdminSelectedCreate, pointOfInterestAdminSelectedGet, poiAdminSelectedImageCreate, poiAdminSelectedImageDelete, pointOfInterestAdminSelectedTravelTagCreate, pointOfInterestAdminSelectedTravelTagDelete, pointOfInterestAdminSelectedUpdate } from '../../../queryMutation';
import { HttpEvent } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpRequestService } from '../../../service/http-request.service';
import { DomSanitizer } from '@angular/platform-browser';

import { concatMap } from 'rxjs/operators';
import { forkJoin, concat } from 'rxjs';
import { ImageUploadType } from '../../../service/Image';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'ngx-poi-admin-selected-edit',
  templateUrl: './poi-admin-selected-edit.component.html',
  styleUrls: ['./poi-admin-selected-edit.component.scss']
})
export class PoiAdminSelectedEditComponent implements OnInit {
  value: number = 1;

  public queryGetTravelTag: any;
  public queryGetAllPointOfInterest: any;
  DataInizio: any[];
  imageUpload: boolean = false;
  showPubblicaBtn: boolean = false;
  public model = { dollarSigns: '', pointOfInterestId: '',position:"" };
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionPoi: Subscription;
  public querySubscriptionProfileUser: Subscription;

  public PointOfInterestPagination: QueryRef<any>;
  public travelTagPagination: QueryRef<any>;

  public selectedPoi: any;
  public selectedTravelTag: any;
  typeOfLingua: any;
  daysOfWeekGetAll: any;
  ImageSource: any;
  modelToMapping: any;
  public travelTagCategoryGetAll: any[];
  public pointOfInterestGetAll: any[];
  public ImageAssociationGetAll: any[];
  public tempArray: any[];

  imageData = { image: '' };
  public filesToUpload: any[];
  public imageToUpload: ImageUploadType;
  
  configPointOfInterest = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not Records found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Find point of interest', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  configTravelCategory = {
    displayKey: "code", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Nothing selected', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'Not Records found', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Find travel category', // label thats displayed in search input,
    searchOnKey: 'code', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }
  limit: any = 25;
  fileToUpload: FileList;
  response: any;
  previewImage: any;

  constructor(public apollo: Apollo, public fileUp: FileUploadService, public _location: Location, public router: Router, public requestService: HttpRequestService, public _sanitizer: DomSanitizer,public spinner: NgxSpinnerService) {
    this.queryGetTravelTag = new travelTagGetAll({}, this.limit).getQuery();
    this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit).getQuery();
    this.travelTagCategoryGetAll = [];
    this.pointOfInterestGetAll = [];
    this.ImageAssociationGetAll = [];
    this.filesToUpload = [];
    this.imageToUpload = {
      'fileName': '',
      'size': 0,
      'mimeType': '',
      'belongsToId': 0,
      'uploadedByBaseUserId': 0,
      'role': '',
      'belongsTo':'',
      'tags':[]
    };
  }

  async ngOnInit() {
   await this.pointOfInterestAdminSelectedGet();

  }
 async pointOfInterestAdminSelectedGet() {
    this.querySubscriptionProfileUser = this.apollo.watchQuery<Response>({
      query: pointOfInterestAdminSelectedGet.pointOfInterestAdminSelectedGet,
      variables: {
        'id': parseInt(localStorage.getItem("poiAdminSelectedID"))
      },
    })
      .valueChanges.subscribe((result: any) => {

        this.model = result.data.pointOfInterestAdminSelectedGet;
        this.modelToMapping = result.data.pointOfInterestAdminSelectedGet;

        this.value = result.data.pointOfInterestAdminSelectedGet.dollarSigns;
        

         this.pointOfInterestAll();

      });
  }


  async pointOfInterestAll() {

    this.PointOfInterestPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllPointOfInterest,
      variables: {
        'data': {
          'poiTranslationFilter':{
            'hasTranslation':true,
            'containsLanguageId':[]
          }
        },
      },
    });

    this.querySubscriptionPoi = this.PointOfInterestPagination.valueChanges.subscribe((data: any) => {

      const lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['poiTranslationGetAllSub'][0]['id']) - (data.data.pointOfInterestGetAll.length)) : null;
      this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit, lastId).getQuery();
      this.PointOfInterestPagination.fetchMore({
        query: this.queryGetAllPointOfInterest,
        variables: {
          'data': {
            'poiTranslationFilter':{
              'hasTranslation':true,
              'containsLanguageId':[]
            }
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {

          if (fetchMoreResult['pointOfInterestGetAll'].length < 1) {
            this.pointOfInterestGetAll.push(...prev['pointOfInterestGetAll']);
            this.pointOfInterestGetAll = this.pointOfInterestGetAll.map((item) => {
              return item;
              //return item['poiTranslationGetAllSub'][0];
            })
            console.log(this.pointOfInterestGetAll);

            this.selectedPointOfInterest();
            return prev;
          } else {
            return Object.assign({}, prev, {

              pointOfInterestGetAll: [...prev['pointOfInterestGetAll'], ...fetchMoreResult['pointOfInterestGetAll']]
            });
          }
        }
      });
    });


  }

  selectedPointOfInterest() {
    this.selectedPoi = [];

    var data = this.pointOfInterestGetAll.filter(item => item.pointOfInterestId == this.modelToMapping.pointOfInterestId)
    if(data.length > 0){
      this.selectedPoi.push(data[0]);
    }
  }
  updatePoiAdminSelected() {
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use("super-admin").mutate({
        mutation: pointOfInterestAdminSelectedUpdate.pointOfInterestAdminSelectedUpdate,
        variables: {
          'data': {
            'id': parseInt(localStorage.getItem("poiAdminSelectedID")),
            'dollarSigns':this.model.dollarSigns,
            'position':parseInt(this.model.position),
            'isActive':true, 
          }
        },
      }).subscribe((result: any) => {
        if (result.data['pointOfInterestAdminSelectedUpdate']['success']) {
          setTimeout(() => {
         
            this.spinner.hide();
            this.router.navigateByUrl("/pages/pointOfInterest/admin");
          }, 2000);
        }
      },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.router.navigateByUrl("/pages/pointOfInterest/admin");
        }, 2000);
       })
    

  }

  goBack(){
    this._location.back();
  }
}
