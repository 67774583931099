import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ModalPoiOpenTranslationItComponent } from "../modal-poi-open-translation-it/modal-poi-open-translation-it.component";
import { Router } from "@angular/router";
@Component({
  selector: "ngx-open-translation-poi",
  templateUrl: "./open-translation-poi.component.html",
  styleUrls: ["./open-translation-poi.component.scss"],
})
export class OpenTranslationPoiComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Input() data: any;
  ddlLanguage = {};

  @Output() savePoiTranslation: EventEmitter<any> = new EventEmitter();
  translationIt = "";
  public indexSelected: any;
  translations: any;
  constructor(public dialogService: NbDialogService, public router: Router) {
    this.data = [];
  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
    console.log("ngOnInit", this.rowData)
    console.log("ngOnInit", this.rowData.poiTranslationGetAllSub)
    this.translations = []
    this.translations['it'] = this.rowData.poiTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
    this.translations['eng'] = this.rowData.poiTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
    this.translations['fr'] = this.rowData.poiTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
    this.savePoiTranslation.emit(this.data);
  }

  openEditModal(languageId) {
    this.indexSelected = this.rowData.poiTranslationGetAllSub.filter(
      (item) => item.languageId == languageId
    );
    this.data = this.rowData.poiTranslationGetAllSub.filter(
      (item) => item.languageId == languageId
    );

    console.log("openEditModal", this.data, this.data.length);

    if (this.data.length > 0) {

      console.log("openEditModal", "this.data.length > 0")
      var context = {
          title: "Edit Translation",
          categoryId: this.data[0].pointOfInterestId,
          translationName: this.data[0].name,
          translationId: this.data[0].id,
          languageId: languageId,
          additionalInformation: this.data[0].additionalInformation,
          indicationsContent: this.data[0].indicationsContent,
          indicationsTitle: this.data[0].indicationsTitle,
          description: this.data[0].description,
          preview: this.data[0].preview,
          limitations: this.data[0].limitations,
          facilitations: this.data[0].facilitations,
          isAdditionalInformation: false
        }
      console.log(this.data[0].id, context)
      const ref = this.dialogService.open(ModalPoiOpenTranslationItComponent, {
        context: context
      });
      ref.componentRef.instance["editPoiTrans"].subscribe((data) => {
        ref.close();
        console.log("poiTranslationGetAllSub", this.rowData.poiTranslationGetAllSub)
        console.log("indexSelected", this.indexSelected)
        console.log("editPoiTrans", data)
        this.rowData.poiTranslationGetAllSub[0].id = data.id;
        this.rowData.poiTranslationGetAllSub[0].pointOfInterestId = data.pointOfInterestId;
        this.rowData.poiTranslationGetAllSub[0].name = data.name;
        this.rowData.poiTranslationGetAllSub[0].description = data.description;
        this.rowData.poiTranslationGetAllSub[0].languageId = data.languageId;
        this.rowData.poiTranslationGetAllSub[0].indicationsContent = data.indicationsContent;
        this.rowData.poiTranslationGetAllSub[0].additionalInformation = data.additionalInformation;
        this.rowData.poiTranslationGetAllSub[0].indicationsTitle = data.indicationsTitle;
        this.rowData.poiTranslationGetAllSub[0].facilitations = data.facilitations;
        this.rowData.poiTranslationGetAllSub[0].limitations = data.limitations;
        this.savePoiTranslation.emit(this.rowData);
      });

      ref.componentRef.instance["deletePoiTrans"].subscribe((data) => {
        ref.close();
        const indexToRemove = this.rowData.poiTranslationGetAllSub.findIndex(
          (res) => res.id == data.id
        );
        this.rowData.poiTranslationGetAllSub.splice(indexToRemove, 1);
        this.savePoiTranslation.emit(this.rowData);
      });
      return;
    } else {
      console.log("openEditModal", "this.data.length ELSE")
      var indicationsContent = "";
      var indicationsTitle = "";

      if (languageId == 1) {
        var indicationsContent =
          'Per raggiungere il punto di interesse, segui le indicazioni oppure lasciati aiutare dal tasto "Ottieni indicazioni."';
        var indicationsTitle = " Come arrivare a";
        var additionalInformation = "<h3>Consigli per la visita</h3>";
      }
      if (languageId == 2) {
        var indicationsContent =
          'To reach the Point of Interest, follow the indications or push the button "Get directions."';
        var indicationsTitle = "Getting to";
        var additionalInformation = "<h3>Tips for the visit</h3>";
      }
      if (languageId == 4) {
        var indicationsContent =
          'Pour rejoindre la Casina Vanvitelliana, suivez les indications ou laissez-vous aider par le bouton "Obtenir directions."';
        var indicationsTitle = "Comment arriver à";
        var additionalInformation = "<h3>Conseils pour votre visite</h3>";
      }
      const ref = this.dialogService.open(ModalPoiOpenTranslationItComponent, {
        context: {
          title: "Insert Translation",
          categoryId: this.rowData.id,
          translationName: "",
          translationId: null,
          languageId: languageId,
          additionalInformation: additionalInformation,
          indicationsContent: indicationsContent,
          indicationsTitle: indicationsTitle,
          description: "",
          preview: "",
          limitations: "",
          facilitations: "",
          isAdditionalInformation: false
        },
      });
      ref.componentRef.instance["addPoiTrans"].subscribe((data) => {
        ref.close();
        console.log("poiTranslationGetAllSub", this.rowData.poiTranslationGetAllSub)
        console.log("indexSelected", this.indexSelected)
        console.log("editPoiTrans", data)

        this.rowData.poiTranslationGetAllSub.push(data);
        this.savePoiTranslation.emit(this.rowData);
      });

      return;
    }
  }
}
