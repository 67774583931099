import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { Router } from '@angular/router';


@Component({
  selector: 'ngx-open-admin-poi-cover-image-upload-action',
  templateUrl: './open-admin-poi-cover-image-upload-action.component.html',
  styleUrls: ['./open-admin-poi-cover-image-upload-action.component.scss']
})
export class OpenAdminPoiCoverImageUploadActionComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage = {}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt = "";
  public setData:any[];
  public data = {
    data: {},
    isSelected: true,
    selected: []

  };
  constructor(public dialogService: NbDialogService, public router: Router) {
this.setData=[];

  }

  ngOnInit() {
    //this.renderValue = this.value.toString().toUpperCase();
    console.log("qui c'è? " + this.rowData.id, this.rowData)
  }

  onClick() {
    this.save.emit(this.rowData);
  }

  openImageModal() {
    console.log(this.rowData.id);
    if (this.rowData.id > 0) {

      localStorage.setItem("poiAdminSelectedId", this.rowData.id);
      this.router.navigateByUrl("/pages/pointOfInterest/admin-cover-upload-image");

    }
  }




  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
   }
  openEditModal() {

    localStorage.removeItem("imageData");
    if (this.rowData.id > 0) {

      var imageFilter = this.rowData.poiAdminSelectedImageGetAllSub.filter(item => (item.imageGetSub.role.includes("ORIGINAL")) && (item.imageGetSub.tags.includes("POI_ADMIN_COVER_IMAGE")));
        for (let index = 0; index < imageFilter.length; index++) {

          imageFilter[index].imageGetSub.positionCover = imageFilter[index].positionCover;
          imageFilter[index].imageGetSub.imageId = imageFilter[index].imageId;
          imageFilter[index].imageGetSub.backOfficeData = (imageFilter[index].backOfficeData == undefined) ?  "":imageFilter[index].backOfficeData;
          imageFilter[index].imageGetSub.poiAdminSelectedId = imageFilter[index].poiAdminSelectedId;
          this.setData.push(imageFilter[index].imageGetSub);
        }

        if(imageFilter.length > 0) {
          var imageFilter2:any[] = this.removeDuplicates(this.setData, 'id');
          console.log(imageFilter2);
          
          if (imageFilter2.length > 0) {

            for (let index = 0; index < imageFilter2.length; index++) {
    
              this.data.selected.push(imageFilter2[index]);
            }
            localStorage.setItem("imageData", JSON.stringify(this.data));
        localStorage.setItem("poiId", this.rowData.id);
        this.router.navigateByUrl("/pages/pointOfInterest/edit-admin-cover-upload-image");
          }
        }
    

    }
  }

}
