import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, poiTranslationUpdate, poiTranslationCreate, poiTranslationDelete, journeyTranslationCreate, journeyTranslationUpdate, journeyTranslationDelete } from '../../../queryMutation';
import { NgxSpinnerService } from 'ngx-spinner';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';

@Component({
  selector: 'ngx-journey-modal-translation',
  templateUrl: './journey-modal-translation.component.html',
  styleUrls: ['./journey-modal-translation.component.scss']
})
export class JourneyModalTranslationComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }
  
  @Input() title: string;
  @Input()journeyId:any;
  @Input() translationName:string;
  @Input() translationId:any;
  @Input() isFromIt:boolean;
  @Input() languageId:any;
  @Input() description:string;

  @Output("addJourneyTrans") addJourneyTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editJourneyTrans") editJourneyTrans: EventEmitter<any> = new EventEmitter();
  @Output("deleteJourneyTrans") deleteJourneyTrans: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<JourneyModalTranslationComponent>,public apollo:Apollo,public spinner:NgxSpinnerService) { 
    
  }

  ngOnInit() {
  }
  dismiss() {
    this.ref.close();
  }
  newsCategoryTranslationDelete(){
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    this.querySubscriptionProfileUser = this.apollo.use('movery-user').mutate({
      mutation: journeyTranslationDelete.journeyTranslationDelete,
      variables: {
        'id':this.translationId
      }
    }).subscribe((result: any) => {
      if( result.data['journeyTranslationDelete']['success']){
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          this.deleteJourneyTrans.emit({ 
            'id':parseInt(this.translationId),
          });
        }, 2000);
      }
     },(err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        
      }, 2000);
     })
  }
 
  onDeleteConfirm(event): void {
    if (window.confirm('Conferma che voi eliminare questa Traduzione?')) {

      this.newsCategoryTranslationDelete();
    } else {
     event.confirm.reject();
    }
  }
  
  Salva() {
 
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
      this.querySubscriptionProfileUser = this.apollo.use('movery-user').mutate({
        mutation: journeyTranslationCreate.journeyTranslationCreate,
        variables: {
          data:{
            journeyId:parseInt(this.journeyId),
            languageId:this.languageId,
            name:this.translationName,
            description:this.description
          }
      },
      }).subscribe((result: any) => {
        if( result.data['journeyTranslationCreate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.addJourneyTrans.emit({ 
              'journeyId':parseInt(this.journeyId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'id':result.data['journeyTranslationCreate']['id'],
              'description':this.description
            });
            this.ref.close();
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
    Edit() {
      this.spinner.show(undefined,
        {
          type: 'square-spin',
          size: 'medium',
          bdColor: 'rgba(100,149,237, .8)',
          color: 'white',
          fullScreen: true
        }
      );
      this.querySubscriptionProfileUser = this.apollo.use('movery-user').mutate({
        mutation: journeyTranslationUpdate.journeyTranslationUpdate,
        variables: {
          data:{
            journeyId:parseInt(this.journeyId),
            languageId:this.languageId,
            name:this.translationName,
            id:this.translationId,
            description:this.description
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['journeyTranslationUpdate']['success']){
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
            this.editJourneyTrans.emit({ 
              'journeyId':parseInt(this.journeyId),
              'languageId':parseInt(this.languageId),
              'name':this.translationName,
              'id':parseInt(this.translationId),
              'description':this.description
            });
          }, 2000);
        }
       },(err) => {
        alert("An error occurred.Try again later.");
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
          
        }, 2000);
       })
    } 
}

