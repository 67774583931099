import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../service/utilities/constants.service';
import { FileUploadService } from '../../../service/file-upload.service';
import { ImageButtonParameter } from '../../table/image-button/image-button.component';
import { Apollo } from 'apollo-angular';

import { imageGet, imageGetAll, 
  newsImageAssociationGetAll, newsImageAssociationDelete, newsImageAssociationCreate,
  pointOfInterestImageGetAll, pointOfInterestImageDelete, pointOfInterestImageCreate, 
  journeyAdminSelectedImageGetAll, journeyAdminSelectedImageCreate, journeyAdminSelectedImageDelete, imageDelete} from '../../../queryMutation';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalDataSource } from 'ng2-smart-table';
import { forkJoin } from 'rxjs';

class Info {
  getAll = ""
  tag = ""
}

class ImagePosition {
  constructor(value: string) {
    this.title = value
    this.value = value//{imagePosition: value}
  }
  title = ""
  value: any
}

@Component({
  selector: 'ngx-image-position',
  templateUrl: './image-position.component.html',
  styleUrls: ['./image-position.component.scss']
})
export class ImagePositionComponent implements OnInit {

  constructor(public constantsService: ConstantsService, public apollo: Apollo, public _sanitizer: DomSanitizer, public fileUp:FileUploadService) { }

  id: number
  data = []
  dataAll = []
  imageType: ImageButtonParameter
  info: Info
  getAll = "GetAll"
  positions = []
  rowSelected = [] 

  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: true,
      delete: true
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    columns: {

      id: {
        title: 'ID',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.imageGetSub.id) {
            return this._sanitizer.bypassSecurityTrustHtml(row.imageGetSub.id)
          }
        }
      },
      positionGallery: {
        title: 'positionGallery',
        type: 'number',
        filter: false,
        editable: true,
        addable: false
      },
      role: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: true,
        addable: false,
        valuePrepareFunction: (cell, row, test) => {
          return cell.title
        },
        editor: {
          type: 'list',
          config: {
            selectText: 'Select one',
            list: this.positions
          }
        }
      },
      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.imageGetSub.url) {
            row.UrlConverted = "<img class='imgRendering' src=" + row.imageGetSub.url + " style='height:100px;width: 100px;'>"
            console.log("UrlConverted", row.UrlConverted)
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted)
          }
        }
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.imageGetSub.mimeType) {
            return row.imageGetSub.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      }
    }
  }

  source: LocalDataSource = new LocalDataSource();
  
  ngOnInit() {

    this.constantsService.showSpinner()

    this.id = parseInt(localStorage.getItem("id"))
    console.log("id", this.id)
    this.imageType = JSON.parse(localStorage.getItem("image-type"))
    console.log("image-type", this.imageType)

    this.positions.push(new ImagePosition(""))
    this.positions.push(new ImagePosition("Top"))
    this.positions.push(new ImagePosition("Bottom"))

    this.info = new Info()

    switch(this.imageType.bo) {
      case ImageButtonParameter.BO_POI:
        this.info.getAll = "pointOfInterestImage" + this.getAll
        if (this.imageType.type == ImageButtonParameter.TYPE_GALLERY) {
          this.info.tag = "POI_IMAGE"
        } else {
          this.info.tag = "POI_COVER_IMAGE"
        }
        break;
    }

    this.getImage()

  }

  getImage(lastId = null) {
    var data = {parentId: this.id, role: "ORIGINAL", mimeType: "image/webp", tags: this.info.tag}
    console.log("getImage", data)
    if (lastId == null) {
      this.data = []
    }
    this.apollo.query({
      query: this.getQuery(lastId),
      fetchPolicy: 'network-only',
      variables: {
        data: data
      }
    }).subscribe((data: any) => {
      var elements = data.data[this.info.getAll]
      console.log("elements", elements)
      this.data.push(...elements)
      if (elements.length == ConstantsService.LIMIT_QUERY) {
        this.getImage(elements[elements.length-1].id)
      } else {
        console.log("DATA", this.data)
        this.data.forEach(element => {
          console.log("element -> ", element)
          if (element['backOfficeData']) {
            var backOfficeData = JSON.parse(element['backOfficeData'])
            element['role'] = new ImagePosition(backOfficeData.imagePosition)
          } else 
            element['role'] = new ImagePosition("")
        })
        this.source.load(this.data)
        this.constantsService.hideSpinner()
      }
    })
  }


  getAllImage(tag, lastId = null) {
    var data = {parentId: this.id, tags: tag}
    console.log("getAllImage", data)
    return this.apollo.query({
      query: this.getQuery(lastId),
      fetchPolicy: 'network-only',
      variables: {
        data: data
      }
    })
  }

  getQuery(lastId = null) {
    if (this.imageType.bo == ImageButtonParameter.BO_POI)
      return new pointOfInterestImageGetAll({}, ConstantsService.LIMIT_QUERY, lastId).getQuery()
  }

  async onDeleteConfirm(event) {
    console.log("onDeleteConfirm", event, this.rowSelected.length)
    if (!event && this.rowSelected.length == 0)
      return

    this.constantsService.showSpinner()
    this.dataAll = []
    var tags = []
    if (event) {
      console.log("onDeleteConfirm", "this", event, event.data.imageGetSub.tags)

      var filter = event.data.imageGetSub.tags.filter(element => element.includes("-"))
      if (filter) {
        tags.push(filter[0])
      } 
    } else if (this.rowSelected.length > 0) { // all selected
      console.log("onDeleteConfirm", "rowSelected", this.rowSelected)
      this.rowSelected.forEach(element => {
        var filter = element.imageGetSub.tags.filter(element => element.includes("-"))
        if (filter) {
          tags.push(filter[0])
        } 
      })
    }

    console.log("onDeleteConfirm", "tags", tags)

    var tasks$ = []
    tags.forEach(tag => {
      tasks$.push(this.getAllImage(tag, null))
    })

    forkJoin(tasks$).subscribe(element => {
      tasks$ = []
      element.forEach(element => {
        console.log("forkJoinNew", element)
        element.data['pointOfInterestImageGetAll'].forEach(element => {
          console.log("forkJoinNew-Inner", element.id)
          tasks$.push(this.fileUp.pointOfInterestImageDelete(element.id))
        })
      })
      forkJoin(tasks$).subscribe(element => {
        element.forEach(element => {
          console.log("forkJoinNew-delete", element)
          var id = element.data['pointOfInterestImageDelete'].id
          console.log("forkJoin.element.id", id)
          this.data = this.data.filter(filter => filter.id != id)
        })

        this.source.load(this.data)
        this.constantsService.hideSpinner()
      })

    })
        

  }

  async onRowSelect(event) {
    console.log("onRowSelect", event)
    this.rowSelected = event.selected
  }

  async onEditConfirm(event) {
    console.log("onEditConfirm", event)
    console.log("onEditConfirm.newData", event.newData)
    console.log("onEditConfirm.newData.id", event.newData.id)
    console.log("onEditConfirm.newData.positionGallery", event.newData.positionGallery)
    console.log("onEditConfirm.newData.positionCover", event.newData.positionCover)
    console.log("onEditConfirm.newData.backOfficeData.pre", event.newData.backOfficeData)
    var role = event.newData.role
    console.log("onEditConfirm.newData.role", role)
    if (role) {
      event.newData.backOfficeData = JSON.stringify({imagePosition: role})
    } else {
      event.newData.backOfficeData = "\"\""
    }
    console.log("onEditConfirm.newData.backOfficeData.post", event.newData.backOfficeData)

    this.constantsService.showSpinner()

    this.fileUp.pointOfInterestImageUpdatePositionCover(event.newData.id, event.newData.imageId,
    this.id, event.newData.positionCover, event.newData.positionGallery, event.newData.backOfficeData).subscribe((data: any) => {
      console.log("onEditConfirm", "pointOfInterestImageUpdate", data)
      this.constantsService.hideSpinner()
      event.newData.role = new ImagePosition(role)
      event.confirm.resolve(event.newData)
    })

  }

  goBack(){
    this.constantsService.goBack()
  }

}
