
import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalOpenCategorySeoCodeComponent } from '../modal-open-category-seo-code/modal-open-category-seo-code.component';


@Component({
  selector: 'ngx-open-category-seo-code',
  templateUrl: './open-category-seo-code.component.html',
  styleUrls: ['./open-category-seo-code.component.scss']
})
export class OpenCategorySeoCodeComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  public data:any[];
  ddlLanguage={}
  public indexSelected:any;
  @Output() saveCatSeoCode: EventEmitter<any> = new EventEmitter();
  translations: any;

  constructor(public dialogService:NbDialogService ) {

    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
    this.data =[];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.saveCatSeoCode.emit(this.rowData);
  }
  openModal(languageId) {
    
    this.indexSelected = this.rowData.seoCodeGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoCodeGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          const ref = this.dialogService.open(ModalOpenCategorySeoCodeComponent, {
           context: {
           
            title: 'Edit SeoCode',
            seoCodeId:this.data[0].id,
            newsCategoryId:this.data[0].newsCategoryId,
            languageId: this.data[0].languageId,
            seoCodeName:this.data[0].code,
            redirectStatusCode:this.data[0].redirectStatusCode,
            staticRedirectUrl:this.data[0].staticRedirectUrl,
            redirectToId:this.data[0].redirectToId
           },
         });

         ref.componentRef.instance["editCategorySeoCode"].subscribe((data) => {
          ref.close();
  
          this.rowData.seoCodeGetAllSub[this.indexSelected].newsCategoryId = data.newsCategoryId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].code = data.code;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectStatusCode = data.redirectStatusCode;
          this.rowData.seoCodeGetAllSub[this.indexSelected].redirectToId = data.redirectToId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].staticRedirectUrl = data.staticRedirectUrl;
          this.rowData.seoCodeGetAllSub[this.indexSelected].languageId = data.languageId;
          this.rowData.seoCodeGetAllSub[this.indexSelected].id = data.id;
          this.saveCatSeoCode.emit(this.rowData);
        });
        ref.componentRef.instance["deleteCategorySeoCode"].subscribe((data) => {
          ref.close();
          const indexToRemove =  this.rowData.seoCodeGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.seoCodeGetAllSub.splice(indexToRemove,1);
          this.saveCatSeoCode.emit(this.rowData);
        });
       return;
    }
    else{
      const ref =  this.dialogService.open(ModalOpenCategorySeoCodeComponent, {
        context: {
          title: 'Create SeoCode',
          seoCodeId:'',
          newsCategoryId:this.rowData.id,
          languageId: languageId,
          seoCodeName:'',
          redirectStatusCode:null,
          staticRedirectUrl:'',
          redirectToId:''
        },
      });

      ref.componentRef.instance["addCategorySeoCode"].subscribe((data) => {
        ref.close();
        this.rowData.seoCodeGetAllSub.push(data);
        this.saveCatSeoCode.emit( this.rowData);
      })
     return;

    }
  }



}
