import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

import { SmartTableService } from '../../@core/data/smart-table.service';
import { Subscription } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { GetAllUserAdminVersion } from '../../queryMutation';

@Component({
  selector: 'ngx-smart-table',
  templateUrl: './smart-table.component.html',
  styles: ['./smart-table.component.css'],
})
export class SmartTableComponent implements OnInit {
  public querySubscriptionListMoveryUser: Subscription;
  public queryGetAllUsers: any;
  public businessPagination: QueryRef<any>;
  limit:number=25;
  baseUserGetAll:any[];
  error: any;
  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo:Apollo,public service: SmartTableService) {
    this.queryGetAllUsers = new GetAllUserAdminVersion({}, this.limit).getQuery();
    this.baseUserGetAll = [];
   }

   async getAllBaseUser() {
   
    this.businessPagination = this.apollo.watchQuery<Response>({
      query: this.queryGetAllUsers,
      fetchPolicy: 'network-only',
      variables: {
        data: {

        },
      },
    });

    this.querySubscriptionListMoveryUser = this.businessPagination.valueChanges.subscribe((data: any) => {
      const lastId = data.data.baseUserGetAll.length > 0 ? ((data.data['baseUserGetAll'][data.data['baseUserGetAll'].length - 1]['id'])) : null;
      console.log(lastId);
      this.queryGetAllUsers = new GetAllUserAdminVersion({}, this.limit, lastId).getQuery();
      this.businessPagination.fetchMore({
        query: this.queryGetAllUsers,
        variables: {
          data: {
            
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {

          if (fetchMoreResult['baseUserGetAll'].length < 1) {
            this.baseUserGetAll.push(...prev['baseUserGetAll']);


            this.source.load(this.baseUserGetAll);
            setTimeout(() => {    //<<<---    using ()=> syntax
              this.source.refresh();
            }, 3000);
            return prev;
          } else {
            return Object.assign({}, prev, {
              baseUserGetAll: [...prev['baseUserGetAll'], ...fetchMoreResult['baseUserGetAll']]
            });

          }
        }
      });
    });


  }
  async ngOnInit() {

    await this.getAllBaseUser();
  
  }
  settings = {
    add: {

    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      // deleteButtonContent: '<i class="nb-trash"></i>',
      // confirmDelete: true,
    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
      },
      name: {
        title: 'Nome',
        type: 'string',
      },
      surname: {
        title: 'Cognome',
        type: 'string',
      },
      email: {
        title: 'Email',
        type: 'string',
      },
      emailConfirmed: {
        title: 'Email Confirmed',
        type: 'boolean',
      },
    },
  };

  
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      event.confirm.resolve();
    } else {
      event.confirm.reject();
    }
  }
}
