import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  public static LIMIT_QUERY = 50

  public goBack() {
    this._location.back()
  }

  public showSpinner() {
    this.spinner.show()
  }

  public hideSpinner(milli = 2000) {
    setTimeout(() => {
      this.spinner.hide();
    }, milli)
  }

  constructor(public _location:Location, public spinner: NgxSpinnerService) { }
}
