
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { FaqsModalOpenTranslationComponent } from '../faqs-modal-open-translation/faqs-modal-open-translation.component';

@Component({
  selector: 'ngx-faqs-open-translation-action',
  templateUrl: './faqs-open-translation-action.component.html',
  styleUrls: ['./faqs-open-translation-action.component.scss']
})
export class FaqsOpenTranslationActionComponent implements OnInit {

  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  public data:any[];
  @Output() saveFaqTrans: EventEmitter<any> = new EventEmitter();
  translationIt="";
  public indexSelected:any;
  constructor(public dialogService:NbDialogService ) {
this.data=[];

  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.saveFaqTrans.emit(this.rowData);
  }

  openEditModal(languageId) {
    
    this.indexSelected = this.rowData.faqTranslationGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.faqTranslationGetAllSub.filter(item => item.languageId == languageId);

    if(this.data.length > 0){
      console.log(this.data[0].id);
          const ref = this.dialogService.open(FaqsModalOpenTranslationComponent, {
           context: {
            title: 'Edit Translation',
            faqId:this.rowData.id,
            translationName : this.data[0].name,
            translationId: this.data[0].id,
            languageId:languageId,
            description:this.data[0].description
           },
         });

         ref.componentRef.instance["editFaqTrans"].subscribe((data) => {
          ref.close();
          this.rowData.faqTranslationGetAllSub[this.indexSelected].id = data.id;
          this.rowData.faqTranslationGetAllSub[this.indexSelected].faqId = data.faqId;
          this.rowData.faqTranslationGetAllSub[this.indexSelected].name = data.name;
          this.rowData.faqTranslationGetAllSub[this.indexSelected].description = data.description;
          this.rowData.faqTranslationGetAllSub[this.indexSelected].languageId = data.languageId;
      
          this.saveFaqTrans.emit(this.rowData);
        });
     
      ref.componentRef.instance["deleteFaqTrans"].subscribe((data) => {
        ref.close();
        const indexToRemove =  this.rowData.faqTranslationGetAllSub.findIndex(res => res.id == data.id);
        this.rowData.faqTranslationGetAllSub.splice(indexToRemove,1);
        this.saveFaqTrans.emit(this.rowData);
      });
         return;
       
    }
    else{
      const ref =  this.dialogService.open(FaqsModalOpenTranslationComponent, {
        context: {
          title: 'Insert Translation',
          faqId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          description:""
        },
      });

      ref.componentRef.instance["addFaqTrans"].subscribe((data) => {
        ref.close();
        this.rowData.faqTranslationGetAllSub.push(data);
        this.saveFaqTrans.emit(this.rowData);
      });
      return;
    }
  }


}
