import { Component, OnInit, Compiler } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { ZipService } from '../../../service/zipFile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewsTranslationComponent } from '../../news/news-translation/news-translation.component';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { imageGetAll, imageGetAllAdminVersion, getAllImageFromParent, pointOfInterestImageGetAll } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { FileUploadService } from '../../../service/file-upload.service';
import { forkJoin, concat } from 'rxjs';
import { Location } from '@angular/common';
import { concatMap } from 'rxjs/operators';
import { HttpRequestService } from '../../../service/http-request.service';

@Component({
  selector: 'ngx-poi-cover-image-upload-action',
  templateUrl: './poi-cover-image-upload-action.component.html',
  styleUrls: ['./poi-cover-image-upload-action.component.scss']
})
export class PoiCoverImageUploadActionComponent implements OnInit {

  limit = 50;
  selectedMode: boolean = true;
  // This will contain selected rows
  selectedRows: any;
  showbtnUpload : boolean = false;
  public querySubscriptionImageCreate: Subscription;
  isPoiFilterAtive:any;
  public queryGetAllImage: any;
  public businessPagination: QueryRef<any>;

  public dataProvince: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn:boolean=false;
  public imageAll : any[];
  public imageParentAll : any[];
  public imageGetByTag:any[];
  source: LocalDataSource = new LocalDataSource();


  constructor(public apollo: Apollo,public _location:Location, public service: SmartTableService, public zipS:ZipService ,public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler,public spinner: NgxSpinnerService,public fileUp:FileUploadService,public httpS:HttpRequestService) {
  this.imageAll = [];
  this.imageGetByTag=[];
  this.queryGetAllImage = new imageGetAllAdminVersion({}, this.limit).getQuery();

   }
  settings = {
    pager: {
      perPage: 50,
    },
    selectMode: 'multi',
    actions: {
      add: false,
      edit: false,
      delete:false
    },
    columns: {

      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      fileName: {
        title: 'Name',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          var trans = ""
          var fil = row.imageTranslationGetAllSub.filter(item => item.languageId == 1);
          if (fil.length > 0) {
            trans = "</div>" + fil[0].name + "<div>"
          }
          row.fullName = "<div>"+ row.fileName + trans + "</div>"
          return this._sanitizer.bypassSecurityTrustHtml(row.fullName) 
          //
        }
      },
      url: {
        title: 'Image',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => { 
          if(row.url ){

            row.UrlConverted = "<img class='imgRendering' src="+row.url+" style='height:100px;width: 100px;'>";
            return this._sanitizer.bypassSecurityTrustHtml(row.UrlConverted) }
          }
      },
      backOfficeData: {
        title: 'Image Position',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => { 
          if(row.backOfficeData){

            const data = JSON.parse(row.backOfficeData);
            if(data !== ""){

              if (data.imagePosition !== undefined) {
                data.imagePosition = (data.imagePosition == "") ? this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>") : data.imagePosition;

                return data.imagePosition
              } else {
                return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
              }
          }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
           
          }
        }
      },
      mimeType: {
        title: 'mimeType',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.mimeType) {
            
            return row.mimeType;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      }
 
    },
  };

  async ngOnInit() {
    console.log("ngOnInit")
    this.poiId = parseInt(localStorage.getItem("poiId"))

    //await this.getAllImageByParent(true)
    await this.getImageFromId(this.poiId)
  }

  async getImageFromId(id) {
    console.log("getImageFromId", "poi", id)

    var lastId = this.imageParentAll != null && this.imageParentAll.length > 0 ? this.imageParentAll[this.imageParentAll.length-1].id : null

    if (lastId == null) {
      this.imageParentAll = []
      this.spinner.show(undefined, {
        type: "square-spin",
        size: "medium",
        bdColor: "rgba(100,149,237, .8)",
        color: "white",
        fullScreen: true,
      });
    }
    this.apollo.query({
      query: new pointOfInterestImageGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          'parentId': id,
          'role': 'ORIGINAL',
          'mimeType': 'image/webp',
          'tags': 'POI_COVER_IMAGE'
        }
      }
    }).subscribe((data : any) => {
      var result = data.data.pointOfInterestImageGetAll
      console.log("getImageFromId", result)
      this.imageParentAll.push(...result)
      if (result.length == this.limit) {
        this.getImageFromId(id)
      } else {
        this.imageParentAll = this.imageParentAll.map((item) => {
          return item.imageGetSub
        })
        this.loadMore()
        //this.getAllImageByParent(true)
      }
    })
  }

  onRowSelect(event) {
    console.log("event", event)
    this.selectedRows = event.selected;
    /*
    if (event.isSelected == true)
      this.getAllImagebyTagName();
    else {
      this.deselectedAllImagebyTagName();
    }*/
  }

  pointOfInterestImageGetAllSub = []
  poiId = 0

  async loadMore() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });

    var lastId = this.imageAll.length > 0 ? this.imageAll[this.imageAll.length-1].id : null
    this.apollo.use("super-admin").query<Response>({
      query: new imageGetAllAdminVersion({}, this.limit, lastId).getQuery(),
      fetchPolicy:'network-only',
      variables: {
        data: {
          'role': "ORIGINAL",
          'belongsTo': 'SUPER_ADMIN',
          'filePermission': "PUBLIC",
          'mimeType': "image/webp"
        },
      },
    }).subscribe((data: any) => {
      console.log("loadMore", data.data.imageGetAllAdminVersion);
      this.imageAll.push(...data.data.imageGetAllAdminVersion)
      this.imageAll = this.imageAll.map((item) => {
        return item;
      })
      //this.source.load(this.imageAll)
      this.filtra()
      this.spinner.hide()
    })
  }

  isAssociato = false
  isNonAssociato = true
  isNonAssociatoLength = ""
  isAssociatoLength = ""

  isAssociatoChange() {
    this.isAssociato = !this.isAssociato
    this.filtra()
  }
  isNonAssociatoChange() {
    this.isNonAssociato = !this.isNonAssociato
    this.filtra()
  }

  filtra() {
    var imageFilter2 = []
    var ids_imageParentAll = this.imageParentAll.map(e => e.id)
    this.imageAll.forEach(e => {
      if (ids_imageParentAll.includes(e.id) == this.isAssociato) {
        imageFilter2.push(e)
      }
    })
    var a = this.imageAll.length - imageFilter2.length
    var b = this.imageAll.length - a
    var isAssociatoLength = this.isAssociato ? b : a
    var isNonAssociatoLength = this.isAssociato ? a : b
    this.isAssociatoLength = `(${isAssociatoLength})`
    this.isNonAssociatoLength = `(${isNonAssociatoLength})`
    if (this.isAssociato && this.isNonAssociato) {
      this.source.load(this.imageAll);
    } else if (this.isAssociato || this.isNonAssociato) {
      this.source.load(imageFilter2);
    } else {
      this.source.load([]);
    }
  }

  async loadAll() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this._loadAll()
  }
  async _loadAll() {
    var lastId = this.imageAll.length > 0 ? this.imageAll[this.imageAll.length-1].id : null
    this.apollo.use("super-admin").query<Response>({
      query: new imageGetAllAdminVersion({}, this.limit, lastId).getQuery(),
      fetchPolicy:'network-only',
      variables: {
        data: {
          'role': "ORIGINAL",
          'belongsTo': 'SUPER_ADMIN',
          'filePermission': "PUBLIC",
          'mimeType': "image/webp"
        },
      },
    }).subscribe((data: any) => {
      console.log("_loadAll", data.data.imageGetAllAdminVersion)
      this.imageAll.push(...data.data.imageGetAllAdminVersion)
      this.imageAll = this.imageAll.map((item) => {
        return item;
      })

      if (data.data.imageGetAllAdminVersion.length == this.limit) {
        this._loadAll()
      } else {
        this.spinner.hide()
        //this.source.load(this.imageAll)
        this.filtra()
      } 
      
    })
  }

  async getAllImageByParent(isInit = false) {
    var length = this.pointOfInterestImageGetAllSub.length
    var lastId = length == 0 ? null : this.pointOfInterestImageGetAllSub[length-1].id
    this.apollo.query({
      query: getAllImageFromParent.pointOfInterestGet,
        fetchPolicy: 'network-only',
        variables: {
          id: this.poiId,
          limit: this.limit,
          cursor: lastId
        },
      })
      .subscribe(
        (result: any) => {
          var data = result.data.pointOfInterestGet.pointOfInterestImageGetAllSub
          console.log("pointOfInterestImageGetAllSub", data)
          this.pointOfInterestImageGetAllSub.push(...data)
          if (data.length == this.limit)
            this.getAllImageByParent(isInit)
          else {
            console.log("pointOfInterestImageGetAllSub", "END", this.pointOfInterestImageGetAllSub.length)
            if (isInit){
             this.getImage()
            } else {
              setTimeout(() => {
                this.spinner.hide();
              }, 2000);
            }
          }
        }
      );

  }

  imageGetAllAdmin = []

  getImageFromTag() {
    console.log("getImageFromTag", this.selectedRows)


    this.pointOfInterestImageGetAllSub.forEach(element => {
      console.log("element", element.imageGetSub)
    });

    var map = this.pointOfInterestImageGetAllSub.map(element => element.imageGetSub.id)
    console.log("map", map)

    var filter = []
    this.selectedRows.forEach(element => {
      console.log("map", element.id, map.includes(element.id))
      if (!map.includes(element.id))
        filter.push(element)
    })
    console.log("filter", filter)
    var end = filter.length;
    this.imageGetAllAdmin = []

    if (end == 0) {
      setTimeout(() => {
        this.spinner.hide();
      }, 2000)
      return
    }

    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });

    filter.forEach(element => {
      console.log("element", element)

      this.apollo.use("super-admin").query<Response>({
        query: new imageGetAllAdminVersion({}, this.limit, null).getQuery(),
        fetchPolicy:'network-only',
        variables: {
          data: {
            'tags': element.tags[0],
            'belongsTo': "SUPER_ADMIN",
            'filePermission': "PUBLIC"
          },
        },
      }).subscribe((data: any) => {
        console.log("data", data.data.imageGetAllAdminVersion)
        this.imageGetAllAdmin.push(...data.data.imageGetAllAdminVersion)
        if (--end <= 0) {
          var tasks$ = []
          end = this.imageGetAllAdmin.length
          var index = 0
          for (let index = 0; index < this.imageGetAllAdmin.length; index++) {
            var image = this.imageGetAllAdmin[index]
            if(image.tags.indexOf("POI_COVER_IMAGE") == -1){
              image.tags.push("POI_COVER_IMAGE");
            }
          
              tasks$.push(this.fileUp.pointOfInterestImageCreate(image.id, this.poiId, null, JSON.stringify("")).pipe(
                concatMap((first) => {    
                  return this.fileUp.updateImage(image.id, image.tags);
                })
              ));

          }
          console.log(tasks$)
    
          concat(...tasks$).subscribe((y: any) => {

            console.log("element add", y, y.data.imageUpdate.id)
            /*
            this.pointOfInterestImageGetAllSub.push({
              id: this.imageGetAllAdmin[index++].id,
              imageGetSub: {
                id: y.data.imageU
              }
            })
        */
            if (--end <= 0) {
              this.pointOfInterestImageGetAllSub = []
              this.getAllImageByParent()
            }
          }, (err) => {
            console.log("Error", err)
            alert("Error!")
            setTimeout(() => {
              this.spinner.hide();
              this.router.navigateByUrl("/pages/pointOfInterest/list");
            }, 2000);
          });
        }
      })


    });
  }

goCitta(){
  this._compiler.clearCache();
  this.router.navigateByUrl("/pages/pointOfInterest/list");
}


_getAllImagebyTagName(lastId = null) {
  this.apollo.use("super-admin").query<Response>({
    query: new imageGetAllAdminVersion({}, this.limit, lastId).getQuery(),
    fetchPolicy:'network-only',
    variables: {
      data: {
        'tags':this.selectedRows.tags[0],
        'belongsTo':"SUPER_ADMIN",
        'filePermission':"PUBLIC"
      },
    },
  }).subscribe((data: any) => {
    console.log("_getAllImagebyTagName", data.data.imageGetAllAdminVersion);
    this.imageGetByTag.push(...data.data.imageGetAllAdminVersion)
    if (data.data.imageGetAllAdminVersion.length > 0) {
      lastId = this.imageAll[this.imageAll.length-1].id;
      this._getAllImagebyTagName(lastId);
    } else {
      console.log(this.imageGetByTag)
    }
  });
}
deselectedAllImagebyTagName() {
  console.log("deselectedAllImagebyTagName", this.selectedRows.tags[0])
  this.imageGetByTag = this.imageGetByTag.filter(item => {
    console.log(item.tags[0], this.selectedRows.tags[0], item.tags[0] != this.selectedRows.tags[0]);
    return item.tags[0] != this.selectedRows.tags[0]
  })
  console.log("deselectedAllImagebyTagName", this.imageGetByTag)

}

async  getAllImagebyTagName(){
  this._getAllImagebyTagName()

/*
  this.businessPagination = this.apollo.use("super-admin").watchQuery<Response>({
    query: this.queryGetAllImage,
    fetchPolicy:'network-only',
    variables: {
      data: {
        'tags':this.selectedRows.tags[0],
        'belongsTo':"SUPER_ADMIN",
        'filePermission':"PUBLIC"
      },
    },
  });

  this.querySubscriptionImageCreate = this.businessPagination.valueChanges.subscribe((data: any) => {
    const lastId = data.data.imageGetAllAdminVersion.length > 0 ? ((data.data['imageGetAllAdminVersion'][data.data['imageGetAllAdminVersion'].length - 1]['id'])) : null;
    this.queryGetAllImage = new imageGetAllAdminVersion({}, this.limit, lastId).getQuery();
    this.businessPagination.fetchMore({
      query: this.queryGetAllImage,
      
      variables: {
        data: {
          'tags':this.selectedRows.tags[0],
        'belongsTo':"SUPER_ADMIN",
        'filePermission':"PUBLIC"
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult['imageGetAllAdminVersion'].length < 1) {
          this.imageGetByTag.push(...prev['imageGetAllAdminVersion']);
      
          this.imageGetByTag = this.imageGetByTag.map((item) => {
            return item;
          })

          return prev;
        } else {
          return Object.assign({}, prev, {
            imageGetAllAdminVersion: [...prev['imageGetAllAdminVersion'], ...fetchMoreResult['imageGetAllAdminVersion']]
          });

        }
      }
    });
  });
  */
  }

  setDataToList(data){
    var imageData = data.filter(item => item.mimeType == "image/webp");

    this.source.load(imageData);
    this.spinner.hide();
  }


getImage(lastId = null) {
  console.log("getImage", "lastId=" + lastId);
  this.apollo.use("super-admin").query<Response>({
    query: new imageGetAllAdminVersion({}, this.limit, lastId).getQuery(),
    fetchPolicy:'network-only',
    variables: {
      data: {
        'role': "ORIGINAL",
        'belongsTo': 'SUPER_ADMIN',
        'filePermission': "PUBLIC",
        'mimeType': "image/webp"
      },
    },
  }).subscribe((data: any) => {
    console.log("getImage", data.data.imageGetAllAdminVersion);

    this.imageAll.push(...data.data.imageGetAllAdminVersion)

    if (data.data.imageGetAllAdminVersion.length == 0) {
      this.imageAll = this.imageAll.map((item) => {
        return item;
      })
      this.source.load(this.imageAll);
      this.spinner.hide();
    } else {
      lastId = this.imageAll[this.imageAll.length-1].id;
      this.getImage(lastId);
    }
    
  })
}

    isFilterAtive(event){
      this.source.reset();
      if (event.target.checked == true) {
        //var imageFilter2 : any[] = this.httpS.filterImageByTags(this.imageAll);
        var imageFilter2 = []
        var ids_imageParentAll = this.imageParentAll.map(e => e.id)
        this.imageAll.forEach(e => {
          //console.log("imageFilter2", e.id)
          if (ids_imageParentAll.includes(e.id) == false) {
            imageFilter2.push(e)
          }
        })
        this.source.load(imageFilter2);
      } else {
        this.source.load(this.imageAll);
      }
    }
    removeDuplicates(array, key) {
      let lookup = new Set();
      return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
     }
     CreateImageUpload() {
      this.getImageFromTag()
     }
    _CreateImageUpload() {
      this.spinner.show(undefined, {
        type: "square-spin",
        size: "medium",
        bdColor: "rgba(100,149,237, .8)",
        color: "white",
        fullScreen: true,
      });
      const dataP =   {
        position:-1
      }
      var tasks$ = [];
      var imageFilter2:any[] = this.removeDuplicates(this.imageGetByTag, 'id');
      console.log(imageFilter2);
      for (let index = 0; index < imageFilter2.length; index++) {

        if(imageFilter2[index].tags.indexOf("POI_COVER_IMAGE") == -1){
          imageFilter2[index].tags.push("POI_COVER_IMAGE");
        }
      
          tasks$.push(this.fileUp.pointOfInterestImageCreate(imageFilter2[index].id, parseInt(localStorage.getItem("poiId")),null,JSON.stringify("")).pipe(
            concatMap((first) => {    
              return this.fileUp.updateImage(imageFilter2[index].id,imageFilter2[index].tags);
            })
          ));

      }
      console.log(tasks$)
  
      return concat(...tasks$).subscribe(y => {
        this.showbtnUpload=true;
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
  
      }, (err) => {
        console.log("Error", err)
        alert("Error!")
        setTimeout(() => {
          this.spinner.hide();
          this.router.navigateByUrl("/pages/pointOfInterest/list");
        }, 2000);
      })
  
    }
  goBack(){
    this._location.back();
  }
}


