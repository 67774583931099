import { Component, OnInit } from '@angular/core';
import { Subscription } from 'apollo-client/util/Observable';
import { Apollo } from 'apollo-angular';
import { passwordResetConfirmation, changeEmailConfirmation } from '../queryMutation';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-change-email-confirmation',
  templateUrl: './change-email-confirmation.component.html',
  styleUrls: ['./change-email-confirmation.component.scss']
})
export class ChangeEmailConfirmationComponent implements OnInit {

  public querySubscriptionProfileUser : Subscription
  changeEmailConfirmation:any={};
  constructor(public apollo:Apollo,public _location:Location) { }

  ngOnInit() {
  }


  sendchangeEmailConfirmation(){
    this.querySubscriptionProfileUser =  this.apollo.use("base-user").mutate({
      mutation: changeEmailConfirmation.changeEmailConfirmation,
      variables: {
          'data': {
            'newEmail':this.changeEmailConfirmation.email,
            'token':this.changeEmailConfirmation.token
          }
      },
    }).subscribe((result: any) => {
      if (result.data['changeEmailConfirmation']['success']) {
        console.log(result.data.changeEmailConfirmation);
        
      } 
     });
  } 
  goBack(){
    this._location.back();
  }
}
