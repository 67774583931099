import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { pointOfInterestSetBackOfficeDataDemo, journeySetBackOfficeDataDemo, journeyAdminSelectedImageGetAll, poiAdminSelectedImageGetAll, pointOfInterestImageGetAll } from '../../../queryMutation';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogService } from '@nebular/theme';
import { ModalPoiCoverEditPositionActionComponent } from '../modal-poi-cover-edit-position-action/modal-poi-cover-edit-position-action.component';
import { FileUploadService } from '../../../service/file-upload.service';
import { concat } from 'rxjs';

@Component({
  selector: 'ngx-poi-open-cover-edit-position-action',
  templateUrl: './poi-open-cover-edit-position-action.component.html',
  styleUrls: ['./poi-open-cover-edit-position-action.component.scss']
})
export class PoiOpenCoverEditPositionActionComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  public querySubscriptionProfileUser: Subscription;
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage = {}
  @Output() save: EventEmitter<any> = new EventEmitter();
  translationIt = "";
  public limit: number = 25;
  public imageGetByPosition: any[];
  public businessPagination: QueryRef<any>;
  public businessPaginationImage: QueryRef<any>;
  public queryGetAllImageByPosition: any;
  public querySubscriptionImageUpdate: Subscription;
  public data = {
    imagePosition: ""
  }
  constructor(public router: Router, public apollo: Apollo, public spinner: NgxSpinnerService, public dialogService: NbDialogService, public fileUp: FileUploadService) {

    this.imageGetByPosition = [];
    this.queryGetAllImageByPosition = new pointOfInterestImageGetAll({}, this.limit).getQuery();
  }


  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
  }

  async openEditModal() {

    console.log(this.rowData);
    //await this.onEditConfirm(this.rowData);

  }

  removeDuplicates(array, key) {
    let lookup = new Set();
    return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
  async setTopStyle(newValue) {

    console.log(this.rowData);
    this.data.imagePosition = newValue;


    this.rowData.backOfficeData = JSON.stringify(this.data);


    this.getAllImagebyPosition(this.rowData).then((res: any[]) => {

      const FilterData: any[] = res.filter(item => item.poiAdminSelectedId == this.rowData.poiAdminSelectedId);
      const data: any[] = this.removeDuplicates(FilterData, 'imageId');

      console.log(data);
      this.Edit(data);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  async setBottomStyle(newValue) {

    this.data.imagePosition = newValue;

    this.rowData.backOfficeData = JSON.stringify(this.data);


    this.getAllImagebyPosition(this.rowData).then((res: any[]) => {

      const FilterData: any[] = res.filter(item => item.pointOfInterestId == this.rowData.pointOfInterestId);
      const data: any[] = this.removeDuplicates(FilterData, 'imageId');

      console.log(data);
      this.Edit(data);
    })
      .catch((err) => {
        console.log(err);
      });


  }


  async  getAllImagebyPosition(event): Promise<any> {
    this.imageGetByPosition=[];
    return new Promise((resolve, reject) => {
      this.businessPaginationImage = this.apollo.watchQuery<Response>({
        query: this.queryGetAllImageByPosition,
        variables: {
          data: {
            'tags': event.tags[0]

          },
        },
      });

      this.querySubscriptionImageUpdate = this.businessPaginationImage.valueChanges.subscribe((data: any) => {
        const lastId = data.data.pointOfInterestImageGetAll.length > 0 ? ((data.data['pointOfInterestImageGetAll'][data.data['pointOfInterestImageGetAll'].length - 1]['id'])) : null;
        this.queryGetAllImageByPosition = new pointOfInterestImageGetAll({}, this.limit, lastId).getQuery();
        this.businessPaginationImage.fetchMore({
          query: this.queryGetAllImageByPosition,
          variables: {
            data: {
              'tags': event.tags[0]

            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult['pointOfInterestImageGetAll'].length < 1) {

              this.imageGetByPosition.push(...prev['pointOfInterestImageGetAll']);

              for (let index = 0; index < this.imageGetByPosition.length; index++) {
                this.imageGetByPosition[index].positionCover = event.positionCover;
                this.imageGetByPosition[index].backOfficeData = JSON.stringify(event.backOfficeData);
              }
              resolve(this.imageGetByPosition);
              // return prev;
            } else {
              return Object.assign({}, prev, {
                pointOfInterestImageGetAll: [...prev['pointOfInterestImageGetAll'], ...fetchMoreResult['pointOfInterestImageGetAll']]
              });

            }
          }
        });
      });
    });
  }

  Edit(data) {

    var tasks$ = [];

    for (let index = 0; index < data.length; index++) {

      if (data[index].positionCover == null)
        data[index].positionCover = null
      tasks$.push(this.fileUp.pointOfInterestImageUpdate(data[index].id, data[index].imageId, data[index].pointOfInterestId, data[index].positionCover,data[index].backOfficeData));
    }

    return concat(...tasks$).subscribe(y => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();
        this.save.emit(this.rowData);
      }, 2000);


    }, (err) => {
      // this._compiler.clearCache();
      setTimeout(() => {
        // this.router.navigateByUrl("/pages/journey/list-journey");
        this.spinner.hide();

      }, 2000);

    })
  }
}



