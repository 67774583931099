import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';

// Apollo
import { ApolloModule, Apollo } from 'apollo-angular';
import { setContext } from 'apollo-link-context';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment.prod';
import { onError } from 'apollo-link-error'
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ApolloLink } from 'apollo-link';
import { Router } from '@angular/router';
import { FileUploadService } from './service/file-upload.service';
// GraphiQL
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem("token"));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      // headers,
      'Authorization': token ? `Bearer ${token}` : ``,
      'content-type': 'text/plain'
    }
  };
});

const publicLink = setContext((_, { headers }) => {
  return {
    headers: {
      'content-type': 'text/plain'
    }
  };
});

@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule
  ],
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ],
  declarations: []
})
export class GraphqlModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    spinner: NgxSpinnerService,
    public serviceUpload: FileUploadService,

    public _router: Router
  ) {


    const link = onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case 'UNAUTHENTICATED':
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                ...oldHeaders,
                authorization: ''
              });
              alert("Token is expired");
              console.log('expired token?');
              return forward(operation);
          }
        }
      }

      if (networkError) {
        if ((networkError as HttpErrorResponse).status === 401) {
          if (localStorage && localStorage.getItem('refreshToken')) {
            this.serviceUpload.loginRefresh(
              localStorage.getItem('refreshToken'),
              parseInt(localStorage.getItem('userLoggedId'), 10)
            ).subscribe((refreshLogin) => {
              console.log('Refreshing Login');
              if (!refreshLogin.data) {
                alert("Token is not refreshed");
                return;
              }
              alert("Token is successfully refreshed");
              localStorage.setItem('userId', refreshLogin.data.loginRefresh.userId.toString());
              localStorage.setItem('refreshToken', refreshLogin.data.loginRefresh.refreshToken);
              localStorage.setItem("userLoggedId", refreshLogin.data.loginRefresh.userId.toString());
              localStorage.setItem('token', JSON.stringify(refreshLogin.data.loginRefresh.token));
            });

            this._router.routeReuseStrategy.shouldReuseRoute = () => false;
            this._router.onSameUrlNavigation = 'reload';
            this._router.navigateByUrl(this._router.url);
            return forward(operation);
          }

          //redirect to login
          this._router.navigate(['auth/login']);
          return;
        }

        if ((networkError as HttpErrorResponse).status === 502) {
   
          alert("The server is down. Please try again later CODE 502");
          return;
        }
        
        if ((networkError as HttpErrorResponse).status === 400) {
   
          alert("An error ocurred. Please try again later CODE 400");
          return;
        }
      }
    });



    // create Apollo Multiple Client
    apollo.create({
      link: publicLink.concat(httpLink.create({ uri: environment.baseurl + '/api/v1/public/graphql' })),
      cache: new InMemoryCache()
    });

    apollo.create({
      link: authLink.concat(ApolloLink.from([
        link,
        httpLink.create({ uri: environment.baseurl + '/api/v1/authenticated/user/graphql' })
      ])),
      cache: new InMemoryCache()
  
    }, 'base-user');

    apollo.create({
      link: authLink.concat(ApolloLink.from([
        link,
        httpLink.create({ uri: environment.baseurl + '/api/v1/authenticated/super-admin/graphql' })
      ])),
      cache: new InMemoryCache()
   
    }, 'super-admin');

    apollo.create({
      link: authLink.concat(ApolloLink.from([
        link,
        httpLink.create({ uri: environment.baseurl + '/api/v1/authenticated/movery-user/graphql' })
      ])),
      cache: new InMemoryCache(),
    
    }, 'movery-user');

    apollo.create({
      link: authLink.concat(ApolloLink.from([
        link,
        httpLink.create({ uri: environment.baseurl + '/api/v1/authenticated/municipality-operator/graphql' })
      ])),
      cache: new InMemoryCache()
    }, 'municipality')


    apollo.create({
      link: authLink.concat(ApolloLink.from([
        link,
        httpLink.create({ uri: environment.baseurl + '/api/v1/authenticated/editor/graphql' })
      ])),
      cache: new InMemoryCache()
    }, 'editor')


  }
}