


import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModelEditArticoliComponent } from '../model-edit-articoli/model-edit-articoli.component';
import { Route } from 'aws-sdk/clients/apigatewayv2';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-open-news-cover-image-upload',
  templateUrl: './open-news-cover-image-upload.component.html',
  styleUrls: ['./open-news-cover-image-upload.component.scss']
})


export class OpenNewsCoverImageUploadComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() save: EventEmitter<any> = new EventEmitter();
  public setData:any[];
  public data = {
    data : {},
    isSelected : true,
    selected:[]
  
  };
  translationIt="";

  class = "btn-success"

  constructor(public router:Router) {
this.setData =[];
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
      if (this.rowData.newsImageAssociationGetAllSub.filter(item => (item.imageGetSub.role.includes("ORIGINAL") && item.imageGetSub.tags.includes("NEWS_COVER_IMAGE"))).length == 0) {
        this.class = "btn-danger"
      }
  }

  onClick() {
      this.save.emit(this.rowData);
  }


  openAddModal() {
    if(this.rowData.id > 0){

    
      console.log(this.rowData);
      
     localStorage.setItem("newsId",this.rowData.id);
     this.router.navigateByUrl("/pages/articoli/cover-uploadImage");
  }
}
removeDuplicates(array, key) {
  let lookup = new Set();
  return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
 }
openEditModal() {

  localStorage.removeItem("imageData");
  if(this.rowData.id > 0){

  var imageFilter = this.rowData.newsImageAssociationGetAllSub.filter(item => (item.imageGetSub.role.includes("ORIGINAL")) && (item.imageGetSub.tags.includes("NEWS_COVER_IMAGE")));
  for (let index = 0; index < imageFilter.length; index++) {

    imageFilter[index].imageGetSub.positionCover = imageFilter[index].positionCover;
    imageFilter[index].imageGetSub.imageId = imageFilter[index].imageId;
    imageFilter[index].imageGetSub.backOfficeData =  (imageFilter[index].backOfficeData == undefined) ?  "":imageFilter[index].backOfficeData;
    imageFilter[index].imageGetSub.newsId = imageFilter[index].newsId;
    this.setData.push(imageFilter[index].imageGetSub);
  }

  if(imageFilter.length > 0) {
    var imageFilter2:any[] = this.removeDuplicates(this.setData, 'id');
    console.log(imageFilter2);
    
    if (imageFilter2.length > 0) {

      for (let index = 0; index < imageFilter2.length; index++) {

        this.data.selected.push(imageFilter2[index]);
      }
      localStorage.setItem("imageData",JSON.stringify(this.data));
      localStorage.setItem("newsId",this.rowData.id);
      this.router.navigateByUrl("/pages/articoli/edit-cover-upload-image");
    }
  }


}
}
}

