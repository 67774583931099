  import { Component, OnInit, Compiler } from '@angular/core';
import { pointprova, municipalityGet, newsGetAll, newsDelete, pointOfInterestGetAll, pointOfInterestDelete, poiDayNoteGet, poiDayNoteGetAll, pointOfInterestSetBackOfficeDataDemo } from '../../../queryMutation';
import { OpenDialogComponent } from '../../news/open-dialog/open-dialog.component';
import { LocalDataSource } from 'ng2-smart-table';
import { Apollo, QueryRef } from 'apollo-angular';
import { SmartTableService } from '../../../@core/data/smart-table.service';
import { Subscription } from 'rxjs/internal/Subscription';

import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { OpenTranslationPoiComponent } from '../open-translation-poi/open-translation-poi.component';
import { OpenUpdatePointOfInterestComponent } from '../open-update-point-of-interest/open-update-point-of-interest.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenSeoCodePoiComponent } from '../open-seo-code-poi/open-seo-code-poi.component';
import { forkJoin } from "rxjs/observable/forkJoin";
import { Observable } from 'rxjs/Observable';
import { fromPromise } from 'apollo-link';
import { OpenSeoTagPoiComponent } from '../open-seo-tag-poi/open-seo-tag-poi.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClonePointOfInterestComponent } from '../clone-point-of-interest/clone-point-of-interest.component';
import { OpenTranslationBillingComponent } from '../open-translation-billing/open-translation-billing.component';
import { OpenPoiImageUploadActionComponent } from '../open-poi-image-upload-action/open-poi-image-upload-action.component';
import { OpenPoiCoverImageUploadActionComponent } from '../open-poi-cover-image-upload-action/open-poi-cover-image-upload-action.component';
import { OpenPoiDayNoteTranslationComponent } from '../open-poi-day-note-translation/open-poi-day-note-translation.component';
import { SetActiveOrDisativePaypalBtnComponent } from '../set-active-or-disative-paypal-btn/set-active-or-disative-paypal-btn.component';

@Component({
  selector: 'ngx-point-of-interest-paypal-config',
  templateUrl: './point-of-interest-paypal-config.component.html',
  styleUrls: ['./point-of-interest-paypal-config.component.scss']
})
export class PointOfInterestPaypalConfigComponent implements OnInit {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionDeletePoi: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetAllPointOfInterest: any;
  public queryGetNewsTranslationCategories: any;
  public PointOfInterestPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsGetAll: any[];
  public pointOfInterestGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  lastId = null;
  teste = [];
  newsData: any;
  limit: number = 25;
  datauri: any;
  myFile: Blob;
  profile: any;
  fileToUpload: File;
  response: any;
  selectedProduct: any;
  pageSize = 25;
  selectedRows: any;

  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo: Apollo, public _compiler: Compiler, public service: SmartTableService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public spinner: NgxSpinnerService) {

    this.queryGetAllPointOfInterest = new pointOfInterestGetAll({}, this.limit).getQuery();
    this.pointOfInterestGetAll = [];
  }

  async ngOnInit() {
    this._compiler.clearCache();
    this.source.reset();
    this.pointOfInterestGetAll = [];
    await this.getAllPontoDiInterese();

  }
  settings = {
    actions: {

      add: false,
      edit: false,
      delete:false,

    },
    columns: {
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      isPaypalActive:{
        title: 'Paypal Active',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.backOfficeData !== null) {

            var data = JSON.parse(row.backOfficeData);
            console.log(data)
            if(data !== null){
              if(data.paypalBtnActiveForSell == undefined){
                row.isPaypalActive = (data !== null)? "NO":"SI";
              }
              else{
                row.isPaypalActive = (data.paypalBtnActiveForSell === false)? "NO":"SI";
              }
              
            }
            return row.isPaypalActive;
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
      paypalActive:{
        title: 'Is Button Active',
        type: 'custom',
        renderComponent: SetActiveOrDisativePaypalBtnComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false

      },
      name: {
        title: 'Translation Name',
        type: 'html',
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.poiTranslationGetAllSub.length > 0) {


            var data = row.poiTranslationGetAllSub.filter(item => item.languageId == 1);

            if (data.length > 0) {

              row.name = data[0].name;
              return row.name;
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
    },
  };

  createPoi() {
    this.router.navigateByUrl("/pages/pointOfInterest/createPoi");
  }

  pageChange() {
    this.apollo.watchQuery<Response>({
      query: pointprova.pointprova,
      variables: {
        'data': {

        },
        'limit': 25,
        'idCursor': this.lastId
      },
    }).valueChanges.subscribe((data: any) => {
      this.lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['id'])) : null;
      if (this.source.count() > 0) {
        this.source.load(data.data.pointOfInterestGetAll);
        
      }
      else {
        this.source.load(data.data.pointOfInterestGetAll);
        
      }
    })
  };

  async getAllPontoDiInterese() {
    if (this.pointOfInterestGetAll.length === 0) {
      this.apollo.watchQuery<Response>({
        query: pointprova.pointprova,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
          },
          'limit': 25,
          'idCursor': this.lastId
        },
      }).valueChanges.subscribe((data: any) => {
        this.lastId = data.data.pointOfInterestGetAll.length > 0 ? ((data.data['pointOfInterestGetAll'][data.data['pointOfInterestGetAll'].length - 1]['id'])) : null;
        console.log(data.data.pointOfInterestGetAll);
        if (this.source.count() > 0) {
          this.source.load(data.data.pointOfInterestGetAll);
        }
        else {
          
          this.source.load(data.data.pointOfInterestGetAll);
        }
      });
    }
  }
}


