import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ModalPoiOpenTranslationItComponent } from '../modal-poi-open-translation-it/modal-poi-open-translation-it.component';
import { Router } from '@angular/router';
import { ModalPoiTypeTranslationComponent } from '../modal-poi-type-translation/modal-poi-type-translation.component';
import { ModalPoiDayNoteTranslationComponent } from '../modal-poi-day-note-translation/modal-poi-day-note-translation.component';
@Component({
  selector: 'ngx-open-poi-day-note-translation',
  templateUrl: './open-poi-day-note-translation.component.html',
  styleUrls: ['./open-poi-day-note-translation.component.scss']
})
export class OpenPoiDayNoteTranslationComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Input() data: any;
  ddlLanguage={}

  @Output() savePoiDayNote: EventEmitter<any> = new EventEmitter();
  translationIt="";

  public indexSelected:any;
  constructor(public dialogService:NbDialogService,public router:Router) {

    this.data = [];
    
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
      this.savePoiDayNote.emit(this.data);
  }

  openEditModal(languageId) {
    
    this.indexSelected = this.rowData.poiDayNoteGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.poiDayNoteGetAllSub.filter(item => item.languageId == languageId);
    
    console.log(this.data);

    if(this.data.length > 0){
      console.log(this.data[0].id);
        const ref = this.dialogService.open(ModalPoiDayNoteTranslationComponent, {
           context: {
             title: 'Edit Translation',
             pointOfInterestId:this.data[0].pointOfInterestId,
             translationName : this.data[0].name,
             translationId: this.data[0].id,
             languageId:languageId,
             startingDateTime:this.data[0].startingDateTime,
             endingDateTime:this.data[0].endingDateTime,
             
           },
         });
         ref.componentRef.instance["editPoiDayNoteTrans"].subscribe((data) => {
          ref.close();
  
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].pointOfInterestId = data.pointOfInterestId;
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].name = data.name;
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].startingDateTime = data.startingDateTime;
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].endingDateTime = data.endingDateTime;
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].languageId = data.languageId;
          this.rowData.poiDayNoteGetAllSub[this.indexSelected].id = data.id;
          this.savePoiDayNote.emit(this.rowData);
        });
        ref.componentRef.instance["deletePoiDayNoteTrans"].subscribe((data) => {
          ref.close();
          const indexToRemove =  this.rowData.poiDayNoteGetAllSub.findIndex(res => res.id == data.id);
          this.rowData.poiDayNoteGetAllSub.splice(indexToRemove,1);
          this.savePoiDayNote.emit(this.rowData);
        });
         return;
    }
    else{

      const ref =  this.dialogService.open(ModalPoiDayNoteTranslationComponent, {
        context: {
          title: 'Insert Translation',
          pointOfInterestId:this.rowData.id,
          translationName : "",
          translationId: null,
          languageId:languageId,
          startingDateTime:"",
          endingDateTime:"",
         
        },
      });
      ref.componentRef.instance["addPoiDayNoteTrans"].subscribe((data) => {
        ref.close();
        this.rowData.poiDayNoteGetAllSub.push(data);
        this.savePoiDayNote.emit(this.rowData);
      });
      return;
     
    }
  }

}

