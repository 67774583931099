import gql from "graphql-tag";

// Mutation Public GraphQL

// creates a base user. Roles can be connected to it (ex: MoveryUser, SuperAdmin, ...)
export class baseUserCreate {
  public static baseUserCreate = gql`
    query baseUserCreate($data: baseUserCreateInput!) {
      baseUserCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class baseUserUpdate {
  public static baseUserUpdate = gql`
    query baseUserUpdate($data: baseUserUpdateInput!) {
      baseUserUpdate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class superAdminGivePermission {
  public static superAdminGivePermission = gql`
    mutation superAdminGivePermission($id: Long!) {
      superAdminGivePermission(baseUserId: $id) {
        id
        success
      }
    }
  `;
}

// Create movery user
export class moveryUserCreate {
  public static moveryUserCreate = gql`
    mutation moveryUserCreate($createData: moveryUserCreateInput!) {
      moveryUserCreate(createData: $createData) {
        id
        success
      }
    }
  `;
}

// Delete movery user
export class moveryUserDelete {
  public static moveryUserDelete = gql`
    mutation moveryUserDelete($id: Long!) {
      moveryUserDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class municipalityOperatorCreate {
  public static municipalityOperatorCreate = gql`
    mutation municipalityOperatorCreate($data: municipalityOperatorCreateInput!) {
      municipalityOperatorCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class editorCreate {
  public static editorCreate = gql`
    mutation editorCreate($data: EditorCreateInput!) {
      editorCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//newsFindHintGetAll
export class editorGetAll {
  public static editorGetAll = gql`
    query editorGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long) {
      editorGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor) {
        baseUserId
        createdAt
        id
        updatedAt
      }
    }
  `;
}

export class editorDelete {
  public static editorDelete = gql`
    mutation editorDelete($id: Long!) {
      editorDelete(id: $id) {
        id
        success
      }
    }`
}


// Get current movery user id
export class MoveryUserLoggedInData {
  public static getCurrentMoveryUserId = gql`
    query getCurrentMoveryUserId {
      moveryUserLoggedInData {
        id
      }
    }
  `;
}

// resets the Password
export class passwordResetConfirmation {
  public static passwordResetConfirmation = gql`
    mutation passwordResetConfirmation($data: UserResetPasswordType!) {
      passwordResetConfirmation(passwordReset: $data) {
        success
      }
    }
  `;
}

// sends a Reset Email.facebook users can't use the service
export class passwordResetRequest {
  public static passwordResetRequest = gql`
    mutation passwordResetRequest(
      $data: baseUserPasswordResetSendMailRequest!
    ) {
      passwordResetRequest(data: $data) {
        success
      }
    }
  `;
}

// verifies User Email
export class verifyEmail {
  public static verifyEmail = gql`
    mutation verifyEmail($data: userVerifyEmailInput!) {
      verifyEmail(verifyEmailData: $data) {
        success
      }
    }
  `;
}

// Query Public GraphQL

// login
export class LoginUser {
  public static LoginUser = gql`
    query login($data: userLoginInput!) {
      login(loginData: $data) {
        expiryDate
        refreshToken
        roleAddOnScopes
        roleScopes
        token
        userId
      }
    }
  `;
}


export class baseUserGetLight {
  public static baseUserGetLight = gql`
    query baseUserGet($id: Long!) {
      baseUserGet(id: $id) {
        email
        emailConfirmed
        id
        name
        phoneNumber
        preferredLanguageId
        surname
      }
    }`
  }

// login
export class baseUserGet {
  public static baseUserGet = gql`
    query baseUserGet($id: Long!) {
      baseUserGet(id: $id) {
        allowEmailNotifications
        email
        emailConfirmed
        gender
        id
        name
        phoneNumber
        preferredLanguageId
        superAdminGetSub {
          baseUserId
          id
        }
        editorGetSub {
          baseUserId
          id
        }
        moveryUserGetSub {
          id
        }
        municipalityOperatorGetSub {
          baseUserId
          id
          municipalityId
          workPhoneNumber
        }
        baseUserImageGetAllSub(genericFilterQuery: {}) {
          imageGetSub {
            url
            mimeType
            fileName
          }
        }
        surname
      }
    }
  `;
}

// get all user of movery
export class BaseUserGetAllAdminVersion {
  public static baseUserGetAll = gql`
    query baseUserGetAll($data: baseUserGetAll!) {
      baseUserGetAll(baseUserGetAll: $data) {
        email
        emailConfirmed
        gender
        name
        phoneNumber
        preferredLanguageId
        surname
      }
    }
  `;
}

export class GetAllUserAdminVersion {
  public GetAllAdmin: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.GetAllAdmin = gql`
      query baseUserGetAll($data:genericQueryFilterInput!) {
        baseUserGetAll(genericFilterQuery: $data, limit: ${this.limit}, idCursor: ${idCursor}) {
          email
          emailConfirmed
          id
          name
          phoneNumber
          preferredLanguageId
          surname
          partnerId
          baseUserImageGetAllSub(genericFilterQuery: {}) {
            id
          }
          baseUserPushNotificationGetAllSub(genericFilterQuery: {}) {
            id
          }
          baseUserTranslationGetAllSub(genericFilterQuery: {}) {
            id
          }
          editorGetSub {
            id
          }
          moveryUserGetSub {
            id
          }
          supplierGetSub {
            id
          }
          superAdminGetSub{
            baseUserId
            id
          }
          municipalityOperatorGetSub {
            id
          }
        }
      }`;
  }

  public getQuery() {
    return this.GetAllAdmin;
  }
}

// Partners


export class partnerCRUD {

  public static partnerGetAll = gql`
      query partnerGetAll(
        $data:genericQueryFilterInput!
        $limit: Long
        $idCursor: Long
      ) {
        partnerGetAll(
          genericFilterQuery: $data
          limit: $limit
          idCursor: $idCursor
        ) {
          id
          name
        }
      }`

/*
  public static partnerGetAll (data: object, limit: number, idCursor: number = null) {
    return gql`
      query partnerGetAll($data:genericQueryFilterInput!) {
        partnerGetAll(genericFilterQuery: $data, limit: ${limit}, idCursor: ${idCursor}) {
          id
          name
        }
      }`
  }
*/
  public static partnerGet = gql`
    query partnerGet($id: Long!) {
      partnerGet(id: $id) {
        id
        name
      }
    }`

  public static partnerDelete = gql`
    mutation partnerDelete($id: Long!) {
      partnerDelete(id: $id) {
        id
        success
      }
    }`

  public static partnerCreate = gql`
    mutation partnerCreate($data: partnerCreateInputType!) {
      partnerCreate(createData: $data) {
        id
        success
      }
    }`

    public static partnerUpdate = gql`
      mutation partnerUpdate($data: partnerUpdateInputType!) {
        partnerUpdate(updateData: $data) {
          id
          success
        }
      }`
}


export class municipalityOperatorCRUD {

  public static municipalityOperatorDelete = gql`
    mutation municipalityOperatorDelete($id: Long!) {
      municipalityOperatorDelete(id: $id) {
        id
        success
      }
    }`
}

export class superAdminCRUD {

  public static superAdminDelete = gql`
    mutation superAdminDelete($id: Long!) {
      superAdminDelete(id: $id) {
        id
        success
      }
    }`
}

export class supplierReviewCRUD {

  public static supplierReviewDelete = gql`
    mutation supplierReviewDelete($id: Long!) {
      supplierReviewDelete(id: $id) {
        id
        success
      }
    }`

  public static supplierReviewGetAll = gql`
    query supplierReviewGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long
    ) {
      supplierReviewGetAll(
        genericFilterQuery: $data,
        limit: $limit
        idCursor: $idCursor){
          avatar
          createdAt
          date
          id 
          img
          name
          supplierGetSub {
            id
          }
          supplierId
          text
          updatedAt
          url
    }
  }`

}

export class supplierCRUD {

  public static supplierDelete = gql`
    mutation supplierDelete($id: Long!) {
      supplierDelete(id: $id) {
        id
        success
      }
    }`

  public static supplierCreate = gql`
    mutation supplierCreate($data: SupplierCreateInput!) {
      supplierCreate(createData: $data) {
        id
        success
      }
    }`

  public static supplierUpdate = gql`
    mutation supplierUpdate($data: SupplierUpdateInput!) {
      supplierUpdate(updateData: $data) {
        id
        success
      }
    }`


  public static supplierGet = gql`
    query supplierGet($id: Long!) {
      supplierGet(id: $id) {
        baseUserId
        baseUserGetSub {
          id
          baseUserImageGetAllSub(genericFilterQuery: {}, limit: 25, idCursor: null) {
            id
          }
        }
        createdAt
        journeyAdminSelectedGetAllSub(genericFilterQuery: {}) {
          journeyAdminSelectedTranslationGetAllSub(genericFilterQuery: {}) {
            id
            name
            languageId
          }
          address
          createdAt
          dollarSigns
          endingDateTime
          estimatedVisitLength
          facilitations
          id
          isActive
          journeyId
          latitude
          limitations
          longitude
          position
          price
          startingDateTime
          supplierId
          updatedAt
          journeyGetSub {
            id
            journeyTranslationGetAllSub(genericFilterQuery: {}) {
              id
              name
              languageId
            }
          }
        }
        pointOfInterestGetAllSub(genericFilterQuery: {}) {
          address
          backOfficeData
          createdAt
          email
          estimatedReopeningDate
          estimatedVisitLength
          id
          importance
          latitude
          lockedFromScriptUpdates
          longitude
          municipalityId
          musementVenueId
          nearbyTypesFifteenKilometers
          nearbyTypesFiveKilometers
          nearbyTypesTenKilometers
          openingPeriodValidated
          starsAdmin
          supplierId
          updatedAt
          webSite
          workInProgress
          pointOfInterestImageGetAllSub(genericFilterQuery: {}) {
            imageId
          }
          poiTranslationGetAllSub(genericFilterQuery: {}) {
            languageId
            name
          }
          poiBillingGetSub {
            price
          }
        }
        supplierReviewGetAllSub(genericFilterQuery: {}) {
          avatar
          createdAt
          date
          id 
          img
          name
          supplierGetSub {
            id
          }
          supplierId
          text
          updatedAt
          url
        }
        id
        address
        denominazione
        phoneNumber
        pIva
        updatedAt
      }
    }`

  public static supplierGetAll = gql`
    query supplierGetAll(
      $data: getAllFilterSupplierInput!
      $limit: Long
      $idCursor: Long
    ) {
      supplierGetAll(
        genericFilterQuery: $data,
        limit: $limit
        idCursor: $idCursor){
          baseUserId
          baseUserGetSub {
            id
            baseUserImageGetAllSub(genericFilterQuery: {}, limit: 25, idCursor: null) {
              id
            }
          }
          createdAt
          journeyAdminSelectedGetAllSub(genericFilterQuery: {}) {
            id
            supplierId
            dollarSigns
            position
            isActive
            address
            journeyGetSub {
              id
              journeyTranslationGetAllSub(genericFilterQuery: {}) {
                id
                name
                languageId
              }
            }
          }
          pointOfInterestGetAllSub(genericFilterQuery: {}) {
            id
            supplierId
            latitude
            longitude
            address
            pointOfInterestImageGetAllSub(genericFilterQuery: {}) {
              imageId
            }
            poiTranslationGetAllSub(genericFilterQuery: {}) {
              languageId
              name
            }
            poiBillingGetSub {
              price
            }
          }
          supplierReviewGetAllSub(genericFilterQuery: {}) {
            id 
          }
          id
          address
          denominazione
          phoneNumber
          pIva
          updatedAt
        }
      }`
}

// Get POI Count

export class GetPOICount {
  public static count = gql`
    query pointOfInterestCount($filter: poiQueryInput) {
      pointOfInterestCount(countFilter: $filter)
    }
  `;
}

// Get Id Business Activity Macro type

export class GetBusinessTypeId {
  public static getId = gql`
    query poiMacroTypeGetAll($filter: genericQueryFilterInput!) {
      poiMacroTypeGetAll(genericFilterQuery: $filter) {
        code
        id
      }
    }
  `;
}

// Get admin selected Tours

export class GetAdminTours {
  public AdminTours: any;
  public data: object;
  public limit: number;

  constructor(data: object, limit: number) {
    this.AdminTours = gql`
      query journeyAdminSelectedGetAll($data: journeyAdminSelectedGetAllQueryInput!) {
        journeyAdminSelectedGetAll(genericFilterQuery: $data, limit: ${limit}) {
          id
          isActive
          journeyGetSub {
            name
            journeypoiTicketPriceSum
            journeyTravelTagGetAllSub(genericFilterQuery: {}) {
              travelTagGetSub {
                code
              }
            }
            journeyMeetingPointGetAllSub(genericFilterQuery: {
  }){
    address
    endingDateTime
    id
    journeyId
    latitude
    longitude
    startingDateTime
  }
            journeyDayGetAllSub(genericFilterQuery: {}) {
              provinceToVisitInTheDayGetAllSub(genericFilterQuery: {}) {
                provinceToVisitInTheDayActivityGetAllSub(genericFilterQuery: {}) {
                  pointToVisitGetSub {
                    pointOfInterestGetSub {
                      municipalityId
                      images {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
  }

  public getQuery() {
    return this.AdminTours;
  }
}

// Get Tours Count
export class GetToursCount {
  public static count = gql`
    query journeyCount {
      journeyCount
    }
  `;
}

// Get municipality

export class GetMunicipality {
  public static get = gql`
    query municipalityGet($id: Long!) {
      municipalityGet(id: $id) {
        capoluogoProvincia
        code
        id
        latitude
        longitude
        provinceId
        inHome
        convenzionato
        workInProgress
      }
    }
  `;
}
// baseUserGetAllAdminVersion
// Get Generic POI

export class GetPOI {
  public poi: any;
  public data: object;
  public limit: number;
  public cursorId: number;

  constructor(data: object, limit: number, cursorId?: number) {
    if (typeof cursorId === "undefined") {
      this.poi = gql`
        query pointOfInterestGetAll($data: poiQueryInput!) {
          pointOfInterestGetAll(genericFilterQuery: $data, limit: ${limit}) {
            id
            latitude
            longitude
            address
            pointOfInterestImageGetAllSub(genericFilterQuery: {}) {
              imageId
            }
            poiTranslationGetAllSub(genericFilterQuery: {}) {
              languageId
              name
            }
            poiBillingGetSub {
              price
            }
          }
        }
      `;
    } else {
      this.poi = gql`
        query pointOfInterestGetAll($data: poiQueryInput!) {
          pointOfInterestGetAll(genericFilterQuery: $data, limit: ${limit}, idCursor: ${cursorId}) {
            id
            latitude
            longitude
            address
            pointOfInterestImageGetAllSub(genericFilterQuery: {}) {
              imageId
            }
            poiTranslationGetAllSub(genericFilterQuery: {}) {
              languageId
              name
            }
            poiBillingGetSub {
              price
            }
          }
        }
      `;
    }
  }

  public getQuery() {
    return this.poi;
  }
}

// Get Image

export class ImageGet {
  public static get = gql`
    query imageGet($id: Long!) {
      imageGet(id: $id) {
        url
      }
    }
  `;
}


export class importData {
  public static importData = gql`
    mutation importData($supplierId: Long!, $csvBase64: String!) {
      importData(supplierId: $supplierId, csvBase64: $csvBase64) {
        success
      }
    }
  `
}

// Mutation Movery User

// Journey Create
export class journeyCreate {
  public static journeyCreate = gql`
    mutation journeyCreate($data: journeyCreateInput!) {
      journeyCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
// give SuperAdmin Permission
export class updateBaseUser {
  public static baseUserUpdateInfo = gql`
    mutation baseUserUpdate($data: baseUserUpdate!) {
      baseUserUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

// update PhoneNumberBaseUser
export class updatePhoneNumberUser {
  public static baseUserPhoneNumber = gql`
    mutation phoneNumberSet($data: String!) {
      phoneNumberSet(mobileNumber: $data) {
        success
      }
    }
  `;
}

// update PhoneNumberBaseUser
export class updateEmailDataBaseUser {
  public static baseUseChangeEmail = gql`
    mutation changeEmail($data: baseUserEmailChangeInput!) {
      changeEmail(changeEmailData: $data) {
        success
      }
    }
  `;
}

// update baseUserChangePassword
export class passwordChange {
  public static passwordChange = gql`
    mutation passwordChange($oldPassword: String!, $newPassword: String!) {
      passwordChange(oldPassword: $oldPassword, newPassword: $newPassword) {
        id
        success
      }
    }
  `;
}
// changeEmail Confirmation
export class changeEmailConfirmation {
  public static changeEmailConfirmation = gql`
    mutation changeEmailConfirmation($data: confirmEmailChangeInput!) {
      changeEmailConfirmation(confirmEmailChangeData: $data) {
        success
      }
    }
  `;
}
// changeEmail Confirmation
export class imageTranslationCreate {
  public static imageTranslationCreate = gql`
    mutation imageTranslationCreate($data: imageTranslationCreateInput!) {
      imageTranslationCreate(createData: $data) {
        success
        id
      }
    }
  `;
}

// changeEmail Confirmation
export class imageTranslationUpdate {
  public static imageTranslationUpdate = gql`
    mutation imageTranslationUpdate($data: imageTranslationUpdateInput!) {
      imageTranslationUpdate(updateData: $data) {
        success
        id
      }
    }
  `;
}

export class imageTranslationDelete {
  public static imageTranslationDelete = gql`
    mutation imageTranslationDelete($id: Long!) {
      imageTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
// changeEmail Confirmation
export class imageCreateUploadLink {
  public static imageCreateUploadLink = gql`
    mutation imageCreateUploadLink($data: imageUploadRequestInput!) {
      imageCreateUploadLink(uploadRequest: $data) {
        expiresIn
        fileName
        headers {
          key
          value
        }
        filePermission
        mimeType
        remoteFileUploadRequestId
        signedUrl
      }
    }
  `;
}

export class imageCreateFromUpload {
  public static imageCreateFromUpload = gql`
    mutation imageCreateFromUpload($data: imageCreateFromUploadInput!) {
      imageCreateFromUpload(create: $data) {
        id
        success
      }
    }
  `;
}

export class remoteFileUploadRequestDelete {
  public static remoteFileUploadRequestDelete = gql`
    mutation remoteFileUploadRequestDelete($id: Long!) {
      remoteFileUploadRequestDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class baseUserDelete {
  public static baseUserDelete = gql`
    mutation baseUserDelete($id: Long!) {
      baseUserDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class baseUserImageCreate {
  public static baseUserImageCreate = gql`
    mutation baseUserImageCreate($data: baseUserImageCreateInput!) {
      baseUserImageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class provinceGetAll {
  public getAllProvince: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllProvince = gql`
      query provinceGetAll($data:getAllFilterGeographicInputType!) {
        provinceGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code
          id
          regionId
        }
      }
    `;
  }

  public getQuery() {
    return this.getAllProvince;
  }
}

// superAdmin News Create
export class newsCreate {
  public static newsCreate = gql`
    mutation newsCreate($data: newsCreateInput!) {
      newsCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
/*
export class getAllNewsByAlert {
  public static getAllNewsByAlert = gql`
  query newsGetAll(
    $data: getAllFilterNewsInputType!
    $limit: Long $idCursor: Long
  ) {
    newsGetAll(
      genericFilterQuery: $data limit: $limit idCursor: $idCursor
    ) {
      code
      createdAt
      id
      contentCreatedAt
      contentUpdatedAt
      municipalityId
      municipalityOperatorId
      newsTypeId
      provinceId
      regionId
      superAdminId
      updatedAt
      newsImageAssociationGetAllSub(
        genericFilterQuery: {
          role: "ORIGINAL"
          queryOperator: OR
          tags: ["NEWS_COVER_IMAGE", "NEWS_IMAGE"]
        }
      ) {
        id
        imageGetSub {
          fileName
          id
          mimeType
          role
          size
          tags
          url
        }
        imageId
        newsId
      }
      municipalityGetSub {
        capoluogoProvincia
        code
        id
        editorId
        municipalityTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
  
          description
          id
          languageId
          municipalityId
          name
        }
        provinceId
      }
      newsUnitGetAllSub(genericFilterQuery: {}) {
        body
        createdAt
        id
        languageId
        municipalityOperatorId
        newsId
        pinned
        preview
        superAdminId
        title
        updatedAt
        workInProgress
      }
      seoCodeGetAllSub(genericFilterQuery: {
        seoAssociation: NEWS
      }) {
        id
        code
        newsId
        languageId
  
        redirectStatusCode
        redirectToId
        staticRedirectUrl
      }
      seoTagGetAllSub(genericFilterQuery: {
        seoAssociation: NEWS
      }) {
        description
        id
        journeyId
        languageId
        newsId
        openGraphTags
        noFollow
        superAdminId
        title
      }
    }
  }
  `;
}
*/

export class newsGetAll {
  public getAllNews: any;
  public data: object;
  public limit: number;
  public idCursor: number;
  //
  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    //editorId
      this.getAllNews = gql`
      query newsGetAll($data: getAllFilterNewsInputType!) {
        newsGetAll(genericFilterQuery: $data, limit: ${this.limit}, idCursor: ${idCursor}) {
          code
          createdAt
          id
          contentCreatedAt
          contentUpdatedAt
          publishedAt
          municipalityId
          municipalityOperatorId
          newsTypeId
          provinceId
          regionId
          superAdminId
          updatedAt
          newsCategoryAssociationGetAllSub(genericFilterQuery: {}) {
            id
          }
          newsJourneyAdminSelectedAssociationGetAllSub(genericFilterQuery: {}) {
            id
          }
          newsJourneyAssociationGetAllSub(genericFilterQuery: {}) {
            id
          }
          newsWarningGetAllSub(genericFilterQuery: {}) {
            id
          }
          newsPointAssociationGetAllSub(genericFilterQuery: {}) {
            id
            newsId
            pointOfInterestId
            updatedAt
            pointOfInterestGetSub {
              id
              poiTranslationGetAllSub(genericFilterQuery: {}) {
                additionalInformation
                facilitations
                limitations
                description
                id
                languageId
                name
                pointOfInterestId
              }
            }
          }
          newsImageAssociationGetAllSub(genericFilterQuery: {
            role: "ORIGINAL",
            queryOperator: OR,
            tags: ["NEWS_COVER_IMAGE", "NEWS_IMAGE"]
          }) {
            imageId
            newsId
            id
            positionCover
            positionGallery
            backOfficeData
            imageGetSub {
              fileName
              id
              mimeType
              role
              size
              tags
              url
            }
          }
          municipalityGetSub {
            code
            id
            provinceId
            updatedAt
            municipalityTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation
              description
              id
              languageId
              municipalityId
              name
            }
          }
          newsUnitGetAllSub(genericFilterQuery: {}) {
            body
            createdAt
            id
            languageId
            municipalityOperatorId
            newsId
            pinned
            preview
            superAdminId
            title
            updatedAt
            workInProgress
          }
          seoCodeGetAllSub(genericFilterQuery: {
            seoAssociation: NEWS
          }) {
            id
            code
            newsId
            languageId
            redirectStatusCode
            staticRedirectUrl
            redirectToId
          }
          seoTagGetAllSub(genericFilterQuery: {
            seoAssociation: NEWS
          }) {
            description
            id
            journeyId
            languageId
            newsId
            openGraphTags
            noFollow
            superAdminId
            title
          }
        }
      }
  `;
  }

  public getQuery() {
    return this.getAllNews;
  }
}

//News CategoryCreate
export class newsCategoryCreate {
  public static newsCategoryCreate = gql`
    mutation newsCategoryCreate($data: newsCategoryCreateInput!) {
      newsCategoryCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//News CategoryCreate
export class superAdminSendNotification {
  public static superAdminSendNotification = gql`
    mutation superAdminSendNotification(
      $data: superAdminSendNotificationInput!
    ) {
      superAdminSendNotification(adminSendNotification: $data) {
        success
      }
    }
  `;
}
export class municipalityOperatorSendNotification {
  public static municipalityOperatorSendNotification = gql`
    mutation municipalityOperatorSendNotification(
      $data: municipalityOperatorSendNotificationInput!
    ) {
      municipalityOperatorSendNotification(notificationData: $data) {
        success
      }
    }
  `;
}
//News CategoryUpdate
export class newsCategoryUpdate {
  public static newsCategoryUpdate = gql`
    mutation newsCategoryUpdate($data: newsCategoryUpdateInput!) {
      newsCategoryUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News CategoryUpdate
export class newsCategoryDelete {
  public static newsCategoryDelete = gql`
    mutation newsCategoryDelete($id: Long!) {
      newsCategoryDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class newsUpdate {
  public static updateNews = gql`
    mutation newsUpdate($data: newsUpdateInput!) {
      newsUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
//News CategoryUpdate
export class newsDelete {
  public static GetNewsDelete = gql`
    mutation newsDelete($id: Long!) {
      newsDelete(id: $id) {
        id
        success
      }
    }
  `;
}
//News CategoryUpdate
export class newsUnitDelete {
  public static newsUnitDelete = gql`
    mutation newsUnitDelete($id: Long!) {
      newsUnitDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class newsUnitDeleteMany {
  public static newsUnitDeleteMany = gql`
    mutation newsUnitDeleteMany($id: [Int!]!) {
      newsUnitDeleteMany(id: $id) {
        id
        success
      }
    }
  `;
}

export class superAdminLoggedInData {
  public static superAdminLoggedInData = gql`
    query superAdminLoggedInData {
      superAdminLoggedInData {
        baseUserId
        id
      }
    }
  `;
}

export class editorLoggedInData {
  public static editorLoggedInData = gql`
  query editorLoggedInData {
    editorLoggedInData {
      baseUserId
      id
    }
  }`
}

export class baseUserLoggedInData {
  public static baseUserLoggedInData = gql`
  query baseUserLoggedInData {
    baseUserLoggedInData {
      id
    }
  }`
}

export class poiTravelCategoryGetAll {
  public poiTravelCategoryGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.poiTravelCategoryGetAll = gql`
    query poiTravelCategoryGetAll($data:genericQueryFilterInput!) {
      poiTravelCategoryGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        id
        travelCategoryGetSub{
          code     
          id
          icon
          travelCategoryTranslationGetAllSub(genericFilterQuery:  {}){
            description
            id
            languageId
            name
            travelCategoryId
          }
        }
      }
    }
    `;
  }

  public getQuery() {
    return this.poiTravelCategoryGetAll;
  }
}

export class newsCategoryGet {
  public static newsCategoryGet = gql`
    query newsCategoryGet($id: Long!) {
      newsCategoryGet(id: $id) {
        canBeUsedByMunicipalityOperator
        canBeUsedBySuperAdmin
        code
        icon
        id
        position
      }
    }
  `;
}
export class travelCategoryGet {
  public static travelCategoryGet = gql`
    query travelCategoryGet($id: Long!) {
      travelCategoryGet(id: $id) {
        code
        icon
        id
      }
    }
  `;
}
export class newsCategoryGetAll {
  public getAllNewsCategory: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllNewsCategory = gql`
      query newsCategoryGetAll($data:genericQueryFilterInput!) {
        newsCategoryGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          canBeUsedByMunicipalityOperator
          canBeUsedBySuperAdmin
          code
          id
          icon
          position
          newsCategoryTranslationGetAllSub(genericFilterQuery: {}){
            description,
            id
            languageId
            name
            newsCategoryId
          }
          seoTagGetAllSub(genericFilterQuery:  {seoAssociation: NEWS_CATEGORY}){
            description
            id
            languageId
            newsCategoryId
            openGraphTags
            noFollow
            superAdminId
            title
          }
          seoCodeGetAllSub(genericFilterQuery: {seoAssociation:NEWS_CATEGORY}){
            id
            code
            newsCategoryId
            languageId
            redirectStatusCode
            staticRedirectUrl
            redirectToId
          }
        }
      }
    `;
  }

  public getQuery() {
    return this.getAllNewsCategory;
  }
}

///////////////////////// News Category Translation///////////////////////////

//News Category Translation Create
export class newsCategoryTranslationCreate {
  public static newsCategoryTranslationCreate = gql`
    mutation newsCategoryTranslationCreate(
      $data: newsCategoryTranslationCreateInput!
    ) {
      newsCategoryTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Update
export class newsCategoryTranslationUpdate {
  public static newsCategoryTranslationUpdate = gql`
    mutation newsCategoryTranslationUpdate(
      $data: newsCategoryTranslationUpdateInput!
    ) {
      newsCategoryTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Delete
export class newsCategoryTranslationDelete {
  public static newsCategoryTranslationDelete = gql`
    mutation newsCategoryTranslationDelete($id: Long!) {
      newsCategoryTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class newsCategoryTranslationGetAll {
  public getAllNewsTranslationCategory: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllNewsTranslationCategory = gql`
      query newsCategoryTranslationGetAll($data:genericQueryFilterInput!) {
        newsCategoryTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          description
          id
          languageId
          name
          newsCategoryId
      
        }
        }
      `;
  }

  public getQuery() {
    return this.getAllNewsTranslationCategory;
  }
}

export class newsCategoryAssociationCreate {
  public static newsCategoryAssociationCreate = gql`
    mutation newsCategoryAssociationCreate(
      $data: newsCategoryAssociationCreateInput!
    ) {
      newsCategoryAssociationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsCategoryAssociationUpdate {
  public static updateNewsCategoryAssociation = gql`
    mutation newsCategoryAssociationUpdate(
      $data: newsCategoryAssociationUpdateInput!
    ) {
      newsCategoryAssociationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class newsCategoryAssociationDelete {
  public static newsCategoryAssociationDelete = gql`
    mutation newsCategoryAssociationDelete($id: Long!) {
      newsCategoryAssociationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class newsPointAssociationCreate {
  public static newsPointAssociationCreate = gql`
    mutation newsPointAssociationCreate(
      $data: newsPointAssociationCreateInput!
    ) {
      newsPointAssociationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsPointAssociationDelete {
  public static newsPointAssociationDelete = gql`
    mutation newsPointAssociationDelete($id: Long!) {
      newsPointAssociationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class imageGet {
  public static imageGet = gql`
    query imageGet($id: Long!) {
      imageGet(id: $id) {
        description
        fileName
        id
        mimeType
        role
        size
        tags
        url
      }
    }
  `;
}
export class newsGetByid {
  public static GetNew = gql`
    query newsGet($id: Long!) {
      newsGet(id: $id) {
        code
        id
        contentCreatedAt
        contentUpdatedAt
        publishedAt
        newsJourneyAdminSelectedAssociationGetAllSub(genericFilterQuery: {}) {
          createdAt
          id
          journeyAdminSelectedId
          newsId
          updatedAt
          journeyAdminSelectedGetSub {
            id
            journeyAdminSelectedTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation
              description
              id

              languageId
              name
              journeyAdminSelectedId
            }
          }
        }
        seoTagGetAllSub(genericFilterQuery: {seoAssociation: NEWS}) {
          id
          description
          title
          noFollow
          languageId
        }
        newsCategoryAssociationGetAllSub(genericFilterQuery: {}) {
          id
          newsCategoryGetSub {
            canBeUsedByMunicipalityOperator
            canBeUsedBySuperAdmin
            code
            icon
            id
            newsCategoryTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation
              description
              id
              languageId
              name
              newsCategoryId
            }
          }
          newsCategoryId
          newsId
          updatedAt
        }
        newsImageAssociationGetAllSub(genericFilterQuery: {}) {
          id
          imageGetSub {
            description
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          newsId
        }
        newsPointAssociationGetAllSub(genericFilterQuery: {}) {
          id
          newsId
          pointOfInterestGetSub {
            id
            poiTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation
              facilitations
              limitations
              description
              id

              languageId
              name
              pointOfInterestId
            }
          }
          pointOfInterestId
          updatedAt
        }
        municipalityGetSub {
          code
          id
          municipalityTranslationGetAllSub(genericFilterQuery: {}) {
            description
            id
            languageId
            municipalityId
            name
          }
          provinceId
        }
        municipalityId
        municipalityOperatorId
        newsTypeId
        newsUnitGetAllSub(genericFilterQuery: {}) {
          body
          createdAt
          id
          languageId
          municipalityOperatorId
          newsId
          pinned
          preview
          superAdminId
          title
          workInProgress
          author
        }
        provinceId
        regionId
        seoCodeGetAllSub(genericFilterQuery: { seoAssociation: NEWS }) {
          id
          updatedAt
          code
          newsId
          languageId

          redirectStatusCode
          staticRedirectUrl
          redirectToId
        }
        superAdminId
        updatedAt
      }
    }
  `;
}

export class pointprova {
  public static pointprova = gql`
    query pointOfInterestGetAll(
      $data: poiQueryInput!
      $limit: Long
      $idCursor: Long
    ) {
      pointOfInterestGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        municipalityGetSub {
          capoluogoProvincia
          code
          id
          latitude
          longitude
          provinceId
        }
        estimatedVisitLength
        id
        municipalityId
        backOfficeData
        openingPeriodValidated
        updatedAt
        webSite
        workInProgress
        poiTravelTagGetAllSub(genericFilterQuery: {}) {
          id
          travelTagGetSub {
            code
          }
        }
        poiTranslationGetAllSub(genericFilterQuery: {}) {
          id
          description
          languageId
          facilitations
          limitations
          name
          preview
          additionalInformation
          indicationsContent
          indicationsTitle
        }
        poiOpeningPeriodGetAllSub(genericFilterQuery: {}) {
          derogatedOpeningPeriodId
          endingDateTime
          id
          isClosed
          derogatingOpeningPeriodsGetAll(genericFilterQuery: {}) {
            derogatedOpeningPeriodId
            endingDateTime
            id
            isClosed
            poiOpeningDayGetAllSub(genericFilterQuery: {}) {
              daysOfWeek
              endingTime
              id
              notes
              poiOpeningPeriodId
              startingTime
            }
            startingDateTime
          }
          poiOpeningDayGetAllSub(genericFilterQuery: {}) {
            daysOfWeek
            endingTime
            id
            notes
            poiOpeningPeriodId
            startingTime
          }
          startingDateTime
        }
        seoCodeGetAllSub(
          genericFilterQuery: { seoAssociation: POINT_OF_INTEREST }
        ) {
          id
          code
          pointOfInterestId
          languageId

          redirectStatusCode
          staticRedirectUrl
          redirectToId
        }
        seoTagGetAllSub(
          genericFilterQuery: { seoAssociation: POINT_OF_INTEREST }
        ) {
          description
          id

          languageId

          openGraphTags
          noFollow
          pointOfInterestId
          superAdminId
          title
        }
        poiBillingGetSub {
          id
          poiBillingTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            languageId
            name
            poiBillingId
          }
          pointOfInterestId
          price
        }
        pointOfInterestImageGetAllSub(
          genericFilterQuery: {
            role: "ORIGINAL",
            mimeType: "image/webp",
            queryOperator: OR
            tags: ["POI_COVER_IMAGE", "POI_IMAGE"]
          }
        ) {
          id
          positionCover
          positionGallery
          backOfficeData
          imageGetSub {
            bucketName
            description
            fileName
            id
            role
            size
            tags
            mimeType
            url
          }
          imageId
          pointOfInterestId
        }
      }
    }
  `;
}

export class ListImageGetAll {
  public static imageGetAll = gql`
    query imageGetAll(
      $data: imageQueryFilterInputType!
      $limit: Long
      $idCursor: Long
    ) {
      imageGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        fileName
        id
        mimeType
        role
        size
        tags
        url
        fileOnwership {
          belongsTo
          id
        }
        imageTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
          createdAt
          description
          id
          imageId
          languageId
          name
          updatedAt
        }
      }
    }
  `;
}

export class getFirtsTravelCategories {
  public static getFirtsTravelCategories = gql`
    query travelCategoryGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long
    ) {
      travelCategoryGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        code
        icon
        id
        travelCategoryImageGetAllSub(genericFilterQuery: {}) {
          id
          imageGetSub {
            bucketName
            description
            fileName
            id
            role
            size
            tags
            mimeType
            url
          }
          imageId
          travelCategoryId
          updatedAt
        }
        travelCategoryTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
          description
          id
          languageId
          name
          travelCategoryGetSub {
            code

            icon
            id
          }
          travelCategoryId
        }
      }
    }
  `;
}

export class getFirtsCategories {
  public static getFirtsCategories = gql`
    query newsCategoryGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long
    ) {
      newsCategoryGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        canBeUsedByMunicipalityOperator
        canBeUsedBySuperAdmin
        code
        id
        icon
        position
        newsCategoryTranslationGetAllSub(genericFilterQuery: {}) {
          description
          id
          languageId
          name
          newsCategoryId
        }
        seoTagGetAllSub(genericFilterQuery: { seoAssociation: NEWS_CATEGORY }) {
          description
          id
          languageId
          newsCategoryId
          openGraphTags
          noFollow
          superAdminId
          title
        }
        seoCodeGetAllSub(
          genericFilterQuery: { seoAssociation: NEWS_CATEGORY }
        ) {
          id
          code
          newsCategoryId
          languageId

          redirectStatusCode
          staticRedirectUrl
          redirectToId
        }
      }
    }
  `;
}
export class pointOfInterestGetAll {
  public getAllPointOfInterest: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllPointOfInterest = gql`
    query pointOfInterestGetAll($data:poiQueryInput!) {
      pointOfInterestGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        estimatedVisitLength
        id
        municipalityId
        backOfficeData
        openingPeriodValidated
        updatedAt
        webSite
        workInProgress
        poiTranslationGetAllSub(genericFilterQuery: {parentTwoId:1}) {
          id
          languageId
          pointOfInterestId
          name
          additionalInformation
          indicationsContent
          indicationsTitle
          preview
        }
        seoCodeGetAllSub(genericFilterQuery: {seoAssociation:POINT_OF_INTEREST}){
          id
          code
          municipalityId
          languageId
          redirectStatusCode
          staticRedirectUrl
          redirectToId
        }
        pointOfInterestImageGetAllSub(genericFilterQuery: {role:"ORIGINAL",queryOperator:OR,tags:["POI_COVER_IMAGE","POI_IMAGE"]}){
          id
          positionCover
          positionGallery
          backOfficeData
          imageGetSub{
            description
            fileName
            id
            role
            size
            mimeType
            tags
            url
          }
          imageId
          pointOfInterestId
        }
      }
    }
  `;
  }
  public getQuery() {
    return this.getAllPointOfInterest;
  }
}

export class poiTranslationGetAll {
  public poiTranslationGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.poiTranslationGetAll = gql`
    query poiTranslationGetAll($data:getAllFilterPoiTranslationInputType!) {
      poiTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit}, idCursor: ${this.idCursor}) {
        id
        languageId
        pointOfInterestId
        name
        }
      }
    `;
  }
  public getQuery() {
    return this.poiTranslationGetAll;
  }
}

export class GetpoiTranslationGetAll {
  public poiTranslationGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.poiTranslationGetAll = gql`
    query poiTranslationGetAll($data:getAllFilterPoiTranslationInputType!) {
      poiTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit}, idCursor: ${this.idCursor}) {
          id
          languageId
          pointOfInterestId
          name
        }
      }
    `;
  }
  public getQuery() {
    return this.poiTranslationGetAll;
  }
}

export class pointOfInterestImageGetAll {
  public pointOfInterestImageGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.pointOfInterestImageGetAll = gql`
    query pointOfInterestImageGetAll($data:imageAssociationGetAllFilterInputType!) {
      pointOfInterestImageGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        id
        positionCover
        positionGallery
        backOfficeData
        imageGetSub {
          description
          fileName
          id
          role
          mimeType
          size
          tags
          url
        }
        imageId
        pointOfInterestId
      }
    }
  `;
  }
  public getQuery() {
    return this.pointOfInterestImageGetAll;
  }
}

export class poiAdminSelectedImageGetAll {
  public poiAdminSelectedImageGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.poiAdminSelectedImageGetAll = gql`
    query poiAdminSelectedImageGetAll($data:imageAssociationGetAllFilterInputType!) {
      poiAdminSelectedImageGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        id
        positionCover
        positionGallery
        backOfficeData
        imageGetSub {
          description
          fileName
          id
          role
          size
          mimeType
          tags
          url
        }
        imageId
        pointOfInterestAdminSelectedId
        }
      }
    `;
  }
  public getQuery() {
    return this.poiAdminSelectedImageGetAll;
  }
}

export class municipalityImageGetAll {
  public municipalityImageGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.municipalityImageGetAll = gql`
      query municipalityImageGetAll($data:imageAssociationGetAllFilterInputType!) {
        municipalityImageGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          positionCover
          positionGallery
          backOfficeData
          id
          imageGetSub {
            description
            fileName
            id
            role
            size
            mimeType
            tags
            url
          }
          imageId
          municipalityId 
        }
      }
    `;
  }
  public getQuery() {
    return this.municipalityImageGetAll;
  }
}
export class journeyAdminSelectedImageGetAll {
  public getAllImageAssociation: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllImageAssociation = gql`
      query journeyAdminSelectedImageGetAll($data:imageAssociationGetAllFilterInputType!) {
        journeyAdminSelectedImageGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          id
          positionCover
          positionGallery
          imageGetSub{
            description
            fileName
            id
            role
            size
            mimeType
            tags
            url
          }
          imageId
          journeyAdminSelectedId
        }
      }
    `;
  }
  public getQuery() {
    return this.getAllImageAssociation;
  }
}
export class newsImageAssociationGetAll {
  public getAllImageAssociation: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllImageAssociation = gql`
      query newsImageAssociationGetAll($data:imageAssociationGetAllFilterInputType!) {
        newsImageAssociationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          id
          positionCover
          positionGallery
          backOfficeData
          imageGetSub{
            description
            fileName
            id
            role
            size
            mimeType
            tags
            url
          }
          imageId
          newsId
        }
      }
    `;
  }
  public getQuery() {
    return this.getAllImageAssociation;
  }
}
export class newsImageAssociationCreate {
  public static newsImageAssociationCreate = gql`
    mutation newsImageAssociationCreate(
      $data: newsImageAssociationCreateInput!
    ) {
      newsImageAssociationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsImageAssociationDelete {
  public static newsImageAssociationDelete = gql`
    mutation newsImageAssociationDelete($id: Long!) {
      newsImageAssociationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class journeyAdminSelectedImageDelete {
  public static journeyAdminSelectedImageDelete = gql`
    mutation journeyAdminSelectedImageDelete($id: Long!) {
      journeyAdminSelectedImageDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class seoCodeGetByUniqueCode {
  public static seoCodeGetByUniqueCode = gql`
    query seoCodeGetByUniqueCode($code: String!) {
      seoCodeGetByUniqueCode(code: $code) {
        activityId
        code
        createdAt
        id
        journeyId
        languageId
        municipalityId
        newsCategoryId
        newsId
        pointOfInterestId
        redirectStatusCode
        redirectToId
        staticRedirectUrl
        superAdminId
        updatedAt
      }
    }
  `;
}

export class seoCodeCreate {
  public static seoCodeCreate = gql`
    mutation seoCodeCreate($data: seoCodeCreateInput!) {
      seoCodeCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class seoCodeUpdate {
  public static seoCodeUpdate = gql`
    mutation seoCodeUpdate($data: seoCodeUpdateInput!) {
      seoCodeUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class municipalityGetAll {
  public getAllMunicipality: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.getAllMunicipality = gql`
      query municipalityGetAll($data:getAllFilterMunicipalityInputType!) {
        municipalityGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          capoluogoProvincia
          code
          id
          latitude
          longitude
          provinceId
          municipalityImageGetAllSub(genericFilterQuery: {}){
            id
            imageGetSub{
              description
              fileName
              id
              role
              size
              tags
              mimeType
              url
            }
            imageId
            municipalityId
          }
          municipalityTranslationGetAllSub(genericFilterQuery:{}){
            description
            id
            languageId
            name
            municipalityId
          }
        }
      }
    `;
  }
  public getQuery() {
    return this.getAllMunicipality;
  }
}

export class provinceGet {
  public static provinceGet = gql`
    query provinceGet($id: Long!) {
      provinceGet(id: $id) {
        code
        id
        latitude
        longitude
        regionId
        vehicleCode
      }
    }
  `;
}

export class municipalityGet {
  public static municipalityGet = gql`
    query municipalityGet($id: Long!) {
      municipalityGet(id: $id) {
        capoluogoProvincia
        code
        id
        latitude
        longitude
        provinceId
        workInProgress
      }
    }
  `;
}

//DeletePoi
export class pointOfInterestDelete {
  public static pointOfInterestDelete = gql`
    mutation pointOfInterestDelete($id: Long!) {
      pointOfInterestDelete(id: $id) {
        id
        success
      }
    }
  `;
}

//Create Poi
export class pointOfInterestCreate {
  public static pointOfInterestCreate = gql`
    mutation pointOfInterestCreate($data: pointOfInterestCreateInput!) {
      pointOfInterestCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//Create TranslationPoi

//   name
// description
// additionalInformation
// languageId
// pointOfInterestId
// indicationsTitle
// indicationsContent

export class poiTranslationCreate {
  public static poiTranslationCreate = gql`
    mutation poiTranslationCreate($data: poiTranslationCreateInput!) {
      poiTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTranslationDelete {
  public static poiTranslationDelete = gql`
    mutation poiTranslationDelete($id: Long!) {
      poiTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

//Poi UpdateData
export class pointOfInterestUpdate {
  public static pointOfInterestUpdate = gql`
    mutation pointOfInterestUpdate($data: pointOfInterestUpdateInput!) {
      pointOfInterestUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//Create TranslationPoi
export class poiTranslationUpdate {
  public static poiTranslationUpdate = gql`
    mutation poiTranslationUpdate($data: poiTranslationUpdateInput!) {
      poiTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestImageCreate {
  public static pointOfInterestImageCreate = gql`
    mutation pointOfInterestImageCreate(
      $data: pointOfInterestImageCreateInput!
    ) {
      pointOfInterestImageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
//mibacOrarioBiglietteria
// openingPeriodValidated
// features
// price
// onlineUrl
// pointOfInterestId
export class poiBillingCreate {
  public static poiBillingCreate = gql`
    mutation poiBillingCreate($data: poiBillingCreateInput!) {
      poiBillingCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//   // id
//mibacOrarioBiglietteria
// openingPeriodValidated
// features
// price
// onlineUrl
// pointOfInterestId

export class poiBillingUpdate {
  public static poiBillingUpdate = gql`
    mutation poiBillingUpdate($data: poiBillingUpdateInput!) {
      poiBillingUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
//pointOfInterestId
//startingDateTime
//endingDateTime
export class poiOpeningPeriodCreateDate {
  public poiOpeningPeriodCreate: any;
  constructor(public startingDateTime: string, public endingDateTime: string) {
    this.poiOpeningPeriodCreate = gql`
      mutation poiOpeningDayCreate($pointOfInterestId:Long,$derogatedOpeningPeriodId:Long!,$isClosed:Boolean!) {
        poiOpeningDayCreate(createData: {
          startingDateTime: ${this.startingDateTime}
          endingDateTime: ${this.endingDateTime}
          pointOfInterestId: $pointOfInterestId
          isClosed: $isClosed
          derogatedOpeningPeriodId: $derogatedOpeningPeriodId
        }) {
        id
        success
      }
    }
    `;
  }

  public getQuery() {
    return this.poiOpeningPeriodCreate;
  }
}

export class poiOpeningPeriodCreate {
  public static poiOpeningPeriodCreate = gql`
    mutation poiOpeningPeriodCreate($data: poiOpeningPeriodCreateInput!) {
      poiOpeningPeriodCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiOpeningPeriodDelete {
  public static poiOpeningPeriodDelete = gql`
    mutation poiOpeningPeriodDelete($id: Long!) {
      poiOpeningPeriodDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class getAllImageFromParent {
  public static pointOfInterestGet = gql`
    query pointOfInterestGet($id: Long!, $limit: Long!, $cursor: Long) {
      pointOfInterestGet(id: $id) {
        pointOfInterestImageGetAllSub(
          genericFilterQuery: {}
          limit: $limit
          idCursor: $cursor) {
          id
          imageGetSub {
            id
            tags
            fileName
            url
            mimeType
            role
          }
          positionCover
          positionGallery
        }
      }
    }
  `;
}

//TODO
export class poiOpeningPeriodGetAllSub  {
  public static pointOfInterestGet = gql`
    query pointOfInterestGet($id: Long!) {
      pointOfInterestGet(id: $id) {
        id
        supplierId
        poiOpeningPeriodGetAllSub(genericFilterQuery: {}) {
          derogatedOpeningPeriodId
          endingDateTime
          backOfficeData
          backOfficeStatus
          id
          isClosed
          derogatingOpeningPeriodsGetAll(genericFilterQuery: {}) {
            derogatedOpeningPeriodId
            backOfficeStatus
            endingDateTime
            backOfficeData
            id
            isClosed
            poiOpeningDayGetAllSub(genericFilterQuery: {}) {
              daysOfWeek
              endingTime
              id
              notes
              poiOpeningPeriodId
              startingTime
            }
            startingDateTime
          }
          poiOpeningDayGetAllSub(genericFilterQuery: {}) {
            daysOfWeek
            endingTime
            id
            notes
            poiOpeningPeriodId
            startingTime
          }
          startingDateTime
        }
      }
    }
  `;
  }
//TODO
export class poiOpeningPeriodGet {
  public static poiOpeningPeriodGet = gql`
    query poiOpeningPeriodGet($id: Long!) {
      poiOpeningPeriodGet(id: $id) {
        derogatedOpeningPeriodId
        endingDateTime
        id
        isClosed
        derogatingOpeningPeriodsGetAll(genericFilterQuery: {}) {
          derogatedOpeningPeriodId
          endingDateTime
          id
          isClosed
          poiOpeningDayGetAllSub(genericFilterQuery: {}) {
            daysOfWeek
            endingTime
            id
            notes
            poiOpeningPeriodId
            startingTime
          }
          startingDateTime
        }
      }
    }
  `;  
}

export class poiOpeningPeriodDeleteMany {
  public static poiOpeningPeriodDeleteMany = gql`
    mutation poiOpeningPeriodDeleteMany($ids: [Long!]!) {
      poiOpeningPeriodDeleteMany(ids: $ids) {
        success
      }
    }
  `;}

export class poiOpeningDayDelete {
  public static poiOpeningDayDelete = gql`
    mutation poiOpeningDayDelete($id: Long!) {
      poiOpeningDayDelete(id: $id) {
        id
        success
      }
    }
  `;
}
//id
export class poiOpeningPeriodUpdate {
  public static poiOpeningPeriodUpdate = gql`
    mutation poiOpeningPeriodUpdate($data: poiOpeningPeriodUpdateInput!) {
      poiOpeningPeriodUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//poiOpeningPeriodId
//endingTime
//startingTime
//daysOfWeek

export class poiOpeningPeriodCreateMany {
  public static poiOpeningPeriodCreateMany = gql`
    mutation poiOpeningPeriodCreateMany(
      $data: [poiOpeningPeriodCreateInput!]!
    ) {
      poiOpeningPeriodCreateMany(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiOpeningPeriodUpdateMany {
  public static poiOpeningPeriodUpdateMany = gql`
    mutation poiOpeningPeriodUpdateMany(
      $data: [poiOpeningPeriodUpdateInput!]!
    ) {
      poiOpeningPeriodUpdateMany(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiOpeningDayCreateMany {
  public static poiOpeningPeriodCreateMany = gql`
    mutation poiOpeningPeriodCreateMany($data: [poiOpeningDayCreateInput!]!) {
      poiOpeningPeriodCreateMany(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiOpeningDayUpdateMany {
  public static poiOpeningDayUpdateMany = gql`
    mutation poiOpeningDayUpdateMany($data: [poiOpeningDayUpdateInput!]!) {
      poiOpeningDayUpdateMany(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiOpeningDayCreate {
  public poiOpeningDayCreate: any;

  constructor(public startingTime: number, public endingTime: number) {
    this.poiOpeningDayCreate = gql`
      mutation poiOpeningDayCreate($daysOfWeek:[dayOfWeekEnumType],$notes:String,$openingDaySpecialNotes:[openingDaySpecialNoteEnumType],$poiOpeningPeriodId:Long!) {
        poiOpeningDayCreate(createData: {
          daysOfWeek: $daysOfWeek
          endingTime: ${this.endingTime}
          notes: $notes
          openingDaySpecialNotes: $openingDaySpecialNotes
          poiOpeningPeriodId: $poiOpeningPeriodId
          startingTime:  ${this.startingTime}
        }) {
        id
        success
      }
    }
    `;
  }

  public getQuery() {
    return this.poiOpeningDayCreate;
  }
}

export class poiOpeningDayUpdate {
  public poiOpeningDayUpdate: any;

  constructor(public startingTime: number, public endingTime: number) {
    this.poiOpeningDayUpdate = gql`
      mutation poiOpeningDayUpdate($daysOfWeek:[dayOfWeekEnumType],$notes:String,$openingDaySpecialNotes:[openingDaySpecialNoteEnumType],$poiOpeningPeriodId:Long!,$id:Long!) {
        poiOpeningDayUpdate(updateData: {
          daysOfWeek: $daysOfWeek
          endingTime: ${this.endingTime}
          notes: $notes
          openingDaySpecialNotes: $openingDaySpecialNotes
          poiOpeningPeriodId: $poiOpeningPeriodId
          id: $id
          startingTime:  ${this.startingTime}
        }) {
        id
        success
      }
    }
    `;
  }

  public getQuery() {
    return this.poiOpeningDayUpdate;
  }
}

export class setpoiOpeningDayCreateMany {
  public poiOpeningDayCreateMany: any;

  constructor(public startingTime: number, public endingTime: number) {
    this.poiOpeningDayCreateMany = gql`
      mutation poiOpeningDayCreateMany($daysOfWeek:[dayOfWeekEnumType],$notes:String,$openingDaySpecialNotes:[openingDaySpecialNoteEnumType],$poiOpeningPeriodId:Long!) {
        poiOpeningDayCreateMany(createData: {
          daysOfWeek: $daysOfWeek
          endingTime: ${this.endingTime}
          notes: $notes
          openingDaySpecialNotes: $openingDaySpecialNotes
          poiOpeningPeriodId: $poiOpeningPeriodId
          startingTime:  ${this.startingTime}
        }) {
        id
        success
      }
    }
    `;
  }

  public getQuery() {
    return this.poiOpeningDayCreateMany;
  }
}
//id
//poiOpeningPeriodId
//endingTime
//startingTime
//daysOfWeek
export class poiOpeningDayUpdateInput {
  public static poiOpeningDayUpdateInput = gql`
    mutation poiOpeningDayUpdateInput($data: poiOpeningDayCreateInput!) {
      poiOpeningDayUpdateInput(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Create
export class travelCategoryTranslationCreate {
  public static travelCategoryTranslationCreate = gql`
    mutation travelCategoryTranslationCreate(
      $data: travelCategoryTranslationCreateInput!
    ) {
      travelCategoryTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Update
export class travelCategoryTranslationUpdate {
  public static travelCategoryTranslationUpdate = gql`
    mutation travelCategoryTranslationUpdate(
      $data: travelCategoryTranslationUpdateInput!
    ) {
      travelCategoryTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Delete
export class travelCategoryTranslationDelete {
  public static travelCategoryTranslationDelete = gql`
    mutation travelCategoryTranslationDelete($id: Long!) {
      travelCategoryTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

//News CategoryCreate
export class travelCategoryCreate {
  public static travelCategoryCreate = gql`
    mutation travelCategoryCreate($data: travelCategoryCreateInput!) {
      travelCategoryCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
//News CategoryUpdate
export class travelCategoryUpdate {
  public static travelCategoryUpdate = gql`
    mutation travelCategoryUpdate($data: travelCategoryUpdateInput!) {
      travelCategoryUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News CategoryUpdate
export class macroFeatureDelete {
  public static macroFeatureDelete = gql`
    mutation macroFeatureDelete($id: Long!) {
      macroFeatureDelete(id: $id) {
        id
        success
      }
    }
  `;
}

//News CategoryCreate
export class macroFeatureCreate {
  public static macroFeatureCreate = gql`
    mutation macroFeatureCreate($data: macroFeatureCreateInput!) {
      macroFeatureCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
//News CategoryUpdate
export class macroFeatureUpdate {
  public static macroFeatureUpdate = gql`
    mutation macroFeatureUpdate($data: macroFeatureUpdateInput!) {
      macroFeatureUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News CategoryUpdate
export class travelCategoryDelete {
  public static travelCategoryDelete = gql`
    mutation travelCategoryDelete($id: Long!) {
      travelCategoryDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class macroFeatureGetAll {
  public macroFeatureGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.macroFeatureGetAll = gql`
      query macroFeatureGetAll($data:genericQueryFilterInput!) {
        macroFeatureGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code
          doNotShow
          icon
          id
          frontendLayoutPosition
          macroFeatureTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            languageId
            macroFeatureId
            name
          }
        }
      }
    `;
  }
  public getQuery() {
    return this.macroFeatureGetAll;
  }
}
export class macroFeatureTranslationGetAll {
  public macroFeatureTranslationGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.macroFeatureTranslationGetAll = gql`
      query macroFeatureTranslationGetAll($data:genericQueryFilterInput!) {
        macroFeatureTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          additionalInformation
          description
          id
          languageId
          macroFeatureId
          name
        }
      }
    `;
  }
  public getQuery() {
    return this.macroFeatureTranslationGetAll;
  }
}

//macroFeatureTranslationCreate CategoryUpdate
export class macroFeatureTranslationCreate {
  public static macroFeatureTranslationCreate = gql`
    mutation macroFeatureTranslationCreate(
      $data: macroFeatureTranslationCreateInput!
    ) {
      macroFeatureTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//macroFeatureTranslationCreate CategoryUpdate
export class macroFeatureTranslationUpdate {
  public static macroFeatureTranslationUpdate = gql`
    mutation macroFeatureTranslationUpdate(
      $data: macroFeatureTranslationUpdateInput!
    ) {
      macroFeatureTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
//News CategoryUpdate
export class macroFeatureTranslationDelete {
  public static macroFeatureTranslationDelete = gql`
    mutation macroFeatureTranslationDelete($id: Long!) {
      macroFeatureTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class featureGetAll {
  public featureGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.featureGetAll = gql`
      query featureGetAll($data:genericQueryFilterInput!) {
        featureGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          canBeUsedByAdminSelected
          code
          icon
          id
          macroFeatureId 
          macroFeatureGetSub{
            id
            code
          }
          featureTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            languageId
            featureId
            name
          }
        }
      }
    `;
  }
  public getQuery() {
    return this.featureGetAll;
  }
}

//featureCreate CategoryUpdate
export class featureCreate {
  public static featureCreate = gql`
    mutation featureCreate($data: featureCreateInput!) {
      featureCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//featureCreate CategoryUpdate
export class featureDelete {
  public static featureDelete = gql`
    mutation featureDelete($id: Long!) {
      featureDelete(id: $id) {
        id
        success
      }
    }
  `;
}

//macroFeatureTranslationCreate CategoryUpdate
export class featureUpdate {
  public static featureUpdate = gql`
    mutation featureUpdate($data: featureUpdateInput!) {
      featureUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Update
export class featureTranslationCreate {
  public static featureTranslationCreate = gql`
    mutation featureTranslationCreate($data: featureTranslationCreateInput!) {
      featureTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

//News Category Translation Update
export class featureTranslationUpdate {
  public static featureTranslationUpdate = gql`
    mutation featureTranslationUpdate($data: featureTranslationUpdateInput!) {
      featureTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

//News CategoryUpdate
export class featureTranslationDelete {
  public static featureTranslationDelete = gql`
    mutation featureTranslationDelete($id: Long!) {
      featureTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class featureGet {
  public static featureGet = gql`
    query featureGet($id: Long!) {
      featureGet(id: $id) {
        code

        icon
        id
        macroFeatureId
      }
    }
  `;
}

export class macroFeatureGet {
  public static macroFeatureGet = gql`
    query macroFeatureGet($id: Long!) {
      macroFeatureGet(id: $id) {
        code
        createdAt
        doNotShow
        icon
      }
    }
  `;
}

export class municipalityTranslationCreate {
  public static municipalityTranslationCreate = gql`
    mutation municipalityTranslationCreate(
      $data: municipalityTranslationCreateInput!
    ) {
      municipalityTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class municipalityTranslationUpdate {
  public static municipalityTranslationUpdate = gql`
    mutation municipalityTranslationUpdate(
      $data: municipalityTranslationUpdateInput!
    ) {
      municipalityTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class municipalityUpdate {
  public static municipalityUpdate = gql`
    mutation municipalityUpdate($data: municipalityUpdateInput!) {
      municipalityUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class municipalityTranslationDelete {
  public static municipalityTranslationDelete = gql`
    mutation municipalityTranslationDelete($id: Long!) {
      municipalityTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class municipalityDelete {
  public static municipalityDelete = gql`
    mutation municipalityDelete($id: Long!) {
      municipalityDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class municipalityGetForTranslation {
  public static municipalityGetForTranslation = gql`
    query municipalityTranslationGet($id: Long!) {
      municipalityTranslationGet(id: $id) {
        additionalInformation
        preview
        description
        id
        languageId
        municipalityId
        name

        workInProgress
      }
    }
  `;
}
export class municipalityTranslationGetAll {
  public municipalityTranslationGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.municipalityTranslationGetAll = gql`
      query municipalityTranslationGetAll($data:getAllFilterTranslationInputType!) {
        municipalityTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          additionalInformation
          description
          id
          languageId
          municipalityId
          name
          workInProgress
        }
      }
    `;
  }
  public getQuery() {
    return this.municipalityTranslationGetAll;
  }
}

export class travelCategoryGetAll {
  public travelCategoryGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.travelCategoryGetAll = gql`
      query travelCategoryGetAll($data:genericQueryFilterInput!) {
        travelCategoryGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          code

         icon
          id
          updatedAt
          travelCategoryImageGetAllSub(genericFilterQuery: {}) {
        
            id
            imageGetSub{
                bucketName
                createdAt
                description
                doNotShow
                fileName
                id
                imageTypology
                metaDataCopyright
                
                mimeType
                path
                permission
                role
                size
                tags
                url
              }
            imageId
            travelCategoryId
            updatedAt
          } 
          travelCategoryTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            createdAt
            description
            id
            languageId
            name
            travelCategoryGetSub{
                code
               createdAt
              icon
              id} 
            travelCategoryId
             updatedAt
          } 
        } 
      } 
    `;
    } else {
      this.travelCategoryGetAll = gql`
      query travelCategoryGetAll($data:genericQueryFilterInput!) {
        travelCategoryGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code

         icon
          id

          travelCategoryImageGetAllSub(genericFilterQuery: {}) {

            id
            imageGetSub{
              fileName
              id
              mimeType
              role
              size
              tags
              url
              }
            imageId
            travelCategoryId
          } 
          travelCategoryTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            languageId
            name
            travelCategoryGetSub{
                code
              icon
              id} 
            travelCategoryId
          } 
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.travelCategoryGetAll;
  }
}

export class poiTravelCategoryCreate {
  public static poiTravelCategoryCreate = gql`
    mutation poiTravelCategoryCreate($data: poiTravelCategoryCreateInput!) {
      poiTravelCategoryCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiTravelCategoryUpdate {
  public static poiTravelCategoryUpdate = gql`
    mutation poiTravelCategoryUpdate($data: poiTravelCategoryUpdateInput!) {
      poiTravelCategoryUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTravelCategoryDelete {
  public static poiTravelCategoryDelete = gql`
    mutation poiTravelCategoryDelete($id: Long!) {
      poiTravelCategoryDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class journeyAdminSelectedTravelCategoryUpdate {
  public static journeyAdminSelectedTravelCategoryUpdate = gql`
    mutation journeyAdminSelectedTravelCategoryUpdate(
      $data: journeyAdminSelectedTravelCategoryUpdateInput!
    ) {
      journeyAdminSelectedTravelCategoryUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyAdminSelectedTravelCategoryDelete {
  public static journeyAdminSelectedTravelCategoryDelete = gql`
    mutation journeyAdminSelectedTravelCategoryDelete($id: Long!) {
      journeyAdminSelectedTravelCategoryDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedTravelCategoryCreate {
  public static journeyAdminSelectedTravelCategoryCreate = gql`
    mutation journeyAdminSelectedTravelCategoryCreate(
      $data: journeyAdminSelectedTravelCategoryCreateInput!
    ) {
      journeyAdminSelectedTravelCategoryCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsJourneyAssociationDelete {
  public static newsJourneyAssociationDelete = gql`
    mutation newsJourneyAssociationDelete($id: Long!) {
      newsJourneyAssociationDelete(id: $id) {
        id
        success
      }
    }
  `;

}

export class seoCodeDelete {
  public static seoCodeDelete = gql`
    mutation seoCodeDelete($id: Long!) {
      seoCodeDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class getPoiById {
  public static getPoiById = gql`
    query pointOfInterestGet($id: Long!) {
      pointOfInterestGet(id: $id) {
        address
        supplierId
        email
        estimatedVisitLength
        estimatedReopeningDate
        id
        importance
        latitude
        longitude
        municipalityId
        municipalityGetSub {
          code
          id
          latitude
          longitude
          provinceId
        }
        openingPeriodValidated
        poiBillingGetSub {
          id
          poiBillingTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            languageId
            name
            poiBillingId
          }
          pointOfInterestId
          price
        }
        poiFeatureGetAllSub(genericFilterQuery: {}) {
          featureId
          id
          pointOfInterestId
        }
        poiOpeningPeriodGetAllSub(genericFilterQuery: {}) {
          derogatedOpeningPeriodId
          endingDateTime
          backOfficeData
          backOfficeStatus
          id
          isClosed
          derogatingOpeningPeriodsGetAll(genericFilterQuery: {}) {
            derogatedOpeningPeriodId
            backOfficeStatus
            endingDateTime
            backOfficeData
            id
            isClosed
            poiOpeningDayGetAllSub(genericFilterQuery: {}) {
              daysOfWeek
              endingTime
              id
              notes
              poiOpeningPeriodId
              startingTime
            }
            startingDateTime
          }
          poiOpeningDayGetAllSub(genericFilterQuery: {}) {
            daysOfWeek
            endingTime
            id
            notes
            poiOpeningPeriodId
            startingTime
          }
          startingDateTime
        }
        poiTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation

          description
          id
          indicationsContent
          indicationsTitle
          languageId
          name
          pointOfInterestId
          limitations
          facilitations
        }
        poiTravelTagGetAllSub(genericFilterQuery: {}) {
          id
          travelTagGetSub {
            id
            code
          }
        }
        poiTravelCategoryGetAllSub(genericFilterQuery: {}) {
          id
          travelCategoryGetSub {
            code

            icon
            id
          }
        }
        pointOfInterestImageGetAllSub(
          genericFilterQuery: {
            role: "ORIGINAL"
            queryOperator: OR
            tags: ["POI_COVER_IMAGE", "POI_IMAGE"]
          }
        ) {
          createdAt
          id
          imageGetSub {
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          pointOfInterestId
        }

        starsAdmin
        webSite
        workInProgress
      
        poiAssignedTypeGetAllSub(genericFilterQuery: {}) {
          id
          poiTypeGetSub {
            code
            id
            poiMacroTypeId
            poiTypeTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation

              description
              id
              languageId
              name
              poiTypeId
            }
          }
          poiTypeId
          pointOfInterestId
        }
      }
    }
  `;
}

export class municipalityGetAllforSeoCode {
  public municipalityGetAllforSeoCode: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.municipalityGetAllforSeoCode = gql`
      query municipalityGetAll($data:getAllFilterMunicipalityInputType!) {
        municipalityGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          capoluogoProvincia
          code
          id
          latitude
          longitude
          workInProgress
          municipalityImageGetAllSub (genericFilterQuery: {role:"ORIGINAL",queryOperator:OR,tags:["MUNICIPALITY_COVER_IMAGE","MUNICIPALITY_IMAGE"]}) {
            createdAt
            id
            positionCover
            positionGallery
            backOfficeData
            imageGetSub{
              fileName
              id
              mimeType
              role
              size
              tags
              url
              }
            imageId
            municipalityId
          } 
          municipalityTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
              preview
          description
          id
          languageId
          municipalityId
          name
          }
          provinceId
          seoCodeGetAllSub(genericFilterQuery: {seoAssociation:MUNICIPALITY}){
             id
             code
            municipalityId
            languageId

            redirectStatusCode
          staticRedirectUrl
          redirectToId
          }
          seoTagGetAllSub(genericFilterQuery:  {seoAssociation: MUNICIPALITY}){
           
            description
            id
           
            languageId
            municipalityId
           
            openGraphTags
            noFollow
            superAdminId
            title
     
          }
         
        } 
      } 
    `;
    } else {
      this.municipalityGetAllforSeoCode = gql`
      query municipalityGetAll($data:getAllFilterMunicipalityInputType!) {
        municipalityGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          capoluogoProvincia
          code
          id
          latitude
          longitude
          workInProgress
          municipalityImageGetAllSub (genericFilterQuery: {role:"ORIGINAL",queryOperator:OR,tags:["MUNICIPALITY_COVER_IMAGE","MUNICIPALITY_IMAGE"]}) {

            id
            positionCover
            positionGallery
            backOfficeData
            imageGetSub{
              fileName
              id
              mimeType
              role
              size
              tags
              url
              }
            imageId
            municipalityId

          } 
          municipalityTranslationGetAllSub(genericFilterQuery: {}){
          additionalInformation

          description
          id
          languageId
          municipalityId
          name
          }
          provinceId
          seoCodeGetAllSub(genericFilterQuery: {seoAssociation:MUNICIPALITY}){
             id
             code
            municipalityId
            languageId

            redirectStatusCode
          staticRedirectUrl
          redirectToId
          }
          seoTagGetAllSub(genericFilterQuery:  {seoAssociation: MUNICIPALITY}){
        
            description
            id
            municipalityId
            openGraphTags
            noFollow
            superAdminId
            title

          }

        }
      }
    `;
    }
  }

  public getQuery() {
    return this.municipalityGetAllforSeoCode;
  }
}

export class pointOfInterestImageDelete {
  public static pointOfInterestImageDelete = gql`
    mutation pointOfInterestImageDelete($id: Long!) {
      pointOfInterestImageDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class newsWarningDelete {
  public static newsWarningDelete = gql`
    mutation newsWarningDelete($id: Long!) {
      newsWarningDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiAssignedTypeGetAll {
  public poiAssignedTypeGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.poiAssignedTypeGetAll = gql`
      query poiAssignedTypeGetAll($data:genericQueryFilterInput!) {
        poiAssignedTypeGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          createdAt
    id
    pointOfInterestId
    poiTypeGetSub{
    code
    createdAt
    id
    poiMacroTypeId
    updatedAt
  }
    poiTypeId
    updatedAt
        } 
      } 
    `;
    } else {
      this.poiAssignedTypeGetAll = gql`
      query poiAssignedTypeGetAll($data:genericQueryFilterInput!) {
        poiAssignedTypeGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {

          createdAt
    id
    pointOfInterestId
    poiTypeGetSub{
    code
    createdAt
    id
    poiMacroTypeId
    updatedAt
  }
    poiTypeId
    updatedAt

        }
      }
    `;
    }
  }

  public getQuery() {
    return this.poiAssignedTypeGetAll;
  }
}
export class seoCodeGetAll {
  public seoCodeGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.seoCodeGetAll = gql`
      query seoCodeGetAll($data:getAllFilterSeoInput!) {
        seoCodeGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          activityId
          code
          createdAt
          id
          journeyAdminSelectedId
          journeyId
          languageId
          municipalityId
          newsCategoryId
          newsId
          pointOfInterestId
          redirectStatusCode
          redirectToId
          staticRedirectUrl
          superAdminId
          updatedAt
        } 
      } 
    `;
    } else {
      this.seoCodeGetAll = gql`
      query seoCodeGetAll($data:getAllFilterSeoInput!) {
        seoCodeGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          activityId
          code
          createdAt
          id
          journeyAdminSelectedId
          journeyId
          languageId
          municipalityId
          newsCategoryId
          newsId
          pointOfInterestId
          redirectStatusCode
          redirectToId
          staticRedirectUrl
          superAdminId
          updatedAt
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.seoCodeGetAll;
  }
}

export class travelTagCreate {
  public static travelTagCreate = gql`
    mutation travelTagCreate($data: travelTagCreateInput!) {
      travelTagCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class travelTagTranslationCreate {
  public static travelTagTranslationCreate = gql`
    mutation travelTagTranslationCreate(
      $data: travelTagTranslationCreateInput!
    ) {
      travelTagTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class travelTagGetAll {
  public travelTagGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.travelTagGetAll = gql`
      query travelTagGetAll($data:genericQueryFilterInput!) {
        travelTagGetAll(genericFilterQuery:$data,limit: ${this.limit}) {

    code
    id
    travelTagTranslationGetAllSub(genericFilterQuery: {}){  
  additionalInformation
  description
  id
  languageId
  name
  travelTagId
    }
        } 
      } 
    `;
    } else {
      this.travelTagGetAll = gql`
      query travelTagGetAll($data:genericQueryFilterInput!) {
        travelTagGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
       
          code
          createdAt
          id
          travelTagTranslationGetAllSub(genericFilterQuery: {}){  
        additionalInformation
        description
        id
        languageId
        name
        travelTagId
          }
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.travelTagGetAll;
  }
}
//featureCreate CategoryUpdate
export class travelTagDelete {
  public static travelTagDelete = gql`
    mutation travelTagDelete($id: Long!) {
      travelTagDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class travelTagUpdate {
  public static travelTagUpdate = gql`
    mutation travelTagUpdate($data: travelTagUpdateInput!) {
      travelTagUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class travelTagGetById {
  public static travelTagGet = gql`
    query travelTagGet($id: Long!) {
      travelTagGet(id: $id) {
        canBeUsedByAdminSelected
        canBeUsedByJourney
        canBeUsedByJourneyDay
        canBeUsedByPoi
        canBeUsedByProvinceToVisitInTheDay
        canBeUsedByProvinceToVisitInTheDayActivity
        code
        id
        travelTagTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
          description
          id
          languageId
          name
          travelTagId
        }
      }
    }
  `;
}
export class travelTagTranslationUpdate {
  public static travelTagTranslationUpdate = gql`
    mutation travelTagTranslationUpdate(
      $data: travelTagTranslationUpdateInput!
    ) {
      travelTagTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestAdminSelectedGetAll {
  public pointOfInterestAdminSelectedGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.pointOfInterestAdminSelectedGetAll = gql`
      query pointOfInterestAdminSelectedGetAll($data:getAllFilterPointOfInterestAdminSelectedInputType!) {
        pointOfInterestAdminSelectedGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          dollarSigns
          position
          id
       poiAdminSelectedImageGetAllSub(genericFilterQuery: {role:"ORIGINAL",queryOperator:OR,tags:["POI_ADMIN_COVER_IMAGE","POI_ADMIN_IMAGE"]}){
          id
          imageGetSub{
            fileName
            id
            mimeType
            role
            size
            tags
            url
        }
          imageId
          pointOfInterestAdminSelectedId
        }
          pointOfInterestAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}){
          id
          travelTagGetSub{
          code
          id
        }
          travelTagId
          
          }
          pointOfInterestId
           pointOfInterestGetSub{
          address
          email
          estimatedVisitLength
          id
          importance
          latitude
          longitude
          municipalityId
          openingPeriodValidated
          poiTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
          description
          id
          indicationsContent
          indicationsTitle
          languageId
          name
          pointOfInterestId

          }
          starsAdmin
          webSite
          workInProgress
        }
        } 
      } 
    `;
    } else {
      this.pointOfInterestAdminSelectedGetAll = gql`
      query pointOfInterestAdminSelectedGetAll($data:getAllFilterPointOfInterestAdminSelectedInputType!) {
        pointOfInterestAdminSelectedGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {

          dollarSigns
          id
       poiAdminSelectedImageGetAllSub(genericFilterQuery: {role:"ORIGINAL",queryOperator:OR,tags:["POI_ADMIN_COVER_IMAGE","POI_ADMIN_IMAGE"]}){
      
          id
          imageGetSub{
            fileName
            id
            mimeType
            role
            size
            tags
            url
        }
          imageId
          pointOfInterestAdminSelectedId
        
        }
          pointOfInterestAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}){
          id
          travelTagGetSub{
          code
          id
        }
          travelTagId
          }
          pointOfInterestId
           pointOfInterestGetSub{
          address
          email
          estimatedVisitLength
          id
          importance
          latitude
          longitude
          municipalityId
          openingPeriodValidated
          poiTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
          description
          id
          indicationsContent
          indicationsTitle
          languageId
          name
          pointOfInterestId

          }
          
          starsAdmin
          webSite
          workInProgress
        }
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.pointOfInterestAdminSelectedGetAll;
  }
}
export class poiAdminSelectedGetAll {
  public static poiAdminSelectedGetAll = gql`
    query pointOfInterestAdminSelectedGetAll(
      $data: getAllFilterPointOfInterestAdminSelectedInputType!
      $limit: Long
      $idCursor: Long
    ) {
      pointOfInterestAdminSelectedGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        createdAt
        dollarSigns
        position
        id
        poiAdminSelectedImageGetAllSub(
          genericFilterQuery: {
            role: "ORIGINAL"
            queryOperator: OR
            tags: ["POI_ADMIN_COVER_IMAGE", "POI_ADMIN_IMAGE"]
          }
        ) {
          id
          positionCover
          positionGallery
          backOfficeData
          imageGetSub {
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          pointOfInterestAdminSelectedId
        }
        pointOfInterestAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}) {
          id
          travelTagGetSub {
            code
            id
          }
          travelTagId
        }
        pointOfInterestId
        pointOfInterestGetSub {
          address
          email
          estimatedVisitLength
          id
          importance
          latitude
          longitude
          municipalityId

          openingPeriodValidated
          poiTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            indicationsContent
            indicationsTitle
            languageId
            name
            pointOfInterestId
          }
          starsAdmin
          webSite
          workInProgress
        }
      }
    }
  `;
}

export class pointOfInterestAdminSelectedDelete {
  public static pointOfInterestAdminSelectedDelete = gql`
    mutation pointOfInterestAdminSelectedDelete($id: Long!) {
      pointOfInterestAdminSelectedDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class pointOfInterestAdminSelectedCreate {
  public static pointOfInterestAdminSelectedCreate = gql`
    mutation pointOfInterestAdminSelectedCreate(
      $data: pointOfInterestAdminSelectedCreateInput!
    ) {
      pointOfInterestAdminSelectedCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestAdminSelectedUpdate {
  public static pointOfInterestAdminSelectedUpdate = gql`
    mutation pointOfInterestAdminSelectedUpdate(
      $data: pointOfInterestAdminSelectedUpdateInput!
    ) {
      pointOfInterestAdminSelectedUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestAdminSelectedTravelTagCreate {
  public static pointOfInterestAdminSelectedTravelTagCreate = gql`
    mutation pointOfInterestAdminSelectedTravelTagCreate(
      $data: pointOfInterestAdminSelectedTravelTagCreateInput!
    ) {
      pointOfInterestAdminSelectedTravelTagCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestAdminSelectedTravelTagUpdate {
  public static pointOfInterestAdminSelectedTravelTagUpdate = gql`
    mutation pointOfInterestAdminSelectedTravelTagUpdate(
      $data: pointOfInterestAdminSelectedTravelTagUpdateInput!
    ) {
      pointOfInterestAdminSelectedTravelTagUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class pointOfInterestAdminSelectedTravelTagDelete {
  public static pointOfInterestAdminSelectedTravelTagDelete = gql`
    mutation pointOfInterestAdminSelectedTravelTagDelete($id: Long!) {
      pointOfInterestAdminSelectedTravelTagDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class pointOfInterestAdminSelectedGet {
  public static pointOfInterestAdminSelectedGet = gql`
    query pointOfInterestAdminSelectedGet($id: Long!) {
      pointOfInterestAdminSelectedGet(id: $id) {
        dollarSigns
        position
        id
        poiAdminSelectedImageGetAllSub(genericFilterQuery: {}) {
          id
          imageGetSub {
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          pointOfInterestAdminSelectedId
        }
        pointOfInterestAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}) {
          id
          travelTagGetSub {
            code

            id

            travelTagTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation

              description
              id
              languageId
              name
              travelTagId
            }
          }
          travelTagId
        }
        pointOfInterestId
        pointOfInterestGetSub {
          address

          email
          estimatedVisitLength
          id
          importance
          latitude

          longitude
          municipalityId

          openingPeriodValidated
          poiTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            description
            id
            indicationsContent
            indicationsTitle
            languageId
            name
            pointOfInterestId
          }
          starsAdmin
          webSite
          workInProgress
        }
      }
    }
  `;
}
export class poiAdminSelectedImageCreate {
  public static poiAdminSelectedImageCreate = gql`
    mutation poiAdminSelectedImageCreate(
      $data: poiAdminSelectedImageCreateInput!
    ) {
      poiAdminSelectedImageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiAdminSelectedImageDelete {
  public static poiAdminSelectedImageDelete = gql`
    mutation poiAdminSelectedImageDelete($id: Long!) {
      poiAdminSelectedImageDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiTypeGetAll {
  public poiTypeGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.poiTypeGetAll = gql`
      query poiTypeGetAll($data:genericQueryFilterInput!) {
        poiTypeGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          code
          id
          icon
          poiMacroTypeId
          poiMacroTypeGetSub{
            code
            id
            icon
          }
          poiTypeTranslationGetAllSub(genericFilterQuery: {}){
          id
          languageId
          name
          poiTypeId
          description
          }
        }
      }
    `;
    } else {
      this.poiTypeGetAll = gql`
      query poiTypeGetAll($data:genericQueryFilterInput!) {
        poiTypeGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code
          id
          icon
          poiMacroTypeId
          poiMacroTypeGetSub{
            code
            id
            icon
          }
          poiTypeTranslationGetAllSub(genericFilterQuery: {}){
            id
            languageId
            name
            poiTypeId
            description
          }
        }
      }
    `;
    }
  }
  public getQuery() {
    return this.poiTypeGetAll;
  }
}

export class poiMacroTypeGetAll {
  public poiMacroTypeGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.poiMacroTypeGetAll = gql`
      query poiMacroTypeGetAll($data:genericQueryFilterInput!) {
        poiMacroTypeGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          code
          icon
          id
         poiMacroTypeTranslationGetAllSub(genericFilterQuery: {}){
          additionalInformation

          description
          id
          languageId
          name
          poiMacroTypeId

        }
         poiTypeGetAllSub(genericFilterQuery: {}){
          code
          icon
          id
       poiMacroTypeGetSub{
          code

          id

        }
          poiMacroTypeId

        }
      
    }
  }
    `;
    } else {
      this.poiMacroTypeGetAll = gql`
      query poiMacroTypeGetAll($data:genericQueryFilterInput!) {
        poiMacroTypeGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code
          id
          icon
         poiMacroTypeTranslationGetAllSub(genericFilterQuery: {}){
          additionalInformation
          description
          id
          languageId
          name
          poiMacroTypeId
        }
         poiTypeGetAllSub(genericFilterQuery: {}){
          code
          icon
          id
       poiMacroTypeGetSub{
          code
          id
        }
          poiMacroTypeId
        }

      }
    }
    `;
    }
  }
  public getQuery() {
    return this.poiMacroTypeGetAll;
  }
}
export class poiAssignedTypeDelete {
  public static poiAssignedTypeDelete = gql`
    mutation poiAssignedTypeDelete($id: Long!) {
      poiAssignedTypeDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiAssignedTypeCreate {
  public static poiAssignedTypeCreate = gql`
    mutation poiAssignedTypeCreate($data: poiAssignedTypeCreateInput!) {
      poiAssignedTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class newsUnitCreate {
  public static newsUnitCreate = gql`
    mutation newsUnitCreate($data: newsUnitCreateInput!) {
      newsUnitCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsUnitGet {
  public static newsUnitGet = gql`
    query newsUnitGet($id: Long!) {
      newsUnitGet(id: $id) {
        body
        createdAt
        id
        languageId
        municipalityOperatorId
        newsId
        pinned
        preview
        superAdminId
        author
        title
        updatedAt
        workInProgress
      }
    }
  `;
}

// superAdmin News Create
export class newsUnitUpdate {
  public static newsUnitUpdate = gql`
    mutation newsUnitUpdate($data: newsUnitUpdateInput!) {
      newsUnitUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedGetAll {
  public journeyAdminSelectedGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    var parameter = ""
    if (typeof idCursor === "undefined") {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}`
    } else {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}, idCursor: ${idCursor}`
    }
    this.journeyAdminSelectedGetAll = gql`
      query journeyAdminSelectedGetAll($data:journeyAdminSelectedGetAllQueryInput!) {
        journeyAdminSelectedGetAll(${parameter}) {journeyId
          latitude
          longitude
          address
          dollarSigns
          id
          position
          isActive
          supplierId
          
          seoCodeGetAllSub(genericFilterQuery: {
            seoAssociation: JOURNEY_ADMIN_SELECTED
          }) {
            code
            id
            journeyId
            journeyAdminSelectedId
            languageId
            redirectStatusCode
            staticRedirectUrl
            redirectToId
          }
          seoTagGetAllSub(genericFilterQuery: {
            seoAssociation: JOURNEY_ADMIN_SELECTED
          }) {
            description
            id
            journeyId
            journeyAdminSelectedId
            languageId
            openGraphTags
            noFollow
            superAdminId
            title
          }
          journeyAdminSelectedFeatureGetAllSub(genericFilterQuery: {}) {
            createdAt
            featureGetSub {
              code
              icon
              id
              macroFeatureId
            }
            id
          }
          journeyAdminSelectedTravelCategoryGetAllSub(genericFilterQuery: {}) {
            id
            travelCategoryGetSub {
              code
              icon
              id
            }
          }
          journeyAdminSelectedTranslationGetAllSub(genericFilterQuery: {}) {
            additionalInformation
            createdAt
            description
            id
            journeyAdminSelectedId
            facilitations
            limitations
            languageId
            name
            updatedAt
            preview
          }
          journeyGetSub {
            journeyMeetingPointGetAllSub(genericFilterQuery: {}) {
              address
              endingDateTime
              id
              journeyId
              latitude
              longitude
              startingDateTime
            }
            createdByAdmin
            endingDateTime
            id
            journeyParticipantsTypeId
            journeyPriceRange
            journeyTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation
              description
              id
              journeyId
              languageId
              name
            }
            seoCodeGetAllSub(genericFilterQuery: {
              seoAssociation: JOURNEY
            }) {
              code
              id
              journeyId
              journeyAdminSelectedId
              languageId
              redirectStatusCode
              staticRedirectUrl
              redirectToId
            }
            seoTagGetAllSub(genericFilterQuery: {
              seoAssociation: JOURNEY
            }) {
              description
              id
              journeyId
              journeyAdminSelectedId
              languageId
              openGraphTags
              noFollow
              superAdminId
              title
            }
            startingDateTime
            transportationMethods
          }
          journeyAdminSelectedImageGetAllSub(genericFilterQuery: {
            role: "ORIGINAL",
            mimeType: "image/webp",
            queryOperator: OR,
            tags: ["JOURNEY_ADMIN_COVER_IMAGE", "JOURNEY_ADMIN_IMAGE"]
          }) {
            id
            positionCover
            positionGallery
            imageGetSub {
              fileName
              id
              mimeType
              role
              size
              tags
              url
            }
            imageId
            journeyAdminSelectedId
          }
          journeyAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}) {
            id
            journeyAdminSelectedId
            travelTagGetSub {
              code
              id
              travelTagTranslationGetAllSub(genericFilterQuery: {}) {
                additionalInformation
                description
                id
                languageId
                name
                travelTagId
              }
            }
            travelTagId
          }
        } 
      }`;
  }

  public getQuery() {
    return this.journeyAdminSelectedGetAll;
  }
}

export class journeyAdminSelectedGetAllPoi {
  public journeyAdminSelectedGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    var parameter = ""
    if (typeof idCursor === "undefined") {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}`
    } else {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}, idCursor: ${idCursor}`
    }
    this.journeyAdminSelectedGetAll = gql`
      query journeyAdminSelectedGetAll($data:journeyAdminSelectedGetAllQueryInput!) {
        journeyAdminSelectedGetAll(${parameter}) {
          supplierId
          id
          journeyGetSub{
            journeyDayGetAllSub(genericFilterQuery: {}) {
              journeyDayPriceSum
                id
                provinceToVisitInTheDayGetAllSub(genericFilterQuery:{}) {
                  id
                  provinceToVisitInTheDayElementGetAllSub(genericFilterQuery:{}) {
                    id
                    pointToVisitGetSub {
                      id
                      pointOfInterestGetSub {
                        id
                        poiTranslationGetAllSub(genericFilterQuery:{}) {
                          name
                          languageId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`;
  }

  public getQuery() {
    return this.journeyAdminSelectedGetAll;
  }
}

export class newsJourneyAdminSelectedAssociationCreate {
  public static newsJourneyAdminSelectedAssociationCreate = gql`
    mutation newsJourneyAdminSelectedAssociationCreate(
      $data: NewsJourneyAdminSelectedAssociationCreateInput!
    ) {
      newsJourneyAdminSelectedAssociationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class newsJourneyAdminSelectedAssociationUpdate {
  public static newsJourneyAdminSelectedAssociationUpdate = gql`
    mutation newsJourneyAdminSelectedAssociationUpdate(
      $data: NewsJourneyAdminSelectedAssociationUpdateInput!
    ) {
      newsJourneyAdminSelectedAssociationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class newsJourneyAdminSelectedAssociationDelete {
  public static newsJourneyAdminSelectedAssociationDelete = gql`
    mutation newsJourneyAdminSelectedAssociationDelete($id: Long!) {
      newsJourneyAdminSelectedAssociationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class newsJourneyAssociationCreate {
  public static newsJourneyAssociationCreate = gql`
    mutation newsJourneyAssociationCreate(
      $data: newsJourneyAssociationCreateInput!
    ) {
      newsJourneyAssociationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyGet {
  public static journeyGet = gql`
    query journeyGet($id: Long!) {
      journeyGet(id: $id) {
        id
        journeyTranslationGetAllSub(genericFilterQuery:{}){
          additionalInformation
          description
          id
          journeyId
          languageId
          name
        }

        journeyDayGetAllSub(genericFilterQuery: {}) {
          journeyDayPriceSum
          id
          provinceToVisitInTheDayGetAllSub(genericFilterQuery:{}) {
            id
            provinceToVisitInTheDayElementGetAllSub(genericFilterQuery:{}) {
              id
              pointToVisitGetSub {
                id
                pointOfInterestGetSub {
                  id
                  pointOfInterestImageGetAllSub(genericFilterQuery:{}) {
                    id
                    positionCover
                    positionGallery
                    imageGetSub {
                      id
                      fileName
                      role
                      mimeType
                      tags
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  `;
}

export class journeyGetAll {
  public journeyGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    var parameter = ""
    if (typeof idCursor === "undefined") {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}`
    } else {
      parameter = `genericFilterQuery: $data, limit: ${this.limit}, idCursor: ${idCursor}`
    }
    this.journeyGetAll = gql`
    query journeyGetAll($data:getAllFilterJourneyInput!) {
      journeyGetAll(${parameter}) {
        clonedJourneyId
        backOfficeData
        createdByAdmin
        endingDateTime
        id
        journeyFeatureGetAllSub(genericFilterQuery: {}){
          createdAt
          featureGetSub {
            code
            icon
            id
            macroFeatureId
          }
          featureId
          id
          journeyId
        }
        journeyTranslationGetAllSub(genericFilterQuery:{}){
          additionalInformation
          description
          id
          journeyId
          languageId
          name
        }
        journeyImageGetAllSub(genericFilterQuery:{role:"ORIGINAL",queryOperator:OR,tags:["JOURNEY_COVER_IMAGE","JOURNEY_IMAGE"]}){
          id
          positionCover
          positionGallery
          backOfficeData
          imageGetSub {
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          journeyId
        }
        journeyTravelTagGetAllSub(genericFilterQuery:{}){
          id
          travelTagGetSub {
            code
            id
            travelTagTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
              description
              id
              languageId
              name
              travelTagId
            }
          }
          travelTagId
        }
        journeyDayGetAllSub(genericFilterQuery: {}) {
          journeyDayPriceSum
          id
          provinceToVisitInTheDayGetAllSub(genericFilterQuery:{}) {
            id
            provinceToVisitInTheDayElementGetAllSub(genericFilterQuery:{}) {
              id
              pointToVisitGetSub {
                id
                pointOfInterestGetSub {
                  id
                  poiTranslationGetAllSub(genericFilterQuery:{}) {
                    name
                    languageId
                  }
                }
              }
            }
          }
        }
        journeyPriceRange
        
        journeyTravelCategoryGetAllSub(genericFilterQuery: {}){
          id
          travelCategoryGetSub {
            code
            icon
            id
            travelCategoryTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
              description
              id
              languageId
              name
              travelCategoryId
            }
          }
        }
        journeyTypology
        journeyPriceSum
        numberOfParticipants
        ownerId
        seoCodeGetAllSub(genericFilterQuery: {seoAssociation:JOURNEY}){
          code
          id
          journeyId
          languageId
          redirectStatusCode
          staticRedirectUrl
          redirectToId
        }
        startingDateTime
        transportationMethods
      }
    }`;
  }

  public getQuery() {
    return this.journeyGetAll;
  }
}

export class journeyTranslationGet {
  public static journeyTranslationGet = gql`
    query journeyTranslationGet($id: Long!) {
      journeyTranslationGet(id: $id) {
        additionalInformation
        description
        id
        journeyId
        languageId
        name
      }
    }
  `;
}
export class journeyGetAllForAdminSelected {
  public journeyGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.journeyGetAll = gql`
      query journeyGetAll($data:getAllFilterJourneyInput!) {
        journeyGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          id
          journeyTranslationGetAllSub(genericFilterQuery: {}){
          additionalInformation

    description
    id
    journeyId
    languageId
    name

    }
    journeyImageGetAllSub(genericFilterQuery:{role:"ORIGINAL",queryOperator:OR,tags:["JOURNEY_COVER_IMAGE","JOURNEY_IMAGE"] }){

      id
              imageGetSub{
                fileName
                id
                mimeType
                role
                size
                tags
                url
        }
      imageId
      journeyId
    }
    seoTagGetAllSub(genericFilterQuery:  {seoAssociation: JOURNEY}){
              description
          id
          journeyId
          languageId
         openGraphTags
         noFollow
          superAdminId
          title

        }
        }
      }
    `;
    } else {
      this.journeyGetAll = gql`
      query journeyGetAll($data:getAllFilterJourneyInput!) {
        journeyGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          id
          journeyTranslationGetAllSub(genericFilterQuery: {}){
          additionalInformation
    description
    id
    journeyId
    languageId
    name
    }
    journeyImageGetAllSub(genericFilterQuery:{role:"ORIGINAL",queryOperator:OR,tags:["JOURNEY_COVER_IMAGE","JOURNEY_IMAGE"] }){
      
      id
              imageGetSub{
                fileName
                id
                mimeType
                role
                size
                tags
                url
        }
      imageId
      journeyId

    }
    seoTagGetAllSub(genericFilterQuery:  {seoAssociation: JOURNEY}){

      description
      id
      journeyId
      languageId
      openGraphTags
      noFollows
      superAdminId
      title
    }
        }
      }
    `;
    }
  }
  public getQuery() {
    return this.journeyGetAll;
  }
}

export class journeyMeetingPointTranslationCreate {
  public static journeyMeetingPointTranslationCreate = gql`
    mutation journeyMeetingPointTranslationCreate($data: journeyMeetingPointTranslationCreateInput!) {
      journeyMeetingPointTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyMeetingPointTranslationUpdate {
  public static journeyMeetingPointTranslationUpdate = gql`
  mutation journeyMeetingPointTranslationUpdate($data: journeyMeetingPointTranslationUpdateInput!) {
    journeyMeetingPointTranslationUpdate(updateData: $data) {
      id
      success
    }
  }
`;
}

export class journeyMeetingPointTranslationDelete {
  public static journeyMeetingPointTranslationDelete = gql`
    mutation journeyMeetingPointTranslationDelete($id: Long!) {
      journeyMeetingPointTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class journeyAdminSelectedCreate {
  public static journeyAdminSelectedCreate = gql`
    mutation journeyAdminSelectedCreate(
      $data: journeyAdminSelectedCreateInput!
    ) {
      journeyAdminSelectedCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class journeyAdminSelectedUpdate {
  public static journeyAdminSelectedUpdate = gql`
    mutation journeyAdminSelectedUpdate(
      $data: journeyAdminSelectedUpdateInput!
    ) {
      journeyAdminSelectedUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiTravelTagCreate {
  public static poiTravelTagCreate = gql`
    mutation poiTravelTagCreate($data: poiTravelTagCreateInput!) {
      poiTravelTagCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTravelTagDelete {
  public static poiTravelTagDelete = gql`
    mutation poiTravelTagDelete($id: Long!) {
      poiTravelTagDelete(id: $id) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class journeyAdminSelectedTravelTagCreate {
  public static journeyAdminSelectedTravelTagCreate = gql`
    mutation journeyAdminSelectedTravelTagCreate(
      $data: journeyAdminSelectedTravelTagCreateInput!
    ) {
      journeyAdminSelectedTravelTagCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class journeyAdminSelectedImageCreate {
  public static journeyAdminSelectedImageCreate = gql`
    mutation journeyAdminSelectedImageCreate(
      $data: journeyAdminSelectedImageCreateInput!
    ) {
      journeyAdminSelectedImageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
// superAdmin News Create
export class journeyAdminSelectedTravelTagDelete {
  public static journeyAdminSelectedTravelTagDelete = gql`
    mutation journeyAdminSelectedTravelTagDelete($id: Long!) {
      journeyAdminSelectedTravelTagDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyTranslationGetAll {
  public journeyTranslationGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.journeyTranslationGetAll = gql`
      query journeyTranslationGetAll($data:getAllFilterTranslationInputType!) {
        journeyTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          additionalInformation
          description
          id
          journeyId
          languageId
          name

        }
      }
    `;
    } else {
      this.journeyTranslationGetAll = gql`
      query journeyTranslationGetAll($data:getAllFilterTranslationInputType!) {
        journeyTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          additionalInformation
          description
          id
          journeyId
          languageId
          name
        }
      }
    `;
    }
  }
  public getQuery() {
    return this.journeyTranslationGetAll;
  }
}
export class journeyTranslationCreate {
  public static journeyTranslationCreate = gql`
    mutation journeyTranslationCreate($data: journeyTranslationCreateInput!) {
      journeyTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyTranslationUpdate {
  public static journeyTranslationUpdate = gql`
    mutation journeyTranslationUpdate($data: journeyTranslationUpdateInput!) {
      journeyTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedTranslationCreate {
  public static journeyAdminSelectedTranslationCreate = gql`
    mutation journeyAdminSelectedTranslationCreate(
      $data: journeyAdminSelectedTranslationCreateInput!
    ) {
      journeyAdminSelectedTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyAdminSelectedTranslationUpdate {
  public static journeyAdminSelectedTranslationUpdate = gql`
    mutation journeyAdminSelectedTranslationUpdate(
      $data: journeyAdminSelectedTranslationUpdateInput!
    ) {
      journeyAdminSelectedTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedGet {
  public static journeyAdminSelectedGet = gql`
    query journeyAdminSelectedGet($id: Long!) {
      journeyAdminSelectedGet(id: $id) {
        address
        dollarSigns
        id
        position
        isActive
        price
        supplierId
        journeyAdminSelectedAssignedTypeGetAllSub(genericFilterQuery: {}){
          journeyAdminSelectedBaseTypeGetSub{
            code
            id
            journeyAdminSelectedMacroTypeGetSub {
              id
              code
            }
          }
          id
        }
        journeyAdminSelectedTravelCategoryGetAllSub(genericFilterQuery: {}) {
          id
          travelCategoryGetSub {
            code

            icon
            id
          }
        }
        journeyAdminSelectedFeatureGetAllSub(genericFilterQuery: {}){
          createdAt
          featureGetSub{
            code
            icon
            id
            macroFeatureId
            macroFeatureGetSub{
              code
            }
          }
          id
        }
        journeyAdminSelectedImageGetAllSub(
          genericFilterQuery: {
            role: "ORIGINAL"
            queryOperator: OR
            tags: ["JOURNEY_ADMIN_COVER_IMAGE", "JOURNEY_ADMIN_IMAGE"]
          }
        ) {
          positionCover
          positionGallery
          id
          imageGetSub {
            fileName
            id
            mimeType
            role
            size
            tags
            url
          }
          imageId
          journeyAdminSelectedId
        }
        journeyGetSub {
          id
          journeyTranslationGetAllSub(genericFilterQuery: {}){
            id
            name
          }
          journeyMeetingPointGetAllSub(genericFilterQuery: {}){
            address
            endingDateTime
            id
            journeyId
            latitude
            longitude
            startingDateTime
            journeyMeetingPointTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
              createdAt
              description
              id
              journeyMeetingPointId
              languageId
              name
              updatedAt
            }
          }
        }
        journeyId
        latitude
        longitude
        startingDateTime
        endingDateTime
        estimatedVisitLength
        journeyAdminSelectedTravelTagGetAllSub(genericFilterQuery: {}) {
          id
          journeyAdminSelectedId
          travelTagGetSub {
            code

            id
            travelTagTranslationGetAllSub(genericFilterQuery: {}) {
              additionalInformation

              description
              id
              languageId
              name
              travelTagId
            }
          }
          travelTagId
        }
      }
    }
  `;
}

export class journeyAdminSelectedPoiPriceSum {
  public static journeyAdminSelectedPoiPriceSum = gql`
    query journeyAdminSelectedGet($id: Long!) {
      journeyAdminSelectedGet(id: $id) {
        id
        price
        journeyGetSub {
          id
          journeyPoiPriceSum
        }
      }
    }
  `;
}

export class journeyAdminSelectedGetFacilitations {
  public static getFacilitations = gql`
    query journeyAdminSelectedGet($id: Long!) {
      journeyAdminSelectedGet(id: $id) {
        journeyAdminSelectedTranslationGetAllSub(genericFilterQuery: {}) {
          facilitations
        }
      }
    }
  `;
}

// superAdmin News Create
export class journeyAdminSelectedDelete {
  public static journeyAdminSelectedDelete = gql`
    mutation journeyAdminSelectedDelete($id: Long!) {
      journeyAdminSelectedDelete(id: $id) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class journeyDelete {
  public static journeyDelete = gql`
    mutation journeyDelete($id: Long!) {
      journeyDelete(id: $id) {
        id
        success
      }
    }
  `;
}
// superAdmin News Create
export class journeyTranslationDelete {
  public static journeyTranslationDelete = gql`
    mutation journeyTranslationDelete($id: Long!) {
      journeyTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedTranslationDelete {
  public static journeyAdminSelectedTranslationDelete = gql`
    mutation journeyAdminSelectedTranslationDelete($id: Long!) {
      journeyAdminSelectedTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
//code position
// superAdmin News Create
export class faqCreate {
  public static faqCreate = gql`
    mutation faqCreate($data: faqCreateInput!) {
      faqCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class faqUpdate {
  public static faqUpdate = gql`
    mutation faqUpdate($data: faqUpdateInput!) {
      faqUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class faqGet {
  public static faqGet = gql`
    query faqGet($id: Long!) {
      faqGet(id: $id) {
        code
        faqTranslationGetAllSub(genericFilterQuery: {}) {
          faqId
          id
          languageId
          name
        }
        id
        position
      }
    }
  `;
}
export class faqGetAll {
  public faqGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.faqGetAll = gql`
      query faqGetAll($data:genericQueryFilterInput!) {
        faqGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          code

             faqTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation
          description
          faqId
          id
          languageId
          name

          }
          id
          position
        }
      }
    `;
    } else {
      this.faqGetAll = gql`
      query faqGetAll($data:genericQueryFilterInput!) {
        faqGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          code
        
             faqTranslationGetAllSub(genericFilterQuery: {}){
              additionalInformation

          description
          faqId
          id
          languageId
          name

          }
          id
          position
        }
      }
    `;
    }
  }
  public getQuery() {
    return this.faqGetAll;
  }
}

export class faqDelete {
  public static faqDelete = gql`
    mutation faqDelete($id: Long!) {
      faqDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class faqTranslationCreate {
  public static faqTranslationCreate = gql`
    mutation faqTranslationCreate($data: faqTranslationCreateInput!) {
      faqTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiLanguageCreate {
  public static poiLanguageCreate = gql`
    mutation poiLanguageCreate($data: poiLanguageCreateInput!) {
      poiLanguageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class faqTranslationUpdate {
  public static faqTranslationUpdate = gql`
    mutation faqTranslationUpdate($data: faqTranslationUpdateInput!) {
      faqTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class faqTranslationDelete {
  public static faqTranslationDelete = gql`
    mutation faqTranslationDelete($id: Long!) {
      faqTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class municipalityOperatorLoggedInData {
  public static municipalityOperatorLoggedInData = gql`
    query municipalityOperatorLoggedInData {
      municipalityOperatorLoggedInData {
        baseUserId
        id
        municipalityId
      }
    }
  `;
}

export class seoCodeGet {
  public static seoCodeGet = gql`
    query seoCodeGet($id: Long!) {
      seoCodeGet(id: $id) {
        code

        id
        journeyId
        languageId
        municipalityId
        newsCategoryId
        newsId
        pointOfInterestId
        superAdminId
      }
    }
  `;
}

export class getAuthor {
  public static getAuthor = gql`
    query seoCodeGetAll(
      $data: getAllFilterSeoInput!
      $limit: Long
      $idCursor: Long
    ) {
      seoCodeGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        code
        id
        journeyId
        languageId
        municipalityId
        newsId
        pointOfInterestId
        superAdminId
      }
    }
  `;
}
export class imageGetAll {
  public imageGetAll: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.imageGetAll = gql`
      query imageGetAll($data:imageQueryFilterInputType!) {
        imageGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          fileName
          id
          mimeType
          role
          size
          tags
          url
    imageTranslationGetAllSub(genericFilterQuery: {}){
      additionalInformation
      createdAt
      description
      id
      imageId
      languageId
      name
      updatedAt
    }
        }
      }
    `;
    } else {
      this.imageGetAll = gql`
      query imageGetAll($data:imageQueryFilterInputType!) {
        imageGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          fileName
    id
    mimeType
    role
    size
    tags
    url
          imageTranslationGetAllSub(genericFilterQuery: {}){
            additionalInformation
            createdAt
            description
            id
            imageId
            languageId
            name
            updatedAt
          }
        }
      }
    `;
    }
  }

  // getAllFilterWithPoiTypesAndMunicipalityIdAndOrderByAlphabetAndPriceInputType
  public getQuery() {
    return this.imageGetAll;
  }
}
export class AuthorGetAll {
  public seoCodeGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.seoCodeGetAll = gql`
      query seoCodeGetAll($data:getAllFilterSeoInput!) {
        seoCodeGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          code
          id
          journeyId
          languageId
          municipalityId
          newsId
          pointOfInterestId
          superAdminId
        } 
      } 
    `;
    } else {
      this.seoCodeGetAll = gql`
      query seoCodeGetAll($data:getAllFilterSeoInput!) {
        seoCodeGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          
          code
          id
          journeyId
          languageId
          municipalityId
          newsId
          pointOfInterestId
          superAdminId
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.seoCodeGetAll;
  }
}

// superAdmin News Create
export class baseUserTranslationCreate {
  public static baseUserTranslationCreate = gql`
    mutation baseUserTranslationCreate($data: baseUserTranslationCreateInput!) {
      baseUserTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class baseUserTranslationUpdate {
  public static baseUserTranslationUpdate = gql`
    mutation baseUserTranslationUpdate($data: baseUserTranslationUpdateInput!) {
      baseUserTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class baseUserTranslationDelete {
  public static baseUserTranslationDelete = gql`
    mutation baseUserTranslationDelete($id: Long!) {
      baseUserTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiBillingTranslationCreate {
  public static poiBillingTranslationCreate = gql`
    mutation poiBillingTranslationCreate(
      $data: poiBillingTranslationCreateInput!
    ) {
      poiBillingTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class poiBillingTranslationUpdate {
  public static poiBillingTranslationUpdate = gql`
    mutation poiBillingTranslationUpdate(
      $data: poiBillingTranslationUpdateInput!
    ) {
      poiBillingTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiBillingTranslationDelete {
  public static poiBillingTranslationDelete = gql`
    mutation poiBillingTranslationDelete($id: Long!) {
      poiBillingTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class superAdminGetAll {
  public superAdminGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.superAdminGetAll = gql`
    query superAdminGetAll($data:genericQueryFilterInput!) {
      superAdminGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        baseUserId
        baseUserGerSub {
          email
          id
          name
          preferredLanguageId
          surname
        }
        id
      }
    }
  `;
  }

  public getQuery() {
    return this.superAdminGetAll;
  }
}

export class baseUserGetforAuthor {
  public static baseUserGet = gql`
    query baseUserGet($id: Long!) {
      baseUserGet(id: $id) {
        email
        id
        name
        preferredLanguageId
        surname

        baseUserImageGetAllSub(genericFilterQuery: {}) {
          imageGetSub {
            url
            mimeType
            fileName
          }
        }
        surname
      }
    }
  `;
}

export class superAdminGet {
  public static superAdminGet = gql`
    query superAdminGet($id: Long!) {
      superAdminGet(id: $id) {
        baseUserId

        id
      }
    }
  `;
}

export class baseUserTranslationGetAll {
  public baseUserTranslationGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.baseUserTranslationGetAll = gql`
      query baseUserTranslationGetAll($data:getAllFilterTranslationInputType!) {
        baseUserTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit}) {
          additionalInformation
          baseUserId
          description
          id
          languageId
          name
        } 
      } 
    `;
    } else {
      this.baseUserTranslationGetAll = gql`
      query baseUserTranslationGetAll($data:getAllFilterTranslationInputType!) {
        baseUserTranslationGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
          additionalInformation
          baseUserId
          description
          id
          languageId
          name
        }
      }
    `;
    }
  }

  public getQuery() {
    return this.baseUserTranslationGetAll;
  }
}
export class poiFeatureDelete {
  public static poiFeatureDelete = gql`
    mutation poiFeatureDelete($id: Long!) {
      poiFeatureDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class poiFeatureUpdate {
  public static poiFeatureUpdate = gql`
    mutation poiFeatureUpdate($data: poiFeatureUpdateInput!) {
      poiFeatureUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiFeatureCreate {
  public static poiFeatureCreate = gql`
    mutation poiFeatureCreate($data: poiFeatureCreateInput!) {
      poiFeatureCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiFeatureGetAll {
  public poiFeatureGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor?: number) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    if (typeof idCursor === "undefined") {
      this.poiFeatureGetAll = gql`
      query poiFeatureGetAll($data:getAllFilterTranslationInputType!) {
        poiFeatureGetAll(genericFilterQuery:$data,limit: ${this.limit}) {

    featureId
    id
    pointOfInterestId
        } 
      } 
    `;
    } else {
      this.poiFeatureGetAll = gql`
      query poiFeatureGetAll($data:getAllFilterTranslationInputType!) {
        poiFeatureGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {

    featureId
    id
    pointOfInterestId

        }
      }
    `;
    }
  }

  public getQuery() {
    return this.poiFeatureGetAll;
  }
}

// superAdmin News Create
export class seoTagCreate {
  public static seoTagCreate = gql`
    mutation seoTagCreate($data: seoTagCreateInput!) {
      seoTagCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class seoTagUpdate {
  public static seoTagUpdate = gql`
    mutation seoTagUpdate($data: seoTagUpdateInput!) {
      seoTagUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class seoTagDelete {
  public static seoTagDelete = gql`
    mutation seoTagDelete($id: Long!) {
      seoTagDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyImageCreate {
  public static journeyImageCreate = gql`
    mutation journeyImageCreate($data: journeyImageCreateInput!) {
      journeyImageCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyImageUpdate {
  public static journeyImageUpdate = gql`
    mutation journeyImageUpdate($data: journeyImageUpdateInput!) {
      journeyImageUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyImageDelete {
  public static journeyImageDelete = gql`
    mutation journeyImageDelete($id: Long!) {
      journeyImageDelete(id: $id) {
        id
        success
      }
    }
  `;
}


export class journeyAdminSelectedAssignedType {
  public static journeyAdminSelectedAssignedTypeCreate = gql`
    mutation journeyAdminSelectedAssignedTypeCreate($data: journeyAdminSelectedAssignedTypeCreateInput!) {
      journeyAdminSelectedAssignedTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;

  public static journeyAdminSelectedAssignedTypeDelete = gql`
    mutation journeyAdminSelectedAssignedTypeDelete($id: Long!) {
      journeyAdminSelectedAssignedTypeDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedFeatureCreate {
  public static journeyAdminSelectedFeatureCreate = gql`
    mutation journeyAdminSelectedFeatureCreate($data: JourneyAdminSelectedFeatureCreateInput!) {
      journeyAdminSelectedFeatureCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedFeatureDelete {
  public static journeyAdminSelectedFeatureDelete = gql`
    mutation journeyAdminSelectedFeatureDelete($id: Long!) {
      journeyAdminSelectedFeatureDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class journeyFeatureCreate {
  public static journeyFeatureCreate = gql`
    mutation journeyFeatureCreate($data: journeyFeatureCreateInput!) {
      journeyFeatureCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyFeatureDelete {
  public static journeyFeatureDelete = gql`
    mutation journeyFeatureDelete($id: Long!) {
      journeyFeatureDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class imageDeleteMany {
  public static imageDeleteMany = gql`
    mutation imageDeleteMany($ids: [Int!]!) {
      imageDeleteMany(ids: $ids) {
        success
      }
    }
  `;
}

export class imageDelete {
  public static imageDelete = gql`
    mutation imageDelete($id: Long!) {
      imageDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class imageUpdate {
  public static imageUpdate = gql`
    mutation imageUpdate($data: imageUpdateInput!) {
      imageUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyMeetingPointCreate {
  public static journeyMeetingPointCreate = gql`
    mutation journeyMeetingPointCreate($data: journeyMeetingPointCreateInput!) {
      journeyMeetingPointCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyMeetingPointCreateMany {
  public static journeyMeetingPointCreateMany = gql`
    mutation journeyMeetingPointCreateMany(
      $data: [journeyMeetingPointCreateInput!]!
    ) {
      journeyMeetingPointCreateMany(createData: $data) {
        id
        success
      }
    }
  `;
}
export class journeyMeetingPointUpdateMany {
  public static journeyMeetingPointUpdateMany = gql`
    mutation journeyMeetingPointUpdateMany(
      $data: [journeyMeetingPointUpdateInput!]!
    ) {
      journeyMeetingPointUpdateMany(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyMeetingPointDelete {
  public static journeyMeetingPointDelete = gql`
    mutation journeyMeetingPointDelete($id: Long!) {
      journeyMeetingPointDelete(id: $id) {
        id
        success
      }
    }
  `;
}
export class journeyMeetingPointUpdate {
  public static journeyMeetingPointUpdate = gql`
    mutation journeyMeetingPointUpdate($data: journeyMeetingPointUpdateInput!) {
      journeyMeetingPointUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiTypeGet {
  public static poiTypeGet = gql`
    query poiTypeGet($id: Long!) {
      poiTypeGet(id: $id) {
        code
        id
        icon
        poiMacroTypeGetSub {
          code
          id
          icon
        }
        poiMacroTypeId
      }
    }
  `;
}

export class poiMacroTypeGet {
  public static poiMacroTypeGet = gql`
    query poiMacroTypeGet($id: Long!) {
      poiMacroTypeGet(id: $id) {
        code
        id
        icon
        poiMacroTypeTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
          description
          id
          languageId
          name
          poiMacroTypeId
        }
      }
    }
  `;
}
export class poiTypeCreate {
  public static poiTypeCreate = gql`
    mutation poiTypeCreate($data: poiTypeCreateInput!) {
      poiTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTypeUpdate {
  public static poiTypeUpdate = gql`
    mutation poiTypeUpdate($data: poiTypeUpdateInput!) {
      poiTypeUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTypeDelete {
  public static poiTypeDelete = gql`
    mutation poiTypeDelete($id: Long!) {
      poiTypeDelete(id: $id) {
        id
        success
      }
    }
  `;
}


export class journeyAdminSelectedTypeTranslation {
  public static journeyAdminSelectedTypeTranslationDelete = gql`
  mutation journeyAdminSelectedTypeTranslationDelete($id: Long!) {
    journeyAdminSelectedTypeTranslationDelete(id: $id) {
      id
      success
    }
  }
`;

public static journeyAdminSelectedTypeTranslationCreate = gql`
mutation journeyAdminSelectedTypeTranslationCreate($data: journeyAdminSelectedTypeTranslationCreateInput!) {
  journeyAdminSelectedTypeTranslationCreate(createData: $data) {
    id
    success
  }
}
`;

public static journeyAdminSelectedTypeTranslationUpdate = gql`
mutation journeyAdminSelectedTypeTranslationUpdate($data: journeyAdminSelectedTypeTranslationUpdateInput!) {
  journeyAdminSelectedTypeTranslationUpdate(updateData: $data) {
    id
    success
  }
}
`;
}


export class journeyAdminSelectedMacroTypeTranslation {
  public static journeyAdminSelectedMacroTypeTranslationDelete = gql`
  mutation journeyAdminSelectedMacroTypeTranslationDelete($id: Long!) {
    journeyAdminSelectedMacroTypeTranslationDelete(id: $id) {
      id
      success
    }
  }
`;

public static journeyAdminSelectedMacroTypeTranslationCreate = gql`
mutation journeyAdminSelectedMacroTypeTranslationCreate($data: journeyAdminSelectedMacroTypeTranslationCreateInput!) {
  journeyAdminSelectedMacroTypeTranslationCreate(createData: $data) {
    id
    success
  }
}
`;

public static journeyAdminSelectedMacroTypeTranslationUpdate = gql`
mutation journeyAdminSelectedMacroTypeTranslationUpdate($data: journeyAdminSelectedMacroTypeTranslationUpdateInput!) {
  journeyAdminSelectedMacroTypeTranslationUpdate(updateData: $data) {
    id
    success
  }
}
`;
}

export class journeyAdminSelectedMacroType {

  public static journeyAdminSelectedMacroTypeGetAll = gql`
    query journeyAdminSelectedMacroTypeGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long){
      journeyAdminSelectedMacroTypeGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor) {
        code
        createdAt
        icon
        id
        updatedAt
        journeyAdminSelectedBaseTypeGetAllSub(genericFilterQuery: {}) {
          id
          code
        }
        journeyAdminSelectedMacroTypeTranslationGetAllSub(genericFilterQuery: {}) {
          id
          description
          languageId
          name
          journeyAdminSelectedMacroTypeId 
        }
    }
  }`
  
  public static journeyAdminSelectedMacroTypeGet = gql`
  query journeyAdminSelectedMacroTypeGet($id: Long!) {
    journeyAdminSelectedMacroTypeGet(id: $id) {
        code
        createdAt
        icon
        id
        updatedAt
        journeyAdminSelectedBaseTypeGetAllSub(genericFilterQuery: {}) {
          id
          code
        }
        journeyAdminSelectedMacroTypeTranslationGetAllSub(genericFilterQuery: {}) {
          id
          description
          languageId
          name
        }
    }
  }`

  public static journeyAdminSelectedMacroTypeDelete = gql`
      mutation journeyAdminSelectedMacroTypeDelete($id: Long!) {
        journeyAdminSelectedMacroTypeDelete(id: $id) {
          id
          success
        }
      }
    `;

  public static journeyAdminSelectedMacroTypeCreate = gql`
    mutation journeyAdminSelectedMacroTypeCreate($data: journeyAdminSelectedMacroTypeCreateInput!) {
      journeyAdminSelectedMacroTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;

  public static journeyAdminSelectedMacroTypeUpdate = gql`
    mutation journeyAdminSelectedMacroTypeUpdate($data: journeyAdminSelectedMacroTypeUpdateInput!) {
      journeyAdminSelectedMacroTypeUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class journeyAdminSelectedBaseType {

  public static journeyAdminSelectedBaseTypeGetAll = gql`
    query journeyAdminSelectedBaseTypeGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long){
        journeyAdminSelectedBaseTypeGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor) {
        code
        createdAt
        icon
        id
        updatedAt
        journeyAdminSelectedMacroTypeGetSub {
          id
          code
        }
        journeyAdminSelectedTypeTranslationGetAllSub(genericFilterQuery: {}) {
          id
          description
          languageId
          name
          journeyAdminSelectedBaseTypeId 
        }
    }
  }`
  
  public static journeyAdminSelectedBaseTypeGet = gql`
  query journeyAdminSelectedBaseTypeGet($id: Long!) {
    journeyAdminSelectedBaseTypeGet(id: $id) {
        code
        createdAt
        icon
        id
        updatedAt
        journeyAdminSelectedMacroTypeGetSub {
          id
          code
        }
        journeyAdminSelectedTypeTranslationGetAllSub(genericFilterQuery: {}) {
          id
          description
          languageId
          name
        }
    }
  }`

  public static journeyAdminSelectedBaseTypeDelete = gql`
      mutation journeyAdminSelectedBaseTypeDelete($id: Long!) {
        journeyAdminSelectedBaseTypeDelete(id: $id) {
          id
          success
        }
      }
    `;

  public static journeyAdminSelectedBaseTypeCreate = gql`
    mutation journeyAdminSelectedBaseTypeCreate($data: journeyAdminSelectedBaseTypeCreateInput!) {
      journeyAdminSelectedBaseTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;

  public static journeyAdminSelectedBaseTypeUpdate = gql`
    mutation journeyAdminSelectedBaseTypeUpdate($data: journeyAdminSelectedBaseTypeUpdateInput!) {
      journeyAdminSelectedBaseTypeUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiMacroTypeCreate {
  public static poiMacroTypeCreate = gql`
    mutation poiMacroTypeCreate($data: poiMacroTypeCreateInput!) {
      poiMacroTypeCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiMacroTypeUpdate {
  public static poiMacroTypeUpdate = gql`
    mutation poiMacroTypeUpdate($data: poiMacroTypeUpdateInput!) {
      poiMacroTypeUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiMacroTypeDelete {
  public static poiMacroTypeDelete = gql`
    mutation poiMacroTypeDelete($id: Long!) {
      poiMacroTypeDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiTypeTranslationCreate {
  public static poiTypeTranslationCreate = gql`
    mutation poiTypeTranslationCreate($data: poiTypeTranslationCreateInput!) {
      poiTypeTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTypeTranslationUpdate {
  public static poiTypeTranslationUpdate = gql`
    mutation poiTypeTranslationUpdate($data: poiTypeTranslationUpdateInput!) {
      poiTypeTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}
export class poiTypeTranslationDelete {
  public static poiTypeTranslationDelete = gql`
    mutation poiTypeTranslationDelete($id: Long!) {
      poiTypeTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiMacroTypeTranslationCreate {
  public static poiMacroTypeTranslationCreate = gql`
    mutation poiMacroTypeTranslationCreate(
      $data: poiMacroTypeTranslationCreateInput!
    ) {
      poiMacroTypeTranslationCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiMacroTypeTranslationUpdate {
  public static poiMacroTypeTranslationUpdate = gql`
    mutation poiMacroTypeTranslationUpdate(
      $data: poiMacroTypeTranslationUpdateInput!
    ) {
      poiMacroTypeTranslationUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class regionGetAll {
  public regionGetAll: any;

  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.regionGetAll = gql`
    query regionGetAll($data:getAllFilterGeographicInputType!) {
      regionGetAll(genericFilterQuery:$data,limit: ${this.limit},idCursor: ${idCursor}) {
        code
        id
      }
    }
  `;
  }

  public getQuery() {
    return this.regionGetAll;
  }
}

export class ListImageGetAllAdminVersion {
  public static ListImageGetAllAdminVersion = gql`
    query imageGetAllAdminVersion(
      $data: imageQueryFiltersAdminInputType!
      $limit: Long
      $idCursor: Long
    ) {
      imageGetAllAdminVersion(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        fileName
        id
        mimeType
        role
        size
        tags
        url
        fileOnwership {
          belongsTo
          id
        }
        imageTranslationGetAllSub(genericFilterQuery: {}) {
          additionalInformation
          createdAt
          description
          id
          imageId
          languageId
          name
          updatedAt
        }
      }
    }
  `;
}

export class poiDayNoteGetAll {
  public static poiDayNoteGetAll = gql`
    query poiDayNoteGetAll(
      $data: getAllFilterTranslationDayNoteInput!
      $limit: Long
      $idCursor: Long
    ) {
      poiDayNoteGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        additionalInformation
        description
        endingDateTime
        id
        languageId
        pointOfInterestId
        name
        startingDateTime
      }
    }
  `;
}

export class imageGetAllAdminVersion {
  public imageGetAllAdminVersion: any;
  public data: object;
  public limit: number;
  public idCursor: number;

  constructor(data: object, limit: number, idCursor: number = null) {
    this.data = data;
    this.limit = limit;
    this.idCursor = idCursor;
    this.imageGetAllAdminVersion = gql`
      query imageGetAllAdminVersion($data:imageQueryFiltersAdminInputType!) {
        imageGetAllAdminVersion(genericFilterQuery:$data, limit: ${this.limit}, idCursor: ${idCursor}) {
          fileName
          id
          mimeType
          role
          size
          tags
          url
          imageTranslationGetAllSub(genericFilterQuery: {}){
            additionalInformation
            createdAt
            description
            id
            imageId
            languageId
            name
            updatedAt
            }
          }
        }
    `;
  }

  // getAllFilterWithPoiTypesAndMunicipalityIdAndOrderByAlphabetAndPriceInputType
  public getQuery() {
    return this.imageGetAllAdminVersion;
  }
}
export class poiMacroTypeTranslationDelete {
  public static poiMacroTypeTranslationDelete = gql`
    mutation poiMacroTypeTranslationDelete($id: Long!) {
      poiMacroTypeTranslationDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiDayNoteCreate {
  public static poiDayNoteCreate = gql`
    mutation poiDayNoteCreate($data: poiDayNoteCreateInput!) {
      poiDayNoteCreate(createData: $data) {
        id
        success
      }
    }
  `;
}
export class poiDayNoteUpdate {
  public static poiDayNoteUpdate = gql`
    mutation poiDayNoteUpdate($data: poiDayNoteUpdateInput!) {
      poiDayNoteUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiDayNoteDelete {
  public static poiDayNoteDelete = gql`
    mutation poiDayNoteDelete($id: Long!) {
      poiDayNoteDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class poiDayNoteGet {
  public static poiDayNoteGet = gql`
    query poiDayNoteGet($id: Long!) {
      poiDayNoteGet(id: $id) {
        additionalInformation
        createdAt
        description
        endingDateTime
        id
        languageId
        name
        startingDateTime
        updatedAt
      }
    }
  `;
}
export class pointOfInterestSetBackOfficeDataDemo {
  public static pointOfInterestSetBackOfficeDataDemo = gql`
    mutation pointOfInterestSetBackOfficeDataDemo(
      $data: setBackOfficeDataInput!
    ) {
      pointOfInterestSetBackOfficeDataDemo(data: $data) {
        id
        success
      }
    }
  `;
}
export class journeySetBackOfficeDataDemo {
  public static journeySetBackOfficeDataDemo = gql`
    mutation journeySetBackOfficeDataDemo($data: setBackOfficeDataInput!) {
      journeySetBackOfficeDataDemo(data: $data) {
        id
        success
      }
    }
  `;
}

export class poiFindHintGetAll {
  public static poiFindHintGetAll = gql`
    query poiFindHintGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long
    ) {
      poiFindHintGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        createdAt
        id
        keyWord
        pointOfInterestId
        updatedAt
      }
    }
  `;
}

export class newsFindHintGetAll {
  public static newsFindHintGetAll = gql`
    query newsFindHintGetAll(
      $data: genericQueryFilterInput!
      $limit: Long
      $idCursor: Long
    ) {
      newsFindHintGetAll(
        genericFilterQuery: $data
        limit: $limit
        idCursor: $idCursor
      ) {
        createdAt
        id
        keyWord
        newsId
        updatedAt
      }
    }
  `;
}

export class poiFindHintCreateMany {
  public static poiFindHintCreateMany = gql`
    mutation poiFindHintCreateMany($data: [poiFindHintCreateInput!]!) {
      poiFindHintCreateMany(createData: $data) {
        id
        success
      }
    }
  `;
}
// superAdmin News Create
export class poiFindHintCreate {
  public static poiFindHintCreate = gql`
    mutation poiFindHintCreate($data: poiFindHintCreateInput!) {
      poiFindHintCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class poiFindHintUpdate {
  public static poiFindHintUpdate = gql`
    mutation poiFindHintUpdate($data: poiFindHintUpdateInput!) {
      poiFindHintUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class poiFindHintDelete {
  public static poiFindHintDelete = gql`
    mutation poiFindHintDelete($id: Long!) {
      poiFindHintDelete(id: $id) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class newsFindHintCreate {
  public static newsFindHintCreate = gql`
    mutation newsFindHintCreate($data: newsesFindHintCreateInput!) {
      newsFindHintCreate(createData: $data) {
        id
        success
      }
    }
  `;
}

// superAdmin News Create
export class newsFindHintUpdate {
  public static newsFindHintUpdate = gql`
    mutation newsFindHintUpdate($data: newsesFindHintUpdateInput!) {
      newsFindHintUpdate(updateData: $data) {
        id
        success
      }
    }
  `;
}

export class newsFindHintDelete {
  public static newsFindHintDelete = gql`
    mutation newsFindHintDelete($id: Long!) {
      newsFindHintDelete(id: $id) {
        id
        success
      }
    }
  `;
}

export class newsFindHintCreateMany {
  public static newsFindHintCreateMany = gql`
    mutation newsFindHintCreateMany($data: [newsesFindHintCreateInput!]!) {
      newsFindHintCreateMany(createData: $data) {
        id
        success
      }
    }
  `;
}
