import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { MiscellaneousRoutingModule, routedComponents } from './miscellaneous-routing.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SmartTableService } from '../../@core/data/smart-table.service';
import { NbDialogModule } from '@nebular/theme';
import { PointOfInterestCreateComponent } from './point-of-interest-create/point-of-interest-create.component';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { PointOfInterestUpdateComponent } from './point-of-interest-update/point-of-interest-update.component';
import { OpenUpdatePointOfInterestComponent } from './open-update-point-of-interest/open-update-point-of-interest.component';
import { ModalPoiOpenTranslationItComponent } from './modal-poi-open-translation-it/modal-poi-open-translation-it.component';
import { ModalPoiOpenTranslationEnComponent } from './modal-poi-open-translation-en/modal-poi-open-translation-en.component';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from 'ng-pick-datetime';
import { FormsModule } from '@angular/forms';
import { OpenModalSeoCodePoiComponent } from './open-modal-seo-code-poi/open-modal-seo-code-poi.component';
import { PoiAdminSelectedListComponent } from './poi-admin-selected-list/poi-admin-selected-list.component';
import { PoiAdminSelectedCreateComponent } from './poi-admin-selected-create/poi-admin-selected-create.component';
import { PoiAdminSelectedEditComponent } from './poi-admin-selected-edit/poi-admin-selected-edit.component';
import { PoiAdminSelectedOpenActionComponent } from './poi-admin-selected-open-action/poi-admin-selected-open-action.component';

import { CKEditorModule } from 'ng2-ckeditor';
import { OpenModalSeoTagPoiComponent } from './open-modal-seo-tag-poi/open-modal-seo-tag-poi.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PoiImageUploadActionComponent } from './poi-image-upload-action/poi-image-upload-action.component';
import { OpenPoiImageUploadActionComponent } from './open-poi-image-upload-action/open-poi-image-upload-action.component';
import { OpenPoiAdminImageUploadActionComponent } from './open-poi-admin-image-upload-action/open-poi-admin-image-upload-action.component';
import { PoiAdminImageUploadActionComponent } from './poi-admin-image-upload-action/poi-admin-image-upload-action.component';
import { OpenPoiCoverImageUploadActionComponent } from './open-poi-cover-image-upload-action/open-poi-cover-image-upload-action.component';
import { PoiCoverImageUploadActionComponent } from './poi-cover-image-upload-action/poi-cover-image-upload-action.component';
import { OpenAdminPoiCoverImageUploadActionComponent } from './open-admin-poi-cover-image-upload-action/open-admin-poi-cover-image-upload-action.component';
import { PoiAdminCoverImageUploadActionComponent } from './poi-admin-cover-image-upload-action/poi-admin-cover-image-upload-action.component';
import { PoiEditCoverImageUploadActionComponent } from './poi-edit-cover-image-upload-action/poi-edit-cover-image-upload-action.component';
import { PoiAdminEditCoverImageUploadActionComponent } from './poi-admin-edit-cover-image-upload-action/poi-admin-edit-cover-image-upload-action.component';
import { PoiEditImageUploadActionComponent } from './poi-edit-image-upload-action/poi-edit-image-upload-action.component';
import { PoiAdminEditImageUploadActionComponent } from './poi-admin-edit-image-upload-action/poi-admin-edit-image-upload-action.component';
import { PoiTypeListComponent } from './poi-type-list/poi-type-list.component';
import { PoiTypeCreateComponent } from './poi-type-create/poi-type-create.component';
import { PoiTypeUpdateComponent } from './poi-type-update/poi-type-update.component';
import { OpenPoiTypeUpdateComponent } from './open-poi-type-update/open-poi-type-update.component';
import { PoiMacroTypeListComponent } from './poi-macro-type-list/poi-macro-type-list.component';
import { PoiMacroTypeCreateComponent } from './poi-macro-type-create/poi-macro-type-create.component';
import { PoiMacroTypeUpdateComponent } from './poi-macro-type-update/poi-macro-type-update.component';
import { OpenPoiMacroTypeUpdateComponent } from './open-poi-macro-type-update/open-poi-macro-type-update.component';
import { OpenPoiTypeTranslationComponent } from './open-poi-type-translation/open-poi-type-translation.component';
import { OpenPoiMacroTypeTranslationComponent } from './open-poi-macro-type-translation/open-poi-macro-type-translation.component';
import { ModalPoiMacroTypeTranslationComponent } from './modal-poi-macro-type-translation/modal-poi-macro-type-translation.component';
import { ModalPoiTypeTranslationComponent } from './modal-poi-type-translation/modal-poi-type-translation.component';
import { OpenPoiDayNoteTranslationComponent } from './open-poi-day-note-translation/open-poi-day-note-translation.component';
import { ModalPoiDayNoteTranslationComponent } from './modal-poi-day-note-translation/modal-poi-day-note-translation.component';
import { PointOfInterestPaypalConfigComponent } from './point-of-interest-paypal-config/point-of-interest-paypal-config.component';
import { SetActiveOrDisativePaypalBtnComponent } from './set-active-or-disative-paypal-btn/set-active-or-disative-paypal-btn.component';
import { PoiOpenCoverEditPositionActionComponent } from './poi-open-cover-edit-position-action/poi-open-cover-edit-position-action.component';
import { ModalPoiCoverEditPositionActionComponent } from './modal-poi-cover-edit-position-action/modal-poi-cover-edit-position-action.component';
import { PoiAdminOpenCoverEditPositionActionComponent } from './poi-admin-open-cover-edit-position-action/poi-admin-open-cover-edit-position-action.component';
import { PoiAdminOpenGalleryEditPositionActionComponent } from './poi-admin-open-gallery-edit-position-action/poi-admin-open-gallery-edit-position-action.component';
import { ModalPoiGalleryEditPositionActionComponent } from './modal-poi-gallery-edit-position-action/modal-poi-gallery-edit-position-action.component';
import { PoiOpenGalleryEditPositionActionComponent } from './poi-open-gallery-edit-position-action/poi-open-gallery-edit-position-action.component';
import { OpenPoiFindHintComponent } from './open-poi-find-hint/open-poi-find-hint.component';
import { OpenModalPoiFindHintComponent } from './open-modal-poi-find-hint/open-modal-poi-find-hint.component';
import { ImageButtonComponent } from '../table/image-button/image-button.component'
import { ImagePositionComponent } from '../images/image-position/image-position.component';
import { PointOfInterestExpiredComponent } from './point-of-interest-expired/point-of-interest-expired.component'

const ENTRY_COMPONENTS = [
  ImagePositionComponent,
  ImageButtonComponent,
  ModalPoiOpenTranslationItComponent,
  ModalPoiOpenTranslationEnComponent,
  OpenModalSeoCodePoiComponent,
  PoiAdminSelectedListComponent,
  OpenModalSeoTagPoiComponent,
  PoiImageUploadActionComponent,
    OpenPoiImageUploadActionComponent,
    OpenPoiAdminImageUploadActionComponent,
    PoiAdminImageUploadActionComponent,
    OpenPoiCoverImageUploadActionComponent,
    PoiCoverImageUploadActionComponent,
    OpenAdminPoiCoverImageUploadActionComponent,
    PoiAdminCoverImageUploadActionComponent,
    PoiEditCoverImageUploadActionComponent,
    PoiAdminEditCoverImageUploadActionComponent,
    PoiEditImageUploadActionComponent,
    PoiAdminEditImageUploadActionComponent,
    OpenPoiTypeUpdateComponent,
    OpenPoiMacroTypeUpdateComponent,
    OpenPoiTypeTranslationComponent,
    OpenPoiMacroTypeTranslationComponent,
    OpenPoiDayNoteTranslationComponent,
    PointOfInterestPaypalConfigComponent,
    SetActiveOrDisativePaypalBtnComponent,
    PoiOpenCoverEditPositionActionComponent,
    PoiAdminOpenCoverEditPositionActionComponent,

    PoiAdminOpenGalleryEditPositionActionComponent,
    ModalPoiGalleryEditPositionActionComponent,
    PoiOpenGalleryEditPositionActionComponent,
    OpenPoiFindHintComponent
];
@NgModule({
  imports: [
    ThemeModule,
    MiscellaneousRoutingModule,
    Ng2SmartTableModule,
    NbDialogModule.forChild(),
    SelectDropDownModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    FormsModule,
    CKEditorModule,
    NgxSpinnerModule
  ],
  declarations: [
    ...routedComponents,
    ImagePositionComponent,
    ImageButtonComponent,
    PointOfInterestCreateComponent,
    PointOfInterestUpdateComponent,
    ModalPoiOpenTranslationItComponent,
    ModalPoiOpenTranslationEnComponent,
    OpenModalSeoCodePoiComponent,
    PoiAdminSelectedListComponent,
    PoiAdminSelectedCreateComponent,
    PoiAdminSelectedEditComponent,
    OpenModalSeoTagPoiComponent,
    PoiImageUploadActionComponent,
    OpenPoiImageUploadActionComponent,
    OpenPoiAdminImageUploadActionComponent,
    PoiAdminImageUploadActionComponent,
    OpenPoiCoverImageUploadActionComponent,
    PoiCoverImageUploadActionComponent,
    OpenAdminPoiCoverImageUploadActionComponent,
    PoiAdminCoverImageUploadActionComponent,
    PoiEditCoverImageUploadActionComponent,
    PoiAdminEditCoverImageUploadActionComponent,
    PoiEditImageUploadActionComponent,
    PoiAdminEditImageUploadActionComponent,
    PoiTypeListComponent,
    PoiTypeCreateComponent,
    PoiTypeUpdateComponent,
    OpenPoiTypeUpdateComponent,
    PoiMacroTypeListComponent,
    PoiMacroTypeCreateComponent,
    PoiMacroTypeUpdateComponent,
    OpenPoiMacroTypeUpdateComponent,
    OpenPoiTypeTranslationComponent,
    OpenPoiMacroTypeTranslationComponent,
    OpenPoiDayNoteTranslationComponent,
    PointOfInterestPaypalConfigComponent,
    SetActiveOrDisativePaypalBtnComponent,
    PoiOpenCoverEditPositionActionComponent,
    PoiAdminOpenCoverEditPositionActionComponent,
    PoiAdminOpenGalleryEditPositionActionComponent,
    ModalPoiGalleryEditPositionActionComponent,
    PoiOpenGalleryEditPositionActionComponent,
    OpenPoiFindHintComponent,
    PointOfInterestExpiredComponent
 
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS,
  ],
  providers: [
    SmartTableService,
  ],
})
export class MiscellaneousModule { }
