import { Component, OnInit, Compiler, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { QueryRef, Apollo } from 'apollo-angular';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { SmartTableService } from '../../../../@core/data/smart-table.service';

import { newsCategoryGetAll, newsCategoryDelete, newsCategoryCreate, newsCategoryUpdate, newsGetAll, newsDelete, GetMunicipality, municipalityGet, superAdminLoggedInData, municipalityOperatorLoggedInData, newsUnitDelete, newsFindHintGetAll, baseUserLoggedInData, editorLoggedInData, newsCategoryAssociationDelete, newsImageAssociationDelete, newsPointAssociationDelete, seoCodeDelete, seoTagDelete, newsJourneyAdminSelectedAssociationDelete, newsJourneyAssociationDelete, newsWarningDelete } from '../../../../queryMutation';
import { OpenDialogComponent } from '../../open-dialog/open-dialog.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NewsTranslationComponent } from '../../news-translation/news-translation.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenSeoCodeComponent } from '../../open-seo-code/open-seo-code.component';
import { OpenSeoTagComponent } from '../../open-seo-tag/open-seo-tag.component';
import { ZipService } from '../../../../service/zipFile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CloneNewsComponent } from '../../clone-news/clone-news.component';
import { NewsImaginiOpenActionComponent } from '../../news-imagini-open-action/news-imagini-open-action.component';
import { OpenNewsCoverImageUploadComponent } from '../../open-news-cover-image-upload/open-news-cover-image-upload.component';
import { forkJoin } from 'rxjs';
import { OpenNewsFindHintComponent } from '../../open-news-find-hint/open-news-find-hint.component';
import { last } from '@angular/router/src/utils/collection';

@Component({
  selector: 'button-view',
  template: '<button nbButton status="success" (click)="onClick()">{{ renderValue }}</button>'
  })
  export class NewsUpdateComponent implements ViewCell, OnInit {
    renderValue: string;
    
    @Input() value: string | number;
    @Input() rowData: any;

    @Input() id: string | number;
    
    @Output() click: EventEmitter<any> = new EventEmitter<any>();

    constructor(public router: Router) {}

    ngOnInit() {
      console.log("value", this.value, "O")
      this.renderValue = "Update"//this.value.toString().toUpperCase();
    }
    
    onClick() {
      localStorage.setItem("newsId", this.rowData.id)
      this.router.navigateByUrl("/pages/articoli/update")
      this.click.emit(this.rowData);
    }
  }

@Component({
  selector: 'ngx-list-articoli',
  templateUrl: './list-articoli.component.html',
  styles: [`
  nb-card {
    transform: translate3d(0, 0, 0);
  }
  ng2-smart-table  {
    min-width: 1500px;
  }
  smart-table-container{
    overflow-x: scroll;
}

nb-card-body ::-webkit-scrollbar {
  width: 5px;
    height: 14px;
}
 nb-card ::-webkit-scrollbar {
  width: 5px;
  height: 14px;
}
  
.flex-container {
  padding: 5px;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start { 
  justify-content: flex-start; 
}
.flex-around { 
  justify-content: space-between;
  padding: 0 30px;
  
}
.imgBack{
  width: 30px;
}
.margin-left10{
  margin-left: 10px;
}
.marginTop20{
  margin-top: 20px;
}
.marginTop10{
  margin-top: 10px;
}
.notShowOpacity{
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.showOpacity{
  opacity: 1;
  cursor: default;
  pointer-events:auto;
}
`],
})
export class ListArticoliComponent implements OnInit {

  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsAll: any;
  public queryGetAllNewsByAlert: any;

  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsGetAll: any[];
  public newsCategoryTranslationGetAll: any[];
  isMunicipalityOperatorLoggedIn: boolean = false;
  isMunicipalityFilter: boolean = false;
  roleScopes: any;
  newsData: any;
  dataToFilter: any;
  limit: number = 25;
  datauri: any;
  myFile: Blob;
  profile: any;
  fileToUpload: File;
  response: any;
  selectedProduct: any;
  lastId: any;
  isSearch : boolean = false
  keyword: any;

  public only_alert: Boolean;

  private userTypeEnum = Object.freeze({"SUPER_ADMIN":"super-admin", "EDITOR":"editor", "MUNICIPALITY":"municipality"})
  private userType: string


  source: LocalDataSource = new LocalDataSource();
  constructor(public apollo: Apollo, public service: SmartTableService, public zipS: ZipService, public router: Router, public datePipe: DatePipe, public _sanitizer: DomSanitizer, public _compiler: Compiler, public spinner: NgxSpinnerService) {

    this.queryGetNewsAll = new newsGetAll({}, this.limit).getQuery();


  }

  async ngOnInit() {
    this.only_alert = false;
    this._compiler.clearCache();
    this.dataToFilter = {};

    var municipality_id = parseInt(localStorage.getItem('municipality.id'))
    if(!isNaN(municipality_id) && municipality_id > 0) {
      localStorage.removeItem('municipality.id')
      this.dataToFilter['municipalityId'] = municipality_id
      this.isMunicipalityFilter = true;
    } else {
      this.isMunicipalityFilter = false;
    }

    this.source.reset();
    this.newsGetAll = [];
    this.roleScopes = localStorage.getItem('roleScopes');
    this.isMunicipalityOperatorLoggedIn = false;

    if (this.roleScopes.indexOf("SUPER_ADMIN") !== -1) {
      this.userType = this.userTypeEnum.SUPER_ADMIN
      await this.getIsAdminLoggedIn();
    } else if (this.roleScopes.indexOf("EDITOR") !== -1) {
      this.userType = this.userTypeEnum.EDITOR
      await this.getIsEditorLoggedIn();
    } else {
      this.userType = this.userTypeEnum.MUNICIPALITY
      this.dataToFilter['newsBelongsToFilter'] = "MUNICIPALITY_OPERATOR"
      this.dataToFilter['newsBelongsToFilterId'] = localStorage.getItem("userLoggedId")
      await this.getIsMunicipalityOperatorLoggedIn();
    }


  }
  onRowSelect(event) {
    for (let index = 0; index < event.selected.length; index++) {
      event.selected[index].isSelected = true;
      event.data.isSelected = true;
    }

  }

  deleteMunicipalityFilter() {
    this.ngOnInit()
  }

  async getIsAdminLoggedIn() {
    this.apollo.use(this.userType).query<Response>({
        query: superAdminLoggedInData.superAdminLoggedInData,
        fetchPolicy: 'network-only',
        variables: {
        },
      }).subscribe((result: any) => {
        this.getAllArticoli();
      });

  }

  async getIsEditorLoggedIn() {
    this.apollo.use(this.userType).query<Response>({
        query: editorLoggedInData.editorLoggedInData,
        fetchPolicy: 'network-only',
        variables: {
        },
      }).subscribe((result: any) => {
        console.log("getIsEditorLoggedIn.result", result)
        this.getAllArticoli();
      });

  }
  getIsMunicipalityOperatorLoggedIn() {
    this.isMunicipalityOperatorLoggedIn = true;
    this.apollo.use("municipality").query<Response>({
        query: municipalityOperatorLoggedInData.municipalityOperatorLoggedInData,
        fetchPolicy: 'network-only',
        variables: {
        },
      }).subscribe((result: any) => {
        this.only_alert = true
        this.getAllArticoli();
      });

  }
  settings = {
    selectMode: 'multi',
    actions: {
      add: false,
      edit: false
    },
    delete: {
      deleteButtonContent: "<img src='assets/images/deleteTable.png'>",
      confirmDelete: true,
    },
    columns: {
      Clone: {
        title: 'Clone Action',
        type: 'custom',
        renderComponent: CloneNewsComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      id: {
        title: 'ID',
        type: 'number',
        filter: false,
        editable: false,
        addable: false
      },
      title: {
        title: 'Title',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.newsUnitGetAllSub.length > 0) {

            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.title = data[0].title;

              return this._sanitizer.bypassSecurityTrustHtml(row.title);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      },
      newsUnitId: {
        title: 'newsUnitId',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {


          if (row.newsUnitGetAllSub.length > 0) {

            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.newsUnitId = data[0].id;

              return this._sanitizer.bypassSecurityTrustHtml(row.newsUnitId);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }
          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
      },
      pinned: {
        title: 'In evidence',
        type: 'html',
        filter: false,
        valuePrepareFunction: (cell, row) => {
          if (row.newsUnitGetAllSub.length > 0) {

            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.pinned = data[0].pinned == true ? "SI" : "NO";

              return this._sanitizer.bypassSecurityTrustHtml(row.pinned);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },

      },
      workInProgress: {
        title: 'Draft ',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.newsUnitGetAllSub.length > 0) {

            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.workInProgress = data[0].workInProgress == true ? "SI" : "NO";

              return this._sanitizer.bypassSecurityTrustHtml(row.workInProgress);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
      preview: {
        title: 'Preview',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          if (row.newsUnitGetAllSub.length > 0) {
            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {
              row.preview = data[0].preview == true ? "SI" : "NO";

              return this._sanitizer.bypassSecurityTrustHtml(row.preview);
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }
        },
      },
      contentCreatedAt: {
        title: 'News Created Date',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          var raw = new Date(row.contentCreatedAt);

          var formatted = this.datePipe.transform(raw, 'dd MMM yyyy HH:mm');
          return formatted;
        },
      },
      contentUpdatedAt: {
        title: 'News Updated Date',
        type: 'html',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {

          var raw = new Date(row.contentUpdatedAt);

          var formatted = this.datePipe.transform(raw, 'dd MMM yyyy HH:mm');
          return formatted;
        },
      },
      createdAt: {
        title: 'Created Date',
        type: 'date',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          var raw = new Date(row.createdAt);

          var formatted = this.datePipe.transform(raw, 'dd MMM yyyy HH:mm');
          return formatted;
        },
      },
      publishedAt: {
        title: 'Published At',
        type: 'date',
        filter: false,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          var raw = new Date(row.publishedAt);
          var formatted = this.datePipe.transform(raw, 'dd MMM yyyy HH:mm');
          return formatted;
        },
      },

      municipalityCode: {
        title: 'Municipality',
        type: 'string',
        filter: true,
        editable: false,
        addable: false,
        valuePrepareFunction: (cell, row) => {
          if (row.municipalityGetSub == undefined)
            return ""
          row.municipalityCode = row.municipalityGetSub.code;
          return row.municipalityCode;
        },
      },
      update: {
        title: 'Update',
        type: 'custom',
        filter: false,
        editable: false,
        addable: false,
        renderComponent: NewsUpdateComponent,
        onComponentInitFunction(instance) {
          instance.value = "Update"
          instance.click.subscribe(row => {
            console.log("action", "subscribe")
          });
        }
      },
      /*
      languageId: {

        title: 'Language',
        type: 'html',
        valuePrepareFunction: (cell, row) => {

          if (row.newsUnitGetAllSub.length > 0) {

            var data = row.newsUnitGetAllSub.filter(item => item.languageId == 1);
            if (data.length > 0) {

              return this._sanitizer.bypassSecurityTrustHtml(data[0].languageId == 1 ? "IT" : "EN");
            }
            else {
              return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
            }

          }
          else {
            return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
          }

        },
        editor: {
          type: 'list',
          config: {
            list: [{ value: 1, title: 'IT' }, { value: 2, title: 'EN' }]
          }
        }
      },*/
      traduzzione: {
        title: 'Text Editor',
        type: 'custom',
        renderComponent: NewsTranslationComponent,
        filter: false,
        editable: false,
        addable: false
      },
      seoCode: {
        title: 'Canonical',
        type: 'custom',
        renderComponent: OpenSeoCodeComponent,
        onComponentInitFunction: (instance: any) => {
          instance.saveSeoCod.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      seoTag: {
        title: 'Meta tag',
        type: 'custom',
        renderComponent: OpenSeoTagComponent,
        onComponentInitFunction: (instance: any) => {
          instance.saveNewsTag.subscribe(row => {
            this.source.update(row, row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      // keyWord: {
      //   title: 'keyWord ',
      //   type: 'html',
      //   filter: true,
      //   editable: false,
      //   addable: false,
      //   valuePrepareFunction: (cell, row) => {
      //     if (row.newsFindHintGetAllSub.length > 0) {
      //       var data = row.newsFindHintGetAllSub.filter(item => item.id !== null);
      //       if (data.length > 0) {
      //         row.keyWord = "<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/success.png' style='width: 30px'></div>";

      //         return this._sanitizer.bypassSecurityTrustHtml(row.keyWord);
      //       }
      //       else {
      //         return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
      //       }

      //     } else {
      //       return this._sanitizer.bypassSecurityTrustHtml("<div class='flex-center' style='display:flex; align-items: center;justify-content: center'><img class='imgBack' src='assets/images/error.png' style='width: 30px'></div>");
      //     }
      //   }
      // },
      // findHint: {
      //   title: 'Trova Ricerca per parola ',
      //   type: 'custom',
      //   renderComponent: OpenNewsFindHintComponent,
      //   onComponentInitFunction: (instance: any) => {
      //     instance.saveNewsFindHint.subscribe(row => {
      //       this.source.update(row, row);
      //     });
      //   },
      //   filter: false,
      //   editable: false,
      //   addable: false
      // },
      Actions: {
        title: 'Update Action',
        type: 'custom',
        renderComponent: OpenDialogComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(row => {
            console.log(row);
          });
        },
        filter: false,
        editable: false,
        addable: false
      },
      /*
      UploadImage: {
        title: 'Upload Image Action',
        type: 'custom',
        renderComponent: NewsImaginiOpenActionComponent,
        filter: false,
        editable: false,
        addable: false
      },
      */
      UploadImageCover: {
        title: 'Upload Image Cover',
        type: 'custom',
        renderComponent: OpenNewsCoverImageUploadComponent,
        filter: false,
        editable: false,
        addable: false
      },
    },
  };

  async deleteManyNewsUnitbyId(data) {
    const newsUnitId: any[] = data.newsUnitGetAllSub.map((item) => {
      return item['id'];
    })
    var tasks$2 = [];
    this.spinner.show(undefined,
      {

        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    for (let i = 0; i < newsUnitId.length; i++) {

      tasks$2.push(this.apollo.use(this.userType).mutate({
        mutation: newsUnitDelete.newsUnitDelete,
        fetchPolicy: 'no-cache',
        variables: {
          'id': newsUnitId[i]
        },
      }));
    }
    return forkJoin(...tasks$2).subscribe((result: any) => {
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
        this.newsGetDelete(data);
      }, 2000);

    }, (err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();

      }, 2000);
    })
  }


  edit(id) {
    console.log("GOTO", id)
    localStorage.setItem("newsId", id)
    this.router.navigateByUrl("/pages/articoli/update");
  }

  newsGetDelete(id) {
    console.log(this.userTypeEnum)
    console.log(this.userType)
    console.log(this.userType)
    console.log(`newsGetDelete(${id})`, this.apollo.use(this.userType))
    this.spinner.show(undefined,
      {

        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );

    var tasks$2 = [];

    this.newsGetAll.filter(item => item.id == id).forEach(element => {
      console.log("filter->", element)
      element.newsImageAssociationGetAllSub.forEach(element => {
        console.log("newsImageAssociationGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsImageAssociationDelete.newsImageAssociationDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsPointAssociationGetAllSub.forEach(element => {
        console.log("newsPointAssociationGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsPointAssociationDelete.newsPointAssociationDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsUnitGetAllSub.forEach(element => {
        console.log("newsUnitGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsUnitDelete.newsUnitDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.seoCodeGetAllSub.forEach(element => {
        console.log("seoCodeGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: seoCodeDelete.seoCodeDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.seoTagGetAllSub.forEach(element => {
        console.log("seoTagGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: seoTagDelete.seoTagDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsCategoryAssociationGetAllSub.forEach(element => {
        console.log("newsCategoryAssociationGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsCategoryAssociationDelete.newsCategoryAssociationDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsJourneyAdminSelectedAssociationGetAllSub.forEach(element => {
        console.log("newsJourneyAdminSelectedAssociationGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsJourneyAdminSelectedAssociationDelete.newsJourneyAdminSelectedAssociationDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsJourneyAssociationGetAllSub.forEach(element => {
        console.log("newsJourneyAssociationGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsJourneyAssociationDelete.newsJourneyAssociationDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
      element.newsWarningGetAllSub.forEach(element => {
        console.log("newsWarningGetAllSub", element)
        tasks$2.push(this.apollo.use(this.userType).mutate({
          mutation: newsWarningDelete.newsWarningDelete,
          fetchPolicy: 'no-cache',
          variables: {
            'id': element.id
          },
        }));
      });
    });


    tasks$2.push(this.apollo.use(this.userType).mutate({
      mutation: newsDelete.GetNewsDelete,
      fetchPolicy: 'no-cache',
      variables: {
        'id': id
      },
    }))
    
    forkJoin(tasks$2).subscribe((result: any) => {
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        console.log("newsGetDelete", result, this.newsGetAll)
        //var id = result.data.newsDelete['id'];
        this.spinner.hide();

        var obj = null
        this.newsGetAll = this.newsGetAll.filter(data => {
          if (data.id == id)
            obj = data
          return data.id != id
        })
        this.source.remove(obj);

        /*
        this.source.reset();
        this.newsGetAll = [];
        this.getAllArticoli();*/
      }, 2000);

    }, (err) => {
      alert("An error occurred.Try again later.");
      setTimeout(() => {

        this.spinner.hide();

      }, 2000);
    })

  }

  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Articolo?')) {
      //event.confirm.resolve(event.data.id);
      //this.deleteManyNewsUnitbyId(event.data);
      this.newsGetDelete(event.data.id)
    } else {
      event.confirm.reject();
    }
  }

  async getNewsArticoli() {
    this.only_alert = false;
    this.newsGetAll = [];
    this.source.load([]);
    this.getAllArticoli()
  }
  async getAlertArticoli() {
    this.only_alert = true;
    this.newsGetAll = [];
    this.source.load([]);
    this.getAllArticoli()
  }

  closeSearch() {
    this.isSearch = false
    this.keyword = ""
    this.doSearch()
    console.log("dataToFilter", this.dataToFilter)
  }

  doSearch() {
    this.newsGetAll = []
    this.source.load(this.newsGetAll)
    console.log("dataToFilter", this.dataToFilter)
    this.dataToFilter['textFilter'] = {
      languageId: 1, title: this.keyword
    } 
    console.log("dataToFilter", this.dataToFilter)
    this.pageChange()
  }

  pageChange() {
    this._getAllArticoli()
  }

  //_getAllArticoli(lastId = null) {
  _getAllArticoli() {
    var lastId = null;
    if (this.newsGetAll.length > 0) 
      lastId = this.newsGetAll[this.newsGetAll.length-1].id;
    this.spinner.show(undefined,
      {
        type: 'square-spin',
        size: 'medium',
        bdColor: 'rgba(100,149,237, .8)',
        color: 'white',
        fullScreen: true
      }
    );
    console.log("_getAllArticoli", lastId)
    this.apollo.query<Response>({
      query: new newsGetAll({}, this.limit, lastId).getQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: this.dataToFilter
      },
    }).subscribe((data: any) => {
      if (data.data['newsGetAll'].length > 0) {
        var newsGetAll = data.data['newsGetAll']
        this.newsGetAll.push(...newsGetAll)
        //this.getNewsFindHintGetAll(data)
        //const lastId = newsGetAll[newsGetAll.length-1].id;
        //this._getAllArticoli(lastId)

        console.log("newsGetAll", newsGetAll)

        this.source.load(this.newsGetAll);
        console.log("ESCO")
        this.spinner.hide();

      } else {
        this.source.load(this.newsGetAll);
        console.log("ESCO")
        this.spinner.hide();
      }
    });
  }

  getAllArticoli() {
    var newsTypeId = this.only_alert === true ? 2 : 1;
    this.dataToFilter['hasCategories'] = []
    this.dataToFilter['newsTypeId'] = newsTypeId
    this._getAllArticoli();
  }
  getNewsFindHintGetAll(data: any[]) {
    console.log("getNewsFindHintGetAll", data)
    var tasks$ = [];
    for (let index = 0; index < data.length; index++) {

      tasks$.push(this.apollo.use(this.userType).query({
        query: newsFindHintGetAll.newsFindHintGetAll,
        fetchPolicy: 'network-only',
        variables: {
          'data': {
            'parentId': parseInt(data[index].id),
          },
          'limit': 25,
          'idCursor': this.lastId
        },
      }));
    }

    forkJoin(tasks$).subscribe((result: any) => {


      for (let index = 0; index < result.length; index++) {
        console.log("newsFindHintGetAll", result[index].data.newsFindHintGetAll);
        data[index].newsFindHintGetAllSub = result[index].data.newsFindHintGetAll;
      }
      this.source.load(data);
    });
  }
  createArticoli() {
    this.router.navigateByUrl("/pages/articoli/create");
  }
  update () {
    localStorage.removeItem("newsId")
    this.router.navigateByUrl("/pages/articoli/update");
  }
}
