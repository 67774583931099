import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogRef } from '@nebular/theme';
import { seoCodeDelete, seoCodeCreate, seoCodeUpdate, seoTagCreate, seoTagDelete, seoTagUpdate } from '../../../queryMutation';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';


@Component({
  selector: 'ngx-journey-admin-selected-open-modal-seo-tag',
  templateUrl: './journey-admin-selected-open-modal-seo-tag.component.html',
  styleUrls: ['./journey-admin-selected-open-modal-seo-tag.component.scss']
})
export class JourneyAdminSelectedOpenModalSeoTagComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
   dataProvince: any[];
   newsAllCategories:any[];
   newsCategoryTranslationGetAll:any[];

  @Input() title: string;
  @Input() seoCodeId:any;
  @Input() seoTagName:any[];
  @Input() languageId:any;
  @Input() journeyId:any;
  @Input() description: string;
  @Input() seoTagTitle: string;
  @Input() noFollow: boolean;
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }
  
  @Output("addJourneyAdminTag") addJourneyAdminTag: EventEmitter<any> = new EventEmitter();
    
  @Output("editJourneyAdminTag") editJourneyAdminTag: EventEmitter<any> = new EventEmitter();
  @Output("deleteJourneyAdminTag") deleteJourneyAdminTag: EventEmitter<any> = new EventEmitter();
  constructor(protected ref: NbDialogRef<JourneyAdminSelectedOpenModalSeoTagComponent>,public apollo:Apollo) { 
this.seoTagName = [];
  }

  ngOnInit() {
    this.seoTagName.push("");
  }
  
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent(){
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: seoTagDelete.seoTagDelete,
      variables: {
        'id':parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if(result.data['seoTagDelete']['success']){
        this.deleteJourneyAdminTag.emit({ 
         
          'id':parseInt(this.seoCodeId)
        });
      }
     });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
     event.confirm.reject();
    }
  }
  addInput(){


    this.seoTagName.push("");

    console.log(this.seoTagName);
  }
  Salva() {
 
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagCreate.seoTagCreate,
        variables: {
          data:{
            'journeyId':parseInt(this.journeyId),
            'languageId':this.languageId,
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'noFollow':this.noFollow
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoTagCreate']['success']){
          this.ref.close();
          this.addJourneyAdminTag.emit({ 
            'journeyId':parseInt(this.journeyId),
            'languageId':parseInt(this.languageId),
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'id':result.data['seoTagCreate']['id'],
            'noFollow':this.noFollow
          });
        }
       });
    } 
    trackByFn(index: any, item: any) {
      return index;
   }
    Edit(item) {
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: seoTagUpdate.seoTagUpdate,
        variables: {
          data:{
            'journeyId':parseInt(this.journeyId),
            'languageId':this.languageId,
            'openGraphTags':[""],
            'title':this.seoTagTitle,
            'description':this.description,
            'id':parseInt(this.seoCodeId),
            'noFollow':this.noFollow
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['seoCodeUpdate']['success']){
          this.ref.close();
          this.editJourneyAdminTag.emit({ 
            'journeyId':parseInt(this.journeyId),
            'languageId':parseInt(this.languageId),
            'title':this.seoTagTitle,
            'description':this.description,
            'openGraphTags':[""],
            'id':parseInt(this.seoCodeId),
            'noFollow':this.noFollow
          });
        }
       });
    } 
   }


