import { Component, OnInit } from '@angular/core';
import { Subscription } from 'apollo-client/util/Observable';
import { Apollo } from 'apollo-angular';
import { passwordResetConfirmation } from '../queryMutation';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-request-pass-confirmation',
  templateUrl: './request-pass-confirmation.component.html',
  styleUrls: ['./request-pass-confirmation.component.scss']
})
export class RequestPassConfirmationComponent implements OnInit {
  public querySubscriptionProfileUser : Subscription
  requestPassConfirmation:any={newPassword:'',token:'',email:''};
  constructor(public apollo:Apollo,public _location:Location, public route:ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      console.log(JSON.parse(params.get('data')));
      this.requestPassConfirmation.token = JSON.parse(params.get('data'));
       })
  }

  goBack(){
    this._location.back();
  }
  sendrequestPassConfirmation(){
    this.querySubscriptionProfileUser =  this.apollo.mutate({
      mutation: passwordResetConfirmation.passwordResetConfirmation,
      variables: {
          'data': {
            'email':this.requestPassConfirmation.email,
            'newPassword':this.requestPassConfirmation.newPassword,
            'token':this.requestPassConfirmation.token
          }
      },
    }).subscribe((result: any) => {
      if (result.data['passwordResetConfirmation']['success']) {
        console.log(result.data.passwordResetConfirmation);
        // this.openWithoutBackdropClick(dialog);
      } 
     });
  }
}
