import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
@Component({
  selector: 'ngx-travel-tag-open-component',
  templateUrl: './travel-tag-open-component.component.html',
  styleUrls: ['./travel-tag-open-component.component.scss']
})

export class TravelTagOpenComponentComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveTravelTag: EventEmitter<any> = new EventEmitter();
  translationIt="";
  translations: any;
  constructor(public router:Router) {

  
  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
    
    this.translations = []
    this.translations['it'] = this.rowData.travelTagTranslationGetAllSub.filter(item => item.languageId == 1).length > 0;
    this.translations['eng'] = this.rowData.travelTagTranslationGetAllSub.filter(item => item.languageId == 2).length > 0;
    this.translations['fr'] = this.rowData.travelTagTranslationGetAllSub.filter(item => item.languageId == 4).length > 0;
  }

  onClick() {
      this.saveTravelTag.emit(this.rowData);
  }

  
  openEditModal(languageId) {
    console.log("openEditModal");
    localStorage.removeItem("travelId");
    localStorage.removeItem("languageId");
    localStorage.removeItem("travelTranslationId");
    
    console.log(this.rowData.travelTagTranslationGetAllSub);
    if(this.rowData.travelTagTranslationGetAllSub.length > 0){

      var filter = this.rowData.travelTagTranslationGetAllSub.filter(data => data.languageId == languageId)

      localStorage.setItem("travelId",this.rowData.id);
      localStorage.setItem("languageId", languageId);

      if (filter.length > 0) {
        localStorage.setItem("travelTranslationId", filter[0].id);
        console.log("openEditModal.if");
        console.log("travelTranslationId", filter[0].id);
      } else {
        console.log("openEditModal.else");
        localStorage.setItem("travelTranslationId", null);
      }

      this.router.navigateByUrl("/pages/travel-tag/update");
/*
      for (let index = 0; index < this.rowData.travelTagTranslationGetAllSub.length; index++) {
        if(this.rowData.travelTagTranslationGetAllSub[index].languageId == languageId){
          localStorage.setItem("travelId",this.rowData.id);
          localStorage.setItem("languageId",languageId);
          localStorage.setItem("travelTranslationId",this.rowData.travelTagTranslationGetAllSub[index].id);

          console.log("openEditModal.if");
          console.log("travelId",this.rowData.id);
          console.log("languageId",languageId);
          console.log("travelTranslationId",this.rowData.travelTagTranslationGetAllSub[index].id);
         this.router.navigateByUrl("/pages/travel-tag/update");
        }
        else{
          console.log("openEditModal.else");
          localStorage.setItem("travelTranslationId",null);
          localStorage.setItem("travelId",this.rowData.id);
          localStorage.setItem("languageId",languageId);
          this.router.navigateByUrl("/pages/travel-tag/update");
        }
      }
      */
    }else{
      localStorage.setItem("languageId",languageId);
      this.router.navigateByUrl("/pages/travel-tag/create");
    }
  }
}

