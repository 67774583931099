import { Component, OnInit, Input, Output, EventEmitter,  } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OpenModalSeoCodeComponent } from '../open-modal-seo-code/open-modal-seo-code.component';
import { OpenModalSeoTagComponent } from '../open-modal-seo-tag/open-modal-seo-tag.component';
@Component({
  selector: 'ngx-open-seo-tag',
  templateUrl: './open-seo-tag.component.html',
  styleUrls: ['./open-seo-tag.component.scss']
})

export class OpenSeoTagComponent implements OnInit {
  public isPlaying: boolean;
  public renderValue: string;
  data:any[];
  @Input() value: string | number;
  @Input() rowData: any;
  ddlLanguage={}
  @Output() saveNewsTag: EventEmitter<any> = new EventEmitter();
  public indexSelected:any;
  translations: any;

  constructor(public dialogService:NbDialogService ) {
    this.data = [];
    this.ddlLanguage = {
      availableOptions: [
        {id: "1", name: 'IT'},
        {id: '2', name: 'EN'},
      ]
    }
  }

  ngOnInit() {
      this.renderValue = this.value.toString().toUpperCase();

      this.translations = []
      this.translations['it'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 1).length > 0;
      this.translations['eng'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 2).length > 0;
      this.translations['fr'] = this.rowData.seoTagGetAllSub.filter(item => item.languageId == 4).length > 0;
  
  }

  onClick() {
      this.saveNewsTag.emit(this.rowData);
  }

  openModal(languageId) {
    console.log(this.rowData);

    this.indexSelected  = this.rowData.seoTagGetAllSub.findIndex(item => item.languageId == languageId);
    this.data = this.rowData.seoTagGetAllSub.filter(item => item.languageId == languageId);
   
    if(this.data.length > 0){
      console.log(this.data[0].id);
      const ref = this.dialogService.open(OpenModalSeoTagComponent, {
        context: {
          title: 'Edit SeoTag',
          seoCodeId:this.data[0].id,
          newsId:this.rowData.id,
          languageId: languageId,
          seoTagName:this.data[0].openGraphTags,
          description:this.data[0].description,
          seoTagTitle:this.data[0].title,
          noFollow:this.data[0].noFollow,
        },
      });

      ref.componentRef.instance["editNewsTag"].subscribe((data) => {
        ref.close();

        this.rowData.seoTagGetAllSub[this.indexSelected].newsId = data.newsId;
        this.rowData.seoTagGetAllSub[this.indexSelected].id = data.id;
        this.rowData.seoTagGetAllSub[this.indexSelected].languageId = data.languageId;
        this.rowData.seoTagGetAllSub[this.indexSelected].openGraphTags = data.openGraphTags;
        this.rowData.seoTagGetAllSub[this.indexSelected].description = data.description;
        this.rowData.seoTagGetAllSub[this.indexSelected].title = data.title;
        this.rowData.seoTagGetAllSub[this.indexSelected].noFollow = data.noFollow;

        this.saveNewsTag.emit(this.rowData);
      });
      ref.componentRef.instance["deleteNewsTag"].subscribe((data) => {
      ref.close();
      const indexToRemove =  this.rowData.seoTagGetAllSub.findIndex(res => res.id == data.id);
      this.rowData.seoTagGetAllSub.splice(indexToRemove,1);
      
      this.saveNewsTag.emit(this.rowData);

      });
     return;
    }
    else{
      const ref = this.dialogService.open(OpenModalSeoTagComponent, {
        context: {
          title: 'Create SeoTag',
          seoCodeId:null,
          newsId:this.rowData.id,
          languageId: languageId,
          seoTagName:[""],
          description:"",
          seoTagTitle:"",
          noFollow:false
        },
      });

      ref.componentRef.instance["addNewsTag"].subscribe((data) => {
        ref.close();
        this.rowData.seoTagGetAllSub.push(data);
        this.saveNewsTag.emit(this.rowData);
      });

      return;
    }

          
        
  }

}

