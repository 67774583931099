import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbDialogRef } from '@nebular/theme';
import { seoCodeDelete, seoCodeCreate, seoCodeUpdate, poiBillingTranslationCreate, poiBillingTranslationUpdate, poiBillingTranslationDelete } from '../../../queryMutation';
import '../../../pages/news/list/ckeditor.loader';
import 'ckeditor';


@Component({
  selector: 'ngx-modal-translation-billing',
  templateUrl: './modal-translation-billing.component.html',
  styleUrls: ['./modal-translation-billing.component.scss']
})
export class ModalTranslationBillingComponent  {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories : any;
  public queryGetNewsTranslationCategories : any;
  public businessPagination:QueryRef<any>;
  public newsCategoryPagination:QueryRef<any>;
  public newsCategoryTranslationPagination:QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories:any[];
  public newsCategoryTranslationGetAll:any[];
  
  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }
  
  @Input() title: string;
  @Input() id:any;
  @Input() name:any[];
  @Input() description:any[];
  @Input() languageId:any;
  @Input() poiBillingId:any;
  

  @Output("addPoiBillingTrans") addPoiBillingTrans: EventEmitter<any> = new EventEmitter();
    
  @Output("editPoiBillingTrans") editPoiBillingTrans: EventEmitter<any> = new EventEmitter();
  @Output("deletePoiBillingTrans") deletePoiBillingTrans: EventEmitter<any> = new EventEmitter();

  constructor(protected ref: NbDialogRef<ModalTranslationBillingComponent>,public apollo:Apollo) { 

  }

  ngOnInit() {
  }
  
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent(){
    this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
      mutation: poiBillingTranslationDelete.poiBillingTranslationDelete,
      variables: {
        'id':parseInt(this.id)
      }
    }).subscribe((result: any) => {
      if( result.data['poiBillingTranslationDelete']['success']){
        this.deletePoiBillingTrans.emit({ 
          'id':this.id,
        });
      }
     });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
     event.confirm.reject();
    }
  }

  Salva() {
 
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiBillingTranslationCreate.poiBillingTranslationCreate,
        variables: {
          data:{
            'poiBillingId':parseInt(this.poiBillingId),
            'languageId':this.languageId,
            'name':this.name,
            'description':this.description,
            'offlineOrderInfo':"",
            'onlineOrderInfo':""
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiBillingTranslationCreate']['success']){
          this.addPoiBillingTrans.emit({ 
            'poiBillingId':parseInt(this.poiBillingId),
            'languageId':parseInt(this.languageId),
            'name':this.name,
            'description':this.description,
            'id':result.data['poiBillingTranslationCreate']['id'],
          });
        }
       });
    } 
    Edit(item) {
      this.querySubscriptionProfileUser = this.apollo.use('super-admin').mutate({
        mutation: poiBillingTranslationUpdate.poiBillingTranslationUpdate,
        variables: {
          data:{
            'poiBillingId':parseInt(this.poiBillingId),
            'languageId': parseInt(this.languageId),
            'name':this.name,
            'id':parseInt(this.id),
            'description':this.description,
            'offlineOrderInfo':"",
            'onlineOrderInfo':""
           
          }
      },
      }).subscribe((result: any) => {
        if( result.data['poiBillingTranslationUpdate']['success']){
          this.editPoiBillingTrans.emit({ 
            'poiBillingId':parseInt(this.poiBillingId),
            'languageId':parseInt(this.languageId),
            'name':this.name,
            'description':this.description,
            'id':this.id,
          });
        }
       });
    } 
   }



