import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

import { QueryRef, Apollo } from "apollo-angular";
import { Subscription } from "rxjs/internal/Subscription";
import {
  newsCategoryTranslationCreate,
  newsCategoryTranslationDelete,
  newsCategoryTranslationUpdate,
  travelCategoryTranslationDelete,
  travelCategoryTranslationCreate,
  travelCategoryTranslationUpdate,
  poiTranslationUpdate,
  poiTranslationCreate,
  poiTranslationDelete,
  journeyTranslationCreate,
  journeyTranslationUpdate,
  journeyTranslationDelete,
  journeyAdminSelectedTranslationDelete,
  journeyAdminSelectedTranslationCreate,
  journeyAdminSelectedTranslationUpdate,
  journeyAdminSelectedGetFacilitations,
} from "../../../queryMutation";
import { NgxSpinnerService } from "ngx-spinner";

export interface List {
  key?: any;
  value: any[];
}

@Component({
  selector: "ngx-journey-admin-selected-open-modal-translation",
  templateUrl: "./journey-admin-selected-open-modal-translation.component.html",
  styleUrls: ["./journey-admin-selected-open-modal-translation.component.scss"],
})
export class JourneyAdminSelectedOpenModalTranslationComponent {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionFacilitations: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  dataProvince: any[];
  newsAllCategories: any[];
  newsCategoryTranslationGetAll: any[];
  public block: any;

  configEditor = {
      removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,About,Maximize,ShowBlocks,Source,Smiley,Flash,Replace,Find,SelectAll,Scayt,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField',
      extraPlugins: 'sourcedialog,sourcedialog,autoembed,embedsemantic,codesnippet,font,divarea,stylesheetparser',
      height: '320',
      enterMode: 0,
      htmlEncodeOutput: false,
      entities: false,
      basicEntities: false,
      contentsCss: [
      'http://cdn.ckeditor.com/4.13.0/full-all/contents.css',
      'https://ckeditor.com/docs/vendors/4.13.0/ckeditor/assets/css/classic.css'
      ]
  }

  @Input() title: string;
  @Input() categoryId: any;
  @Input() preview: string;
  @Input() translationName: string;
  @Input() translationId: any;
  @Input() isFromIt: boolean;
  @Input() languageId: any;
  @Input() description: string;
  @Input() facilitations: string;
  @Input() limitations: string;

  @Input() fac: any;
  @Input() lim: any;

  maxPreviewLength = 178

  @Output("addJourneyAdminTrans") addJourneyAdminTrans: EventEmitter<
    any
  > = new EventEmitter();

  @Output("editJourneyAdminTrans") editJourneyAdminTrans: EventEmitter<
    any
  > = new EventEmitter();
  @Output("deleteJourneyAdminTrans") deleteJourneyAdminTrans: EventEmitter<
    any
  > = new EventEmitter();
  constructor(
    protected ref: NbDialogRef<
      JourneyAdminSelectedOpenModalTranslationComponent
    >,
    public apollo: Apollo,
    public spinner: NgxSpinnerService
  ) {
    this.block = {
      lists: [
        {
          key: "Facilitazioni",
          value: [
            {
              key: "Servizi inclusi",
              value: [""],
            },
          ],
        },
        {
          key: "Limitazioni",
          value: [
            {
              key: "Cose importanti da sapere",
              value: [""],
            },
          ],
        },
      ],
    };
  }

  ngOnInit() {
    console.log("facilitations.ngOnInit", this.facilitations);
    if (this.facilitations !== null && this.facilitations !== "") {
      this.fac = JSON.parse(this.facilitations);
      if (this.fac.lists.length == 0)
        this.fac.lists.push({key: "", value: []})
      console.log("facilitations", this.facilitations);
      this.block = {
        lists: this.fac.lists,
      };

      // this.querySubscriptionFacilitations = this.apollo
      //   .query({
      //     query: journeyAdminSelectedGetFacilitations.getFacilitations,
      //     fetchPolicy: "no-cache",
      //     variables: {
      //       id: this.categoryId,
      //     },
      //   })
      //   .subscribe(
      //     (result: any) => {
      //       const data =
      //         result.data.journeyAdminSelectedGet
      //           .journeyAdminSelectedTranslationGetAllSub[0].facilitations;
      //       setTimeout(() => {
      //         if (this.facilitations === undefined) {
      //           this.fac = JSON.parse(data);
      //           this.block = JSON.parse(data);

      //           console.log("fac ->", this.fac, "block ->", this.block);
      //         } else {
      //           this.fac = JSON.parse(this.facilitations);
      //           this.lim = null;
      //         }
      //         this.lim = null;
      //       }, 2000);
      //     },
      //     (err) => {
      //       console.log(err);
      //       alert("An error occurred.Try again later.");
      //       setTimeout(() => {
      //         /** spinner ends after 5 seconds */
      //         this.spinner.hide();
      //       }, 2000);
      //     }
      //   );
      ///////////////

      console.log("facilitations ->", this.facilitations);
      console.log("block ->", this.block, "fac ->", this.fac);
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }
  trackByFunction(index: any, item: any) {
    return index;
  }
  trackByBloc(index: any, item: any) {
    return index;
  }
  trackByRow(index: any, item: any) {
    return index;
  }

  dismiss() {
    this.ref.close();
  }

  newsCategoryTranslationDelete() {
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation:
          journeyAdminSelectedTranslationDelete.journeyAdminSelectedTranslationDelete,
        variables: {
          id: this.translationId,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["journeyAdminSelectedTranslationDelete"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.deleteJourneyAdminTrans.emit({
                id: this.translationId,
              });
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
  }

  onDeleteConfirm(event): void {
    if (window.confirm("Conferma che voi eliminare questa Traduzione?")) {
      this.newsCategoryTranslationDelete();
    } else {
      event.confirm.reject();
    }
  }

  Salva() {

    if (!this.verifyLength())
      return

    var newBlock = {"lists": []}
    for (var item of this.block.lists) {
      if (item.value.length > 0) {
        var single = item.value[0]
        if (single.value.length > 0) {
          var single_2 = single.value[0]
          if (single_2.trim().length > 0) {
            console.log("Salva.item.IF.trim.IF", item)
            newBlock.lists.push(item)
          } else {
          console.log("Salva.item.IF.trim_.ELSE", item)
          }
        } else {
          console.log("Salva.item.IF.single.ELSE", item)
        }
      } else 
        console.log("Salva.item.ELSE", item)
    }
    console.log("Salva.item.newBlock", newBlock)
    this.block = newBlock

    var data = {
      journeyAdminSelectedId: parseInt(this.categoryId),
      languageId: this.languageId,
      name: this.translationName,
      description: this.description,
      facilitations: JSON.stringify(this.block),
      limitations: JSON.stringify(null),
      preview: this.preview
    }
    console.log("Salva", data)
    console.log(JSON.stringify(this.block));
    console.log(this.translationName, this.translationId, this.title);
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation:
          journeyAdminSelectedTranslationCreate.journeyAdminSelectedTranslationCreate,
        variables: {
          data: data,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["journeyAdminSelectedTranslationCreate"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.addJourneyAdminTrans.emit({
                journeyAdminSelectedId: parseInt(this.categoryId),
                languageId: parseInt(this.languageId),
                name: this.translationName,
                id: result.data["journeyAdminSelectedTranslationCreate"]["id"],
                description: this.description,
                facilitations: JSON.stringify(this.block),
                limitations: JSON.stringify(null),
                preview: this.preview,
              });
            }, 2000);
          }
        },
        (err) => {
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
    // setTimeout(() => location.reload(), 3000);
  }
  Edit() {
    
    if (!this.verifyLength())
      return

    var newBlock = {"lists": []}
    for (var item of this.block.lists) {
      if (item.value.length > 0) {
        var single = item.value[0]
        if (single.value.length > 0) {
          var single_2 = single.value[0]
          if (single_2.trim().length > 0) {
            console.log("Edit.item.IF.trim.IF", item)
            newBlock.lists.push(item)
          } else {
          console.log("Edit.item.IF.trim_.ELSE", item)
          }
        } else {
          console.log("Edit.item.IF.single.ELSE", item)
        }
      } else 
        console.log("Edit.item.ELSE", item)
    }
    console.log("Edit.item.newBlock", newBlock)
    this.block = newBlock

    var data = {
      journeyAdminSelectedId: parseInt(this.categoryId),
      name: this.translationName,
      id: this.translationId,
      description: this.description,
      facilitations: JSON.stringify(this.block),
      limitations: JSON.stringify(null),
      preview: this.preview
    }
    console.log("Edit", data)
    console.log(JSON.stringify(this.block));
    console.log(this.translationName, this.translationId, this.title);
    this.spinner.show(undefined, {
      type: "square-spin",
      size: "medium",
      bdColor: "rgba(100,149,237, .8)",
      color: "white",
      fullScreen: true,
    });
    this.querySubscriptionProfileUser = this.apollo
      .use("super-admin")
      .mutate({
        mutation:
          journeyAdminSelectedTranslationUpdate.journeyAdminSelectedTranslationUpdate,
        variables: {
          data: data,
        },
      })
      .subscribe(
        (result: any) => {
          if (result.data["journeyAdminSelectedTranslationUpdate"]["success"]) {
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
              this.editJourneyAdminTrans.emit({
                journeyAdminSelectedId: parseInt(this.categoryId),
                languageId: parseInt(this.languageId),
                name: this.translationName,
                id: parseInt(this.translationId),
                description: this.description,
                facilitations: JSON.stringify(this.block),
                limitations: JSON.stringify(null),
                preview: this.preview,
              });
            }, 2000);
          }
        },
        (err) => {
          console.log(err)
          alert("An error occurred.Try again later.");
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 2000);
        }
      );
    // setTimeout(() => location.reload(), 3000);
  }
  // Create new list
  addList(list) {
    const newList: List = {
      key: "",
      value: [""],
    };
    list.push(newList);
    console.log("Added new list to: ", list, this.block);
  }

  // Removes item from list
  removeList = (list, i) => {
    list.value.splice(i, 1);
    console.log(list, list.length);
  };

  // Create new input when hit enter on last input
  addItemToList(list, i) {
    const newListItem: string = "";
    if (i + 1 === list.value.length) {
      list.value.push(newListItem);
    }
    console.log(list, this.block);
  }

  // Removes item from list
  removeItemFromList = (list, i) => {
    list.value.splice(i, 1);
    console.log(list, list.length);
  };

  getLengthWithoutHtmlTags(html) {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    //console.log("getLengthWithoutHtmlTags", html, text)
    return text.length
  }

  verifyLength(){
    //console.log(this.model.preview.length);
    var length = this.getLengthWithoutHtmlTags(this.preview)
    if(length > this.maxPreviewLength){
      alert(`Per il campo Preview: Il numero di caratteri previsto non deve essere maggiore di ${this.maxPreviewLength}, attualmente è ${length}`)
      return false
    }
    return true
  }


  // Update block title
  updateBlockTitle = (event, block) => (block.key = event.target.value);

  // Update list title
  updateTitle = (event, list) => (list.key = event.target.value);

  // Update list value
  updateValue = (event, list, i) => (list.value[i] = event.target.value);
}
