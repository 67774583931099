import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { QueryRef, Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { newsCategoryTranslationCreate, newsCategoryTranslationDelete, newsCategoryTranslationUpdate, travelCategoryTranslationDelete, travelCategoryTranslationCreate, travelCategoryTranslationUpdate, seoCodeUpdate, seoCodeDelete, seoCodeCreate, municipalityGet, seoCodeGetByUniqueCode, seoCodeGet } from '../../../queryMutation';
import { CookieXSRFStrategy } from '@angular/http';
@Component({
  selector: 'ngx-open-modal-seo-code',
  templateUrl: './open-modal-seo-code.component.html',
  styleUrls: ['./open-modal-seo-code.component.scss']
})
export class OpenModalSeoCodeComponent {
  public querySubscriptionProfileUser: Subscription;
  public querySubscriptionAllProvince: Subscription;
  public querySubscriptionGetIdbyCode: Subscription;
  public querySubscriptionEditMunicipality: Subscription;
  public querySubscriptionCreateMunicipality: Subscription;
  public queryGetProvince: any;
  public queryGetNewsCategories: any;
  public queryGetNewsTranslationCategories: any;
  public businessPagination: QueryRef<any>;
  public newsCategoryPagination: QueryRef<any>;
  public newsCategoryTranslationPagination: QueryRef<any>;
  public dataProvince: any[];
  public newsAllCategories: any[];
  public newsCategoryTranslationGetAll: any[];
  public dataToRedirect: any;
  public isActiveToSend: boolean = false;

  @Input() title: string;
  @Input() seoCodeId: any;
  @Input() seoCodeName: any;
  @Input() languageId: any;
  @Input() newsId: any;
  @Input() redirectStatusCode: any;
  @Input() staticRedirectUrl: string;
  @Input() redirectToId: string;

  @Output("addNewsSeoCode")
  addNewsSeoCode: EventEmitter<any> = new EventEmitter();

  @Output("editNewsSeoCode")
  editNewsSeoCode: EventEmitter<any> = new EventEmitter();

  @Output("deleteNewsSeoCode")
  deleteNewsSeoCode: EventEmitter<any> = new EventEmitter();

  roleScopes: any
  private userTypeEnum = Object.freeze({"SUPER_ADMIN":"super-admin", "EDITOR":"editor", "MUNICIPALITY":"municipality"})
  private userType: string

  constructor(protected ref: NbDialogRef<OpenModalSeoCodeComponent>, public apollo: Apollo) {
    this.dataToRedirect = [];
    this.isActiveToSend = false;
    this.roleScopes = localStorage.getItem("roleScopes");
  }

  async ngOnInit() {

    if (this.roleScopes.indexOf("SUPER_ADMIN") !== -1) {
      this.userType = this.userTypeEnum.SUPER_ADMIN
    }
    else if (this.roleScopes.indexOf("EDITOR") !== -1) {
      this.userType = this.userTypeEnum.EDITOR
    }

    console.log("OpenModalSeoCodeComponent.ngOnInit", 
      "this.redirectToId:", 
      this.redirectToId !== null,
      this.redirectToId !== '',  
      typeof(this.redirectToId) === 'number'
    )

    if(this.redirectToId !== null && this.redirectToId !== '' &&  typeof(this.redirectToId) === 'number')
      await this.getRedirectId();
  }

  async getRedirectId() {
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGet.seoCodeGet,
      fetchPolicy: 'network-only',
      variables: {
        'id': parseInt(this.redirectToId)
      },
    })
      .valueChanges.subscribe((result: any) => {

        this.redirectToId = result.data.seoCodeGet.code;

      });

  }
  dismiss() {
    this.ref.close();
  }
  seoCodeDeleteEvent() {
    this.querySubscriptionProfileUser = this.apollo.use(this.userType).mutate({
      mutation: seoCodeDelete.seoCodeDelete,
      variables: {
        'id': parseInt(this.seoCodeId)
      }
    }).subscribe((result: any) => {
      if (result.data['seoCodeDelete']['success']) {
        this.deleteNewsSeoCode.emit({
          'id': parseInt(this.seoCodeId)
        });
      }
    }, (err) => {
      alert("An error occurred.Try again later.");

    });
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Vuoi eliminare questo Canonnical Url?')) {

      this.seoCodeDeleteEvent();
    } else {
      event.confirm.reject();
    }
  }

  Salva() {

    console.log("Salva",
      this.redirectToId , this.dataToRedirect
    )
    var data = {
      'newsId': parseInt(this.newsId),
      'languageId': parseInt(this.languageId),
      'code': this.seoCodeName,
      'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
      'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
      'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

    }
    console.log("seoCodeCreate", data)
    this.querySubscriptionProfileUser = this.apollo.use(this.userType).mutate({
      mutation: seoCodeCreate.seoCodeCreate,
      variables: {
        data: data
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeCreate']['success']) {
        this.addNewsSeoCode.emit({
          'newsId': parseInt(this.newsId),
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': result.data['seoCodeCreate']['id'],
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        });
      }
    }, (err) => {
      alert("An error occurred.Try again later.");

    })
  }
  Edit() {
    console.log("Edit", this.redirectToId, this.dataToRedirect)
    var data = {
      'newsId': parseInt(this.newsId),
      'languageId': parseInt(this.languageId),
      'code': this.seoCodeName,
      'id': parseInt(this.seoCodeId),
      'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
      'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
      'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

    }
    console.log("seoCodeUpdate", data)
    this.querySubscriptionProfileUser = this.apollo.use(this.userType).mutate({
      mutation: seoCodeUpdate.seoCodeUpdate,
      variables: {
        data: data
      },
    }).subscribe((result: any) => {
      if (result.data['seoCodeUpdate']['success']) {
        this.editNewsSeoCode.emit({
          'newsId': parseInt(this.newsId),
          'languageId': parseInt(this.languageId),
          'code': this.seoCodeName,
          'redirectStatusCode': (this.redirectStatusCode !== null && this.redirectStatusCode !== "") ? this.redirectStatusCode : null,
          'staticRedirectUrl': (this.staticRedirectUrl !== "") ? this.staticRedirectUrl : null,
          'id': parseInt(this.seoCodeId),
          'redirectToId': (this.redirectToId !== "" && this.redirectToId !== null) ? parseInt(this.dataToRedirect[0].id) : null

        });
      }
    }, (err) => {
      alert("An error occurred.Try again later.");

    })
  }


  getBySeoCode() {
    console.log("getBySeoCode")
    this.querySubscriptionGetIdbyCode = this.apollo.watchQuery<Response>({
      query: seoCodeGetByUniqueCode.seoCodeGetByUniqueCode,
      fetchPolicy: 'network-only',
      variables: {
        'code': this.redirectToId
      },
    }).valueChanges.subscribe((data: any) => {
      console.log("getBySeoCode.subscribe", data)
      if (data.data.seoCodeGetByUniqueCode !== null) {
        this.isActiveToSend = true;
        this.dataToRedirect.push(data.data.seoCodeGetByUniqueCode)
      }
      else {
        this.isActiveToSend = false;
        alert("No esiste seoCode, prova con altro.")
      }
    })

  }
}


